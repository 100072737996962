import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DishDescription from 'components/DishDescription';
import PlanCalendar from 'components/PlanCalendar';
import PlanMenu from 'components/PlanMenu';
import DailyMenu from 'components/DailyMenu';
import Order from './Order';
import Main from './Main'; 
import { setPlanParams, updatePlanParams } from 'store/planParams';
import { setCustomPlanParams, updateCustomPlanParams } from 'store/customPlanParams';
import { setMealPlan } from 'store/mealPlan';
import { setCustomMealPlan } from 'store/customMealPlan';
import { setModal } from 'store/modal';
import { userUpdate } from 'store/user';
import { browserHistory } from 'react-router';
import { compareObjects, parseDates, getArrays } from 'lib';
import PaperButton from 'react-paper-button';
import SelectValidation from 'components/SelectValidation';
import Cart from './Cart';
import { spin, stop } from 'store/spinner'; 
require('moment-weekday-calc'); 

const animateTime = 100;

class Plan extends Component {
  constructor(props) {
    super(props); 
    this.animateRun = false;
    const { planParams } = props;

    var showcart = false;
    if (props.user.user_id == 1860) {
      //973
      //1860
      showcart = true;
    }
  
    this.state = {
      showChildren: true,
      xyz: false,
      showOrder: false,
      modType: 'active',
      main: false,
      paused: false,
      order: false,
      showDescription: false,
      showOnMobileBlock: 'dishes',
      showToggler: true,
      ratings: false,
      skipped: planParams.skipped || {},
      pause: false,
      pause_start: null,
      pause_end: null,
      dietproducts: false,
      customdays: false,
      planParams: planParams,
      usersides: [],
      sidesList: [],
      buySidesList: [],
      oilList:[],
      showcart: showcart,
      cart: [],
      pauseList:false,
    };

    this.getRatings();
    this.getPause();
    this.sendEditRequest();
    if (showcart) {
      this.getCart();
      //this.addToCart(1440);
      //this.addToCart(1442);
    }

    
  }

  componentWillMount() {
    this.props.socket.on('order', this.listener);
    this.props.socket.on('profile', this.listener);
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
    this.props.socket.removeListener('profile', this.listener);
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  getSidesList = (order_id) => this.props.socket.emit('menu_builder', { type: 'getSidesList', data: { orderId: order_id } });
  getOilList = (order_id) => this.props.socket.emit('menu_builder', { type: 'getSidesList', data: { diet: 'oil' } });
  getBuySidesList = (order_id) => this.props.socket.emit('menu_builder', { type: 'getBuySidesList', data: { orderId: order_id } });

  getUserSides(order_id) { 
      const user_id = this.props.user.user_id;
      var data = {userId: user_id, orderId: order_id}
      this.props.socket.emit('order', { type: 'getUserSides', data: data });
  }

  getPlanParams() {
    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'get' } });
  }

  getDietProducts(order_id) {
    this.props.socket.emit('order', { type: 'getDietProducts', data: {orderId: order_id} })
  }

  getCustomDays(order_id) {
    this.props.socket.emit('order', { type: 'getCustomDays', data: {orderId: order_id}})
  }

  getRatings() {
    this.props.socket.emit('order', { type: 'getRatings', data: {userId: this.props.user.user_id} })
  }

  getCart() {
    this.props.socket.emit('order', { type: 'getCart', data: {userId: this.props.user.user_id} });
  }

  getPause() {
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getPause', 
          'userId': this.props.user.user_id,
        } 
    });
  }

  listener = ({ type, data }) => {
    if (this.PlanModRef) {
      const { message, mealPlan, planParams, itWasFuture, url } = data;
      switch (type) {
        case 'getDietProductsOk':
          this.setState({
            dietproducts: data,
          });
        break;
        case 'getCartOk':
          this.setState({
            cart: data, 
          });
        break;
        case 'addToCartOk':
        case 'removeFromCartOk':
          this.getCart();
        break;
        case 'getUserSidesOk':
          this.setState({
            usersides: data,
          });
        break;
        case 'getBuySidesListOk':
          this.setState({
            buySidesList: data,
          });
        break;
        case 'saveUserSidesOk':
          this.getUserSides(data.orderId);
        break;
        case 'getSidesListOk':
          this.setState({
            sidesList: data,
          });
        break;
        case 'getOilListOk':
          this.setState({
            oilList: data,
          });
        break;
        case 'getCustomDaysOk':
          this.props.setCustomMealPlan(data);
          this.props.setCustomPlanParams(planParams);
          this.setState({
            customdays: data,
          });
        break;
        case 'skipTheDayOk':
          this.props.stop();
          this.getOrder();
        break;
        case 'getOk':
          this.props.setMealPlan(mealPlan);
          this.props.setPlanParams(planParams);
          this.getDietProducts(planParams.id);
          this.getCustomDays(planParams.id);
          const { dietIds: diet_id, mealType: mealTypes, date } = planParams || {};
          this.onChange(moment.unix(date).utc());
          if (diet_id && mealTypes) {
            this.props.socket.emit('custom_plan', { type: 'get', data: { diet_id, mealTypes } });
            this.getDefaultDishes(diet_id, mealTypes);
          }
          break;
        case 'exportPdfOk':
          this.props.stop();
          this.props.setModal({ headLine: '  ', message: (
            <div className='viewmenupdf'>
              <p align="center">
                  <PaperButton className='button-regular next transition'
                    onClick={ (e) => {
                      window.open(url, '_blank');
                    }}
                  >
                  <span className="">View my menu in PDF</span>
                  </PaperButton>
              </p>
            </div>)}); 
        break;
        case 'mailPdfOk':
          this.props.stop();
          this.props.setModal({ headLine: 'Mail PDF', message });
        break;
        case 'getRatingsOk':
          this.setState({
            ratings: data.ratings
          });
        break;
        case 'getPauseOk':
          this.setState({
            pause: data.isPause,
            pause_start: data.startDate,
            pause_end: data.endDate,
            pauseList:data.pauseList
          });
          
        break;
        case 'setCutleryOk':
          this.props.setModal({ headLine: 'Cutlery Update', message: (
            <div>
              <p>Cutlery value set well</p>
            </div>)
          });        
        break;
        case 'modifyOk':
          //this.props.setModal({ message, closeRedirect: '/' });
          break;
        case 'custommodifyOk':
          break;
        case 'planRequestOk':
          const {clearModal, setModal} = this.props;
          clearModal();
          setModal({ headLine: 'Request Sent', message: (
            <div>
              <p>Thank you! Our team will be in touch with you.</p>
            </div>)
          });                          
        break;
        case 'cancelOk':
          if (itWasFuture) {
            this.props.userUpdate({ renewPlan: true, canBeRenewed: true, alreadyRenewed: false });
          } else {
            this.props.userUpdate({ hasPlan: false });
          }
          this.props.setModal({ message, closeRedirect: '/' });
          break;
        case 'exportPdfErr':
          case 'mailPdfErr':
            this.props.stop();
            this.props.setModal({ message });
          break;  
        case `${type}Err`:
          this.props.setModal({ message });
          break;
      }
    }
  };

  onChange = (date,index) => {
    this.date = date;
    if (this.ModifyMainRef) {
      this.ModifyMainRef.setState({ date });
      this.ModifyMainRef.MainRef.scrollTop = 0;
      if (this.ModifyMainRef.datelistRef && this.ModifyMainRef.datelistRef.children[index]) {
        this.ModifyMainRef.datelistRef.children[index].scrollIntoView({ behavior: 'smooth', block: "end", inline: "start" });
      }
    }
    if (this.ModifyOrderRef) {
      this.ModifyOrderRef.getWrappedInstance().setState({ date });
    }
    if (this.ModifyCalendarRef) {
      this.ModifyCalendarRef.setState({ date });
    }
  };

  hideToggler = (hide) => {
    this.setState({ showToggler: hide });
  };

  prepareData = (planParams, detailsData, updateData, updateDate, mealType) => {
    const { id: orderId, deliverySlot, cutlery, skipped } = planParams;
    return {
      orderId,
      deliverySlot,
      detailsData,
      skipped,
      cutlery,
      updateData,
      updateDate,
      mealType
    };
  };

  placeOrder = (cutlery='no',unix,mealType) => {
    const { mealPlan } = this.props;
    var {planParams} = this.props;
    if (cutlery !== 'no') {
      planParams.cutlery = cutlery;
    }
    const updatedData = mealPlan[unix];
    const updateMealId = updatedData[mealType];
    const data = this.prepareData(planParams, mealPlan,updateMealId, unix,mealType);

    this.props.socket.emit('order', { type: 'modify', data });
  };
  updateCutlery = (cutlery='no') =>{
    var {planParams} = this.props;
    if (cutlery !== 'no') {
      planParams.cutlery = cutlery;
    }
    //const data = this.prepareData(planParams, mealPlan );
    this.props.socket.emit('order', { type: 'setCutlery', data:{ orderId:planParams.id,cutlery:planParams.cutlery} });
  }
  customPlaceOrder = (cutlery='no')=>{
    //debugger;
    // const { customdays } = this.state;
    const { customMealPlan } = this.props;
    var {planParams} = this.props;
     if (cutlery !== 'no') {
       planParams.cutlery = cutlery;
     }
     planParams.customMealPlan = true;
     const data = this.prepareData(planParams, customMealPlan);
    this.props.socket.emit('order', { type: 'custommodify', data});
  };
  cancelOrder = makeCoupon => {
    const { id: orderId } = this.props.planParams;
    const data = { orderId, makeCoupon };
    this.props.socket.emit('order', { type: 'cancel', data });
  };

  pauseOrder = date => {
    this.onChange(date);
    this.setState({ paused: true });
  };

  cancelPause = () => {
    const date = moment().locale(this.props.locale).utc().add(3, 'days').startOf('week');
    this.setState({ paused: false });
    this.onChange(date);
    this.getOrder();
  };

  goMenu = (type, val) => {
    if (!this.animateRun) {
      this.animateRun = true;
      if (this.state.showChildren) {
        setTimeout(
          () => {
            if (this.state[type] !== val) {
              let state = { showChildren: true };
              if (!this.state[type]) {
                state = { ...state, showOrder: false, showDescription: false };
              }
              this.setState({ ...state, [type]: val });
              setTimeout(() => {
                this.animateRun = false;
              }, animateTime);
            } else {
              this.setState({ showOrder: false, showDescription: false, showChildren: true });
              this.animateRun = false;
            }
            this.hideToggler(false);
          },
          animateTime
        );
      }
      this.setState({ showChildren: false });
    }
  };

  hide = type => {
    if (!this.animateRun) {
      this.setState({ showChildren: false });
      setTimeout(
        () => {
          this.setState({ [type]: false });
          this.setState({ showChildren: true });
        },
        animateTime
      );
    }
  };

  showOnMobileBlockToggler = (block) => {
    if (window.innerWidth <= 768 || screen.width <= 768) {
      this.setState({ prevOnMobileBlock: this.state.showOnMobileBlock });
      this.setState({ showOnMobileBlock: block });
      document.querySelector('body').scrollIntoView();
    }
  };

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
    const { socket, dataArray, planParams } = this.props;
    const types = ['typeList', 'dietList', 'products', 'ingredientList', 'slotsList', 'planList', 'dishLabels'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    this.getOrder();
    if (typeof(planParams.id) != 'undefined') {
      this.getDietProducts(planParams.id);
      this.getCustomDays(planParams.id);
      this.getUserSides(planParams.id);
      this.getSidesList(planParams.id);
      this.getBuySidesList(planParams.id);
      this.getOilList(planParams.id);
    } else {
      this.getPlanParams();
    }
  }

  getOrder = (orderType = 'active') => this.props.socket.emit('order', { type: 'get', data: { orderType ,user_id:this.props.user.user_id, hidePause:true } });

  componentWillReceiveProps(next) {
    if (this.PlanModRef) {
      let state = null;
      if (next.planParams && next.planParams.skipped && !compareObjects(next.planParams.skipped, this.state.skipped)) {
        state = { skipped: next.planParams.skipped };
      }
      if (!compareObjects(next.mealPlan, this.props.mealPlan)) {
        state = state || {};
      }
      state && this.setState(state);
    }
  }

  skipTheDay = (date, new_date) => {
    const {planParams} = this.props;
    var planId =  planParams.id; 
    this.props.spin();
    this.changeCustomMealSkipped(date, new_date);
    this.props.socket.emit('order', { type: 'skipTheDay', data: { skip_date: date, new_date: new_date, planId: planId }});
  }
  changeCustomMealSkipped = (date, newDateStamp) => {
     var { customdays }  = this.state;
    delete customdays[date];
    this.setState({
      customdays: customdays
    })

  }

  changeSkipped = (date, newDateStamp) => {
    const { planParams, dataArray, updatePlanParams, setMealPlan, defaultDishes, settings, customdays } = this.props;
    const excludedDates = parseDates(settings.getValue('HOLIDAYS'));
    const { planList } = { ...dataArray };
    const plan = (planList && planParams['mealPlan']) && planList[planParams['mealPlan']];
    const excludedDays =(planParams['exclude_week_day']!=undefined && planParams['exclude_week_day'])?planParams['exclude_week_day']: (plan || {}).excludeWeekDay || [];
    const includesDays = Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(el));
    let mealPlan = { ...this.props.mealPlan };
    let skipped = { ...planParams.skipped };
    if (date in skipped) {
      delete mealPlan[skipped[date]];
      setMealPlan(mealPlan);
      delete skipped[date];
    } else {
      const newDate = moment.unix(newDateStamp).subtract(1, 'd');
      const realDate = newDate.addWeekdaysFromSet(1, includesDays, excludedDates);
      const realDoM = realDate.date();
      const realDateStamp = realDate.unix();
      const { mealType: types } = planParams;
      const defaultData = types.reduce((acc, type) => {
        const defDish = (defaultDishes[type] || {})[realDoM];
        return defDish ? { ...acc, [type]: +defDish } : acc;
      }, {});
      mealPlan = { ...mealPlan, [realDateStamp]: defaultData };
      skipped = { ...skipped, [date]: realDateStamp };
    }
    setMealPlan(mealPlan);
    updatePlanParams({ skipped, modified: !!Object.keys(skipped) });
  };

  getDefaultDishes = (dietId, types) => {
    this.props.socket.emit('default_dishes', { type: 'get', data: { dietId, types } });
  };
  sendEditRequest = () => {
    const { planParams } = this.props;
    this.props.socket.emit('order', { type: 'sendEditRequest', data:{ orderId:planParams.id,userId:this.props.user.user_id} });
  }
  showCalendlyModal =()=> {
    var message=<div><iframe allowfullscreen="allowfullscreen" height="500" width="100%"  allow="autoplay; fullscreen" src="https://calendly.com/team-basiligo" scrolling="auto"></iframe></div>;
    this.props.setModal({ headLine:' ',message });
  }

  sentRenewRequest = () =>{
    const { user }= this.props;
    this.props.setModal({ headLine: 'Renew Plan', message: (
      <div>
        <p>Send Renew Plan Request</p>
        <p align="center"><PaperButton
          onClick={e => {
            var data = {
              userId: user.user_id, 
              email: user.email, 
              firstName: user.firstName, 
              lastName: user.lastName, 
              'phone': user.phone 
            };
            // this.setNotification(new_mealplan_text, 2);
            this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
          }}
          className='button-regular next transition'
        >
          <span>Send Request</span>
        </PaperButton></p>
      </div>
    )});
  }
  render() {
    const { showChildren, showOrder, showDescription, main, order, xyz, skipped, paused, showOnMobileBlock, showToggler, modType, ratings, customdays, usersides, sidesList, buySidesList, oilList, showcart, cart } = this.state;
    const { dataArray, planParams, locale, mealPlan, settings, updatePlanParams, renewPlan, alreadyRenewed, user,customMealPlan } = this.props;
    const excludedDates = parseDates(settings.getValue('HOLIDAYS'));
    const { slot_id, cutlery } = planParams || {};
    const changePlanParams = (name, value) => {
      if (name === 'deliverySlot' && !+value) {
        return null;
      }
      updatePlanParams({ [name]: +value, modified: true });
      if (name == 'cutlery') {
        // this.placeOrder(value);
        this.updateCutlery(value);
      }
    };
    const { dietChanges,exclude_week_day,days_count } = planParams;
    const { products, planList, typeList, ingredientList, slotsList, dietList } = dataArray;
    const deliveryItems = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const plan = (planList && planParams['mealPlan']) && planList[planParams['mealPlan']];
    const excludeWeekDay = (exclude_week_day!=undefined && exclude_week_day)?exclude_week_day:(plan || { excludeWeekDay: [] }).excludeWeekDay;
    const planDates = Object.keys(mealPlan);
    const firstDate = Math.min(...planDates);
    const lastDate = Math.max(...planDates, ...(skipped || {}));
    const date = paused ? moment.unix(firstDate) : moment.unix(planParams['date']).locale(locale).utc().startOf('day');
    const enddate =moment.unix(planParams['end_date']).locale(locale).utc().startOf('day');
    const planName = (dietList && planParams['diet']) && dietList[planParams['diet']];
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getExcludeWeek =()=>(exclude_week_day!=undefined && exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const planTitle =((exclude_week_day!=undefined && days_count<=0)?plan && plan.title:days_count+' Days - '+getExcludeWeek());
    // const planTitle = plan && plan.title;
    const startOfCurrentWeek = moment().locale(locale).utc().add(3, 'days').startOf('week');
    this.date = startOfCurrentWeek.isBefore(date) ? date.clone().startOf('week') : moment().utc().startOf('week');
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excludeWeekDay.includes(el));
    const originTo = plan && date.clone().addWeekdaysFromSet(plan.count, includesDay, excludedDates).startOf('day');
    const modified = plan && mealPlan && originTo.isSameOrBefore(moment.unix(Object.keys(mealPlan || {}).reverse()[0]).utc().startOf('day'));
    const period = plan && mealPlan && !paused && !modified ? {
      from: date,
      // to: date.clone().addWeekdaysFromSet(((days_count>0)?+days_count:+plan.count) + Object.keys(skipped || {}).length, includesDay, excludedDates).startOf('day')
      // to:enddate.clone().add(1, 'days')
      to: moment.unix(lastDate).utc().addWeekdaysFromSet(1, includesDay, excludedDates)
    } : (paused || modified ? {
      from: moment.unix(firstDate).utc(),
      to: moment.unix(lastDate).utc().addWeekdaysFromSet(1, includesDay, excludedDates)
    } : false);
    let isMob = false;
    if (window.innerWidth <= 768 || screen.width <= 768) {
      isMob = true;
    }
    const { showOnMobileBlockToggler } = this;
    const wrapOneDay = showOrder || (showDescription && products[showDescription]) ? 'wrap-one-day' : '';
    const toShow = (showOnMobileBlock === 'dishes');
    const {dietproducts} = this.state;
    var buffered = false;
    var daydiff =0;
    if(period && period.to!=undefined){
      const today = moment().startOf('day');
      const cdate = moment.unix(period.to);
      buffered = cdate.isBetween(today, today.clone().add(5, 'day'), '[)'); // Check 5 days for renew
      daydiff = cdate.diff(today,'days');
    }
    
    return (
      <Fragment>
        {isMob && !showOrder && !showDescription && <div className={'row togle-dishes-menu hidden-on-desktop'}>
          <div className='col-2 col-sm-2 btnb text-center'>
          <p className={'regular-button'} onClick={() => browserHistory.push('/menu/preferences')}><i aria-hidden="true" class="fa fa-arrow-left"></i></p>
          </div>
          <div className='col-8 col-sm-8 btnb text-center bk-con-btn'>
             {/*<p className={`button-regular book-btn transition mt-auto mb-4 w-90`}
              onClick={() => this.showCalendlyModal()}
            >Book a consultation</p> */}
            {buffered && <p className={`button-regular book-btn transition mt-auto mb-4 w-90`}
              onClick={() => this.sentRenewRequest()}
            >Renew My Plan({daydiff} days left)</p>}
          </div>
            <div className='col-2 col-sm-2 btnb text-center backtotop'>
            <p className={`regular-button ${showOnMobileBlock === 'dishes' ? 'next' : ''}`}
              onClick={() => this.showOnMobileBlockToggler('dishes')}
            ><i class="fa fa-chevron-up" aria-hidden="true"></i></p>
          </div>
          {/* <div className='col-4 col-sm-4 btnb text-center'>
            <p className={`regular-button ${showOnMobileBlock === 'menu' ? 'next' : ''}`}
              onClick={() => this.showOnMobileBlockToggler('menu')}
            >Order</p>
          </div> */}
        </div>}
        <div className={`height-papa main-holder modify-page ${xyz ? 'xyz-fin' : 'xyz'} ${wrapOneDay}`} ref={el => (this.PlanModRef = el)}>
          <div className='w-100 main-inner flex-grow p-0'>
            <div className={'d-flex w-100 flex-column h-100 mx-0'}>
              
              <div className={'h-100 flex-grow px-0'}> 

                {period && <div className={'row h-100 mx-0'}>
                  
                  {main && !isMob && order && <PlanMenu
                    onChange={this.onChange}
                    bottomButtonClick={() => browserHistory.push('/')}
                    dayRenderer={'modify'}
                    locale={locale}
                    date={this.date}
                    period={period}
                    skipped={skipped}
                    excludeWeekDay={excludeWeekDay}
                    ref={el => (this.ModifyCalendarRef = el)}
                    mealPlan={mealPlan}
                    mealType={planParams.mealType}
                    socket={this.props.socket}
                    spin={this.props.spin}
                    stop={this.props.stop}
                    showCalendlyModal={this.showCalendlyModal}
                    sentRenewRequest={this.sentRenewRequest}
                    isModify
                    pause={this.state.pause}
                    pause_start={this.state.pause_start}
                    pause_end={this.state.pause_end}
                  >
                    <div className='w-90 mb-4 mr-auto ml-auto select-delivery'>
                      <span className='req'>Delivery time:</span>
                      <SelectValidation
                        containerClassName='pointer position-relative'
                        className='styled-select b-rounded-gray '
                        selectClassName='letter-xs pointer'
                        emptyValue='Select delivery time window'
                        list={deliveryItems}
                        onChange={changePlanParams}
                        value={slot_id}
                        name='deliverySlot'
                        disabled={true}
                      />
                      <span className='req'>I need cutlery:</span>
                      <SelectValidation
                        containerClassName='pointer position-relative'
                        className='styled-select b-rounded-gray '
                        selectClassName='letter-xs pointer'
                        emptyValue='Choose if you need cutlery'
                        list={{ 0: 'No', 1: 'Yes' }}
                        onChange={changePlanParams}
                        value={cutlery}
                        name='cutlery' 
                      />
                    </div> 
                  </PlanMenu>}
 
                  {toShow && <Main 
                    dietproducts={dietproducts}
                    customdays={customdays}
                    usersides={usersides}
                    sidesList={sidesList}
                    buySidesList={buySidesList}
                    oilList={oilList}
                    pause={this.state.pause}
                    pause_start={this.state.pause_start}
                    pause_end={this.state.pause_end}
                    pauseList={this.state.pauseList}
                    ref={el => (this.ModifyMainRef = el)}
                    goMenu={this.goMenu}
                    onChangeDate={this.onChange}
                    changeSkipped={this.changeSkipped}
                    skipTheDay={this.skipTheDay}
                    skipped={skipped}
                    originTo={originTo}
                    mealPlan={mealPlan}
                    customMealPlan={customMealPlan}
                    dietChanges={dietChanges}
                    diet={planParams['diet']}
                    dietList={dietList}
                    hideToggler={this.hideToggler}
                    showOnMobile={showOnMobileBlock}
                    onReady={() => this.setState({ main: true })}
                    date={this.date}
                    period={period}
                    isMob={isMob}
                    excludeWeekDay={excludeWeekDay}
                    planName={planName}
                    planTitle={planTitle}
                    showSwitcher={renewPlan && alreadyRenewed}
                    curModType={modType}
                    placeOrder={this.placeOrder}
                    customPlaceOrder={this.customPlaceOrder}
                    ratings={ratings}
                    user={user}
                    switchModType={modType => {
                      this.setState({ modType });
                      this.getOrder(modType);
                    }}
                  />}
                  <div className={`list chat-holder-part p-0 h-100 ${showChildren ? 'active-part' : ''} ${showOnMobileBlock === 'menu' ? 'position-front' : 'position-hidden'}`}>
                    <div className='d-flex flex-column mobile justify-content-start h-100 w-100'>
                      <div className={`custom-scrollbar inner-plan-details mobile h-100 d-flex flex-column ${wrapOneDay}`}>
                        {!showOrder && !showDescription && <Order
                          ref={el => (this.ModifyOrderRef = el)}
                          goMenu={this.goMenu}
                          date={this.date}
                          onChangeDate={this.onChange}
                          placeOrder={this.placeOrder}
                          customPlaceOrder={this.customPlaceOrder}
                          cancelOrder={this.cancelOrder}
                          pauseOrder={this.pauseOrder}
                          skipped={skipped}
                          originTo={originTo}
                          onReady={() => this.setState({ order: true })}
                          excludeWeekDay={excludeWeekDay}
                          excludedDates={excludedDates}
                          period={period}
                          paused={paused}
                          cancelPause={this.cancelPause}
                          showOnMobileBlock={showOnMobileBlock}
                          showToggler={showToggler}
                          curModType={modType}
                          showSwitcher={renewPlan && alreadyRenewed}
                          switchModType={modType => {
                            this.setState({ modType });
                            this.getOrder(modType);
                          }}
                          showOnMobileBlockToggler={this.showOnMobileBlockToggler}
                          bottomButtonClick={() => browserHistory.push('/')}
                          {...{ showOnMobileBlockToggler }}
                        />}
                        {showOrder && <DailyMenu
                          products={products}
                          typeList={typeList}
                          ingredientList={ingredientList}
                          dishes={mealPlan[showOrder]}
                          showOnMobileBlockToggler={this.showOnMobileBlockToggler}
                          itemClick={productId => this.goMenu('showDescription', productId)}
                        >
                          <div className='row m-0 p-0'>
                            <div className='back col-3 p-0 m-0' onClick={() => {
                              this.hide('showOrder');
                              this.hideToggler(true);
                            }}><span>←</span>Back
                            </div>
                          </div>
                          <h2> Menu for: <span className='green date'>{moment.unix(showOrder).format('dddd, Do MMM')}</span></h2>
                        </DailyMenu>}
                        {(showDescription && products[showDescription]) && <DishDescription
                          product={{ ...products[showDescription], id: showDescription }}
                          ingredients={dataArray['ingredientList'] || {}}
                          ratings={ratings}
                          onClick={() => {
                            this.hide('showDescription');
                            this.hideToggler(true);
                          }}
                        />}

                        {showcart && <Cart cart={cart} typeList={typeList} products={products} user={this.props.user.user_id} socket={this.props.socket}/>}
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
    
  }
}

Plan.propTypes = {
  socket: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  customMealPlan: PropTypes.object.isRequired,
  defaultDishes: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setCustomMealPlan: PropTypes.func.isRequired,
  setCustomPlanParams: PropTypes.func.isRequired,
  updateCustomPlanParams: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  updatePlanParams: PropTypes.func.isRequired,
  userUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  renewPlan: PropTypes.bool,
  alreadyRenewed: PropTypes.bool,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const props = state => {
  const { language, renewPlan, alreadyRenewed } = state.user;
  return {
    user: state.user,
    dataArray: state.dataArray,
    planParams: state.planParams,
    mealPlan: state.mealPlan,
    customMealPlan: state.customMealPlan,
    defaultDishes: state.defaultDishes,
    locale: (language || 'en-US').substr(-2),
    settings: state.settings,
    renewPlan,
    alreadyRenewed
  };
};

const actions = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  userUpdate: obj => dispatch(userUpdate(obj)),
  setMealPlan: obj => dispatch(setMealPlan(obj)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  updatePlanParams: obj => dispatch(updatePlanParams(obj)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  setCustomMealPlan: obj => dispatch(setCustomMealPlan(obj)),
  setCustomPlanParams: obj => dispatch(setCustomPlanParams(obj)),
  updateCustomPlanParams: obj => dispatch(updateCustomPlanParams(obj)),
});

export default socketConnect(connect(props, actions)(Plan));
