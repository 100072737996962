import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { needCloseButtons, buttons, needButtons, headLine, message, type, close } = this.props;
    const { xyz } = this.state;
    const listButtons = buttons.map((button, key) =>
      <div className={`d-flex justify-content-center mb-3 transition ${button.className}`} key={key}>
        <input type='button' className={`modal-button ${button.type}`} value={button.label} onClick={() => button.onClick(button.value)} />
      </div>
    );
    const modalHeadLineOuther = (headLine) =>
      <div className='row modal-header-styled'>
        <div className='col-12 title-block'>
          <p className={'w-100'}><span className='ar-golr-l' />
            <span className='m-title'>{headLine}</span>
            <span className='ar-golr-r' /></p>
        </div>
      </div>;
    const modalMessageOuther = (message) =>
      <div className='row'>
        <div className='col-12'>
          {message}
        </div>
      </div>;
    return (
      <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-back-layer ttt`}>
        <div className={`row justify-content-around align-items-center modal-father model-type-${type}`} onClick={() => close()}>
          <div className='modal-custom-body b-rounded-blue personal-shadow col-sm-10 col-md-6 col-lg-4 px-0' onClick={e => e.stopPropagation()}>
            {needCloseButtons && <div className='d-flex justify-content-end align-items-center px-3 close'>
              <i onClick={() => close()} className='icon-close letter-normal mt-2 pt-1 mb-2 pointer' />
            </div>}
            <div className='text-center modal-message pop-style dark-text w-100 letter-sm px-3'>
              {modalHeadLineOuther(headLine || 'Meal Plan')}
              {modalMessageOuther(message)}
            </div>
            {needButtons &&
            <div className='row'>
              {listButtons}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  close: PropTypes.func.isRequired,
  headLine: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'success',
    'info'
  ]),
  needButtons: PropTypes.bool,
  needCloseButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf([
      'error',
      'success',
      'close',
      'info'
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string
  }))
};

Modal.defaultProps = {
  type: 'success',
  needButtons: false,
  needCloseButtons: true,
  buttons: []
};

export default Modal;
