export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';

class SettingsItem {
  constructor(alias, itemData = {}) {
    this._alias = alias;
    this._data = itemData;
  }

  get alias() {
    return this._alias;
  }

  get value() {
    return this._data['value'];
  }

  get old() {
    return this._data['old_value'];
  }

  get data() {
    return this._data;
  }

  update(itemData) {
    this._data = { ...this._data, ...itemData };
  }
}

class SettingsCollection {
  constructor(items = {}) {
    this._settings = {};
    for (const alias in items) {
      this._settings[alias] = new SettingsItem(alias, items[alias]);
    }
  }

  update(items) {
    for (const alias in items) {
      this._settings[alias].update(items[alias]);
    }
    return this;
  }

  getValue(alias) {
    const instance = this._settings[alias];
    return instance ? instance.value : '';
  }
}

const initialState = new SettingsCollection();

export function setSettings(obj) {
  return {
    type    : SET_SETTINGS,
    payload : obj
  };
}

export function updateSettings(obj) {
  return {
    type    : UPDATE_SETTINGS,
    payload : obj
  };
}

export function clearSettings() {
  return {
    type    : CLEAR_SETTINGS
  };
}

export default function settingsReducer(state = initialState, action) {
  if (action.type === SET_SETTINGS) {
    return new SettingsCollection(action.payload);
  }
  if (action.type === UPDATE_SETTINGS) {
    return state.update(action.payload);
  }
  if (action.type === CLEAR_SETTINGS) {
    return initialState;
  }
  return state;
}
