export const SET_REMAIN_DELIVERIES = 'SET_REMAIN_DELIVERIES';
export const APPEND_REMAIN_DELIVERIES = 'APPEND_REMAIN_DELIVERIES';
export const CLEAR_REMAIN_DELIVERIES = 'CLEAR_REMAIN_DELIVERIES';

const initialState = [];

export function setRemainDeliveries(arr) {
  return {
    type    : SET_REMAIN_DELIVERIES,
    payload : arr
  };
}

export function appendRemainDeliveries(date, arr) {
  return {
    type    : APPEND_REMAIN_DELIVERIES,
    payload : arr
  };
}

export function clearRemainDeliveries() {
  return {
    type    : CLEAR_REMAIN_DELIVERIES
  };
}

export default function remainDeliveriesReducer(state = initialState, action) {
  if (action.type === SET_REMAIN_DELIVERIES) {
    return action.payload;
  }
  if (action.type === APPEND_REMAIN_DELIVERIES) {
    return [...state, ...action.payload];
  }
  if (action.type === CLEAR_REMAIN_DELIVERIES) {
    return initialState;
  }
  return state;
}
