import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getDataArray } from 'store/dataArray';
import { Link, browserHistory } from 'react-router';
import noImage from 'assets/no_img.svg';

class Article extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      xyz: false,
      postData: {}
    };
    this.getPostData(params.id);
  }

  getPostData = postId => {
    this.props.socket.emit('blog', {
      type: 'get',
      data: {
        postId,
        getNeighbours: true
      }
    });
  };

  listener = action => {
    if (this.Article) {
      switch (action.type) {
        case 'getOk':
          this.setState({ ...action.data });
          break;
        case 'getErr':
          console.error(action.data);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('blog', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('blog', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.Article && this.props.params.id !== next.params.id) {
      this.getPostData(next.params.id);
    }
  }

  componentDidMount() {
    if (this.Article) {
      const { blogCategories, socket } = this.props;
      if (socket && !Object.keys(blogCategories).length) {
        getDataArray({ keys: ['blogCategories'], socket });
      }
      setTimeout(() => this.setState({ xyz: true }), 300);
    }
  }

  goToPost = (post) => {
    if (post) {
      browserHistory.push(`/nutrition-support/blog/article/${post}`);
    }
  };

  renderCardContent = ({ id, image, title, tags }, className, photosPathMin) => {
    return (
      <Fragment>
        <Link to={`/nutrition-support/blog/article/${id}`} className={`transition ${className}`}>
          <div className='row next-prev-article'>
            {className === 'prev-class' &&
              <div className='image-bg col-3'
                style={{
                  backgroundImage: image ? `url(${photosPathMin}${image})` : noImage
                }}
              />
            }
            <div className='word-content col-9'>
              <h2 className='article-title'>{title}</h2>
            </div>
            {className === 'next-class' &&
              <div className='image-bg col-3'
                style={{
                  backgroundImage: image ? `url(${photosPathMin}${image})` : noImage
                }}
              />
            }
          </div>
        </Link>
      </Fragment>

    );
  };

  render() {
    const { xyz, postData } = this.state;
    const { title, blogCategoryId, image, prevPost, nextPost } = postData;
    let text = '';
    try {
      text = decodeURI(atob(postData.text));
    } catch (e) {
      text = postData.text;
    }
    const { blogCategories, settings } = this.props;
    const photosPath = settings.getValue('PHOTOS_PATH');
    const photosPathMin = settings.getValue('PHOTOS_PATH_MIN');
    const categoryName = (blogCategories && Object.keys(blogCategories).length) ? blogCategories[blogCategoryId] : '';
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.Article = el)}>
        <div className='container-fluid bg-gray-blog height-papa blog-page-post vertical-scrollbar'>
          <div className='row title-holder blog-heading single' style={{ backgroundImage: `url(${image ? photosPath.concat(image) : noImage})` }}>
            <div className='col-12  d-flex flex-column justify-content-center title-block blog'>
              <h1>{title}</h1>
              <p>
                <span className='ar-golr-l' />
                <span>{categoryName}</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='article-wrapper single-blog-wrapper row support-holder '>
            <div className='col-12'>
              <div className='row sticky-back'>
                <div className='all-my p-0'>
                  <div className='blog-filters p-0'>
                    <div className='back-holder'>
                      <Link to='/nutrition-support/blog' className='back-link'>
                        <span className='back'>&larr;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row blog-border'>
                <div className='col-12 single-blog-page'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className={'article-body'} dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    <div className='row d-none'>
                      <div className='col-6'>
                        <div className={`week-switch ${prevPost ? '' : 'disabled'}`} onClick={() => this.goToPost(prevPost)}><span>←</span>Prev post</div>
                      </div>
                      <div className='col-6 text-right'>
                        <div className={`week-switch last ${nextPost ? '' : 'disabled'}`} onClick={() => this.goToPost(nextPost)}>Next post<span>→</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 bg-and-sh'>
                  {prevPost && this.renderCardContent(prevPost, 'prev-class', photosPathMin)}
                </div>
                <div className='col-6 bg-and-sh'>
                  {nextPost && this.renderCardContent(nextPost, 'next-class', photosPathMin)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Article.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  settings: PropTypes.object,
  blogCategories: PropTypes.object
};

Article.defaultProps = {
  blogCategories: {}
};

const mapStateToProps = state => ({
  blogCategories: state.dataArray.blogCategories,
  settings: state.settings
});

export default socketConnect(connect(mapStateToProps, null)(Article));
