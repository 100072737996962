export const SET_CUSTOM_MEAL_PLAN = 'SET_CUSTOM_MEAL_PLAN';
export const UPDATE_CUSTOM_MEAL_PLAN = 'UPDATE_CUSTOM_MEAL_PLAN';
export const CLEAR_CUSTOM_MEAL_PLAN = 'CLEAR_CUSTOM_MEAL_PLAN';

const initialState = {};

export function setCustomMealPlan(arr) {
  return {
    type    : SET_CUSTOM_MEAL_PLAN,
    payload : arr
  };
}

export function updateCustomMealPlan(date, obj) {
  return {
    type    : UPDATE_CUSTOM_MEAL_PLAN,
    payload : { date, obj }
  };
}

export function clearCustomMealPlan() {
  return {
    type    : CLEAR_CUSTOM_MEAL_PLAN
  };
}

export default function customMealPlanReducer(state = initialState, action) {
  if (action.type === SET_CUSTOM_MEAL_PLAN) {
    return action.payload;
  }
  if (action.type === UPDATE_CUSTOM_MEAL_PLAN) {
    const { date, obj } = action.payload;
    return { ...state, [date]: obj };
  }
  if (action.type === CLEAR_CUSTOM_MEAL_PLAN) {
    return initialState;
  }
  return state;
}
