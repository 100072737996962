export const SET_DATA_ARRAY = 'SET_DATA_ARRAY';
export const UPDATE_DATA_ARRAY = 'UPDATE_DATA_ARRAY';
export const CLEAR_DATA_ARRAY = 'CLEAR_DATA_ARRAY';

const initialState = {};

export function getDataArray(data) {
  const { keys, socket } = data;
  
  keys.push('lang');
  
  if (socket) {
    socket.emit('get_arrays', {
      type: 'get',
      data: {
        type: keys,
        language: null
      }
    });
  }
}

export function setDataArray(array) {
  return {
    type    : SET_DATA_ARRAY,
    payload : array
  };
}

export function updateDataArray(array) {
  return {
    type    : UPDATE_DATA_ARRAY,
    payload : array
  };
}

export function clearDataArray() {
  return {
    type    : CLEAR_DATA_ARRAY
  };
}

export default function dataArrayReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case SET_DATA_ARRAY:
      newState = action.payload;
      break;
    case UPDATE_DATA_ARRAY:
      newState = { ...state, ...action.payload };
      break;
    case CLEAR_DATA_ARRAY:
      newState = initialState;
      break;
  }
  return newState;
}
