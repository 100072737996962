import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HeadLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { title, desc } = this.props;
    return (
      <div className='container-fluid title-holder blog-heading-menu'>
        <div className='row blog-heading menu-heading'>
          <div className='col-12 d-flex flex-column justify-content-center heading title-block blog'>
            <h1>{title}</h1>
            <p>
              <span className='ar-golr-l' />
              <span>{desc}</span>
              <span className='ar-golr-r' />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

HeadLine.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
};

export default HeadLine;
