import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import moment from 'moment';
import SelectValidation from '../../../components/SelectValidation';
class ChartHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 'month',
      amount: 1,
      activeYear:props.activeYear
    };
  }
  renderMonthDatas=(timestamps,datas,yearList)=>{
    const { activeYear } = this.state;
    let existm={};
    (timestamps)&& timestamps.map((timestamp,ind)=>{
       let timm=moment.unix(timestamp).format('MMM');
       if(existm[timm]==undefined){
         existm[timm]=[datas[ind]];
       }else{
        existm[timm].push(datas[ind]);
       }
    });
    const listval=(existm)&&Object.keys(existm).map((key)=>{
        const subval = (existm[key]!=undefined)&&(existm[key].filter(el=>(el!=null)).map((val)=>{
            return <li>+ {val}</li>;
        }));
      return <li>{key}{subval?<ul>{subval}</ul>:''}</li>;
    })
    // console.log(existm);
    return  <ul className='bd-month-year'>
      <li> <SelectValidation
                  name={'activeYear'}
                  value={activeYear || null}
                  onChange={(name, value) => this.changeActiveYear(value)}
                  list={yearList}
                  emptyValue={'Select Year'}
                /></li>
        {listval}
    </ul>;
  }
  changeActiveYear= (value)=>{
    var year = moment(). year();
    if(value!=year){
      this.setState({activeYear:value,period:'year'});
    }else{
      this.setState({activeYear:value});
    }
  }
  render() {
    const { renderer, propser, caption, isEmpty,yearList,emptyClass } = this.props;
    const { period, activeYear, amount } = this.state;
    const props = propser(period, activeYear, amount);
    const { timestamps,label,dataSets } =props;
    var year = moment(). year();
    const setParams = (period, activeYear, amount = 1) => this.setState({ period, activeYear, amount });
    return (
      <div className='col-12 col-lg-6'>
        <div className='row chart-head-line'>
          <div className='col-12'><h5 className='green'>{caption} - {activeYear}</h5></div>
          <div className='col-8'>
            <ul className={'progress-chart-navigation'}>
              <li className={period === 'year' && amount === 1 ? 'active' : ''} onClick={() => setParams('year',activeYear)}>Year</li>
              {activeYear==year && <li className={period === 'month' && amount === 1 ? 'active' : ''} onClick={() => setParams('month',activeYear,1)}>Last Month</li>}
            </ul>
          </div>
          {timestamps && <div className='col-12'>
             {this.renderMonthDatas(timestamps,dataSets[label]['data'],yearList)}
          </div>}
        </div>
        <div className={isEmpty ? 'empty-chart'+' '+emptyClass : ''}>
          {renderer(props)}
        </div>
      </div>
    );
  }
}

ChartHandler.propTypes = {
  renderer: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  propser: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired
};

export default ChartHandler;
