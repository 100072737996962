import React from 'react';
import PropTypes from 'prop-types';
import InputValidation from '../../../../components/InputValidation';

const InputLayout = props => {
  return (
    <div className='col-12 col-lg-3'>
      <div className='form-input '>
        <InputValidation
          {...props}
          className=''
          inputSelfClassName='standart-input b-rounded-blue  b-shadowed-gray w-100'
        />
      </div>
    </div>
  );
};

InputLayout.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default InputLayout;
