import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getDataArray } from 'store/dataArray';
import { Link, browserHistory } from 'react-router';
import Disqus from 'components/Disqus';

class Thread extends Component {
  constructor(props) {
    super(props);
    const { id: threadId } = props.params;
    this.state = {
      xyz: false,
      threadId,
      threadData: {}
    };
    this.getThreadData(threadId);
  }

  getThreadData = threadId => {
    this.props.socket.emit('forum', {
      type: 'get',
      data: {
        threadId
      }
    });
  };

  listener = action => {
    if (this.ThreadRef) {
      switch (action.type) {
        case 'getOk':
          const { threadData } = action.data;
          let text = '';
          try {
            text = threadData.text ? decodeURI(atob(threadData.text)) : '';
          } catch (e) {
            text = threadData.text;
          }
          this.setState({ threadData: { ...threadData, text } });
          break;
        case `${action.type}Err`:
          browserHistory.push('/nutrition-support/forum');
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('forum', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('forum', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.ThreadRef && this.props.params.id !== next.params.id) {
      this.getThreadData(next.params.id);
    }
  }

  componentDidMount() {
    if (this.ThreadRef) {
      const { forumCategories, socket } = this.props;
      if (socket && !Object.keys(forumCategories).length) {
        getDataArray({ keys: ['forumCategories'], socket });
      }
      setTimeout(() => this.setState({ xyz: true }), 300);
    }
  }

  render() {
    const { xyz, threadData } = this.state;
    const { id, title, text, category_id, created_at, user_id } = threadData;
    const { forumCategories } = this.props;
    const categoryName = forumCategories && Object.keys(forumCategories).length ? forumCategories[category_id] : '';
    const disqusConfig = {
      url: `/nutrition-support/forum/thread/${id}`,
      identifier: `${`${created_at}_${id}_${user_id}`}`
    };
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ThreadRef = el)}>
        <div className='container-fluid bg-gray-blog height-papa blog-page custom-scrollbar vertical-scrollbar'>
          <div className='row title-holder blog-heading single disqussion'>
            <div className='col-12  d-flex flex-column justify-content-center title-block blog'>
              <h1>{title}</h1>
              <p>
                <span className='ar-golr-l' />
                <span>{categoryName}</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='article-wrapper single-blog-wrapper row support-holder'>
            <div className='col-12'>
              <div className='row blog-border'>
                <div className='all-my p-0'>
                  <div className='blog-filters p-0'>
                    <div className='back-holder'>
                      <Link to='/nutrition-support/forum' className='back-link'>
                        <span className='back'>&larr;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row blog-border'>
                <div className='col-12 single-blog-page t-page'>
                  <div className='row'>
                    <div className='col-12'>
                      <div>
                        <div className={'article-body'} dangerouslySetInnerHTML={{ __html: text }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row blog-border'>
                <div className='col-12 single-blog-page'>
                  {(created_at && id && user_id) && <Disqus {...disqusConfig} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Thread.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  forumCategories: PropTypes.object
};

Thread.defaultProps = {
  forumCategories: {}
};

const mapStateToProps = state => ({
  forumCategories: state.dataArray.forumCategories
});

export default socketConnect(connect(mapStateToProps, null)(Thread));
