import React from 'react';
import SelectValidation from '../../../../components/SelectValidation';

const SelectLayout = props => {
  return (
    <div className='col-12 col-lg-3'>
      <div className='form-input b-shadowed-gray'>
        <SelectValidation {...props} />
      </div>
    </div>
  );
};

export default SelectLayout;
