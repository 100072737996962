import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import noImage from 'assets/no_img.svg';

class DailyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullDesc: false
    };
  }

  render() {
    const { dishes, typeList, products, itemClick, ingredientList, children, showDetails, showOnMobileBlockToggler } = this.props;
    const result = dishes && typeList && ingredientList && products ? Object.keys(dishes)
      .map(typeId => {
        const type = typeList[typeId];
        const product = products[dishes[typeId]];
        let list = null;
        switch (showDetails) {
          case 'ingredients':
            list = product.ingredients.map(el => <li key={el}>{ingredientList[el]}</li>);
            break;
          case 'macros':
            list = (
              <Fragment>
                <p className='regular-bold green'>
                  Calories -
                  <span className='green'>{` ${(product['calories'] / 1000)} kcal`}</span>
                </p>
                <p className='macros'>
                  <span>Fat</span>
                  <span>{`${(product['fat'] / 1000)} g`}</span>
                  <span>Protein</span>
                  <span>{`${(product['protein'] / 1000)} g`}</span>
                  <span>Carbs</span>
                  <span>{`${(product['carb'] / 1000)} g`}</span>
                </p>
              </Fragment>
            );
            break;
        }
        return (
          <Fragment key={typeId} >
            <p className='regular-bold green'>{type.title}</p>
            <div className='menu-product align-items-center' onClick={() => {
              showOnMobileBlockToggler('menu');
              itemClick(dishes[typeId]);
            }}>
              <div className='image'>
                <img className='dish-image' src={`${product.image || noImage}`} alt={'dish-image'} />
              </div>
              <div className='description'>
                <h2 className='product-title transition text-left p-0 m-0'>{product.title}</h2>
                <ul className='tags m-0 p-0 text-left'>
                  {list}
                </ul>
              </div>
            </div>
          </Fragment>
        );
      }) : [];
    return (
      <div className='one-day-menu custom-scrollbar vertical-scrollbar'>
        {children}
        {result}
      </div>
    );
  }
}

DailyMenu.propTypes = {
  dishes: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  typeList: PropTypes.object.isRequired,
  ingredientList: PropTypes.object.isRequired,
  itemClick: PropTypes.func,
  showOnMobileBlockToggler: PropTypes.func,
  children: PropTypes.any,
  showDetails: PropTypes.oneOf(['ingredients', 'macros'])
};

DailyMenu.defaultProps = {
  itemClick: () => {},
  children: '',
  showDetails: 'ingredients'
};

export default DailyMenu;
