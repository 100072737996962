import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'; //because some charts are jquery based (design tier scope)
import { Chart, CircleChartS } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { setModal, clearModal } from '../../store/modal';
import CalendarView from './CalendarView.js';
import MyProgress from './MyProgress.js';
import MainSettings from './MainSettings.js';
import Main from './Main.js';
import moment from 'moment';
import { spin, stop } from 'store/spinner';
import { Link, browserHistory } from 'react-router';
import PaperButton from 'react-paper-button';
import html2canvas from 'html2canvas'

import Edit from 'assets/dashboard/img/edit.svg';
import EditWhite from 'assets/dashboard/img/edit-white.svg';
import Question from 'assets/dashboard/img/questions.svg';
//import Avatar from 'assets/dashboard/img/user-photo.png';
import Avatar_male from 'assets/dashboard/img/ava_male.jpg';
import Avatar_feemale from 'assets/dashboard/img/ava_female.jpg';
import Avatar_nutritionist from 'assets/dashboard/img/ava_nutritionist.jpg';
import Avatar_manager from 'assets/dashboard/img/ava_manager.jpg';
import Twitter from 'assets/dashboard/img/twitter.svg';
import Facebook from 'assets/dashboard/img/facebook.svg';
import Instagram from 'assets/dashboard/img/instagram.svg';
import Ava1 from 'assets/dashboard/img/alex-colder.png';
import Ava2 from 'assets/dashboard/img/aurelie.png';
import No_meal from 'assets/no_img.svg';
import Meal1 from 'assets/dashboard/img/working-week.png';
import Meal2 from 'assets/dashboard/img/monthly24days.png';
import Meal3 from 'assets/dashboard/img/the-taster.png';
import Meal4 from 'assets/dashboard/img/monthly20days.png';
import Fitbit from 'assets/dashboard/img/fitbit.png';
import Inst1 from 'assets/dashboard/img/insta-1.png';
import Inst2 from 'assets/dashboard/img/insta-2.png';
import Inst3 from 'assets/dashboard/img/insta-3.png';
import Inst4 from 'assets/dashboard/img/insta-4.png';
import Inst5 from 'assets/dashboard/img/insta-5.png';
import Inst6 from 'assets/dashboard/img/insta-6.png';
import Phone from 'assets/dashboard/img/round-phone.png';
import WhatsApp from 'assets/dashboard/img/whatsapp-phone.png';
import ShareButton from 'react-social-share-buttons'
import ToolTip from 'components/ToolTip'; 
//import SmallProfile from './SmallProfile';

class Dashboard extends Component { 
  constructor(props) {
    super(props);
    var now = Date.now() / 1000;
    this.state = { 
      userData: false,
      managerInfo: false,
      startWeightEdit: false,
      goalWeightEdit: false,
      startWeightVal: null,
      goalWeightVal: null,
      weightStats: null,
      menuDateText: 'Today\'s Menu Total Calories & Macros',
      mealsstamp: now,
      weightChartAction: 'days',
      weightChartLabels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
      weightChartData: [90, 87, 86, 82, 82, 81, 78, 81, 84, 80],
      ifDemo: true,
      chartYear: moment().format('YYYY'),
      chartMonth: moment().format('MM'),
      files: [],
      sharetext: 'Hey, look on my today\'s healthy menu which I have from Basiligo!',
      paused: false,
      pause: false,
      pause_start: false,
      pause_end: false,
      goalDemo: false,
      photoDemo: true,
      notes: null,
      futurestart: null,
    };
    
  }
  
  componentWillMount() {
    this.props.socket.on('profile', this.listener);
    this.props.socket.on('login', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
    this.props.socket.removeListener('login', this.listener);
  }

  listener = action => {
      const {dataArray} = this.props;
      const {lang} = dataArray;
      var request_sent_text = 'Request Sent';
      var intouch_text = 'Thank you! Our team will be in touch with you.';
      if (typeof(lang) != 'undefined' && Object.keys(lang).length > 0) {
        request_sent_text = lang[request_sent_text];
        intouch_text = lang[intouch_text];
      }

      switch (action.type) {
        case 'logoutOk':
          browserHistory.push('/sign-in');
        break;
        case 'getPauseOk':
          console.log('PAUSE RESPONSE');
          this.setState({
            pause: action.data.isPause,
            pause_start: action.data.startDate,
            pause_end: action.data.endDate,
          });
          this.checkPause();
        break;
        case 'planRequestOk':
          const {clearModal, setModal} = this.props;
          clearModal();
          setModal({ headLine: request_sent_text, message: (
            <div>
              <p>{intouch_text}</p>
            </div>)
          });                          
        break;
        case 'getOk':
          const {userData} = action.data;
          const {plan} = userData;
          this.setState({userData: userData, heightVal: userData.height, weightVal: userData.weight/1000});

          if (plan.order && typeof(plan.order) != 'undefined') {
            this.getPlanInfo(plan.order);
          }
          break;
        case 'getManagersOk':
          this.setState({
            managerInfo: action.data
          });  
        break;
        case 'getFutureStartOk':
          this.setState({
            futurestart: action.data
          });
        break;
        case 'saveProgressOk':
          console.log('saved');
          console.log(action);
        break;
        case 'saveProgressErr':
          console.log(action);
        break;
        case 'saveProfDataOk':
          console.log('saved');
        break;
       
        case 'saveProfDataErr':
          console.log(action);
        break;
        case 'getMealplanOk':
          this.setState({
            mealplan: action.data
          });
          this.checkMealsStatus();  
        break;
        case 'getWeightStatsOk':
          this.setState({
            weightStats: action.data.stats,
            ifDemo: false,
          });
          this.calculateChart();
        break;
        case 'getFilesOk':
          this.setState({
            files: action.data.files
          });
        break;
        case 'getErr':
          console.log(action.data.errors);
        break;
      }
    
  };

  getProfile = () => this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'get' } });
  getManagers = () => this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'getManagers' } });
  getPlanInfo = orderId => { this.props.socket.emit('profile', { type: 'getForDashboard', data: { orderId: orderId, userId: this.props.user.user_id, 'subtype': 'getMealplan'  } }); };
  getWeightStats = () => { this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'getWeightStats' } }); }
  getFutureStart = () => { this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'getFutureStart' } }); }
  
  getFiles = () => {
    console.log('start');
    this.props.socket.emit('profile', {  type: 'getForDashboard', data: {
        'subtype': 'getFiles', 
        'userId': this.props.user.user_id,
    } });
  }

  hideId(theid) {
    $('#'+theid).slideUp(); //because jquery anyway is in use and modals don't work with states from this file or from the Calendar component.
  }

  checkPause() {
    const {pause, pause_start, pause_end} = this.state;
    var paused = false;
    if (pause) {
      var now = Date.now() / 1000;
      if (pause_end) {
        if (now >= pause_start) {
          paused = true;
        }
      } else {
        if ( (now >= pause_start) && (now < pause_end) ) {
          paused = true;
        }
      }
    }

    if (paused) {
      this.setState({
        paused: true
      });
    }
  }

  checkMealsStatus() {
    const {mealplan, mealsstamp} = this.state;
    const {mealPlan} = mealplan;
    const {dataArray} = this.props;
    const {lang} = dataArray;

    var upcoming_menu_text = 'Upcoming Menu on';
    if (typeof(lang) != 'undefined' && Object.keys(lang).length > 0) {
      upcoming_menu_text = lang[upcoming_menu_text];
    }
    const getDate = date => moment.unix(date).format('DD.MM');
    var exist = false;
    Object.keys(mealPlan).map((stamp, key) => {
      if (getDate(stamp) == getDate(mealsstamp)) {
        exist = true;
      }
    });

    console.log(exist);
    if (!exist) {
      var newstamp = false;
      //looking for next day:
      Object.keys(mealPlan).map((stamp, key) => {
        if (stamp > mealsstamp) {
          if (!newstamp) {
            newstamp = stamp;
          }
        } 
      });

      var text = null;
      if (newstamp) {
        text = upcoming_menu_text+' ' + moment.unix(newstamp).format('DD MMM, YYYY');
      }

      this.setState({
        mealsstamp: newstamp,
        menuDateText: text,
      });
    }
  }

  changeNotesInfo(name, value) {
    this.setState({
      [name]:value,
    });

    setTimeout(() => this.updateMealplanPopup(false), 10);
  }

  componentDidMount() {

    // sockets stuff:
      setTimeout(() => this.setState({ xyz: true }));
      this.props.socket.emit('get_arrays', {
        type: 'get',
        data: {
          type: ['gender', 'language', 'planList', 'typeList', 'productsFull', 'areasList', 'allEmirates', 'products', 'productsFull', 'dietList','medicalConditions','dishGroups','deliveryPartnerFull'],
          language: this.props.user.language
        }
      });
    this.getProfile();
    this.getManagers();
    // this.getWeightStats();
    // this.getFiles();
    this.getFutureStart();
    // charts and circle diagrams:
     /* function CircleChartS(id) {
        var canvas = document.getElementById(id);
        var ctx = canvas.getContext("2d");
        var value = canvas.dataset.value;
        canvas.width = 130;
        canvas.height = 130;
        ctx.beginPath();
        ctx.strokeStyle = '#D2E8DD';
        ctx.lineWidth = 1;
        ctx.arc(65, 65, 60, Math.PI / 2, Math.PI * 2.5, false);
        ctx.stroke();
        ctx.beginPath();
        ctx.textAlign = 'center';
        ctx.fillStyle = "#59585A";
        ctx.font = '40px Garamond-Basiligo';
        ctx.fillText(value, 65, 78);
      };

      function CircleChartC(id) {
        var canvas = document.getElementById(id);
        var ctx = canvas.getContext("2d");
        var value = canvas.dataset.value;
        var label = canvas.dataset.label;
        canvas.width = 130;
        canvas.height = 130;
        ctx.beginPath();
        ctx.strokeStyle = '#D2E8DD';
        ctx.lineWidth = 1;
        ctx.arc(65, 65, 60, Math.PI / 2, Math.PI * 2.5, false);
        ctx.stroke();
        ctx.beginPath();
        ctx.textAlign = 'center';
        ctx.fillStyle = "#59585A";
        ctx.font = '40px Garamond-Basiligo';
        ctx.fillText(value, 65, 72);
        ctx.beginPath();
        ctx.textAlign = 'center';
        ctx.fillStyle = "#59585A";
        ctx.font = '26px Garamond-Basiligo';
        ctx.fillText(label, 65, 98);
      };

      CircleChartS("friend-invited");
      CircleChartS("cdr");
      CircleChartS("friend-invited-m");
      CircleChartS("cdr-m");
      CircleChartC("ramadan");
      CircleChartC("meals");
      CircleChartC("juice");
      this.calculateChart();     */   
  }

  weekOfMonth(date) {
    let weekInYearIndex = date.week();
    if (date.year() !== date.weekYear()) {
      weekInYearIndex = date.clone().subtract(1,'week').week() + 1;
    }
    const weekIndex = weekInYearIndex - moment(date).startOf('month').week() + 1;
    return weekIndex;
  }

  setCrop(newCrop) {
    console.log(newCrop);
  }

  CropDemo({ src }) {
    const [crop, setCrop] = useState({ aspect: 9 / 9 });
    return <ReactCrop src={src} crop={crop} onChange={newCrop => setCrop(newCrop)} />;
  }

  shareSocial(type) {
    const {setModal, dataArray} = this.props;
    const {lang} = dataArray;
    var {sharetext} = this.state;

    var daily_meals_text = 'My Daily Healthy Meals from Basiligo';
    var please_download_text = 'Please download the image and attach manually to your post after clicking the';
    var link_bellow_text = 'link bellow';
    if (typeof(lang) != 'undefined' && Object.keys(lang).length > 0) {
      daily_meals_text = lang[daily_meals_text];
      please_download_text = lang[please_download_text];
      link_bellow_text = lang[link_bellow_text];
      sharetext = lang[sharetext];
    }
    if(type=='instagram'){
      please_download_text= 'Please download the image and attach manually to your';
      link_bellow_text='post';
    }
    html2canvas(document.getElementById("dish-screen")).then(function(canvas) {
      var my_screen = canvas;
      var img = my_screen.toDataURL("image/png");
      setModal({ headLine: daily_meals_text , message: (
      <div>
        <p><img src={img} className="img-responsive share-img"/></p>
        <p align="center">{please_download_text} {type} {link_bellow_text}</p>
        {type!='instagram'&&<p align="center">
          <ShareButton
                compact
                socialMedia={type}
                url={"https://healthyme.loseweight.ae"}
                media={img} // so you can see, that I have an image and I want to attach it here. But it doesn't work. And looks like because of it. So we can have something like "Download this image and attach it manually after a button click". I understand, that it looks weird, but looks like the functionality doesn't work anymore.
                text={sharetext}
          />
        </p>}
      </div>
      )});
    }); 

    
  }

  calculateChart () {

    const {weightStats, weightChartAction, chartYear, chartMonth} = this.state;
    var ifDemo = false;

    if (weightStats && Object.keys(weightStats).length > 0) {
      this.setState({ifDemo: ifDemo});
      switch(weightChartAction) {
        case 'month':
          var weightChartLabels = [];
          var weightChartData = [];
          var monthc = 1;
          var weight = 0;
          var counter = 1;
          if ( typeof(weightStats[chartYear]) != 'undefined') {
                Object.keys(weightStats[chartYear]).map( (month, index) => {
                    var themonth = moment(chartYear+'-'+month+'-01').format('MM');
                    Object.keys(weightStats[chartYear][month]).map( (arr_index, index) => {   
                        Object.keys(weightStats[chartYear][month][arr_index]).map( (day, index) => {
                            if (monthc != themonth) {
                              weightChartLabels.push(moment(chartYear+'-'+month+'-01').format('MMM')+'-'+chartYear);
                              weight = weight/counter;
                              weightChartData.push(weight.toFixed(1));
                              monthc = themonth;
                              weight = 0;
                              counter = 0;
                            } else {
                              weight = weight + weightStats[chartYear][chartMonth][arr_index][day];
                              counter = counter + 1;
                            }
                             
                        });
                    });
                });
                              weightChartLabels.push(moment(chartYear+'-'+monthc+'-01').format('MMM')+'-'+chartYear);
                              weight = weight/counter;
                              weightChartData.push(weight.toFixed(1));
          }
        break;

        case 'weeks':
          var weightChartLabels = [];
          var weightChartData = [];
          var week_number = 1;
          var weight = 0;
          var counter = 1;
              if ( typeof(weightStats[chartYear]) != 'undefined' && typeof(weightStats[chartYear][chartMonth]) != 'undefined' ) {
                    Object.keys(weightStats[chartYear][chartMonth]).map( (arr_index, index) => {   
                        Object.keys(weightStats[chartYear][chartMonth][arr_index]).map( (day, index) => {
                           var num = this.weekOfMonth(moment(chartYear+'-'+chartMonth+'-'+day));
                            if (num != week_number) {
                              weightChartLabels.push('week '+week_number);
                              weight = weight/counter;
                              weightChartData.push(weight.toFixed(1));
                              week_number = num;
                              weight = 0;
                              counter = 0;
                            } else {
                              weight = weight + weightStats[chartYear][chartMonth][arr_index][day];
                              counter = counter + 1;
                            }
                        });
                    });
                              weightChartLabels.push('week '+week_number);
                              weight = weight/counter;
                              weightChartData.push(weight.toFixed(1));
              }
        break;

        case 'days':
          var weightChartLabels = [];
          var weightChartData = [];
          
              if (typeof(weightStats[chartYear]) != 'undefined') {
                Object.keys(weightStats[chartYear]).map( (month, index) => {
                    var themonth = moment(chartYear+'-'+month+'-01').format('MMM');
                    Object.keys(weightStats[chartYear][month]).map( (arr_index, index) => {   
                        Object.keys(weightStats[chartYear][month][arr_index]).map( (day, index) => {
                            weightChartLabels.push(day+' '+themonth);
                            weightChartData.push(weightStats[chartYear][month][arr_index][day]);
                        });
                    });
                });
              }
        break;

        default:
          var weightChartLabels = [];
          var  weightChartData = [];
        break;
      }
    } else {
      var weightChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'];
      var weightChartData = [90, 87, 86, 82, 82, 81, 78, 81, 84, 80];
      ifDemo = true;
      this.setState({ifDemo: ifDemo}); 
    }
    
                var ctx = document.getElementById('WeightChart').getContext('2d');
                if (ifDemo) {
                    $('#WeightChart').addClass('demo-view');
                } else {
                    $('#WeightChart').removeClass('demo-view');
                }
                var chart = new Chart(ctx, {
                    // The type of chart we want to create
                    type: 'line',

                    // The data for our dataset
                    data: {
                        labels: weightChartLabels,
                        datasets: [{
                            label: 'Weight, kg',
                            backgroundColor: '#D2E8DD',
                            borderColor: '#3A675A',
                            data: weightChartData,
                        }]
                    },

                    // Configuration options go here
                    options: {
                      maintainAspectRatio: false,
                    }
                });


  }

  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }

  updateMealplanPopup(first) {
    const {setModal, clearModal, dataArray} = this.props;
    const {lang} = dataArray;
    const {notes} = this.state;

    var great_to_see_text = 'Its great to see that you would like to renew your meal plan and continue your wellness journey. Our team, will get back to you soon with updated menu. Please leave us a note, if any.';
    if (typeof(lang) != 'undefined' && Object.keys(lang).length > 0) {
      great_to_see_text = lang[great_to_see_text];
    }
    if (first) {
      //this.setNotification('Renew mealplan Request', 2);
    }
    return (
            setModal({ headLine: 'Renew meal plan', message: (
                                      <div>
                                        <p>{great_to_see_text}</p>

                                        <textarea className="medical-text-area" value={notes} onChange={ (e) => { this.changeNotesInfo('notes', e.target.value)}}/>
                                        <p align="center"><PaperButton
                                          onClick={e => {
                                            const {userData, notes} = this.state;
                                            var data = {
                                              'notes': notes, 
                                              userId: userData.user_id, 
                                              email: userData.email, 
                                              firstName: userData.firstName, 
                                              lastName: userData.lastName, 
                                              'phone': userData.phone 
                                            };

                                            this.setNotification('Renew mealplan Request: '+notes, 2);
                                            clearModal();
                                            setModal({ headLine: 'Thank you', message: 'Request sent well'}); 
                                            this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
                                          }}
                                          className='button-regular next transition'
                                        >
                                          <span>Send Request</span>
                                        </PaperButton></p>
                                      </div>
            )})
    )
  }
  

  setChartAction(action) {
    this.setState({
      weightChartAction: action,
    }, function(){
      this.calculateChart();
    }); 
  }

  uploadHandler(id){
    document.getElementById(id).click();
  }

  saveStartWeight() {
    var {userData, startWeightVal} = this.state;
    userData.start_weight = startWeightVal*1000;
    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, subtype: 'saveProfData', theaction: 'start_weight', value: userData.start_weight} });
    this.setState({userData: userData, startWeightEdit: false});
    this.setNotification('Start weight changes', 1);
  }

  saveGoalWeight() {
    var {userData, goalWeightVal} = this.state;
    userData.goal_weight = goalWeightVal*1000;
    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, subtype: 'saveProfData', theaction: 'goal_weight', value: userData.goal_weight} });
    this.setState({userData: userData, goalWeightEdit: false});
    this.setNotification('Goal weight changes', 1);
  }

  render() {
    //console.log(this.state);
    //console.log(this.props);
    const {userData, managerInfo, mealplan, enableSaveProgress, startWeightEdit, goalWeightEdit, startWeightVal, goalWeightVal, ifDemo, mealsstamp, paused, goalDemo, photoDemo, futurestart} = this.state;
    var {menuDateText} = this.state;
    const {dataArray, user, setModal, spin} = this.props; 
    const {dietList, products, typeList, lang, deliveryPartnerFull} = dataArray;

    console.log('===== PHRASES =====');
    console.log(lang);
    console.log('===== PHRASES =====');

    var dishheader_text = 'Today\'s Healthy Meals';
    var great_to_see_text = 'Its great to see that you would like to renew your meal plan and continue your wellness journey. Our team, will get back to you soon with updated menu. Please leave us a note, if any.';
    var total_calories_text = 'Total Calories';
    var protein_text = 'Protein';
    var fat_text = 'Fat';
    var carbs_text = 'Carbs:';
    var kcal_text = 'Kcal';
    var cho_text = 'CHO';
    var total_macros_text = 'Total Macros';
    var sad_to_see_text = 'Sad to see you have no healthy meal plans currently. Don\'t worry, start your wellness journey now by clicking below. Our team will get back to you.';
    var on_pause_text = 'The meal plan is on pause';
    var days_left_text = 'days left';
    var new_starts_on_text = 'Thank you for renewing. You new plan starts on';
    var send_request_text = 'Send request to Basiligo to add new Mealplan';
    var no_active_text = 'No Active Meal Plan';
    var new_mealplan_text = 'New Meal Plan Request';
    var send_request_text = 'Send Request';
    var view_modify_text = 'View / Modify Full Meal Plan';
    var order_new_mealplan_text = 'Order new meal plan';
    var nutritionist_text = 'Your Nutritionist';
    var manager_text = 'Your Wellness Manager';
    var my_goal_text = 'My Goal';
    var coming_soon_text = 'Coming soon';
    var starting_weight_text = 'Starting Weight';
    var save_text = 'Save';
    var goal_weight_text = 'Goal Weight';
    var please_change_weight_text = 'Please change your weight under your profile picture on the days you measure your weight to reflect on this graph.';
    var weight_progress_text = 'Weight Progress';
    var days_text = 'Days';
    var weeks_text = 'Weeks';
    var months_text = 'Months';
    if (typeof(lang) != 'undefined' && Object.keys(lang).length > 0) {
      dishheader_text = lang[dishheader_text];
      great_to_see_text = lang[great_to_see_text];
      total_calories_text = lang[total_calories_text];
      protein_text = lang[protein_text];
      fat_text = lang[fat_text];
      carbs_text = lang[carbs_text];
      menuDateText = lang[menuDateText];
      kcal_text = lang[kcal_text];
      cho_text = lang[cho_text];
      total_macros_text = lang[total_macros_text];
      sad_to_see_text = lang[sad_to_see_text];
      on_pause_text = lang[on_pause_text];
      days_left_text = lang[days_left_text];
      new_starts_on_text = lang[new_starts_on_text];
      send_request_text = lang[send_request_text];
      no_active_text = lang[no_active_text];
      new_mealplan_text = lang[new_mealplan_text];
      send_request_text = lang[send_request_text];
      view_modify_text = lang[view_modify_text];
      order_new_mealplan_text = lang[order_new_mealplan_text];
      nutritionist_text = lang[nutritionist_text];
      manager_text = lang[manager_text];
      my_goal_text = lang[my_goal_text];
      coming_soon_text = lang[coming_soon_text];
      starting_weight_text = lang[starting_weight_text];
      save_text = lang[save_text];
      goal_weight_text = lang[goal_weight_text];
      please_change_weight_text = lang[please_change_weight_text];
      weight_progress_text = lang[weight_progress_text];
      days_text = lang[days_text];
      weeks_text = lang[weeks_text];
      months_text = lang[months_text];
    }
    
    const {firstName, lastName, gender, weight, height, plan, birthday, regDate, goal_weight, start_weight, delivery_partner_id} = userData;
    const {manager_name, manager_gender, nutritionist_name, nutritionist_gender} = managerInfo;
    var mealday = [];

    //var now = '1576108800';
    var carb = 0;
    var calories = 0;
    var protein = 0;
    var fat = 0;
    const getDate = date => moment.unix(date).format('DD.MM');
    var takefuture = false;
    if (typeof(mealplan) != 'undefined') {
       
        const {mealPlan} = mealplan;
        if (typeof(mealPlan) != 'undefined' && typeof(products) != 'undefined') {
          Object.keys(mealPlan).map((stamp, key) => {
            if (getDate(stamp) == getDate(mealsstamp)) {
                if (typeof(mealPlan[stamp]) != 'undefined') {
                  Object.keys(mealPlan[stamp]).map((dish_type, num) => {
                    var dish_id = mealPlan[stamp][dish_type];
                    calories = calories + products[dish_id]['calories'] / 1000;
                    carb = carb + products[dish_id]['carb'] / 1000;
                    protein = protein + products[dish_id]['protein'] / 1000;
                    fat = fat + products[dish_id]['fat'] / 1000;

                    mealday.push(<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-center">
                                  <div className="meal-card">
                                    <div className="position-relative my-4 plans-card-image">
                                      <img src={products[dish_id]['preview'] || No_meal} className="img-fluid" alt="" />
                                      <div className="card-type">{typeList[dish_type].title}</div>
                                    </div>
                                    <div className="plans-card-title">
                                      <h5>{products[dish_id]['title']}</h5>
                                    </div>
                                    <div className="card-calories">{total_calories_text}: {products[dish_id]['calories']/1000}</div>
                                    <div className="card-macros">{fat_text}: {products[dish_id]['fat']/1000}g, {protein_text}: {products[dish_id]['protein']/1000}g, {carbs_text}: {products[dish_id]['carb']/1000}g</div>
                                  </div>
                                </div>);
                  });
                } 
            } 
          });
        }
    }

    var dietname = '';
    var manager_avatar = Avatar_manager;
    var nutritionist_avatar = Avatar_nutritionist;
    /*if (nutritionist_gender && nutritionist_gender == 'female') {
      nutritionist_avatar = Avatar_feemale;
    }

    if (manager_gender && manager_gender == 'female') {
      manager_avatar = Avatar_feemale;
    }*/

    var left = null;
    var active_plan = false;
    var renewbtn = false;
    var updated = false;
    var s = 's';
    //var viewtext = 'Create New Meal Plan';
    if (typeof(plan) != 'undefined' && plan != null && typeof(dietList) != 'undefined') {
      const {diet, end} = plan;
      var now = moment().unix();
      var diff = parseInt( (end - parseInt(now)) / 60 / 60 / 24 ) + 1;
      if (diff <= 3) {
        if (diff < 0) {
          diff = 0;
        }
        left = diff;

        if (left == 1) {
          s = null;
        }
        renewbtn = true;
      }

      if (plan.renew_status == 1) {
        updated = true;
        renewbtn = false;
      }

      /*console.log('===========');
      console.log(now);
      console.log(end);
      console.log(diff);
      console.log(left);
      console.log('===========');*/

      active_plan = true;
      dietname = dietList[plan.diet];
      //viewtext = 'View Full Meal Plan';
    }

     
    
    return (
      <div className="row bg-1-1 m-0 main-holder" id="dashboard">
        <div className='col-12'>

            <section id="user-data">
                <div className="container-fluid">
                  <div className="row"> 
                    <Main uploadHandler={this.uploadHandler} setNotification={this.setNotification} firstName={firstName} lastName={lastName} gender={gender} weight={weight} height={height} birthday={birthday} regDate={regDate} userData={userData} />
                    <div className="col-12 col-lg-6 mb-4">
                      <div className="today-meal-wrap">
                        <h3 className="text-center mb-4">
                         {dietname} - {dishheader_text}
                        </h3>
                        <div className="today-meal-grid position-relative">
                          <div className="today-meal-social-button position-absolute">
                            <div className="today-meal-social-button-wrap">
                              <a className="pointer" onClick={ (e) => this.shareSocial('twitter')}><img src={Twitter} alt="" /></a>
                              <a className="pointer" onClick={ (e) => this.shareSocial('facebook')}><img src={Facebook} alt="" /></a> 
                              <a className="pointer" onClick={ (e) => this.shareSocial('instagram')}><img src={Instagram} alt="" /></a>
                            </div>
                          </div> 
                          <div className="row" id="dish-screen">
                           {mealday}
                           {!paused && mealsstamp && active_plan && <div className="col-12 text-center total-macros menu-day-text">
                              {menuDateText}
                            </div>}
                           {!paused && mealsstamp && active_plan &&  <div className="mb-2 col-12 text-center total-calories">
                              <span className="grey mr-1">{total_calories_text}</span> <span className="font-weight-bold total-calories-data">{calories}</span> {kcal_text}
                            </div>}
                            {!paused && mealsstamp && active_plan && <div className="col-12 text-center total-macros">
                              <span className="grey mr-1">{total_macros_text}</span> <span className="font-weight-bold fat">{fat}g</span> {fat_text}, <span className="font-weight-bold protein">{protein}g</span> {protein_text}, <span className="font-weight-bold cho">{carb}g</span> {cho_text}
                            </div>}
                            
                            {!paused && !active_plan && !updated && <div className="col-12 text-center total-macros menu-day-text">
                              {sad_to_see_text}
                            </div>}
                            {paused && <div className="col-12 text-center total-macros menu-day-text">
                              {on_pause_text}
                            </div>}

                            {renewbtn && <div className="col-12 text-center">
                              <span className="days-left-text">{left} {typeof(lang) == 'undefined' ? 'day'+s+' left' : days_left_text }</span><br/>
                              <button className="btn gold-button gold-renew animated infinite pulse delay-4s" onClick={(e) => this.updateMealplanPopup(true)}>Renew Now</button>
                            </div>}

                            {updated && futurestart && <div className="col-12 text-center">
                              <span className="days-upd-text">{new_starts_on_text} {moment.unix(futurestart).format('DD MMM YYYY')}</span><br/>
                            </div>}
                            

                            <div className="today-meal-full-plan position-absolute">
                              <div className="today-meal-full-plan-wrap">
                                <a href="/modify" target="_blank" onClick={ e => {
                                  e.preventDefault();
                                  if (active_plan) {
                                    spin();
                                    browserHistory.push('/modify');
                                  } else {
                                    setModal({ headLine: no_active_text, message: (
                                      <div>
                                        <p>{send_request_text}</p>
                                        <p align="center"><PaperButton
                                          onClick={e => {
                                            const {userData} = this.state;

                                            var data = {
                                              userId: userData.user_id, 
                                              email: userData.email, 
                                              firstName: userData.firstName, 
                                              lastName: userData.lastName, 
                                              'phone': userData.phone 
                                            };
                                            console.log(data);
                                            this.setNotification(new_mealplan_text, 2);
                                            this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
                                          }}
                                          className='button-regular next transition'
                                        >
                                          <span>{send_request_text}</span>
                                        </PaperButton></p>
                                      </div>
                                    )});
                                  } 
                                }}>
                                { (active_plan || updated) && (view_modify_text)}
                                {!active_plan && !updated && (order_new_mealplan_text)}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-3 mb-4">
                      <div className="managers-wrap mb-4 position-relative">
                        <div className="bg-right-corner"></div>
                        <div className="row h-100">
                          <div className="col-3 text-center my-auto">
                            <img src={nutritionist_avatar} alt="" className="img-fluid manager-img" />
                          </div>
                          <div className="col-8 my-auto">
                            <div className="manager-name">
                              <h4 className="mb-1"> 
                                {nutritionist_name}
                              </h4>
                            </div>
                            <div className="mb-2 manager-position grey">
                              {nutritionist_text} 
                            </div>
                          </div>
                        </div>
                        <div className="grey-line-1px"></div>
                        <div className="row h-100">
                          <div className="col-3 text-center my-auto">
                            <img src={manager_avatar} alt="" className="img-fluid manager-img" />
                          </div>
                          <div className="col-8 my-auto">
                            <div className="manager-name">
                              <h4 className="mb-1">
                               {manager_name}
                              </h4>
                            </div>
                            <div className="mb-2 manager-position grey">
                              {manager_text}
                            </div>
                          </div>
                        </div>
                        <div className="col-8 my-auto">
                            <div className="manager-contacts"> 
                              <a className="waurl" href="tel:+971025530049" target="_blank"><img className="small-icon" src={Phone} /> 02-553-0049</a> <a href="https://wa.me/9710507218261" target="_blank" className="waurl"><img className="small-icon" src={WhatsApp} />050-721-8261</a> 
                            </div>
                        </div>
                        {delivery_partner_id && deliveryPartnerFull && <div className='row'><div className="col-12 my-auto">
                              
                               <div className="deliverypartner-name"><strong>Delivery Partner : </strong> {deliveryPartnerFull[delivery_partner_id]['name']}</div>
                               <div className="deliverypartner-name"><strong>Delivery Partner Helpline : </strong> <a className="waurl" href={`tel:${deliveryPartnerFull[delivery_partner_id]['helpline']}`} target="_blank">{deliveryPartnerFull[delivery_partner_id]['helpline']}</a></div></div>
                             
                        </div>}
                      </div>
                      <MainSettings/>
                    </div>
                  </div>
                </div>
              </section> 


<section id="goals">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 col-lg-3 text-center">
        <div className="my-goal-wrap mb-4">
          <h3>{my_goal_text}</h3>
          {goalDemo && <div className="progressSoon">{coming_soon_text}...</div>}
          <table className={goalDemo ? 'demoview mx-auto mb-4' : 'mx-auto mb-4'}>
            <tbody>
              <tr>
                <td className="text-right grey"><span>{starting_weight_text}</span></td>
                <td className="text-left"><span className="mx-1 font-weight-bold weight-data">{start_weight/1000}</span>kg {!goalDemo && <a className="pointer" onClick={() => {this.setState({startWeightEdit: true})}}><img src={Edit} alt="" /></a>}</td>
              </tr>
              {startWeightEdit && <tr><td colspan="2"><input type="text" id="weightEdit" value={startWeightVal} onChange={ (e) => {this.setState({startWeightVal: e.target.value});} } /><button className="btn gold-button small-save" onClick={ () => {this.saveStartWeight()} }>{save_text}</button></td></tr>}
              <tr>
                <td className="text-right grey"><span>{goal_weight_text}</span></td>
                <td className="text-left"><span className="mx-1 font-weight-bold height-data">{goal_weight/1000}</span>kg {!goalDemo && <a className="pointer" onClick={ () => { this.setState({goalWeightEdit: true})}}><img src={Edit} alt="" /></a>}</td>
              </tr>
              {goalWeightEdit && <tr><td colspan="2"><input type="text" id="weightEdit" value={goalWeightVal} onChange={ (e) => {this.setState({goalWeightVal: e.target.value});} } /><button className="btn gold-button small-save" onClick={ () => {this.saveGoalWeight()} }>{save_text}</button></td></tr>}
            </tbody>
          </table>
          <CalendarView goalDemo={goalDemo} weightStats={this.state.weightStats} files={this.state.files} hide={this.hideId} />
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-4 text-center">
        <div className="chart-wrap">
          <ToolTip 
                overlayClassName={'on-dishes'}
                tooltipDirection={'bottom'}
                trigger={['click']}
                tooltipText={<div className=''>
                  
                  <div className='t-total'><span className='green'>{please_change_weight_text}</span></div>
                </div>}
              >
                <h3>
                  <span className="hint-bubl">i</span>
                  {weight_progress_text}
                </h3>
          </ToolTip>
         
          {ifDemo && <div className="progressSoon">{coming_soon_text}...</div>}
          {!ifDemo &&
          <div className="period-buttons">
            <a onClick={ () => {this.setChartAction('days')} } id="chart-days" className="pointer">{days_text}</a>
            <a onClick={ () => {this.setChartAction('weeks')} } id="chart-weeks" className="pointer">{weeks_text}</a>
            <a onClick={ () => {this.setChartAction('month')} } id="chart-months" className="pointer">{months_text}</a>
          </div>}
          <div className="position-relative chart-container">
              <canvas id="WeightChart"></canvas>
          </div>
        </div>
      </div>
      {/* <div className="col-12 col-lg-3 mb-4 text-center">
        <MyProgress photoDemo={photoDemo} uploadHandler={this.uploadHandler} userId={this.props.user.user_id}  />
      </div> */}
    </div> 
  </div>
</section>

{/*<section id="meal-plans">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="meal-plans-wrap text-center position-relative">
          <div className="bg-left-corner"></div>
          <div className="bg-right-corner"></div>
          <div className="bg-meal-plans-center"></div>
          <div className="row">
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal3} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Keto</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                26 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal4} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Clean Eating</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                7 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal2} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Weight Loss</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                0 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal1} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Athletes</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                0 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal4} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Plant Based</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                0 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xl-2 mb-5">
              <div className="position-relative mb-4 plans-card-image">
                <img src={Meal1} className="img-fluid" alt="" />
              </div>
              <div className="plans-card-title">
                <h4>Juice Plan</h4>
              </div>
              <div className="mb-2 plans-card-duration">
                0 Days
              </div>
              <div className="plans-card-more-button">
                <a href="#" className="btn transparent-gold-button">Try now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>*/}

{/*<section id="current-meal-plan">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="current-meal-plan-wrap position-relative">
          <div className="bg-current-plan-right"></div>
          <div className="bg-current-plan-center"></div>
          <div className="row h-100">
            <div className="col-12 col-lg-9 offset-lg-3 col-xl-7 offset-xl-2">
              <h3>Keto - Working Week</h3>
              <div className="font-weight-bold mb-2">
                Breakfast & Lunch or Lunch & Dinner
              </div>
              <div className="current-meal-plan-description mb-2">
                This plan includes meat and seafood. Gluten & dairy free.
                Get to know our flavor profiles through this short plan over five days. This plan provides delicious main meals. Option to boost your plan with healthy snacks.
              </div>
            </div>
            <div className="col-12 col-lg-9 offset-lg-3 col-xl-3 offset-xl-0 my-auto">
              <div className="current-plans-dates text-center text-xl-right mb-2">
                <span className="start-date">16th Jun</span> to <span className="end-date">21th Jun 2019</span>
              </div>
              <div className="delivery-buttons text-center mb-3">
                <a href="#" className="btn transparent-gold-button">Pause delivery</a>
                <a href="#" className="btn gold-button">Renew now</a>
              </div>
            </div>
            <div className="col-12 text-center">
              <a href="#">View Previous Plans</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

{/*<section id="referal-program">
  <div className="container-fluid">
    <div className="row">
      <div className="d-none d-lg-block col-lg-3 mb-4 text-center">
        <div className="invitation-wrap position-relative">
          <div className="bg-left-corner"></div>
          <h3 className="mb-3">Refer A Friend</h3>
          <div className="row mb-5">
            <div className="col-6">
              <div className="mx-auto mb-2 friend-invited">
                <canvas id="friend-invited" data-value="12" data-label=""></canvas>
              </div>

              <h5>Friends Invited</h5>
            </div>
            <div className="col-6">
              <div className="mx-auto mb-2 cdr">
                <canvas id="cdr" data-value="10" data-label=""></canvas>
              </div>
              <h5>Complimentary Days Received</h5>
            </div>
          </div>
          <div className="invite-button mb-5">
            <a href="#" className="btn gold-button wide-button">Send Invite</a>
          </div>
          <div className="gold mb-2">
            Rewards when your friends join
          </div>
          <div className="rewards-table grey">
            <table className="mx-auto" cellSpacing="5" cellPadding="15">
              <tbody>
                <tr>
                  <td className="text-right">+2 Complimentary Days</td>
                  <td className="text-left">For Every 10 Invites</td>
                </tr>
                <tr>
                  <td className="text-right">+2 Complimentary Days</td>
                  <td className="text-left">For every friend who subscribes for a month</td>
                </tr>
                <tr>
                  <td className="text-right">+1 Complimentary Days</td>
                  <td className="text-left">For every friend who subscribes for a week</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-4">
        <div className="voucher-wrap">
          <h3 className="text-center">
            Vouchers
          </h3>
          <div className="row h-100">
            <div className="col-12 col-xl-8 my-auto">
              <h4>Free Keto plan for 5 days!</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 col-xl-4 my-auto text-center">
              <div className="claim-button">
                <a href="#" className="btn transparent-button">Claim</a>
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
          </div>
          <div className="row h-100">
            <div className="col-12 col-xl-8 my-auto">
              <h4>15% for all plans </h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 col-xl-4 my-auto text-center">
              <div className="claim-button">
                <a href="#" className="btn transparent-button">Claim</a>
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
          </div>
          <div className="row h-100">
            <div className="col-12 col-xl-8 my-auto">
              <h4>Free Keto plan for 5 days!</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 col-xl-4 my-auto text-center">
              <div className="claim-button">
                <a href="#" className="btn transparent-button">Claim</a>
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
          </div>
          <div className="row h-100">
            <div className="col-12 col-xl-8 my-auto">
              <h4>15% for all plans </h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 col-xl-4 my-auto text-center">
              <div className="claim-button">
                <a href="#" className="btn transparent-button">Claim</a>
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
          </div>
          <div className="all-voucher-button text-center mb-4">
            <a href="#">View All Vauchers</a>
          </div>
          <div className="voucher-notes text-center grey">
            *Only one discount can be used at a time on a single plan. Cannot for redemmed for cash
          </div>
          <div className="get-voucher-form">
            <form action="">
              <div className="row">
                <div className="col-12 col-lg-6 text-center text-lg-right">
                  <input type="text" placeholder="Code" />
                </div>
                <div className="col-12 col-lg-6 text-center text-lg-left">
                  <input className="btn gold-button" type="submit" value="Get voucher" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 d-lg-none mb-4 text-center">
        <div className="invitation-wrap position-relative">
          <div className="bg-left-corner"></div>
          <h3 className="mb-3">Refer A Friend</h3>
          <div className="row mb-5">
            <div className="col-6">
              <div className="mx-auto mb-2 friend-invited">
                <canvas id="friend-invited-m" data-value="12" data-label=""></canvas>
              </div>

              <h5>Friends Invited</h5>
            </div>
            <div className="col-6">
              <div className="mx-auto mb-2 cdr">
                <canvas id="cdr-m" data-value="10" data-label=""></canvas>
              </div>
              <h5>Complimentary Days Received</h5>
            </div>
          </div>
          <div className="invite-button mb-5">
            <a href="#" className="btn gold-button wide-button">Send Invite</a>
          </div>
          <div className="gold mb-2">
            Rewards when your friends join
          </div>
          <div className="rewards-table grey">
            <table className="mx-auto" cellSpacing="5" cellPadding="15">
              <tbody>
                <tr>
                  <td className="text-right">+2 Complimentary Days</td>
                  <td className="text-left">For Every 10 Invites</td>
                </tr>
                <tr>
                  <td className="text-right">+2 Complimentary Days</td>
                  <td className="text-left">For every friend who subscribes for a month</td>
                </tr>
                <tr>
                  <td className="text-right">+1 Complimentary Days</td>
                  <td className="text-left">For every friend who subscribes for a week</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3 mb-4">
        <div className="gift-wrap">
          <h3 className="text-center">
            Gift a friend
          </h3>
          <div className="row">
            <div className="col-12">
              <h4 className="gift-title">50 AED Voucher</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
            <div className="col-12">
              <h4 className="gift-title">100 AED Voucher</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
            <div className="col-12">
              <h4 className="gift-title">Weekly Meal Plan</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
            <div className="col-12">
              <h4 className="gift-title">100 AED Voucher</h4>
              <div className="voucher-description grey">
                All meals are delivered ready to eat and crafted to taste great cold or heated up.
              </div>
            </div>
            <div className="col-12 voucher-line"></div>
          </div>
          <div className="all-gift-button text-center">
            <a href="#">View All Gifts</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>*/}
{/*<section id="charts">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 col-lg-3 mb-4">
        <div className="fitbit-wrap d-flex">
          <a href="#"><img src={Fitbit} alt="" /></a>
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-4">
        <div className="charts-wrap">
          <h3 className="text-center mb-3">Charity</h3>
          <div className="row">
            <div className="col-12 col-md-4 mb-3 text-center">
              <div className="charts-height position-relative">
                <canvas id="ramadan" data-value="1000" data-label="PCS"></canvas>
                <h5>
                  Ramadan Charity Boxes Donated
                </h5>
                <div className="donate-button">
                  <a href="#" className="btn gold-button">DONATE NOW</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3 text-center">
              <div className="charts-height position-relative">
                <canvas id="meals" data-value="100" data-label="Meals"></canvas>
                <h5>
                  Make A Wish - Healthy Meal 
                </h5>
                <div className="donate-button">
                  <a href="#" className="btn gold-button">DONATE NOW</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3 text-center">
              <div className="charts-height position-relative">
                <canvas id="juice" data-value="500" data-label="PCS"></canvas>
                <h5>
                  Juice Distributed 
                </h5>
                <div className="donate-button">
                  <a href="#" className="btn gold-button">DONATE NOW</a>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="col-12 col-lg-3 mb-4">
        <div className="insta-wrap position-relative">
          <div className="insta-bg"></div>
          <div className="row no-gutters text-center">
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst1} alt="" />
            </div>
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst2} alt="" />
            </div>
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst3} alt="" />
            </div>
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst4} alt="" />
            </div>
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst5} alt="" />
            </div>
            <div className="col-4 px-1 py-1">
              <img className="img-fluid" src={Inst6} alt="" />
            </div>
          </div>
          <div className="insta-buttons px-1 mt-3">
            <a href="#" className="btn transparent-gold-button">View All</a>
            <a href="#" className="btn gold-button">ADD</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>*/}
          
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  dataArray: state.dataArray
});

const bindAction = dispatch => ({
  setModal: data => dispatch(setModal(data)),
  clearModal: data => dispatch(clearModal(data)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(mapStateToProps, bindAction, null, { withRefs: true })(Dashboard));
