export const SET_MEAL_PLAN = 'SET_MEAL_PLAN';
export const UPDATE_MEAL_PLAN = 'UPDATE_MEAL_PLAN';
export const CLEAR_MEAL_PLAN = 'CLEAR_MEAL_PLAN';

const initialState = {};

export function setMealPlan(obj) {
  return {
    type    : SET_MEAL_PLAN,
    payload : obj
  };
}

export function updateMealPlan(date, obj) {
  return {
    type    : UPDATE_MEAL_PLAN,
    payload : { date, obj }
  };
}

export function clearMealPlan() {
  return {
    type    : CLEAR_MEAL_PLAN
  };
}

export default function mealPlanReducer(state = initialState, action) {
  if (action.type === SET_MEAL_PLAN) {
    return action.payload;
  }
  if (action.type === UPDATE_MEAL_PLAN) {
    const { date, obj } = action.payload;
    return { ...state, [date]: obj };
  }
  if (action.type === CLEAR_MEAL_PLAN) {
    return initialState;
  }
  return state;
}
