import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import HeadLine from 'layouts/PageLayout/HeadLine';
import PaperButton from 'react-paper-button';
import { setModal } from '../../../store/modal';
import InputCalendar from 'components/InputCalendar';
import { browserHistory } from 'react-router';
const animateTime = 100;
class BirthDay extends Component {
  constructor(props) {
    super(props);
    const { userId } = this.props.params;
    this.state = {
        userId: atob(userId),
        birthday:'',
      errors: {},
      xyz: false
    };
  }

  getUserData = () => this.props.socket.emit('profile', { type: 'getUserInfo', data: {user_id:this.state.userId,userId:this.state.userId} });

  listener = action => {
    if (this.BirthDayRef) {
        console.log(action.type);
      switch (action.type) {
        case 'setBirthdayOk':
          this.props.setModal({ headLine: 'Thank you', message: action.data.message });
            setTimeout(function(){
                browserHistory.push('/');
            },1000)
          break;
          case 'getUserInfoOk':
            this.setState({birthday:action.data.birthday});
            break;
        case `${action.type}`:
            browserHistory.push('/');
        //   this.props.setModal({ headLine: 'Error', message: action.data.message });
          break;
      }
    }
  };
  changeUserInfo = (name, value, errorsToUnset) => 
  {   
      this.setState({
        [name]:value,
      });
  }
  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    this.getUserData();
  }

  saveData = () => {
    const { birthday,userId } = this.state;
    const {  socket } = this.props;
    if (!birthday) {
      let errors = {};
      errors = !birthday ? { ...errors, 'birthday': 'Please select the birthday date!!!' } : errors;
      this.setState({ errors });
    } else {
      socket.emit('profile', {
        type: 'setBirthday',
        data: { userId, birthday}
      });
    }
  };

  render() {
    const { xyz, birthday, errors } = this.state;
    return (
      <div className={`d-flex main-holder flex-column f-page ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.BirthDayRef = el)}>
        <HeadLine
          title='Not the correct date of your birthday?'
          desc='Please enter your correct birthday here'
        />
        <div className='container-fluid regular-page feedback-page general-feedback'>
          <div className='row'>
            <div className='col-12'>
              <div className='letter-xs mb-1 light-dark-text'></div>
              <div className='bg-gray-bordered padded rating-side'>
              <InputCalendar
                  value={birthday || ''}
                  fullYear
                  locale='en'
                  name='birthday'
                  onChange={birthday => this.changeUserInfo('birthday', birthday)}
                  errors={errors}
                  disable={false}
                  placeholder={'Birthday'}
                  defaultValue={moment().subtract(18, 'y')}
                />
                {errors.rating && <span className='has-error letter-xs red-color'>{errors.rating}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid bottom-row m-0 general-feedback'>
          <div className='row step-holder general-feedback bottom-buttons-holder'>            
          <div className='col-12 col-lg-4 m-0 p-0'></div>
            <div className='col-12 col-lg-4 m-0 p-0'>
              <PaperButton onClick={this.saveData} className='button-regular transition next'>
                <span>Save</span>
              </PaperButton>
            </div>
            <div className='col-12 col-lg-4 m-0 p-0'></div>
          </div>
        </div>
      </div>
    );
  }
}

BirthDay.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  user_id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  user_id: state.user.user_id
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(BirthDay));
