import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DishDescription from 'components/DishDescription';
import PlanCalendar from 'components/PlanCalendar';
import DailyMenu from 'components/DailyMenu';
import Order from './Order';
import Main from './Main';
import { clearMealPlan, setMealPlan } from 'store/mealPlan';
import { updatePlanParams } from 'store/planParams';
import { browserHistory } from 'react-router';
import { getArrays, parseDates, diff } from 'lib';
import SelectValidation from 'components/SelectValidation';

// "place my order button"
import PaperButton from 'react-paper-button';
import { setModal } from 'store/modal';
import { setPlanParams } from 'store/planParams';
// ----------------------

require('moment-weekday-calc');

const animateTime = 100;

class Plan extends Component {
  constructor(props) {
    super(props);
    this.animateRun = false;
    this.state = {
      showChildren: true,
      xyz: false,
      showOrder: false,
      main: false,
      order: false,
      showDescription: false,
      showOnMobileBlock: 'dishes',
      prevOnMobileBlock: 'dishes',
      showToggler: true,
      calendarDate: {}
    };
  }

  onChange = date => {
    if (this.MenuPlanRef) {
      this.date = date;
      this.setState({ calendarDate: date });
      if (this.PlanMainRef) {
        this.PlanMainRef.setState({ date });
        this.PlanMainRef.MainRef.scrollTop = 0;
      }
      if (this.PlanOrderRef) {
        this.PlanOrderRef.getWrappedInstance().setState({ date });
      }
      if (this.PlanCalendarRef) {
        this.PlanCalendarRef.setState({ date });
      }
    }
  };

  hideToggler = (hide) => {
    this.setState({ showToggler: hide });
  };

  showOnMobileBlockToggler = (block) => {
    if (window.innerWidth <= 768 || screen.width <= 768) {
      this.setState({ prevOnMobileBlock: this.state.showOnMobileBlock });
      this.setState({ showOnMobileBlock: block });
      document.querySelector('body').scrollIntoView();
      // console.log(this.state.showOnMobileBlock, this.state.prevOnMobileBlock);
    }
  };

  goMenu = (type, val) => {
    if (!this.animateRun) {
      this.animateRun = true;
      if (this.state.showChildren) {
        setTimeout(
          () => {
            if (this.state[type] !== val) {
              let state = { showChildren: true };
              if (!this.state[type]) {
                state = { ...state, showOrder: false, showDescription: false };
              }
              this.setState({ ...state, [type]: val });
              setTimeout(() => { this.animateRun = false; }, animateTime);
            } else {
              this.setState({ showOrder: false, showDescription: false, showChildren: true });
              this.animateRun = false;
            }
            this.hideToggler(false);
          },
          animateTime
        );
      }
      this.setState({ showChildren: false });
    }
  };

  hide = type => {
    if (!this.animateRun) {
      this.setState({ showChildren: false });
      setTimeout(
        () => {
          this.setState({ [type]: false });
          this.setState({ showChildren: true });
        },
        animateTime
      );
    }
  };

  checkDefaultDishes = ({ mealType }, mealPlan) => {
    const existsTypes = Object.values(mealPlan).reduce((acc, cur) => Object.keys(cur).reduce((acc, cur) => acc.includes(+cur) ? acc : [...acc, +cur], acc), []);
    if (diff(mealType, existsTypes).length || diff(existsTypes, mealType).length) {
      this.setDefaults();
    }
  };

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
    const { socket, dataArray, planParams, mealPlan } = this.props;
    const types = ['products', 'ingredientList'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    this.checkDefaultDishes(planParams, mealPlan);
  }

  setDefaults() {
    const { defaultDishes, mealPlan: oldMealPlan, setMealPlan, planParams } = this.props;
    const { mealType: types } = planParams;
    const mealPlan = Object.keys(oldMealPlan).reduce((acc, date) => {
      const curDoM = moment.unix(date).utc().locale('GB').date() - 1;
      const defaultInfo = types.reduce((acc, type) => {
        const curDefDish = (defaultDishes[type] || {})[curDoM];
        return curDefDish ? { ...acc, [+type]: +curDefDish } : acc;
      }, {});
      return { ...acc, [+date]: defaultInfo };
    }, {});
    setMealPlan(mealPlan);
  }

  // "save for later" button:

  save = () => {
    this.props.setModal({ message: 'Your data is saved. Please add this page to bookmarks and complete your menu modify later.' });
  }

  // "place my order button"
  submit = () => {
    const { showOrder, showDescription, main, order, xyz, showOnMobileBlock, prevOnMobileBlock, showToggler, calendarDate } = this.state;
    const { dataArray, planParams, locale, mealPlan, settings, spin, setPlanParams} = this.props;
    const excludedDates = parseDates(settings.getValue('HOLIDAYS'));
    const plan = dataArray['planList'] && dataArray['planList'][planParams['mealPlan']];
    const date = moment.unix(planParams['date']).locale(locale).utc().startOf('day');
    const excludeWeekDay = (plan && plan.excludeWeekDay) || [];
    this.date = date.clone().startOf('week');
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excludeWeekDay.includes(el));

    const period = plan ? {
      from: date,
      to: date.clone().addWeekdaysFromSet(+plan.count - 1, includesDay, excludedDates).startOf('day')
    } : false;

    if (Object.keys(mealPlan).length === +plan.count) {
      let date = period.from.clone();
      const type = planParams['mealType'];
      const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !plan.excludeWeekDay.includes(el));
      let freedate = [];
      while (date.isSameOrBefore(period.to)) {
        const unix = date.unix();
        if (mealPlan[unix] && Object.keys(mealPlan[unix]).length !== type.length) {
          freedate = [...freedate, unix];
        }
        date = date.addWeekdaysFromSet(1, includesDay, excludedDates);
      }
      if (freedate.length) {
        this.props.setModal({ message: 'Please fill in all menu to place your order' });
      } else {
        spin();
        setPlanParams({ ...planParams, step: Math.max(planParams.step, 4) });
        this.modalSubmit();
      }
    } else {
      this.props.setModal({ message: 'Please fill in all menu to place your order' });
    }
  };

  modalSubmit = () => {
    browserHistory.push('/menu/profile');
  };
  // ----------------------

  render() {
    const { showOrder, showDescription, main, order, xyz, showOnMobileBlock, prevOnMobileBlock, showToggler, calendarDate } = this.state;
    const { dataArray, planParams, locale, mealPlan, settings, spin } = this.props;
    const excludedDates = parseDates(settings.getValue('HOLIDAYS'));
    const { products, planList, typeList, ingredientList, dietList } = dataArray;
    const { renew } = planParams;
    const plan = (planList && planParams['mealPlan']) && planList[planParams['mealPlan']];
    const planName = (dietList && planParams['diet']) && dietList[planParams['diet']];
    const planTitle = plan && plan.title;
    const date = moment.unix(planParams['date']).locale(locale).utc().startOf('day');
    const excludeWeekDay = (plan && plan.excludeWeekDay) || [];
    this.date = date.clone().startOf('week');
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excludeWeekDay.includes(el));
    const period = plan ? {
      from: date,
      to: date.clone().addWeekdaysFromSet(+plan.count - 1, includesDay, excludedDates).startOf('day')
    } : false;
    let isMob = false;
    if (window.innerWidth <= 768 || screen.width <= 768) {
      isMob = true;
    }
    const { goMenu, onChange, showOnMobileBlockToggler } = this;
    const wrapOneDay = showOrder || (showDescription && products[showDescription]) ? 'wrap-one-day' : '';
    const toShow = (showOnMobileBlock === 'dishes');
    return (
      <Fragment>
        {(isMob && !showOrder && !showDescription) &&
          <div className={'row togle-dishes-menu hidden-on-desktop'}>
            <div className='col-4 col-sm-4 btnb text-center'>
              <p className={'regular-button'} onClick={() => browserHistory.push('/menu/preferences')}>Back</p>
            </div>
            <div className='col-4 col-sm-4 btnb text-center'>
              <p className={`regular-button ${showOnMobileBlock === 'dishes' ? 'next' : ''}`}
                onClick={() => showOnMobileBlockToggler('dishes')}
              >Dishes</p>
            </div>
            <div className='col-4 col-sm-4 btnb text-center'>
              <p className={`regular-button ${showOnMobileBlock === 'menu' ? 'next' : ''}`}
                onClick={() => showOnMobileBlockToggler('menu')}
              >Order</p>
            </div>
          </div>}
        <div className={`height-papa main-holder plan-page ${xyz ? 'xyz-fin' : 'xyz'} ${wrapOneDay}`} ref={el => (this.MenuPlanRef = el)}>
          <div className='w-100 main-inner flex-grow p-0'>
            <div className={'d-flex flex-column h-100 mx-0'}>
              <div className={'h-100 flex-grow px-0'}>
                {(period && products) && <div className={'row h-100 mx-0'}>
                  {main && !isMob && order && <PlanCalendar
                    ref={el => (this.PlanCalendarRef = el)}
                    bottomButtonClick={() => browserHistory.push('/menu/preferences')}
                    dayRenderer='origin'
                    date={this.date}
                    mealType={planParams.mealType}
                    showOnMobile={showOnMobileBlock}
                    {...{ excludeWeekDay, excludedDates, period, mealPlan, locale, onChange }}
                  >
                  </PlanCalendar>}
                  { toShow &&
                    <Main
                      ref={el => (this.PlanMainRef = el)}
                      goMenu={this.goMenu}
                      onReady={() => this.setState({ main: true })}
                      date={this.date}
                      period={period}
                      excludeWeekDay={excludeWeekDay}
                      excludedDates={excludedDates}
                      showOnMobile={showOnMobileBlock}
                      hideToggler={this.hideToggler}
                      planName={planName}
                      planTitle={planTitle}
                      bottomButtonClick={() => browserHistory.push('/menu/preferences')}
                      {...{ isMob, showToggler, showOnMobileBlock, showOnMobileBlockToggler }}
                    />
                  }
                  <div className={`list chat-holder-part menu-part ${!isMob ? 'h-100' : ''} p-0 `}>
                    <div className={'d-flex flex-column mobile justify-content-start h-100 w-100'}>
                      <div className={`custom-scrollbar inner-plan-details mobile h-100 d-flex flex-column ${wrapOneDay}`}>
                        {!showOrder && !showDescription &&  <Order
                          spin={spin}
                          ref={el => (this.PlanOrderRef = el)}
                          date={Object.keys(calendarDate).length ? calendarDate : this.date}
                          bottomButtonClick={() => browserHistory.push('/menu/preferences')}
                          onChangeDate={onChange}
                          onReady={() => this.setState({ order: true })}
                          {...{ excludeWeekDay, excludedDates, includesDay, period, showOnMobileBlock, isMob, showToggler, goMenu, showOnMobileBlockToggler }}
                        />}
                        {showOrder && <DailyMenu
                          dishes={mealPlan[showOrder]}
                          itemClick={productId => goMenu('showDescription', productId)}
                          {...{ typeList, products, ingredientList, showOnMobileBlock, prevOnMobileBlock, showOnMobileBlockToggler }}
                        >
                          <div className='row m-0 p-0'>
                            <div className='back col-3 p-0 m-0' onClick={() => { this.hide('showOrder'); this.hideToggler(true); }}><span>←</span>Back</div>
                          </div>
                          <h2> Menu for: <span className='green date'>{moment.unix(showOrder).format('dddd, Do MMM')}</span></h2>
                        </DailyMenu>}
                        {(showDescription && products[showDescription]) && <DishDescription
                          product={{ ...products[showDescription], id: showDescription }}
                          ingredients={dataArray['ingredientList'] || {}}
                          onClick={() => { this.hide('showDescription'); this.hideToggler(true); }}
                          {...{ isMob, showOnMobileBlock, prevOnMobileBlock, showOnMobileBlockToggler }}
                        />}

                        <div className="row weeks-nav">
                          <div className="col-12">
                            <PaperButton className='place-order next transition w-100 save-later' onClick={this.save}>
                              Save for Later
                            </PaperButton>
                            <PaperButton className='place-order next transition w-100' onClick={this.submit}>
                              Place my order
                            </PaperButton>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          <div
            className='xxx'
            style={{
              width: '50px',
              height: '50px',
              position: 'fixed',
              bottom: '15px',
              right: '5px',
              // border: '1px solid #000',
              borderRadius: '10px',
              cursor: 'pointer'
            }}
            onClick={() => this.props.clearMealPlan()}
          />
        </div>
      </Fragment>
    );
  }
}

Plan.propTypes = {
  socket: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  defaultDishes: PropTypes.object.isRequired,
  clearMealPlan: PropTypes.func.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  updatePlanParams: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  settings: PropTypes.object,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  planParams: state.planParams,
  defaultDishes: state.defaultDishes,
  mealPlan: state.mealPlan,
  locale: (state.user.language || 'en-US').substr(-2),
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  clearMealPlan: () => dispatch(clearMealPlan()),
  setMealPlan: obj => dispatch(setMealPlan(obj)),
  updatePlanParams: obj => dispatch(updatePlanParams(obj)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(Plan));
