import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getDataArray } from 'store/dataArray';
import moment from 'moment';
import noImage from 'assets/no_img.svg';

class BlogView extends Component {
  constructor(props) {
    super(props);
    const page = 1;
    const limit = 20;
    this.state = {
      posts: {},
      selectedCategory: 999999999,
      page,
      limit,
      xyz: false
    };
    this.getPosts((page - 1) * limit, limit, null);
  }

  getPosts(offset, limit, category) {
    if (category == 999999999) {
      category = null;
    }
    this.props.socket.emit('blog', {
      type: 'infoList',
      data: {
        offset,
        limit,
        categories: category ? [category] : []
      }
    });
  }

  listener = action => {
    if (this.BlogRef) {
      switch (action.type) {
        case 'infoListOk':
          const { posts: newPosts, page: newPage } = action.data;
          const { posts: oldPosts, page: oldPage } = this.state;
          const posts = newPage !== oldPage ? { ...oldPosts, ...newPosts } : newPosts;
          this.setState({ posts, page: newPage });
          break;
        case 'infoListErr':
          console.error(action.data.message);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('blog', this.listener);
    //window.x = getDataArray(['blogCategories']);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('blog', this.listener);
  }

  componentDidMount() {
    if (this.BlogRef) {
      const { blogCategories, socket } = this.props;
      if (socket && !Object.keys(blogCategories).length) {
        getDataArray({ keys: ['blogCategories'], socket });
      }
      if (blogCategories && Object.keys(blogCategories).length && !this.state.selectedCategory) {
        const selectedCategory = Object.keys(blogCategories).reduce((acc, cur) => cur < acc ? +cur : acc, Infinity);
        this.setState({ selectedCategory });
      }
      setTimeout(() => this.setState({ xyz: true }), 300);
    }
  }

  componentWillReceiveProps(next) {
    if (this.BlogRef && Object.keys(next.blogCategories).length && !this.state.selectedCategory) {
      const selectedCategory = Object.keys(next.blogCategories).reduce((acc, cur) => cur < acc ? +cur : acc, Infinity);
      if (!Object.keys(this.state.posts).length) {
        const { offset, limit } = this.state;
        this.getPosts(offset, limit, selectedCategory);
      }
      this.setState({ selectedCategory });
    }
  }

  onScrollHandler = e => {
    const post = this.beacon;
    const t = e.target;
    if (post) {
      const height = t.scrollHeight - t.clientHeight - post.clientHeight;
      if (t.scrollTop > height) {
        const { page, limit, selectedCategory } = this.state;
        this.getPosts(page * limit, limit, selectedCategory);
        this.beacon = null;
      }
    }
  };

  replaceMadLogic(text) {
    text = text.replace(/&period;/g, '.');
    text = text.replace(/&comma;/g, ',');
    text = text.replace(/&quest;/g, '?');
    return text;
  }

  render() {
    const { xyz, posts, selectedCategory: cat, offset, limit, page } = this.state;
    const { blogCategories } = this.props;
    const changeCat = selectedCategory => {
      if (selectedCategory !== cat) {
        this.getPosts(offset, limit, selectedCategory);
        this.setState({ selectedCategory });
        document.querySelector('body').scrollIntoView();
      }
    };

    var categoriesMenu = [];
    categoriesMenu.push(
      <div className={`col-12 category ${+cat === 999999999 ? 'active' : ''}`} key={0} onClick={() => changeCat(999999999)}>
        <div className='row'>
          <div className='col-12'>
            <p className='category-title'>All articles</p>
          </div>
        </div>
      </div>
    );
    
    Object.keys(blogCategories).map(id => (
      categoriesMenu.push(<div className={`col-12 category ${+cat === +id ? 'active' : ''}`} key={id} onClick={() => changeCat(+id)}>
        <div className='row'>
          <div className='col-12'>
            <p className='category-title'>{blogCategories[id]}</p>
          </div>
        </div>
      </div>)
    ));
    const articles = Object.keys(posts).sort((a, b) => +posts[b].createdAt - +posts[a].createdAt).map((postId, idx) => {
      const post = posts[postId];
      return (
        <article className='article' key={postId} ref={el => {
          if (idx + 1 === +limit * page) {
            this.beacon = el;
          }
        }}>
          <Link to={`/nutrition-support/blog/article/${postId}`}>
            <img className='article-thumb' src={post.image || noImage} />
            <div className='word-content'>
              <p className='article-date'>{moment.unix(post.createdAt).format('MMMM DD, YYYY')}</p>
              <h1 className='article-title'>{this.replaceMadLogic(post.title)}</h1>
              <p className='article-desc'>{this.replaceMadLogic(post.description)}</p>
              {post.tags && <p className='categoryes'>{post.tags}</p>}
            </div>
          </Link>
        </article>
      );
    });
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.BlogRef = el)}>
        <div className='container-fluid bg-gray-blog blog-page' onScroll={this.onScrollHandler}>
          <div className='row title-holder blog-heading'>
            <div className='col-12 d-flex flex-column justify-content-center title-block blog'>
              <h1>Blog</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Nutrition support</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='row support-holder'>
            <div className='col-12 col-md-10 col-lg-10'>
              <div className='row sticky-back'>
                <div className='all-my p-0'>
                  <div className='blog-filters p-0'>
                    <div className='back-holder'>
                      <Link to='/nutrition-support' className='back-link'>
                        <span className='back'>&larr;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='filters-on-block hidden-on-desktop'>
                <div className='discussions-holder category-holder'>
                  {categoriesMenu}
                </div>
              </div>
              <div className='row masonry-wrap d-flex'>
                <Masonry
                  className={'masonry col-12'} // default ''
                  elementType={'div'} // default 'div'
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                >
                  {false && <article className='article'>
                    <a href='#'>
                      <div className='word-content'>
                        <p className='article-date text-center'>December 15, 2017</p>
                        <h1 className='article-title text-center'>Lorem ipsum Sed eiusmod esse aliqua sed incididunt aliqua incididunt mollit id et
                          sit proident dolor nulla sed commodo est ad minim elit reprehenderit nisi officia
                          aute incididunt.</h1>
                        <p className='categoryes text-center'>
                          <span>Tips & tricks</span>
                          <span>Paleo</span>
                        </p>
                      </div>
                    </a>
                  </article>}
                  {articles}
                </Masonry>
              </div>
            </div>
            <div className='col-2 hidden-md-down'>
              <div className='row category-holder'>
                {categoriesMenu}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlogView.propTypes = {
  socket: PropTypes.object.isRequired,
  blogCategories: PropTypes.object
};

BlogView.defaultProps = {
  blogCategories: {}
};

const mapStateToProps = state => ({
  blogCategories: state.dataArray.blogCategories,
  language: state.user.language,
  token: state.user.token
});

export default socketConnect(connect(mapStateToProps, null)(BlogView));
