import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
// import PropTypes from 'prop-types';
import CheckBox from '../../../components/CheckBox';
import SelectValidation from '../../../components/SelectValidation';
import PaperButton from 'react-paper-button';

const animateTime = 100;

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
  }

  next = () => {
    browserHistory.push('/menu/summary');
  };

  onChange = (name, value) => this.setState({ [name]: value });
  render() {
    const { xyz } = this.state;
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='container-fluid height-papa regular-page'>
          <div className='row my-plan choose-holder d-flex mb-4'>
            <div className='col-12 heading'>
              <h1>My meal plan summary</h1>
              <p className='sub-haeding mb-0'>
                <span> - This is the end - </span>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-7'>
              {/* summary list */}
              <div className='row'>
                {/* heading */}
                <div className='col-9'>
                  <p className='regular-bold w-100'>Product</p>
                </div>
                <div className='col-3'>
                  <p className='regular-bold w-100'>Price</p>
                </div>
                {/* end of heading */}
                {/* price line */}
                <div className='col-9 summary-product-holder'>
                  <p className='s-product-title green'>Breakfast</p>
                  <p className='s-product-count text-right'>
                    <span className='s-term'>1 week (no friday)</span>
                    <span className='s-count regular-bold green'>6</span>
                  </p>
                </div>
                <div className='col-3'>
                  <p className='s-product-count text-left'>
                    <span className='regular-bold green'>AED 184</span>
                  </p>
                </div>
                {/* end of price line */}
                <div className='col-9 summary-product-holder'>
                  <p className='s-product-title green'>Lunch</p>
                  <p className='s-product-count text-right'>
                    <span className='s-term'>1 week (no friday)</span>
                    <span className='s-count regular-bold green'>6</span>
                  </p>
                </div>
                <div className='col-3'>
                  <p className='s-product-count text-left'>
                    <span className='regular-bold green'>AED 336</span>
                  </p>
                </div>
                <div className='col-9 summary-product-holder'>
                  <p className='s-product-title green'>Dinner</p>
                  <p className='s-product-count text-right'>
                    <span className='s-term'>1 week (no friday)</span>
                    <span className='s-count regular-bold green'>6</span>
                  </p>
                </div>
                <div className='col-3'>
                  <p className='s-product-count text-left'>
                    <span className='regular-bold green'>AED 384</span>
                  </p>
                </div>
                <div className='col-9 summary-product-holder'>
                  <p className='s-product-title green'>Morning Snacks</p>
                  <p className='s-product-count text-right'>
                    <span className='s-term'>1 week (no friday)</span>
                    <span className='s-count regular-bold green'>6</span>
                  </p>
                </div>
                <div className='col-3'>
                  <p className='s-product-count text-left'>
                    <span className='regular-bold green'>AED 385</span>
                  </p>
                </div>
                <div className='col-9 summary-product-holder'>
                  <p className='s-product-title green'>Afternoon Snacks</p>
                  <p className='s-product-count text-right'>
                    <span className='s-term'>1 week (no friday)</span>
                    <span className='s-count regular-bold green'>6</span>
                  </p>
                </div>
                <div className='col-3'>
                  <p className='s-product-count text-left'>
                    <span className='regular-bold green'>AED 388</span>
                  </p>
                </div>
                {/* Subtotal */}
                <div className='col-9 summary-product-holder'>
                  <p className='total regular-bold'>Subtotal</p>
                </div>
                <div className='col-3 summary-product-holder'>
                  <span className='regular-bold'>AED 1,074</span>
                </div>
                {/* end of Subtotal */}
                {/* Shipping */}
                <div className='col-9 summary-product-holder'>
                  <p className='total regular-bold'>Shipping</p>
                </div>
                <div className='col-3 summary-product-holder'>
                  <span className='regular-bold'>Free delivery</span>
                </div>
                {/* end of Shipping */}
                {/* Total */}
                <div className='col-9 summary-product-holder'>
                  <p className='total regular-bold'>Total</p>
                </div>
                <div className='col-3 summary-product-holder'>
                  <span className='regular-bold'>AED 1,074</span>
                </div>
                <div className='col-7'>
                  <div className='row'>
                    <CheckBox
                      className='col-12'
                      labelClassName='checkbox-container checkbox-box'
                      spanClassName='regular'
                      spanClassName2='checkmark mt-1'
                      name='tandc'
                      title='i have read and accept the terms & conditions.'
                      value='true'
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                {/* end of Total */}
              </div>
            </div>
            {/* end of summary list */}
            <div className='col-5'>
              <div className='row'>
                <div className='col-12'>
                  <p>
                    <span className='regular-bold'>Program: </span>
                    <span className='regular-bold green'>Keto</span>
                  </p>
                </div>
                <div className='col-12'>
                  <p>
                    <span className='regular-bold'>Have a coupon?</span>
                  </p>
                  <div className='form-input '>
                    <SelectValidation
                      onChange={this.onChange}
                      emptyValue='Enter coupon code'
                      list={{
                        a: '10%',
                        b: 'AED 138'
                      }}
                    />
                  </div>
                </div>
                <div className='col-12 mt-5'>
                  <p>
                    <span className='regular-bold w-100 m-0 p-0'>Payment Method:</span>
                  </p>
                  <p className='m-0 p-0'>Please choose your payment method</p>
                  <CheckBox
                    className='col-12'
                    labelClassName='checkbox-container checkbox-box'
                    spanClassName='regular'
                    spanClassName2='checkmark mt-1'
                    name='cash_on_dev'
                    title='Cash on delivery'
                    value='true'
                    onChange={this.onChange}
                  />
                  <CheckBox
                    className='col-12'
                    labelClassName='checkbox-container checkbox-box'
                    spanClassName='regular'
                    spanClassName2='checkmark mt-1'
                    name='online'
                    title='Pay online with credit card'
                    value='true'
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row bottom-buttons-holder justify-content-between'>
            <PaperButton
              onClick={() => browserHistory.push('/menu/address')}
              className='col-3 button-regular transition d-flex align-items-center justify-content-center m-0 w-100'
            >
              <span>BACK</span>
            </PaperButton>
            <PaperButton onClick={this.next} className='col-3 button-regular transition d-flex align-items-center justify-content-center m-0 w-100'>
              <span>Place my order</span>
            </PaperButton>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
//
