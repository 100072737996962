import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal';
import { clearModal } from '../../../store/modal';
import { browserHistory } from 'react-router';

class PageLayout extends Component {
  close = () => {
    const { modal, clearModal } = this.props;
    clearModal();
    if (modal.closeRedirect) {
      browserHistory.push(modal.closeRedirect);
    } else if (modal.onClose) {
      modal.onClose();
    }
  };

  render() {
    const { modal } = this.props;
    return (
      <div>
        {modal.display &&
          <Modal {...modal} close={this.close} />
        }
      </div>
    );
  }
}

PageLayout.propTypes = {
  clearModal: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  modal: state.modal
});

const bindAction = dispatch => ({
  clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, bindAction)(PageLayout);
