import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleCalendar from 'components/SimpleCalendar';
import InputCalendar from 'components/InputCalendar';
import ToolTip from 'components/ToolTip';
import moment from 'moment';
import InputValidation from 'components/InputValidation';
import PaperButton from 'react-paper-button';
import noImage from 'assets/no_img.svg';
import femaleImage from 'assets/lady-measurement.png';
class BodyAnalysis extends Component {
  constructor(props) {
    super(props);
    let firstDay=moment().utc().startOf('month').unix();
    this.state = {
      selectedDate: moment().utc().startOf('day'),
      changedData: {},
      newWeight:{},
      selectedMonth: firstDay,
      selectedweek: moment.unix(firstDay).startOf('day').unix(),
      weekdays:[],
      errors:{}
    };
  }
  componentWillMount() {
    // const { selectedMonth } = this.state;
  }
  
 

  

  renderFields =()=>{ 
    const {  BFM, SMM, PBF } = this.props;
   
    const {  changedData, selectedweek, errors} = this.state;
    // console.log(changedData);
    // console.log(BFM);
    // console.log(moment.unix(selectedweek).format('DD/MM/YYYY'));
    const { SMM: newSMM, BFM: newBFM, PBF: newPBF } = changedData;
    const changeHandler = (name, value, date, mul = 1) => this.setState({ changedData: { ...changedData, [name]: { ...changedData[name], [+date]: value * mul } } });
    const stamp = selectedweek;
     return <Fragment><div className='col-6'>
     <h5 className='green'>Skeletal Muscle Mass</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'SMM'}
         value={((newSMM && newSMM[stamp]) || SMM[stamp])/100  || ''}
         placeholder='SMM'
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 100)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>Body Fat Mass</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'BFM'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newBFM && newBFM[stamp]) || BFM[stamp])/100 || ''}
         placeholder='BFM'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 100)}
       />
     </div>
   </div>
   <div className='col-12'>
     <h5 className='green'>Body Fat (PBF) in %</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'PBF'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newPBF && newPBF[stamp]) || PBF[stamp])/100 || ''}
         placeholder='PBF'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 100)}
       />
     </div>
   </div>
   </Fragment>;
  }
  
  validateValues = () =>{
    const { changedData, selectedweek } = this.state;
    const changeValues = changedData;
    let isSave= true;
    const validfeild=['SMM','BFM'];
    let error ={};
    // if(changedData[selectedweek]==undefined){
        validfeild.forEach((key)=>{
            if(changedData[key]== undefined || changedData[key][selectedweek]==undefined){
                error={...error,[selectedweek]:{...error[selectedweek],[key]:'Please enter value'}};
                isSave=false;
            }
        })
        this.setState({ errors: error});
        
    // }


    if(isSave){
      this.props.save(changeValues);
    }
  }
  render() {
    const {  selectedMonth } = this.state;
    console.log('selectedMonth');
    console.log(selectedMonth);
    let firstDay = moment.unix(selectedMonth).startOf('month').unix();
    console.log(moment.unix(firstDay).startOf('day').unix());
    return (
      <div className={'row'} style={{ maxWidth: '700px '}}>
        <div className={'col-12'}>
          <div className='row form-line text-left'>
          <div className='col-12'>
          <h5 className='green'>Select Month</h5>
          <InputCalendar
                  value={selectedMonth}
                  before
                  locale='en'
                  name='month'
                  onChange={selectedMonth => this.setState({selectedMonth:selectedMonth,selectedweek:moment.unix(moment.unix(selectedMonth).startOf('month').unix()).startOf('day').unix()})}
                  // errors={errors}
                  disable={false}
                  viewMode='months'
                  dateFormat='YYYY-MM'
                  placeholder={'Select Month'}
                />
            </div>   
            {selectedMonth && this.renderFields()}
          </div>
        </div>
        <div className='col-12 mt-2'>
          <PaperButton
            className={'place-order button-regular transition w-lg-50 ml-auto mr-auto'}
            onClick={() => this.validateValues()}
          >
            Save
          </PaperButton>
        </div>
      </div>
    );
  }
}

BodyAnalysis.propTypes = {
  locale: PropTypes.string.isRequired,
  progress: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired
};

export default BodyAnalysis;