/* eslint-disable no-octal-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

class InputValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pressed: false,
      mul: 1
    };
  }

  onChangeHandler(name, e) {
    this.props.onChange(name, e.target.value);
  }

  componentDidMount() {
    if (this.props.inputId) {
      this.InputRef.id = this.props.inputId;
    }
  }

  onKeyDownHandler(e) {
    if (this.props.type === 'number' && !this.state.pressed) {
      let mul = 1;
      switch (e.key) {
        case 'Alt':
          // mul = 0.1;
          break;
        case 'Shift':
          mul = 10;
          break;
        case 'Control':
          mul = 100;
          break;
      }
      this.setState({ mul, pressed: true });
    }
  }

  onKeyUpHandler(e) {
    if (this.props.type === 'number' && this.state.pressed && ['Alt', 'Shift', 'Control'].includes(e.key)) {
      this.setState({ mul: 1, pressed: false });
    }
  }

  render() {
    const { name, className, errors, classError, type, value, onKeyPress, placeholder, inputSelfClassName, autoComplete, min, max, step, mask, maskProps } = this.props;
    const { mul } = this.state;
    const newStep = mul !== 1 ? '' + (parseFloat(step) * mul) : step;
    const props = {
      type: type,
      name: name,
      placeholder: placeholder,
      className: inputSelfClassName,
      autoComplete: autoComplete,
      min: min,
      max: max,
      value: value,
      step: newStep, 
      onChange: e => this.onChangeHandler(name, e),
      onKeyPress: data => onKeyPress(data),
      onKeyDown: e => this.onKeyDownHandler(e),
      onKeyUp: e => this.onKeyUpHandler(e)
    };
    return (
      <div className={`${className} ${name in errors ? classError : ''}`}>
        {mask === 'none' ? (
          <input
            {...props}
            ref={el => (this.InputRef = el)}
          />
        ) : (
          <InputMask
            value={value}
            onChange={e => this.onChangeHandler(name, e)}
            {...maskProps}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                className={inputSelfClassName}
                placeholder={placeholder}
                ref={el => (this.InputRef = el)}
              />
            )}
          </InputMask>
        )}
        {name in errors && <span className={classError}>{errors[name]}</span>}
      </div>
    );
  }
}

InputValidation.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string,
  classError: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.oneOf(['none', 'tel']),
  maskProps: PropTypes.object,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  errors: PropTypes.object,
  inputSelfClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  min: PropTypes.any,
  max: PropTypes.any,
  step: PropTypes.string,
  inputId: PropTypes.any
};

InputValidation.defaultProps = {
  placeholder: '',
  inputId: false,
  type: 'text',
  className: 'form-input',
  classError: 'has-error letter-xs',
  value: '',
  name: '',
  mask: 'none',
  maskProps: {},
  min: '',
  max: '',
  step: '',
  onChange: () => {},
  onKeyPress: () => {},
  errors: {},
  inputSelfClassName: 'b-rounded-blue w-100',
  autoComplete: 'on'
};

export default InputValidation;
