import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import PaperButton from 'react-paper-button';
// import { Select2 } from "select2-react-component";
// import Select from 'react-select';

import './style.css';


class PrefsModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      xyz: false,
      allergies: [],
      dislikes: [],
      dishlikes: [],
      dishdislikes: [],
    };
    this.getPrefs();
  }

  getPrefs() {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getPrefs', 
          'userId': user.user_id,
        } 
    });
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
    this.props.socket.on('profile', this.listeners);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listeners);
  }

    listeners = action => {
      console.log(action);
      const {clearModal, setModal,dataArray} = this.props;
      const { ingredientList,productsFull } =dataArray;
      const {message} = action.data;
      switch (action.type) {
        case 'getPrefsOk':

          // var allergies = action.data.allergies.map((value, idx) => {
          //   return ({ value, label: ingredientList[value] });
          // });
         
          // var dislikes = (action.data.dislikes)&&action.data.dislikes.map((value, idx) => {
          //   return ({ value, label: ingredientList[value] });
          // });
        
          // var dishlikes = (action.data.dishlikes)&&action.data.dishlikes.map((value, idx) => {
          //   return ({ value, label: productsFull[value].title });
          // });
          // var dishdislikes = (action.data.dishdislikes)&&action.data.dishdislikes.map((value, idx) => {
          //   return ({ value, label: productsFull[value].title });
          // });

          this.setState({
            // allergies: action.data.allergies,
            // dislikes: action.data.dislikes,
            dishlikes: action.data.dishlikes,
            dishdislikes: action.data.dishdislikes,
          });

        break;
      }    
  };

  changePrefs(name, value) {
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }


  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }

  savePrefs() {
    const {dislikes, allergies, dishlikes, dishdislikes} = this.state;
    // console.log(this.state);
    // const allergiesValues =allergies&&allergies.map((value, idx) => {
    //   return value.value;
    // });
    // const dislikesValues =dislikes&&dislikes.map((value, idx) => {
    //   return value.value;
    // });
    // const dishlikesValues =dishlikes&&dishlikes.map((value, idx) => {
    //   return value.value;
    // });
    // const dishdislikesValues =dishdislikes&&dishdislikes.map((value, idx) => {
    // return value.value;
    // });
    const {user} = this.props;
    var data = {
        'subtype': 'savePrefs', 
        'userId': user.user_id,
        // 'dislikes': dislikes,
        // 'allergies': allergies,
        'dishlikes': dishlikes,
        'dishdislikes': dishdislikes,
    }
    this.props.socket.emit('profile', {type: 'getForDashboard', data: data});
    this.setNotification('Food Favourites', 1);
  }

  render() {
    const { needCloseButtons, buttons, needButtons, headLine, type, close } = this.props;
    const { xyz } = this.state;
    const {dislikes, allergies, dishlikes, dishdislikes} = this.state;
    
    const {errors, dataArray} = this.props;
    const {ingredientList, productsFull} = dataArray;
    
    var productsListData = [];
    var products = [];
    
   Object.keys(productsFull).map( (el, idx) => {
      // products[el] = productsFull[el].title; //.trim()
      products.push({label: productsFull[el].title, value: el});
    });

    var ingredientListData = [];
    var ingredients = [];
    Object.keys(ingredientList).map( (el, idx) => {
      ingredients[el] = ingredientList[el];
    });

    var ingredientsSorted = ingredients.sort();
    if (ingredientList) {
      ingredientsSorted.map( (eln, idxn) => {
        Object.keys(ingredientList).map( (el, idx) => {
          if (eln == ingredientList[el]) {
            ingredientListData.push({label: ingredientList[el], value: el});
          }
        });  
      });
    }
    // console.log(ingredientListData);
    // console.log(this.state);
    const className ='select2 select2-container select2-container--default   select2-container--below select2-container--focus';
   
    var message = (<div>
              

              <div className="popup-select-area">
                <b className="food-prefs-header">Favourite Dishes:</b>
                
                <div className={className}>
                <Select
                 multiple={true}
                 isgrouped={true}
                 placeholder='Change Favourite Dishes'
                  list={products}
                  value={dishlikes || []}
                  isSearchable={true}
                  name='dishlikes'
                  onChange={ (name, val) => {
                    this.changePrefs('dishlikes', val);
                  }}
                />
              </div> 
              </div>

              <div className="popup-select-area">
                <b className="food-prefs-header">Meals I dont like:</b>
             
                <div className={className}>
                <Select
                 multiple={true}
                 isgrouped={true}
                 placeholder='Meals I dont like'
                  list={products}
                  value={dishdislikes || []}
                  isSearchable={true}
                  name='dishdislikes'
                  onChange={ (name, val) => {
                    this.changePrefs('dishdislikes', val);
                  }}
                />
              </div> 
              </div>
               
                
                <p align="center">
                    <p className="popup-note-text"><b>Note:</b> Your Favourites will be saved but you would need to change dishes manually or contact our team to help you change.</p><br/>
                    <PaperButton className='button-regular next transition' 
                      onClick={ (e) => {this.savePrefs(); }} >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>);









    const listButtons = buttons.map((button, key) =>
      <div className={`d-flex justify-content-center mb-3 transition ${button.className}`} key={key}>
        <input type='button' className={`modal-button ${button.type}`} value={button.label} onClick={() => button.onClick(button.value)} />
      </div>
    );
    const PrefsModalHeadLineOuther = (headLine) =>
      <div className='row modal-header-styled this-height-fix'>
        <div className='col-12 title-block'>
          <p className={'w-100'}><span className='ar-golr-l' />
            <span className='m-title'>{headLine}</span>
            <span className='ar-golr-r' /></p>
        </div>
      </div>;
    const PrefsModalMessageOuther = (message) =>
      <div className='row'>
        <div className='col-12'>
          {message}
        </div>
      </div>;
    return (
      <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-back-layer ttt`}>
        <div className={`row justify-content-around align-items-center modal-father model-type-${type}`} onClick={() => close()}>
          <div className='modal-custom-body b-rounded-blue personal-shadow col-sm-10 col-md-6 col-lg-4 px-0' onClick={e => e.stopPropagation()}>
            {needCloseButtons && <div className='d-flex justify-content-end align-items-center px-3 close'>
              <i onClick={() => close()} className='icon-close letter-normal mt-2 pt-1 mb-2 pointer' />
            </div>}
            <div className='text-center modal-message pop-style dark-text w-100 letter-sm px-3'>
              {PrefsModalHeadLineOuther(headLine || 'Meal Plan')}
              {PrefsModalMessageOuther(message)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrefsModal.propTypes = {
  close: PropTypes.func.isRequired,
  headLine: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'success',
    'info'
  ]),
  needButtons: PropTypes.bool,
  needCloseButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf([
      'error',
      'success',
      'close',
      'info'
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string
  }))
};

PrefsModal.defaultProps = {
  type: 'success',
  needButtons: false,
  needCloseButtons: true,
  buttons: []
};

export default PrefsModal;
