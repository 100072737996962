import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MenuModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { xyz } = this.state;
    const { navLeft, navRight } = this.props;
    return (
      <div className={`m-page-menu-modal ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className={'scroll-auto'}>
          <ul className='border-bottom-light-dark pb-2 mb-2'>
            {navLeft}
          </ul>
          <ul>
            {navRight}
          </ul>
        </div>
      </div>
    );
  }
}

MenuModal.propTypes = {
  navLeft: PropTypes.node.isRequired,
  navRight: PropTypes.node.isRequired
};

export default MenuModal;
