import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';

class SimpleCalendar extends Component {
  render() {
    const {
      className, classNameCalendar, locale, viewMode, renderDay, renderMonth, renderYear, defaultValue,
      onNavigateBack, onNavigateForward, onViewModeChange, value, viewDate, changeOnClickOverMonth
    } = this.props;
    let customProps = value ? { value } : {};
    if (viewDate) {
      customProps = { ...customProps, viewDate };
    }
    return (
      <div className={`${className}`}>
        <Datetime
          locale={locale}
          className={classNameCalendar}
          onChange={e => this.props.onChange(e)}
          viewMode={viewMode}
          input={false}
          timeFormat={false}
          utc
          renderDay={renderDay}
          changeOnClickOverMonth={changeOnClickOverMonth}
          renderMonth={renderMonth}
          renderYear={renderYear}
          defaultValue={defaultValue}
          onViewModeChange={onViewModeChange}
          onNavigateBack={onNavigateBack}
          onNavigateForward={onNavigateForward}
          {...customProps}
        />
      </div>
    );
  }
}

SimpleCalendar.propTypes = {
  viewMode: PropTypes.string,
  className: PropTypes.string,
  classNameCalendar: PropTypes.string,
  locale: PropTypes.string.isRequired,
  renderDay: PropTypes.func,
  onViewModeChange: PropTypes.func,
  changeOnClickOverMonth: PropTypes.bool,
  onNavigateBack: PropTypes.func,
  onNavigateForward: PropTypes.func,
  renderMonth: PropTypes.func,
  renderYear: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  value: PropTypes.any,
  viewDate: PropTypes.any,
  onChange: PropTypes.func
};

SimpleCalendar.defaultProps = {
  viewMode: 'days',
  className: 'calendar-container search-profile-main-cal form-input calendar-input',
  classNameCalendar: 'simple-calendar',
  onViewModeChange: () => false,
  onNavigateBack: () => true,
  onNavigateForward: () => true,
  renderDay: (props, currentDate, selectedDate) => {
    return <td {...props}>{ currentDate.date() }</td>;
  },
  renderMonth: (props, month, year, selectedDate) => {
    return <td {...props}>{ moment().month(month).format('MMM') }</td>;
  },
  renderYear: (props, year, selectedDate) => {
    return <td {...props}>{ year }</td>;
  },
  onChange: e => e.startOf('day').unix(),
  defaultValue: moment().startOf('day'),
  changeOnClickOverMonth: true
};

export default SimpleCalendar;
