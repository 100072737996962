import React from 'react';
import PropTypes from 'prop-types';
import TextAriaValidation from '../../../../components/TextAriaValidation';

const TextAriaLayout = props => {
  return (
    <div className='col-12'>
      <div className='form-input text-area-input'>
        <TextAriaValidation
          {...props}
          className=''
          rows={2}
        />
      </div>
    </div>
  );
};

TextAriaLayout.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TextAriaLayout;
