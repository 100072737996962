import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextAriaValidation extends Component {
  render() {
    const { className, name, errors, classError, rows, inputClassName, value, onChange, onKeyDown, placeholder, iClassName, readOnly } = this.props;
    return (
      <div className={`${className} ${name in errors ? classError : ''}`}>
        <textarea
          className={inputClassName}
          rows={rows}
          name={name}
          readOnly={readOnly}
          value={value}
          onChange={e => onChange(name, e.target.value)}
          onKeyDown={data => onKeyDown(data)}
          placeholder={placeholder}
        />
        {name in errors && <span className={classError}>{errors[name]}</span>}
      </div>
    );
  }
}

TextAriaValidation.propTypes = {
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  classError: PropTypes.string,
  iClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  errors: PropTypes.object
};

TextAriaValidation.defaultProps = {
  placeholder: '',
  iClassName: '',
  readOnly: false,
  rows: 1,
  className: 'form-input text-area-input',
  inputClassName: 'w-100 h-100 letter-xs b-rounded-blue shadowed standart-input custom-scrollbar',
  classError: 'has-error letter-xs red-color',
  value: '',
  name: '',
  onChange: () => {},
  onKeyDown: () => {},
  errors: {}
};

export default TextAriaValidation;
