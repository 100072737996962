import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectValidation extends Component {
  render() {
    const { className, name, errors, classError, containerClassName, onChange, value, selectClassName, emptyValue, canEmpty, disabled, classSpanError } = this.props;
    let list = Object.keys(this.props.list).map((key, index) =>
      <option key={index} value={key}>{this.props.list[key]}</option>
    );

    var disabledcss = '';
    if (disabled) {
      disabledcss = 'disabled-select'
    }
    return (
      <div className={containerClassName}>
        <div className={`${className} ${name in errors ? classError : ''}`}>
          <select
            name={name}
            disabled={disabled} 
            onChange={e => onChange(name, e.target.value)}
            value={value}
            className={`${disabledcss} ${selectClassName}`}
          >
            {emptyValue && <option value='' disabled={!canEmpty}>{emptyValue}</option>}
            {list}
          </select>
          {name in errors && <span className={classSpanError}>{errors[name]}</span>}
        </div>
      </div>
    );
  }
}

SelectValidation.propTypes = {
  canEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  emptyValue: PropTypes.string,
  list: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  classError: PropTypes.string,
  classSpanError: PropTypes.string,
  containerClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object
};

SelectValidation.defaultProps = {
  canEmpty: false,
  disabled: false,
  emptyValue: '',
  list: {},
  className: 'form-input  shadowed b-rounded-blue styled-select pointer',
  containerClassName: 'pointer',
  selectClassName: 'pointer',
  classError: 'has-error letter-xs red-color',
  classSpanError: 'has-error letter-xs red-color',
  value: '',
  name: '',
  onChange: () => {},
  errors: {}
};

export default SelectValidation;
