import React, { Component } from 'react';
import OriginAddress from '../../Menu/Address';
import { browserHistory } from 'react-router';

class Address extends Component {
  render() {
    const nav = {
      prev: {
        action: () => browserHistory.push('/'),
        innerText: 'Cancel'
      },
      next: {
        action: () => browserHistory.push('/'),
        innerText: 'Save'
      }
    };
    return <OriginAddress nav={nav} />;
  }
}

export default Address;
