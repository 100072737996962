import React, { Component } from 'react';
class Telr extends Component {
    constructor(props) {
        super(props);
        const { action } = this.props.params;
        this.state = {
            action:action
        }; 
      }
    render() {
        const { action } = this.state;
       
        let message ='';
        let color ="";
        if(action=='fail'){
            color ="#721c24";
            message ='Payment Failed !!!';
        }else if(action=='cancel'){
            color ="#856404";
            message ='Payment Cancelled !!!. Please try again later';
        }else if(action=='success'){
            message ='Payment Completed Successfully!!!';
            color ="#155724";
        }
        return (<div  className={`row main-page m-0 main-holder`}>
                <div className='col-12 main-content without-scroll'>
                <h1 style={{color:color,fontSize:'30px',textAlign:'center'}}>{message}</h1>
        </div>
        </div>
        )
    }
}
export default Telr;