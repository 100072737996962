import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaperButton from 'react-paper-button';
// import Select from 'react-select';
import Select from 'components/Select';
import './style.css';


class DislikeModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      xyz: false,
      allergies: [],
      dislikes: [],
      dishlikes: [],
      dishdislikes: [],
      ingredientListData:[],
    };
    this.getPrefs();
  }

  getPrefs() {
    const {user} = this.props; 
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getPrefs', 
          'userId': user.user_id,
        } 
    });
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
    this.props.socket.on('profile', this.listeners);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listeners);
  }
  componentDidMount(){
    const {dataArray} = this.props;
    const {ingredientList} = dataArray;
    let ingredientListData = [];
    let ingredientList1 = Object.keys(ingredientList).sort((a,b)=>{
      const nameA = ingredientList[a].toUpperCase(); // ignore upper and lowercase
      const nameB = ingredientList[b].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    
    ingredientList1.map((val)=>{
      ingredientListData.push({label: ingredientList[val], value: String(val)});
    });
    this.setState({ingredientListData});
  }
    listeners = action => {
      const {dataArray} = this.props;
      const { ingredientList } =dataArray;
      switch (action.type) {
        case 'getPrefsOk':
          var dislikes = (action.data.dislikes)&&action.data.dislikes.map((value, idx) => {
            return ({ value:String(value), label: ingredientList[value] });
          });
          this.setState({
            dislikes: dislikes,
            allergies:action.data.allergies
          });

        break;
      }    
  };

  changeDislike(name, value) {
    this.setState({
      [name]: value
    });
  }


  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }

  saveDislike() {
    const { dislikes } = this.state;
   
    // const dislikesValues =dislikes&&dislikes.map((value, idx) => {
    //     return value.value;
    // });
    const {user} = this.props;
    var data = {
          'subtype': 'saveDislike', 
          'userId': user.user_id,
          'dislikes': dislikes
    }
    this.props.socket.emit('profile', {type: 'getForDashboard', data: data});
    this.setNotification('Food Preferences Dislikes', 1);
  }
 
  render() {
    const { needCloseButtons, buttons, headLine, type, close } = this.props;
    const { xyz } = this.state;
    const {dislikes, allergies, ingredientListData} = this.state;
    // console.log(dislikes);
    // console.log(allergies);
    const {errors, dataArray} = this.props;
    const {ingredientList} = dataArray;
    var allergiesData = [];
    if(allergies != undefined){
        allergies.forEach((alk)=>{      
          allergiesData.push(ingredientList[alk]);
        });
      }
    const className ='select2 select2-container select2-container--default   select2-container--below select2-container--focus';
   
    var message = (<div>
              
              
              <div className="popup-select-area">
                <b className="food-prefs-header">Dislikes:</b>
                
                <div className={className}>
                <Select
                  multiple={true}
                  isgrouped={true}
                  placeholder='Change Dislikes'
                  list={ingredientListData}
                  value={dislikes || []}
                  isSearchable={true}
                  name='dislikes'
                  // onChange={e => this.changeDislike('dislikes',e)}
                  onChange={ (name, val) => {
                    // console.log(val);
                    this.changeDislike('dislikes', val);
                  }}
                />
              </div> 
              
              </div>
             
                <p align="center">
                    <PaperButton className='button-regular next transition' 
                      onClick={ (e) => {this.saveDislike(); }} >
                        <span>Save</span>
                    </PaperButton>
                </p>
                <div className="dislist1"> <p align="center"><b className="food-prefs-header">Allergies: </b>
                  {allergiesData.length>0?allergiesData.join(" , "):'None'}</p>
               
              <p align="center">
                  <strong>Please contact team to update allergies</strong>
                </p>
                <br/>
                </div>
            </div>);









    const listButtons = buttons.map((button, key) =>
      <div className={`d-flex justify-content-center mb-3 transition ${button.className}`} key={key}>
        <input type='button' className={`modal-button ${button.type}`} value={button.label} onClick={() => button.onClick(button.value)} />
      </div>
    );
    const DislikeModalHeadLineOuther = (headLine) =>
      <div className='row modal-header-styled this-height-fix'>
        <div className='col-12 title-block'>
          <p className={'w-100'}><span className='ar-golr-l' />
            <span className='m-title'>{headLine}</span>
            <span className='ar-golr-r' /></p>
        </div>
      </div>;
    const DislikeModalMessageOuther = (message) =>
      <div className='row'>
        <div className='col-12'>
          {message}
        </div>
      </div>;
    return (
      <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-back-layer ttt`}>
        <div className={`row justify-content-around align-items-center modal-father model-type-${type}`} onClick={() => close()}>
          <div className='modal-custom-body b-rounded-blue personal-shadow col-sm-10 col-md-6 col-lg-4 px-0' onClick={e => e.stopPropagation()}>
            {needCloseButtons && <div className='d-flex justify-content-end align-items-center px-3 close'>
              <i onClick={() => close()} className='icon-close letter-normal mt-2 pt-1 mb-2 pointer' />
            </div>}
            <div className='text-center modal-message pop-style dark-text w-100 letter-sm px-3'>
              {DislikeModalHeadLineOuther(headLine || 'Meal Plan')}
              {DislikeModalMessageOuther(message)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DislikeModal.propTypes = {
  close: PropTypes.func.isRequired,
  headLine: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'success',
    'info'
  ]),
  needButtons: PropTypes.bool,
  needCloseButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf([
      'error',
      'success',
      'close',
      'info'
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string
  }))
};

DislikeModal.defaultProps = {
  type: 'success',
  needButtons: false,
  needCloseButtons: true,
  buttons: []
};

export default DislikeModal;