import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types';

class NavRight extends Component {
  
  render() {
    const { linkClass, isLogin, burgerToggle, burger, burgerHide, toggleMenu, stopSpinner } = this.props;
    const clickHandler = (e, link) => {
      e.preventDefault();
      stopSpinner();
      toggleMenu && toggleMenu(false);
      browserHistory.push(link);
    };
    let key = 0;
    const link = (title, link) => <li key={`link-${++key}`} className='nav-item d-flex align-items-center'>
      <Link className={linkClass} onClick={e => clickHandler(e, link)} to={link}>{title}</Link>
    </li>;
    const elseMenu = isLogin ? [
      (<li key={++key} className='nav-item d-flex align-items-center position-relative pl-4'>
        <i className='profile-icon-subs h-profile-ic icon-profile-ava pointer' onClick={() => burgerToggle()} />
        {burger && <div className='burger-content' onMouseLeave={() => burgerHide()}>
          <ul className='pl-0'>
            {link('Profile settings', '/profile')}
            {<li key={`link-${++key}`} className='nav-item d-flex align-items-center pointer'><a className={linkClass} onClick={e => this.props.logout(e)}>Log Out</a></li>}
          </ul>
        </div>}
      </li>)
    ] : [
      link('Sign In', '/sign-in')
      //link('Sign Up', '/sign-up')
    ];
    const mobelseMenu = isLogin ? [        
        <li key={`link-${++key}`} className='nav-item d-flex align-items-center pointer'><a className={linkClass} onClick={e => this.props.logout(e)}>Log Out</a></li>    
    ] : [
      link('Sign In', '/sign-in')      
    ];

if (window.innerWidth <= 768 || screen.width <= 768) {
    return [      
      <li className='nav-item d-flex align-items-center pointer'><a className={linkClass} onClick={e => this.props.showCalendlyModal()}>Book A Consultation</a></li>,
        link('Feedback', '/feedback/general'),
      link('Profile settings', '/profile'),
      ...mobelseMenu
    ];
}else{
    return [
      // link('Nutrition Support', '/nutrition-support'),
      link('Feedback', '/feedback/general'),
      //link('About Us', '/about'),
      ...elseMenu
    ];
}
    
  }
}

NavRight.propTypes = {
  linkClass: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  burger: PropTypes.bool.isRequired,
  burgerToggle: PropTypes.func.isRequired,
  burgerHide: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func,
  stopSpinner: PropTypes.func,
  showCalendlyModal:PropTypes.func
};

NavRight.defaultProps = {
  linkClass: ''
};

export default NavRight;