import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip/es/Tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

class ToolTip extends Component {
  render() {
    const { tooltipText, children, tooltipDirection, trigger, overlayClassName, overlayStyle } = this.props;
    return (
      <Tooltip
        {...{ trigger, overlayClassName, overlayStyle }}
        placement={tooltipDirection}
        destroyTooltipOnHide
        mouseLeaveDelay={0}
        overlay={tooltipText}
        arrowContent={<div className='rc-tooltip-arrow-inner' />}
      >
        {children}
      </Tooltip>
    );
  }
}

ToolTip.propTypes = {
  tooltipText: PropTypes.node.isRequired,
  children: PropTypes.node,
  overlayClassName: PropTypes.string,
  overlayStyle: PropTypes.object,
  tooltipDirection: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  trigger: PropTypes.array // 'click', 'hover', 'focus'
};

ToolTip.defaultProps = {
  overlayStyle: {},
  overlayClassName: '',
  tooltipDirection: 'right',
  children: null,
  trigger: ['hover', 'focus']
};

export default ToolTip;
