import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    
    const color = 'white';
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? '#001f3d'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'white'
        : isFocused
        ? 'white'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color :'white'),
      },
    };
  },
  input: styles => ({ ...styles}),
  placeholder: styles => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
  }
  handleBlur = (event) =>{

  }
  handleFocus = (event) =>{

  }
  render() {
    const { value, placeholder, className, classError, name, hasError, list, multiple, itself, disabled,isgrouped,menuIsOpen } = this.props;
    const { value: _value } = this.state;
    // console.log(list);
    var options = [];
    if(isgrouped!=undefined && isgrouped){
      options = list;
    }else{
      options = Object.keys(list).map(value => {
        return ({ value, label: list[value] });
      });
    }
    const onChange = itself ? (name, value) => this.setState({ value: value }) : this.props.onChange;
    const onBlur = (this.props.onBlur!=undefined)?this.props.onBlur:this.handleBlur;
    const onFocus = (this.props.onFocus!=undefined)?this.props.onFocus:this.handleFocus;
    return (
      <div className={`${className} ${hasError ? classError : ''}`} ref={el => (this.SelectContainerRef = el)}>
        <ReactSelect
          options={options}
          disabled={disabled}
          multi={multiple}
          removeSelected
          closeOnSelect
          placeholder={placeholder}
          value={itself ? _value : value}
          onBlur ={()=>onBlur()}
          onFocus ={()=> onFocus()}
          onChange={val => onChange(name, multiple ? val.map(el => el.value) : val ? val.value : null)}
          onMenuOpen={() => this.SelectContainerRef.classList.add('opened')}
          onMenuClose={() => this.SelectContainerRef.classList.remove('opened')}
          menuIsOpen={menuIsOpen}         
          styles={colourStyles}
        />
        {hasError.length && <span className={classError}>{hasError}</span>}
      </div>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  itself: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  list: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  classError: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

Select.defaultProps = {
  placeholder: '',
  multiple: false,
  disabled: false,
  itself: false,
  list: {},
  className: 'form-input',
  classError: 'has-error letter-xs red-color',
  value: '',
  name: '',
  onChange: () => {},
  hasError: false
};

export default Select;
