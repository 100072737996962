import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HorizontalScroll from 'components/HorizontalScroll';
import Rating from 'react-rating';
import noImage from 'assets/no_img.svg';

class List extends Component {
  constructor(props) {
    super(props);
    const { types } = props;
    this.state = {
      xyz: false,
      activeType: types && types.length ? +types[0] : false
    };
  }

  renderTypeList = () => {
    const { activeType } = this.state;
    const { typeList, types } = this.props;
    return (
      <ul className='dish-groups pl-0 mb-0'>
        {types && typeList ? types.map(typeId =>
          <li
            key={typeId}
            className={+typeId === +activeType ? 'active' : ''}
            onClick={() => this.setState({ activeType: +typeId })}
          >
            {typeList[+typeId].title}
          </li>
        ) : []}
      </ul>
    );
  };

  renderProduct = () => {
    const { activeType } = this.state;
    const { products, onChange, choice, dishes, ratings } = this.props;
    return Object.keys(dishes)
      .filter(id => products[id] && products[id].type.includes(+activeType))
      .map(id => {
        const dish = products[id];
        const drating=(dishes[id].rating!=null)?dishes[id].rating:ratings[id];
        return (
          <div className='products' key={id} onWheel={e => e.stopPropagation()}>
            <div className={`product transition ${+choice === +id ? 'chosen' : 'not'}`}>
              <div className='clickable' onClick={() => onChange(id)}>
                <div className='img-holder' style={{ backgroundImage: `url(${dish.preview || noImage})` }}></div>
                <div className='stars'>
                  <p>
                    <Rating
                      readonly
                      initialRating={drating || 0}
                      emptySymbol={<span className='icon-star mx-1 letter-normal light-dark-text' />}
                      fullSymbol={<span className='icon-star mx-1 letter-normal green' />}
                    />
                  </p>
                </div>
                <h2 className='product-title transition'>{dish.title}</h2>
                <span className='letter-xs'>{`Total Calories: ${(dish.calories / 1000)}`}</span>
                <ul className='tags m-0 p-0'>
                  <li>
                    <span>Fat</span>
                    <span>{`${(dish['fat'] / 1000)} g`}</span>
                  </li>
                  <li>
                    <span>Protein</span>
                    <span>{`${(dish['protein'] / 1000)} g`}</span>
                  </li>
                  <li>
                    <span>Carbs</span>
                    <span>{`${(dish['carb'] / 1000)} g`}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      });
  };

  render() {
    const renderTypeList = this.renderTypeList();
    const renderProduct = this.renderProduct();
    return (
      <div className='day'>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {renderTypeList}
          <HorizontalScroll elements={renderProduct} className={'d-flex custom-scrollbar horizontal-scrollbar mb-0'} />
        </nav>
      </div>
    );
  }
}

List.propTypes = {
  typeList: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  products: PropTypes.object.isRequired,
  dishes: PropTypes.object.isRequired,
  choice: PropTypes.number
};

export default List;
