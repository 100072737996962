import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Script from 'react-load-script';

class Disqus extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.renderDisqus();
  }

  scriptLoaded = () => {
    console.log('disqus loaded');
  };

  renderDisqus = () => {
    if (window.DISQUS) {
      window.DISQUS.reset({ reload: true });
    }
  };

  render() {
    const { className, identifier, url, settings } = this.props;
    const forumUrl = settings.getValue('DISQUS_FORUM_URL');
    window.disqus_config = () => {
      window.page = { url, identifier };
      console.log(url, identifier);
    };
    return (
      <div className={`disqus-holder ${className}`}>
        {!window.DISQUS && <Script
          url={forumUrl}
          onLoad={this.scriptLoaded}
        />}
        <div id='disqus_thread' />
      </div>
    );
  }
}

Disqus.propTypes = {
  identifier: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  settings: PropTypes.object,
  className: PropTypes.string
};

Disqus.defaulProps = {
  className: ''
};

const props = state => ({
  settings: state.settings
});
export default connect(props)(Disqus);
