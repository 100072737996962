import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
class Nav extends Component { 
  render() {
    const { isLogin, linkClass, toggleMenu, stopSpinner , hasPlan , planEnd,startSpinner, socketExportPdf, sentRenewRequest} = this.props;
    const pathname = window.location.pathname;
    var buffered = false;
    var daydiff =0;
    if(planEnd!=undefined){
      const today = moment().startOf('day');
      const date = moment.unix(planEnd);
      buffered = date.isBetween(today, today.clone().add(16, 'day'), '[)');
      daydiff = date.diff(today,'days');
    }
    
    const clickHandler = (e, link) => {
      e.preventDefault();
      stopSpinner();
      toggleMenu && toggleMenu(false);
      if(link == '/modify'){
        startSpinner();
      }	
      if(link == '/viewmenu'){
        socketExportPdf();
      }else if(link == '/renewplan'){
        sentRenewRequest();
      }else{ 
        browserHistory.push(link);
      }  
    };
    let key = 0;
    const NavLink = ({ title, link }) => <li key={`link-${++key}`} className='nav-item d-flex align-items-center'>
      <Link className={linkClass} onClick={e => clickHandler(e, link)} to={link}>{title}</Link>
    </li>; 
    // console.log(hasPlan);
    // console.log(isLogin);
    if((window.innerWidth <= 768 || screen.width <= 768) && pathname == '/modify'){
      var menulist = [{ title: 'Edit My menu', link: (isLogin && hasPlan)?'/modify':'/home' },
      { title: 'View My menu', link: (isLogin && hasPlan)?'/viewmenu':'/viewmenu' }];
      if(buffered){
        menulist.push({ title: 'Renew My menu ('+daydiff+' days left)', link: (isLogin && hasPlan)?'/renewplan':'/home' });
      }
      return menulist.map((el, idx) => <NavLink {...el} key={idx} />);
    }
    return [
      { title: 'My menu', link: (isLogin && hasPlan)?'/modify':'/' },
      // { title: 'My Credit', link: '/my-credit' },
      // { title: 'My progress', link: '/progress' }
    ].map((el, idx) => <NavLink {...el} key={idx} />);
  }
}

Nav.propTypes = {
  linkClass: PropTypes.string,
  toggleMenu: PropTypes.func,
  stopSpinner: PropTypes.func,
  isLogin: PropTypes.bool,
  startSpinner: PropTypes.func
};

Nav.defaultProps = {
  linkClass: '',
  isLogin:false
};

export default Nav;
