import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';
import { browserHistory } from 'react-router';
import LogoImage from 'assets/Basiligo-Logo-Text.png';
import AppleStore  from 'assets/apple-icon.svg';
import GooglePlayStore  from 'assets/gplay-icon.svg';
class Restore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      showModal: false,
      modalMessage: '',
      xyz: false
    };
  }

  listener = action => {
    if (this.RestoreRef) {
      if (action.type === 'tryOk') {
        this.setState({
          showModal: true,
          modalMessage: (
            <div>
              <h3>Email sent!</h3> <br/>
              {action.message}
            </div>
          )
        });
      } else if (action.type === 'tryErr') {
        console.log('restore error');
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('restore_password', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('restore_password', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  submit(e) {
    e.preventDefault();
    const data = {
      email: this.state.email
    };
    this.props.socket.emit('restore_password', { type: 'try', data: data });
  }

  render() {
    const { errors, showModal, modalMessage, xyz } = this.state;
    const onChange = (name, value) => this.setState({ [name]: value });
    return (
      <div ref={el => (this.RestoreRef = el)}>
        <div className='sign-container'>
        <div className='sign-b-left'>
          <div class="logo">
            <a href='/'><img src={LogoImage} alt="Basiligo" class="img-logo" /></a>
          </div>
        <div className='sign-up-form restore-form'>
        
          <div className='search-title text-center mt-1'>
          <p> Trouple with logging in?</p>
            <span className='colorTitle'>Enter your registered email address and we'll send you a link to get back into your account</span>
          </div>
          <form className='form' onSubmit={e => this.submit(e)}> 
            <div className='sign-up-input mt-4'>
            <label class="textLeft">E-mail address</label>
              <InputValidation
                name='email'
                value={this.state.email}
                onChange={onChange}
                placeholder={'E-mail address'}
                className={`form-input ${'email' in errors ? 'has-error' : ''}`}
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='sign-up-input my-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input type='submit' value='Send Login Link' className='letter-sm w-100' />
            </div>
          </form>
        </div>
        </div>
        <div className={`sign-b-right`}>
         <div class="sign-b-right-details">
            <h3>Manage your Plan via</h3>
            <h2>Basiligo Meal Plan App</h2>
            <h6>Download App to Sign Up</h6>
            <div class="app-store-gpay">
                <a href="https://apps.apple.com/bg/app/basiligo-meal-plan/id6496685190"><img src={AppleStore} alt="" class="img-app" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.basiligo.basiligomobileapp"><img src={GooglePlayStore} alt="" class="img-play" /></a>
            </div>
          </div>
         </div>
        </div>
        {showModal &&
          <Modal
            close={() => {
              this.setState({ showModal: false });
              browserHistory.push('/sign-in');
            }}
            message={modalMessage}
            headLine="Password Reset"
          />
        }
      </div>
    );
  }
}

Restore.propTypes = {
  socket: PropTypes.object.isRequired
};

export default socketConnect(Restore);
