/* eslint-disable no-extend-native */
import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import HeadLine from 'layouts/PageLayout/HeadLine';
import PaperButton from 'react-paper-button';
import BarGrid from 'components/Charts/Bar';
import LineGrid from 'components/Charts/Line';
import ModalWrapper from 'components/ModalWrapper';
import moment from 'moment';
import ModalForm from './ModalForm';
import { setPlanParams, clearPlanParams } from 'store/planParams';
import { setMealPlan, clearMealPlan } from 'store/mealPlan';
import DailyMenu from 'components/DailyMenu';
import DishDescription from 'components/DishDescription';
import ChartHandler from './ChartHandler';
import { getDataArray } from 'store/dataArray';

Array.prototype.sum = function () {
  return this.reduce((acc, cur) => acc + cur, 0);
};

Array.prototype.mid = function () {
  return this.sum() / this.length;
};

const fitBitConnectLink = 'https://www.fitbit.com/oauth2/authorize?' +
  'response_type=code&client_id=22D7K5&redirect_uri=https%3A%2F%2Fmealplan.arkotik.com.ua%3A3030%2Ffitbit&' +
  'scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800';

class ProgressView extends Component {
  constructor(props) {
    super(props);
    this.loaded = false;
    this.state = {
      xyz: false,
      showModal: false,
      closeModal: false,
      type: 'dishes',
      dishId: 0,
      activePart: 0,
      progress: {
        weight: {},
        waist: {},
        calories: {}
      },
      lastDates: false
    };
  }

  showOnMobileBlockToggler = (block) => {
    if (window.innerWidth <= 768 || screen.width <= 768) {
      this.setState({ prevOnMobileBlock: this.state.showOnMobileBlock });
      this.setState({ showOnMobileBlock: block });
      document.querySelector('body').scrollIntoView();
      // console.log(this.state.showOnMobileBlock, this.state.prevOnMobileBlock);
    }
  };

  componentWillMount() {
    const { socket } = this.props;
    socket.on('progress', this.listenerProgress);
    socket.on('order', this.listenerOrder);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('progress', this.listenerProgress);
    this.props.socket.removeListener('order', this.listenerOrder);
  }

  listenerOrder = ({ type, data }) => {
    if (this.ProgressViewRef) {
      switch (type) {
        case 'getOk':
          const { mealPlan, planParams } = data;
          this.props.setMealPlan(mealPlan);
          this.props.setPlanParams(planParams);
          break;
        case `${type}Err`:
          console.error(data.message);
          break;
      }
    }
  };

  listenerProgress = ({ type, data }) => {
    if (this.ProgressViewRef) {
      switch (type) {
        case 'getOk':
          const { progress } = data;
          const today = moment().utc().startOf('day');
          const lastDates = Object.keys(progress).reduce((acc, cur) => {
            const dates = Object.keys(progress[cur]);
            const date = dates.length ? moment.unix(dates.reverse()[0]) : null;
            if (date && today.diff(date, 'h') > 24) {
              return { ...acc, [cur]: date };
            } else {
              return acc;
            }
          }, {});
          let state = { progress };
          if (Object.keys(lastDates).length) {
            state = { ...state, lastDates, showModal: true, closeModal: () => this.setState({ showModal: false, closeModal: false, lastDates: false }) };
          }
          this.setState(state);
          break;
        case 'setOk':
          this.setState({ ...data, showModal: false, closeModal: false, lastDates: false });
          break;
        case 'importFromFitbitOk':
          this.setState({ ...data, showModal: false, closeModal: false, lastDates: false });
          break;
        default:
          if (['getErr', 'setErr', 'importFromFitbitErr'].includes(type)) {
            console.log({ type, data });
          }
          break;
      }
    }
  };

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
    const { dataArray, socket } = this.props;
    const keys = ['typeList', 'dietList', 'planList', 'products', 'ingredientList'].filter(el => !(el in dataArray));
    keys.length && getDataArray({ keys, socket });
    if (!this.loaded) {
      socket.emit('progress', { type: 'get' });
      socket.emit('order', { type: 'get' });
      this.loaded = true;
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { cc, socket } = this.props;
    if (!cc && nextProps.cc && !this.loaded) {
      socket.emit('progress', { type: 'get' });
      socket.emit('order', { type: 'get' });
      this.loaded = true;
    }
  }

  getLabels = (type, initialDate = moment().utc().startOf('day'), amount = 1) => {
    let labels = {};
    let lowDate = initialDate.clone();
    switch (type) {
      case 'year':
        lowDate.subtract(12 * amount, 'month').startOf('month');
        while (lowDate.add(1, 'month').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('MMM') };
        }
        break;
      case 'month':
        lowDate.subtract(amount, 'month');
        while (lowDate.add(1, 'day').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('DD') };
        }
        break;
      case 'week':
        lowDate.subtract(amount, 'week');
        while (lowDate.add(1, 'day').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('dd') };
        }
        break;
    }
    return labels;
  };

  _groupData = (data, dates, calc = 'sum', mul = 1) => {
    const stamps = Object.keys(dates);
    const dif = +stamps[1] - +stamps[0];
    let dataSet = stamps.reduce((acc, cur) => {
      const tmp = Object.keys(data).reduce((res, stamp) => +stamp >= +cur && +stamp < +cur + dif ? [...res, data[stamp]] : res, []);
      return { ...acc, [+cur]: tmp };
    }, {});
    return Object.values(dataSet).map(set => set.length ? (set[calc]() * mul).toFixed(2) : null);
  };

  /* groupData = (data, type = 'year', calc = 'sum', mul = 1, initialDate = moment().startOf('year'), subAmount = 0) => {
    const mapTypes = {
      year: 'month',
      month: 'date',
      week: 'day'
    };
    const mapDiffs = {
      year: 'months',
      month: 'days',
      week: 'days'
    };
    const start = initialDate.clone().subtract(subAmount, type).startOf(type).unix();
    const end = initialDate.clone().endOf(type).unix();
    const getCount = (iniDate, sub) => iniDate.clone().endOf(type)[mapTypes[type]]() + (type !== 'month') + (sub > 0 ? getCount(iniDate.clone().subtract(sub, type), sub - 1) : 0);
    const getIdx = date => date.clone().diff(moment.unix(start), mapDiffs[type]);
    const count = getCount(initialDate, subAmount);
    let amounts = Array(count).fill(0);
    const dataSet = Object.keys(data).filter(el => +el >= start && +el <= end).reduce((acc, cur) => {
      const curd = moment.unix(cur);
      let tmp = [...acc];
      const idx = getIdx(curd);
      tmp[idx] += data[cur];
      amounts[idx]++;
      return tmp;
    }, Array(count).fill(null));
    return dataSet.map((el, idx) => amounts[idx] > 0 ? (el * mul / (calc === 'mid' ? amounts[idx] : 1)).toFixed(2) : el);
  }; */

  getProps = (dataObject) => {
    const { label, data, period, calcType, mul, additional, initialDate, amount } = dataObject;
    const labels = this.getLabels(period, initialDate, amount);
    return {
      labels: Object.values(labels),
      timestamps: Object.keys(labels),
      dataSets: {
        [label]: {
          data: this._groupData(data, labels, calcType, mul),
          ...additional
        }
      }
    };
  };

  saveProgress = progress => {
    this.props.socket.emit('progress', {
      type: 'set',
      data: { progress }
    });
  };

  rightContent = (type) => {
    let content = null;
    const today = moment().utc().startOf('day').unix();
    const { mealPlan, dataArray, hasPlan, fitbit_token, remainDeliveries } = this.props;
    const { products, typeList, ingredientList } = dataArray;
    if (!hasPlan) {
      type = null;
      const { clearMealPlan, clearPlanParams } = this.props;
      clearPlanParams();
      clearMealPlan();
    }
    switch (type) {
      case 'dishes':
        const dates = Object.keys(mealPlan).filter(el => +el >= today).sort((a, b) => +a - +b);
        const remains = remainDeliveries.reduce((acc, { date, mealType_id, dish_id }) => {
          return { ...acc, [date]: { ...(acc[date] || {}), [+mealType_id]: +dish_id } };
        }, {});
        const remainsDates = Object.keys(remains).filter(el => +el >= today).sort((a, b) => +a - +b);
        const nextOrderDelivery = (dates || []).length ? moment.unix(dates[0]) : null;
        const nextRemainDelivery = (remainsDates || []).length ? moment.unix(remainsDates[0]) : null;
        if (products && typeList && ingredientList && (nextOrderDelivery || nextRemainDelivery)) {
          content = (
            <div className='part-2-inner'>
              <DailyMenu
                products={products}
                typeList={typeList}
                ingredientList={ingredientList}
                dishes={nextRemainDelivery ? remains[nextRemainDelivery.unix()] : mealPlan[(nextOrderDelivery || {}).unix()]}
                itemClick={dishId => this.setState({ type: 'description', dishId })}
                showDetails={'macros'}
                showOnMobileBlockToggler={this.showOnMobileBlockToggler}
              >
                <this.Buttons className={'next-delivery'} />
                <h5 className='green delivery-n'>
                  {(nextRemainDelivery || nextOrderDelivery).unix() === today
                    ? <span className={'next-date'}>Today`s delivery</span>
                    : <Fragment>Next delivery: <span className={'next-date'}>{(nextRemainDelivery || nextOrderDelivery).format('DD-MM-YYYY')}</span></Fragment>}
                </h5>
              </DailyMenu>
            </div>
          );
        }
        break;
      case 'description':
        const { dishId } = this.state;
        if (products && ingredientList && dishId) {
          content = (
            <DishDescription
              product={{ ...products[dishId], id: dishId }}
              ingredients={dataArray['ingredientList'] || {}}
              showOnMobileBlockToggler={this.showOnMobileBlockToggler}
              onClick={() => this.setState({ type: 'dishes', dishId: 0 })}
            />
          );
        }
        break;
      default:
        content = (
          <Fragment>
            <div className='row part-2-inner'>
              <div className='col-12'>
                <this.Buttons className={'next-delivery'} />
                <div className='b-rounded-gray desc-holder'>
                  <div className='row desc-headline programm-title bg-gr-light-duration text-center'>
                    <div className='col-12 title-block'>
                      <p>
                        <span className='ar-golr-l' />
                        <span className='m-title'>Progress tracking</span>
                        <span className='ar-golr-r' />
                      </p>
                    </div>
                  </div>
                  <p className='p-4'>{'Lorem ipsum dolor sit amet, et sit modo eloquentiam, his primis essent epicuri ex, ceteros lucilius expetenda pro at.' +
                  ' Vis ei eirmod nostrum platonem, ea pro clita luptatum. Mei delicata ullamcorper at, fastidii inimicus at eum.'}</p>
                </div>
              </div>
            </div>
          </Fragment>
        );
        break;
    }
    return content;
  };

  fitbitImport = () => {
    this.props.socket.emit('progress', {
      type: 'importFromFitbit',
      data: {}
    });
  };

  renderFillLastDatesModal = dates => {
    const fillProgress = () => {
      const today = moment().utc().startOf('day');
      const { progress } = this.state;
      const newProgress = Object.keys(dates).reduce((acc, cur) => {
        let tmp = {};
        const date = dates[cur].clone();
        if (!date.isUTC()) {
          date.utc();
        }
        const val = progress[cur][date.unix()];
        while (date.add(1, 'd').isSameOrBefore(today)) {
          tmp = { ...tmp, [date.unix()]: val };
        }
        return { ...acc, [cur]: tmp };
      }, {});
      this.saveProgress(newProgress);
    };
    return (
      <div className='text-center modal-message'>
        <div className='pl-5 pr-5'>
          <p className='text-center'>{'You did not complete the data for a few days. You can enter data manually or copy the last record'}</p>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-3'>
            <PaperButton
              className='place-order button-regular transition progress-message w-100 ml-auto'
              onClick={() => this.setState({ closeModal: false, lastDates: false })}
            >
              Enter manually
            </PaperButton>
          </div>
          <div className='col-12 col-sm-3'>
            <PaperButton
              className='place-order button-regular transition progress-message w-100 mr-auto'
              onClick={fillProgress}
            >
              Copy last record
            </PaperButton>
          </div>
          <div className='col-12 progress-message col-sm-6'>
            {this.props.fitbit_token ? <PaperButton
              className='place-order button-regular transition w-100 mr-auto ml-auto'
              onClick={this.fitbitImport}
            >
                Import Weight progress from Fitbit
            </PaperButton> : <PaperButton onClick={this.fitbitImport} className={'place-order button-regular transition w-100 mr-auto ml-auto'}>
              Sync Weight progress with Fitbit
            </PaperButton>}
          </div>
        </div>
      </div>
    );
  };

  Buttons = ({ className }) => {
    const { fitbit_token } = this.props;
    const buttClassName = 'w-100 next';
    return <div className={className}>
      <h5 className='green'>Track my progress</h5>
      <div className={'buttons'}>
        <div>
          <PaperButton onClick={() => this.setState({ showModal: true })} className={buttClassName}>
            <span>Progress tracking</span>
          </PaperButton>
        </div>
        {fitbit_token ? <div>
          <PaperButton onClick={this.fitbitImport} className={buttClassName}>
            <span>Sync Weight progress with Fitbit</span>
          </PaperButton>
        </div> : <div>
          <Link onlyActiveOnIndex={false} to={fitBitConnectLink} target={'_blank'}>
            <PaperButton className={buttClassName}>
              <span>Connect Fitbit</span>
            </PaperButton>
          </Link>
        </div>}
      </div>
    </div>;
  };

  render() {
    const { locale, regDate } = this.props;
    const { xyz, progress, showModal, type, closeModal, lastDates, activePart } = this.state;
    const { weight, calories, waist } = progress;
    const close = () => closeModal === false ? this.setState({ showModal: false }) : closeModal();
    this.isMob = window.innerWidth <= 768 || screen.width <= 768 || false;
    const grids = <div className='col-12 col-md-7 col-lg-9 custom-scrollbar vertical-scrollbar part-1'>
      <this.Buttons className={'track-progress'} />
      <div className='row part-1-inner'>
        <ChartHandler
          caption={'Calories intake'}
          renderer={props => <BarGrid {...props} />}
          propser={(period, amount) => this.getProps({
            label: 'Calories',
            data: calories,
            calcType: 'sum',
            mul: 1,
            initialDate: moment().utc().startOf('day'),
            period,
            amount
          })}
          isEmpty={!(calories && Object.keys(calories).length)}
        />
        <ChartHandler
          caption={'Weight progress'}
          renderer={props => <LineGrid {...props} />}
          propser={(period, amount) => this.getProps({
            label: 'Weight',
            data: weight,
            calcType: 'mid',
            mul: 0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            amount
          })}
          isEmpty={!(weight && Object.keys(weight).length)}
        />
        <ChartHandler
          caption={'Waist progress'}
          renderer={props => <LineGrid {...props} />}
          propser={(period, amount) => this.getProps({
            label: 'Waist',
            data: waist,
            calcType: 'mid',
            mul: 0.1,
            initialDate: moment().utc().startOf('day'),
            period,
            amount
          })}
          isEmpty={!(waist && Object.keys(waist).length)}
        />
      </div>
    </div>;
    const right = <div className='col-12 col-md-5 col-lg-3 part-2 custom-scrollbar vertical-scrollbar right-part'>
      {this.rightContent(type)}
    </div>;
    return (
      <div className={`main-holder wrap-progress-page  ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ProgressViewRef = el)}>
        <HeadLine
          title='My progress'
          desc='Calorie intake, Weight and Waist progress '
        />
        <div className='container-fluid regular-page h-100 progress-page'>
          <div className='row progress-page-inner'>
            {this.isMob ? (activePart ? right : grids) : <Fragment>{grids}{right}</Fragment>}
          </div>
        </div>
        {!!this.isMob && <div className={'row togle-dishes-menu'}>
          <div className='col-6 btnb text-center'>
            <button className={activePart === 0 ? 'next button-regular' : 'button-regular'} onClick={() => this.setState({ activePart: 0 })}>
              Progress
            </button>
          </div>
          <div className='col-6 btnb text-center' onClick={() => this.setState({ activePart: 1 })}>
            <button className={activePart === 1 ? 'next button-regular' : 'button-regular'}>
              Next Delivery
            </button>
          </div>
        </div>}
        <ModalWrapper md={4} close={close} show={showModal} headLine={'Track my progress'} className={'some-class progress-modal'}>
          {lastDates
            ? this.renderFillLastDatesModal(lastDates)
            : <ModalForm locale={locale} regDate={regDate} progress={progress} save={this.saveProgress} />
          }
        </ModalWrapper>
      </div>
    );
  }
}

ProgressView.propTypes = {
  socket: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  hasPlan: PropTypes.bool.isRequired,
  regDate: PropTypes.number.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  clearMealPlan: PropTypes.func.isRequired,
  clearPlanParams: PropTypes.func.isRequired,
  remainDeliveries: PropTypes.array,
  fitbit_token: PropTypes.bool,
  cc: PropTypes.bool
};

const props = state => {
  const { language, registered: regDate, fitbit_token, hasPlan, cc } = state.user;
  return {
    dataArray: state.dataArray,
    planParams: state.planParams,
    mealPlan: state.mealPlan,
    remainDeliveries: state.remainDeliveries,
    locale: (language || 'en-US').substr(-2),
    regDate,
    fitbit_token,
    hasPlan,
    cc
  };
};

const actions = dispatch => ({
  setMealPlan: obj => dispatch(setMealPlan(obj)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  clearMealPlan: () => dispatch(clearMealPlan()),
  clearPlanParams: () => dispatch(clearPlanParams())
});

export default socketConnect(connect(props, actions)(ProgressView));
