import React, { Component } from 'react';
// import Emoji from 'components/Emoji';
import Emoji from 'components/EmojiIcon';
class RatingModal extends Component {
    
    constructor(props) {

        super(props);

        const {rating} = this.props;
        this.state = {
            rating,
            feedback:''
        }

    }
    
    render() {

        const {rating,feedback} = this.state;
       
        const { productId, saveRating } =this.props;
        return (
            <div className='text-left'>
          <p className="clr-light-gry-txt">We strive to improve our dishes based on your feedback. You can add these dishes to your likes or dislikes </p>
          <div className="emoji-holder clearfix pt-3">
              <ul className="emoji-pick-sec d-flex justify-content-between p-0">
                <li className={rating==5&&'active'} onClick={()=>{this.setState({rating:5})}}>
                  {/* <i className="emoji-1"></i> */}
                  <Emoji type='good' isActive={(rating==5)?true:false} />
                  <p>Love it</p>
                  <small>add to my likes</small>
                </li>
                <li className={rating==4&&'active'} onClick={()=>{this.setState({rating:4})}}>
                    {/* <i className="emoji-2"></i> */}
                    <Emoji type={'love'} isActive={rating==4?true:false} />
                    <p>Good</p>
                    <small>will try again</small>
                </li>
                <li className={rating==3&&'active'} onClick={()=>{this.setState({rating:3})}}>
                    {/* <i className="emoji-3"></i> */}
                    <Emoji type={'ok'} isActive={rating==3?true:false} />
                    <p>Its ok</p>
                    <small>can be improved!</small>
                </li>
                <li className={rating==2&&'active'} onClick={()=>{this.setState({rating:2})}}>
                    {/* <i className="emoji-4"></i> */}
                    <Emoji type={'not-good'} isActive={rating==2?true:false} />
                    <p>Not good</p>
                    <small>add to my dislikes.</small>
                </li>
              </ul>
           </div>
          <div className="feedback-form-holder pt-3 text-left">
            <div className="form-group">
                <label for="exampleFormControlTextarea1">Feedback</label>
                <textarea className="form-control" rows="3" placeholder="250 characters text field, to give comments about the dish" onChange={(e)=>{  this.setState({feedback:e.target.value})}} value={feedback}></textarea>

                <button type="submit" className="btn-label-gold d-v-more-btn mt-3" onClick={()=>{saveRating(productId,rating,feedback)}} name="button">Submit</button>
              </div>
          </div>
        </div>
        );       
    }
}

export default RatingModal;