import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { setModal, clearModal } from 'store/modal';
import sendFile from 'handler/sendFile';
import { spin, stop } from 'store/spinner';

class CalendarView extends Component {
  
  constructor(props) {
    super(props);
    const {files} = props;
    this.state = {
      selectedYear: 2020,
      goForward: false,
      showFileUpload: false,
      file: null,
      reader: null,
      files: files,
    };
  }


  listener = action => {
      switch (action.type) {
        case 'removeFileOk':
          console.log('removed');
          this.getFiles();
        break;
        case 'getFilesOk':
          const {stop} = this.props;
          stop();
          this.setState({
            files: action.data.files
          });

          if (this.state.showFileUpload) {
            this.getFiles(true); //double query is needed for a some timeout. 
            this.setState({showFileUpload: false});
          }

          if (action.data.openmodal == '1') {
            this.openFileView();
          }
        break;
      }
  };

  setYear(year) {
    const {selectedYear} = this.state;
    var goForward = true;
    
    if (selectedYear == moment().format('YYYY')) {
      goForward = false;
    }

    this.setState({
      selectedYear: year,
      goForward: goForward,
    });
  }

  getFiles = (openafter=false) => {
    this.props.socket.emit('profile', {  type: 'getForDashboard', data: {
        'subtype': 'getFiles', 
        'userId': this.props.user.user_id,
        openafter: openafter,
    }});
  }

  removeFile(hash) {
    spin();
    const {hide} = this.props;
    this.props.socket.emit('profile', { type: 'removeFile', data: { 
        'userId': this.props.user.user_id,
        'hash' : hash,
    } });
    hide(hash);
  }

  openFileView() {
    const {setModal} = this.props;
    const {files} = this.state;
    var rows = [];
    Object.keys(files).map((el, idx) => {
      var size = files[el].size/1000;
      size = size.toFixed(2); 
    
      rows.push(
        <tr id={files[el].hash}>
          <td>{idx+1}</td>
          <td>{files[el].name}</td>
          <td>
              <a className="pointer link-dash" onClick={() => { this.removeFile(files[el].hash) }}>Remove</a>
          </td>
        </tr>
      );
    
    });

    setModal({ headLine: 'File List', message: (
      <table className="table table-noborder">
        <tbody>
          {rows}
        </tbody>
      </table>
    ) });
  }

  _handleSubmit(e) {
    e.preventDefault();
    const {file, reader} = this.state;
    const {user_id, socket, setModal, spin, stop} = this.props;
    spin();
    const allowed_types = [
      'application/pdf', //pdf
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
      'application/msword', //doc
      'image/jpeg' //jpeg, jpg
    ];

    if (allowed_types.indexOf(file.type) != -1) {
      if (file.size < 250000) {
        var modelName = 'Files';
        var attribute = 'thefile';
        const dataForm = new FormData();
        dataForm.append('access_token', this.props.user.token);
        dataForm.append('user_id', this.props.user.user_id);
        dataForm.append('name', file.name);
        dataForm.append('size', file.size);
        dataForm.append('filetype', file.type);
        dataForm.append('modelName', modelName);
        dataForm.append('attribute', attribute);
        dataForm.append(`${modelName}[${attribute}]`, file);
        sendFile.call(this, dataForm, this.afterUpload());
      } else {
        setModal({ headLine: 'File Warning', message: 'A big File Size, please use 250 kb maximum' });
        stop();
      }
    } else {
      setModal({ headLine: 'File Warning', message: 'Not Allowed File Type, Use Image Please' });
      stop();
    }
  }

  afterUpload() {
      const {stop, setModal} = this.props;
      stop();
      this.setState({
        showFileUpload: false,
      });
      setModal({ headLine: 'Medical Documents', message: 'File Uploaded' });
      this.getFiles();
  }

   _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        reader: reader, 
        //imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

	render() {
		const {weightStats, goalDemo} = this.props;
    const {selectedYear, goForward, showFileUpload} = this.state;
    var calBlocks = [];
    if (weightStats) {       
          for (var i =1; i <= 12; i++) { 
                var themonth = moment(selectedYear+'-'+i+'-01').format('MMM');
                calBlocks[i] = (
                  <div className="col-3 pl-1">
                    <div className="calendar-cell dec disabled-month">
                      <div className="month text-left">{themonth}</div>
                      <div className="weight-data-calendar text-right">
                        <span className="font-weight-bold"></span> 
                      </div>
                    </div> 
                  </div>
                );
                if (typeof(weightStats[selectedYear]) != 'undefined') {
                  Object.keys(weightStats[selectedYear]).map( (month, index) => {
                      if (month == i) { 
                        var weight = 0;
                        var nums = 0;
                        Object.keys(weightStats[selectedYear][month]).map( (arr_index, index) => {   
                            Object.keys(weightStats[selectedYear][month][arr_index]).map( (day, index) => {
                              weight = weight + weightStats[selectedYear][month][arr_index][day];
                              nums = nums + 1;
                            });
                        });
                        if (weight > 0) {
                            weight = weight / nums;
                        }
                       
                        calBlocks[i] = (
                          <div className="col-3 pr-1">
                            <div className="calendar-cell jan">
                              <div className="month text-left">{themonth}</div>
                              <div className="weight-data-calendar text-right">
                                <span className="font-weight-bold">{weight.toFixed(1)}</span> kg
                              </div>
                            </div>
                          </div>
                        );
                      }
                  });
                }
          }

          var resBlocks = [];
          var j = 1;
          while (j <= 12) {
            resBlocks.push(
              <div className="row no-gutters mb-2">
                {calBlocks[j]}
                {calBlocks[j+1]}
                {calBlocks[j+2]}
                {calBlocks[j+3]}
              </div>
            );
            j = j+4;
          }
    }
		return(
			<div className="calendar">
            {resBlocks}         
            <div className="row">
              <div className="col-12 text-center mb-4">
                <a onClick={() => this.setYear(selectedYear - 1)} className="pointer calendar-prev-year"></a>
                <a onClick={() => this.setYear(parseInt(moment().format('YYYY')))} className="calendar-years">{selectedYear}</a>
                { goForward && <a onClick={() => this.setYear(selectedYear + 1)} className="pointer calendar-next-year"></a> || <a className="disabled calendar-next-year"></a> }
              </div>
              {!goalDemo && <div className="col-12 text-center">
                <a onClick={ () => {this.setState({ showFileUpload: true })} } className="btn pointer transparent-gold-button slim-button mb-3">Attach your medical documents</a>
                {showFileUpload && 

                  <div className="fileUpload">
                    <form onSubmit={(e)=>this._handleSubmit(e)}>
                      <input className="fileInput" 
                        type="file" 
                        onChange={(e)=>this._handleImageChange(e)} />
                      <button className="submitButton btn gold-button small-save btn-file-upload" 
                        type="submit" 
                        onClick={(e)=>this._handleSubmit(e)}>Upload File</button>
                    </form>
                  </div>

                }
                <a onClick={() => this.openFileView()} className="pointer">Show File List</a>
                <div className="att-document-privacy grey">*Privacy policy applies, only viewable by Nutritionist</div>
              </div>}
            </div>      
      </div>
		);
	}
}

CalendarView.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(CalendarView));