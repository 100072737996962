import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      show: this.props.show || false
    };
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  componentWillReceiveProps(next) {
    if (this.WrapperRef && next.show !== this.state.show) {
      this.setState({ show: next.show });
    }
  }

  render() {
    const { closeButton, close, children, className, headLine } = this.props;
    const { xyz, show } = this.state;
    const modalHeadLineOuther = (headLine) =>
      <div className='row modal-header-styled'>
        <div className='col-12 title-block'>
          <p className={'w-100'}><span className='ar-golr-l' />
            <span className='m-title'>{headLine}</span>
            <span className='ar-golr-r' /></p>
        </div>
      </div>;

    return (
      <div ref={el => (this.WrapperRef = el)}>
        {show && <div className='modal-back-layer' onClick={() => close()}>
          <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-wrapper pd-layer b-rounded-blue ${className}`} onClick={e => e.stopPropagation()}>
            {closeButton && <div className='close-button' onClick={() => close()}>
              <i className='icon-close letter-normal mt-2 pt-1 mb-2 pointer' />
            </div>}
            <div className='text-center modal-message pop-style dark-text w-100 letter-sm px-3'>
              {modalHeadLineOuther(headLine || 'Meal Plan')}
              {children}
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  headLine: PropTypes.any,
  closeButton: PropTypes.bool,
  md: PropTypes.number,
  show: PropTypes.bool,
  className: PropTypes.string
};

ModalWrapper.defaultProps = {
  closeButton: true,
  md: 12,
  className: '',
  close: () => this.setState({ show: false })
};

export default ModalWrapper;
