import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bag from 'assets/cart.svg';
import PaperButton from 'react-paper-button';
import moment from 'moment';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showfull: false,
    };
  }  

  changeCart() {
    const {showfull} = this.state;
    this.setState({
      showfull: !showfull
    });
  } 

  removeFromCart(cart_id) {
    this.props.socket.emit('order', { type: 'removeFromCart', data: {cartId: cart_id} });
  }
 
  render() {
    const {showfull} = this.state;
    const {cart, products, removeFromCart, typeList} = this.props;
    console.log(typeList);
    var num = 0;
    var total = 0;
    var thecart = (<div className="empty-cart-row">
                <div className="empty-cart-img">
                  <img src={Bag} />
                </div>
                <div className="empty-cart-text">
                  Your cart is empty. <br/> Add a side to begin.
                </div>
          </div>);
    if (cart.length > 0) {
      thecart = [];
      Object.keys(cart).map( (el, idx) => {
        num += 1; 

        if (typeof(products[cart[el].dish_id]) != 'undefined') {
          total += cart[el].price;
          thecart.push(
            <div className="cart-row">
                  <div className="cart-img">
                    <img src={Bag} />
                  </div>
                  <div className="cart-text">
                    <h2 className="product-title transition">
                      {products[cart[el].dish_id].title}
                    </h2>
                    {/*<span className="cart-calories">Total calories: {products[cart[el].dish_id].calories/1000}</span>
                    <span className="cart-calories">Fat: {products[cart[el].dish_id].fat/1000}, Protein: {products[cart[el].dish_id].protein/1000}, Carbs: {products[cart[el].dish_id].carb/1000}</span>*/}
                    <span className="cart-calories">Added to: {typeList[cart[el].to_type_id].title}, {moment.unix(cart[el].date).format('Do MMM')}</span>
                    <span className="cart-calories">Dish: {products[cart[el].to_dish_id].title} </span>
                    <span className="cart-price">{cart[el].price} AED</span> <span className="cart-remove" onClick={ (e) => {
                      this.removeFromCart(cart[el].id);
                    }}>Remove</span>
                  </div>
            </div>
          );
        }
      });
    }

    return(
      <div>
      {showfull ? 

      <div className="full-cart modal-message pop-style dark-text letter-sm px-3">
        <div 
          className="icon-close letter-normal mt-2 pt-1 mb-2 pointer" 
          onClick={ (e) => this.changeCart()}
        ></div>
        <div class="row modal-header-styled cart-head">
          <div class="col-12 title-block">
            <p class="w-100">
              <span class="ar-golr-l"></span>
              <span class="m-title">Cart</span>
              <span class="ar-golr-r"></span>
            </p>
          </div>
        </div>
        <div className="cart-content">
          
           {thecart}
         
        </div>
        { (total > 0) && <div className="cart-purchase">
          <p align="center">
           <b className="cart-total">Total Price: {total} AED</b><br/>
          <PaperButton className='button-regular next transition'
                    onClick={ (e) => {
                      window.open(
                        'https://basiligo.ae/sides?id='+this.props.user,
                        '_blank' // <- This is what makes it open in a new window.
                      );
                    }}
                  >
                  <span className="">Checkout</span>
          </PaperButton>
          </p>
        </div> }
      </div> 

      : 

      <div className="small-cart" onClick={ (e) => this.changeCart()}>
        <img src={Bag} />
        { (num > 0) && <span className="cart-counter">{num}</span>}
      </div>}
      </div>
    )
  }

}
 
Cart.propTypes = {
    period: PropTypes.object.isRequired,
};

export default Cart;