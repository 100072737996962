import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputLayout from './InputLayout';
import TextAriaLayout from './TextAriaLayout';
import SelectLayout from './SelectLayout';
import CheckBox from 'components/CheckBox';
import PaperButton from 'react-paper-button';
import HeadLine from 'layouts/PageLayout/HeadLine';
import { setPlanParams } from 'store/planParams';
import SimpleMap from 'components/SimpleMap';
import Marker from 'assets/map-marker.png';
import MarkerSelected from 'assets/map-marker-selected.png';
import { animateScroll } from "react-scroll";
import { spin, stop } from 'store/spinner';

const animateTime = 100;

class Address extends Component {
  constructor(props) {
    super(props);
    const { user, dataArray } = this.props;
    const { workAddress, homeAddress, publicAddress, deliveryInstructions } = user;
   
    this.state = {
      xyz: false,
      workAddress,
      homeAddress,
      publicAddress,
      deliveryInstructions,
      home: {lat: 24.434712, lng: 54.408945},
      work: {lat: 24.434712, lng: 54.408945},
      pub:  {lat: 24.434712, lng: 54.408945},
      homeActive: false,
      workActive: false,
      pubActive: false,
      errors: {}
    };
     this.getArrays(['addressFields', 'areasListFull']);
  } 

  getArrays(type) {
    this.props.socket.emit('get_arrays', {
      type: 'get',
      data: {
        type,
        language: this.props.user.language
      }
    });
  }

  componentDidMount() {
    this.RefAddress && setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
  }

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  onChange = (name, value) => {
    const n = name.split('.');
    this.setState({ [n[0]]: { ...this.state[n[0]], [n[1]]: value } });
  };

  send = userData => {
    const scenario = 'delivery';
    this.props.spin();
    this.props.socket.emit('profile', { type: 'set', data: { ...userData, scenario } });
  };

  listener = action => {
    if (this.RefAddress) {
      const { nav, stop } = this.props;
      stop();
      switch (action.type) {
        case 'setOk':
          if (nav.isDefault) {
            const { planParams, setPlanParams } = this.props;
            setPlanParams({ ...planParams, step: Math.max(planParams.step, 6) });
          }
          nav.next.action();
          break;
        case 'setErr':
          let { errors } = action.data;
          const addressFields = this.props.dataArray['addressFields'] || {};
          const addresses = ['workAddress', 'homeAddress', 'publicAddress'];
          if (errors) {
            addresses.forEach(address => {
              if (address in errors && errors[address].length) {
                if (!(errors[address][0] instanceof Object)) {
                  const fieldsErrors = Object.keys(addressFields).reduce((acc, cur) => ({ ...acc, [cur]: `${addressFields[cur].label} cannot be blank` }), {});
                  errors = { ...errors, [address]: fieldsErrors };
                } else {
                  errors = { ...errors, [address]: errors[address][0] };
                }
              }
            });
            if ('deliveryInstructions' in errors && errors['deliveryInstructions'].length === 1) {
              errors = { ...errors, deliveryInstructions: errors['deliveryInstructions'][0] };
            }
          } else {
            errors = {};
          }
          this.setState({ errors });
          break;
      }
    }
  };

  setNotification(changes, thetype) {
      const {user} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification', 
            'userId': user.user_id,
            'changes': changes,
            'thetype': thetype,
          } 
      });
  }

  next = () => {
    const { workAddress, homeAddress, publicAddress, deliveryInstructions } = this.state;
    this.send({ workAddress, homeAddress, publicAddress, deliveryInstructions });
    this.setNotification('Address is changed from the page with a map', 1);
  };

  unsetError(errors, names) {
    if (errors && names.length === 1) {
      delete errors[names[0]];
    } else if (errors && names.length > 1) {
      errors = { ...errors, [names[0]]: this.unsetError(errors[names[0]], names.splice(1)) };
    }
    return errors;
  }

  renderAddressFields = (data, fieldName, changeData, errors) => {
    const addressFields = this.props.dataArray['addressFields'] || {};
    const onchange = (name, value) => {
      const result = { ...data, [name]: value };
      changeData(fieldName, result, [fieldName, name]);
      console.log(fieldName);
      console.log(name);

      if (name == 'latitude' || name == 'longtitude') {
        switch(fieldName) {
          case 'homeAddress':
            if (name == 'latitude') {
              this.setState({ home: { lat: value, lng: this.state.home.lng }, homeActive: true});
            } else {
              this.setState({ home: { lat: this.state.home.lat, lng: value }, homeActive: true});
            }
          break;

          case 'workAddress':
            if (name == 'latitude') {
              this.setState({ work: { lat: value, lng: this.state.work.lng }, workActive: true});
            } else {
              this.setState({ work: { lat: this.state.work.lat, lng: value }, workActive: true});
            }
          break;

          case 'publicAddress':
            if (name == 'latitude') {
              this.setState({ pub: { lat: value, lng: this.state.pub.lng }, pubActive: true});
            } else {
              this.setState({ pub: { lat: this.state.pub.lat, lng: value }, pubActive: true});
            }
          break;
        }
      }
      console.log(value);
    };
    return Object.keys(addressFields).reduce((acc, cur) => {
      const value = (data && data[cur]) || '';
      return { ...acc, [cur]: this.renderInput(cur, value, addressFields[cur], onchange, errors, fieldName) };
    }, {});
  };

  renderInput = (name, value, info, change, errors, fieldName) => {
    let input = '';
    let onChange = change;
    const { type, props } = info;
    const { areasListFull: areas } = this.props.dataArray;
    console.log('=== data array ===');
    console.log(this.props.dataArray['areasListFull']);
    console.log('=== data array ===');
    switch (type) {
      case 'text':
        const max = props.max || Infinity;
        onChange = (name, value) => {
          change(name, value.length > max ? value.substr(0, max) : value);
        };
        input = <InputLayout name={name} value={value} placeholder={info.placeholder} onChange={onChange} errors={errors}/>;
        break;
      case 'number':
        const maxval = props.max || Infinity;
        onChange = (name, value) => {
            change(name, value.length > max ? value.substr(0, max) : parseFloat(value));
        };
        console.log(fieldName);
        input = <div className="col-12 col-lg-3"><div className="form-input"><input type="text" className="standart-input maps-input b-rounded-blue  b-shadowed-gray w-100" name={name} value={value} placeholder={info.placeholder} onClick={ (e) => {
          animateScroll.scrollTo(1050);
          switch(fieldName) {
            case 'homeAddress':
              this.setState({homeActive: true});
            break;

            case 'workAddress':
              this.setState({workActive: true});
            break;

            case 'publicAddress':
              this.setState({pubActive: true});
            break;
          }
        }} readonly /></div></div>
        //input = <InputLayout name={name} value={value} placeholder={info.placeholder} onChange={onChange} errors={errors}  />;
        break;
      case 'select':
        const curEmirate = +(this.state[fieldName] || {})['emirate'] || 0;
        const list = name.includes('area') ? (areas || []).reduce((acc, { id, name, emirate_id: eid }) => +eid === curEmirate ? { ...acc, [+id]: name } : acc, {}) : props.items;
        input = <SelectLayout name={name} value={value} emptyValue={info.placeholder} list={list} onChange={onChange} errors={errors} />;
        break;
      case 'textarea':
        onChange = (name, value) => {
          change(name, value.length > max ? value.substr(0, max) : value);
        };
        input = <TextAriaLayout name={name} value={value} placeholder={info.placeholder} onChange={onChange} />;
        break;
    }
    return input;
  };

  changeDefault = (name, checked) => {
    if (this.RefAddress) {
      if (name === 'publicAddress') {
        this.setState({ [name]: { ...this.state[name], default: !!checked } });
      } else if (checked) {
        const otherName = name === 'workAddress' ? 'homeAddress' : 'workAddress';
        this.setState({ [name]: { ...this.state[name], default: true }, [otherName]: { ...this.state[otherName], default: false } });
      }
    }
  };

  addressesChange = (lat, lng, type) => {
    var name = 'publicAddress';
    if (type != 'pub') {
      name = type+'Address';
    }
    
    this.setState({
      [name]: {...this.state[name], latitude: lat, longtitude: lng},
      [type]: {...this.state[type], lat: lat, lng: lng},
    })

    console.log(this.state);
  }

  render() {
    const { xyz, workAddress, homeAddress, publicAddress, deliveryInstructions, errors } = this.state;
    const { nav } = this.props;
    const changeData = (name, value, fieldNames) => {
      this.RefAddress && this.setState({ [name]: value, errors: this.unsetError(errors, fieldNames) });
    };
    const work = this.renderAddressFields(workAddress, 'workAddress', changeData, errors['workAddress']);
    const home = this.renderAddressFields(homeAddress, 'homeAddress', changeData, errors['homeAddress']);
    const pub = this.renderAddressFields(publicAddress, 'publicAddress', changeData, errors['publicAddress']);

    return (
      <div className={`main-holder address-page d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => { this.RefAddress = el; }}>
        <HeadLine
          title='My delivery details'
          desc='Please fill in your delivery information'
        />
        <div className='container-fluid regular-page position-relative'>
          <div className='row first-mt-2 form-line'>
            <div className='col-12'>
              <h5 className='green'>*1. Your home address</h5>
            </div>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!home && home.emirate}
                {!!home && home.area}
                {!!home && home.street}
                {!!home && home['apartments']}
              </div>
            </div>
          </div>
          <div className='row mt-4 form-line'>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!home && home['landmark']}
                {!!home && home.latitude}
                {!!home && home.longtitude}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='row bg-gray-bordered'>
                <CheckBox
                  className='col-12 col-lg-4  d-flex'
                  labelClassName='checkbox-container pr-5 pb-2'
                  spanClassName='regular'
                  spanClassName2='radiomark mt-1'
                  name='homeAddress'
                  title='Do you want to use it as default address for delivery?'
                  value={(homeAddress && !!homeAddress.default) || (!workAddress || (workAddress && !workAddress.default))}
                  onChange={this.changeDefault}
                />
              </div>
            </div>
          </div>
          <div className='row mt-4 form-line'>
            <div className='col-12'>
              <h5 className='green'>2. Your work address</h5>
            </div>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!work && work.emirate}
                {!!work && work.area}
                {!!work && work.street}
                {!!work && work['apartments']}
              </div>
            </div>
          </div>
          <div className='row mt-4 form-line'>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!work && work['landmark']}
                {!!work && work.latitude}
                {!!work && work.longtitude}
              </div>
            </div>
          </div>
          <div className='row bg-gray-bordered form-line'>
            <CheckBox
              className='col-12 col-lg-4 d-flex align-items-end'
              labelClassName='checkbox-container pr-5 pb-2'
              spanClassName='regular'
              spanClassName2='radiomark mt-1'
              name='workAddress'
              title='Do you want to use it as default address for delivery?'
              value={(workAddress && workAddress.default) || false}
              onChange={this.changeDefault}
            />
          </div>
          <div className='row mt-4 form-line'>
            <div className='col-12'>
              <h5 className='green'>3. pub holiday/weekend address</h5>
            </div>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!pub && pub.emirate}
                {!!pub && pub.area}
                {!!pub && pub.street}
                {!!pub && pub['apartments']}
              </div>
            </div>
          </div>
          <div className='row mt-4 form-line'>
            <div className='col-12'>
              <div className='row bg-gr-light-2'>
                {!!pub && pub['landmark']}
                {!!pub && pub.latitude}
                {!!pub && pub.longtitude}
              </div>
            </div>
          </div>
          <div className='row bg-gray-bordered form-line'>
            <CheckBox
              className='col-12 col-lg-4 d-flex align-items-end'
              labelClassName='mr-0 checkbox-container pr-5 pb-2'
              spanClassName='regular'
              spanClassName2='checkmark mt-1'
              name='publicAddress'
              title='Do you want to use it as default address for delivery on holidays and weekends?'
              value={(publicAddress && publicAddress.default) || false}
              onChange={this.changeDefault}
            />
          </div>
          <div className='row mt-4  form-line mb-4'>
            <div className='col-12'>
              <h5 className='green'>4. Special delivery instructions</h5>
            </div>
            <div className='col-12'>
              <div className='row bg-gray-bordered'>
                <TextAriaLayout name='deliveryInstructions' value={deliveryInstructions || ''} placeholder='text' onChange={(name, val) => changeData(name, val, [name])} />
              </div>
            </div>
          </div>
           <div className='row mt-4  form-line mb-4'>
            <div className='col-12'>
              <h5 className='green'>5. Set your Locations on Map</h5>
              <ul className="map-ul">
                <li>If you want to set your location on map, Click on "Latitude" or "Longtitude" field to activate the marker</li>
                <li>Click on the marker to select it, than click on the map to set the location.</li>
              </ul>
            </div>
            <div className='col-12'>
              <div className='row bg-gray-bordered'> 
                <SimpleMap 
                  marker={Marker} 
                  marker_selected={MarkerSelected} 
                  addressesChange={this.addressesChange}
                  home={this.state.home}
                  work={this.state.work}
                  pub={this.state.pub}
                  homeActive={this.state.homeActive}
                  workActive={this.state.workActive}
                  pubActive={this.state.pubActive}
                  name="maps"
                /> 
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid bottom-row first-mt-2'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-6 col-lg-3 m-0 p-0'>
              <PaperButton
                onClick={nav.prev.action}
                className='button-regular transition'
              >
                <span>{nav.prev.innerText}</span>
              </PaperButton>
            </div>
            <div className='col-6 bg-1-2 hidden-md-down' />
            <div className='col-6 col-lg-3 m-0 p-0'>
              <PaperButton
                onClick={this.next}
                className='button-regular next transition'
              >
                <span>{nav.next.innerText}</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Address.propTypes = {
  planParams: PropTypes.object.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  nav: PropTypes.shape({
    prev: PropTypes.shape({ action: PropTypes.func, innerText: PropTypes.string }),
    next: PropTypes.shape({ action: PropTypes.func, innerText: PropTypes.string }),
    isDefault: PropTypes.bool
  }),
  spin: PropTypes.func,
  stop: PropTypes.func
};

Address.defaultProps = {
  nav: {
    prev: {
      action: () => browserHistory.push('/menu/profile'),
      innerText: 'BACK'
    },
    next: {
      action: () => browserHistory.push('/menu/summary'),
      innerText: 'NEXT'
    },
    isDefault: true
  }
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  planParams: state.planParams,
  user: state.user,

});

const bindAction = dispatch => ({
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop())
});

export default socketConnect(connect(mapStateToProps, bindAction)(Address));
