import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Terms from './Terms';
import Privacy from './Privacy';
import Refunds from './Refunds';
import NotFound from '../NotFound';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';

const style = {
  // marginTop: '65px'
};

class Static extends Component {
  constructor(props) {
    super(props);
    const { params, location } = this.props;
    const { alias } = params;
    const { pathname } = location;
    const page = alias || pathname;
    this.state = {
      notFound: false,
      page
    };
  }

  getPage = id => this.props.socket.emit('static_pages', { type: 'get', data: { id } });

  componentWillMount() {
    const { socket, cc, token } = this.props;
    const { page } = this.state;
    socket.on('static_pages', this.listener);
    if (page) {
      (!token || cc) && this.getPage(page);
    } else {
      this.setState({ notFound: true });
    }
  }

  componentWillUnmount() {
    this.props.socket.removeEventListener('static_pages', this.listener);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.token && !this.props.cc && nextProps.cc) {
      this.getPage(this.state.page);
    }
  }

  listener = ({ type, status, data }) => {
    if (status === 'ok') {
      if (type === 'get') {
        const { data: pageData } = data;
        let content = '';
        try {
          content = decodeURI(atob(pageData.content));
        } catch (e) {
          content = pageData.content;
        }
        this.setState({ ...pageData, content, notFound: false });
      }
    } else if (status === 'err') {
      this.setState({ notFound: true });
      const { message, ...rest } = data;
      console.error(message, rest);
    }
  };

  render() {
    const { content, notFound, description, name } = this.state;
    const wrapperClassName = '';
    if (notFound) {
      return <NotFound />;
    } else {
      return <div className={'main-holder d-flex flex-column'}>
        <div className='container-fluid bg-gray-blog height-papa blog-page custom-scrollbar vertical-scrollbar'>
          <div className='row title-holder blog-heading single disqussion'>
            <div className='col-12  d-flex flex-column justify-content-center title-block blog'>
              <h1>{name}</h1>
              <p>
                <span className='ar-golr-l' />
                <span>{description}</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='article-wrapper single-blog-wrapper row support-holder'>
            <div className='col-12'>
              <div className='row blog-border'>
                <div className='col-12 single-blog-page t-page'>
                  <div className='row'>
                    <div className='col-12 static-page'>
                      {content ? <div className={wrapperClassName} dangerouslySetInnerHTML={{ __html: content }} /> : <div />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>;
    }
  }
}

Static.propTypes = {
  socket: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  cc: PropTypes.bool,
  token: PropTypes.bool
};

const props = ({ user }) => ({
  cc: user.cc,
  token: !!user.token
});

export default socketConnect(connect(props)(Static));

export { Privacy, Terms, Refunds };
