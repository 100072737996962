import React, { Component } from 'react';
import './style.css';
class Emojiicon extends Component {

    constructor(props) {
        super(props);
        const { isActive,type } = props;
    
        this.state = {
            isActive:isActive,
            type:type
        }
    }
    renderHaha(){
      const { isActive } =this.props;
      return (<div className={isActive ? 'emoji-icon emoji-icon--haha  active' : 'emoji-icon emoji-icon--haha'}>
      <i className="emoji-icon-icon"></i>
    </div>);
    }
    renderHahaLove(){
      const { isActive } =this.props;
      return (<div className={isActive ? 'emoji-icon emoji-icon--haha-love  active' : 'emoji-icon emoji-icon--haha-love'}>
       <i className="emoji-icon-icon"></i>
    </div>);
    }
    renderYay(){
      const { isActive } =this.props;
      return (<div className={isActive ? 'emoji-icon emoji-icon--yay  active' : 'emoji-icon emoji-icon--yay'}>
      <i className="emoji-icon-icon"></i>
    </div>);
    }
    renderWow(){
      const { isActive } =this.props;
      return (<div className={isActive ? 'emoji-icon emoji-icon--wow  active' : 'emoji-icon emoji-icon--wow'}>
     <i className="emoji-icon-icon"></i>
    </div>);
    }
    renderSad(){
      const { isActive } =this.props;
      return (<div className={isActive ? 'emoji-icon emoji-icon--sad  active' : 'emoji-icon emoji-icon--sad'}>
     <i className="emoji-icon-icon"></i>
    </div>);
    }
    render() {
       const { type } = this.state;
      //  console.log(type);
       let conetent='';
       switch(type){
         case 'good':
          conetent= this.renderHaha();
         break;
         case 'love':
          conetent= this.renderHahaLove();
         break;
         case 'ok':
          conetent= this.renderYay();
         break;
         case 'not-good':
          conetent= this.renderSad();
         break;
         default:
          conetent= this.renderWow();
         break;
       }
        return (<div className="emoji-icon-container">{conetent}</div>)
    }
}
export default Emojiicon;