import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { setModal, clearModal, updateModal } from 'store/modal';
import PaperButton from 'react-paper-button';
import sendImage from 'handler/sendImage';
import { spin, stop } from 'store/spinner';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import InputCalendar from 'components/InputCalendar';
import { browserHistory } from 'react-router';

import Edit from 'assets/dashboard/img/edit.svg';
import Question from 'assets/dashboard/img/questions.svg';
import EditWhite from 'assets/dashboard/img/edit-white.svg';
import Avatar_male from 'assets/dashboard/img/ava_male.jpg';
import Avatar_feemale from 'assets/dashboard/img/ava_female.jpg';


class Main extends Component {

	constructor(props) {
	    super(props);
	    this.state = { 
	    	avatarFile: null,
	      	enableSave: false,
	      	avatarPreview: false,
	      	avatarRaw: false,
	      	weightEdit: false,
	      	heightEdit: false,
	      	birthEdit: false,
	      	weightVal: null,
      		heightVal: null,
      		src: null,
      		blob: false,
		    crop: {
		      unit: '%',
		      width: 160,
		      height: 160,
		      aspect: 16 / 16,
		    },
		    errors: [],
		    uplo: false,
	    };
	    this.getAvatar();
  	}

  	getAvatar = () => this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'getAvatar' } });

  	componentWillMount() {
	    this.props.socket.on('profile', this.listener);
	}

	componentWillUnmount() {
	    this.props.socket.removeListener('profile', this.listener);
	}

  	listener = action => {
      	switch (action.type) {
	        case 'getAvatarOk':
	          this.setState({avatarRaw: action.data.avatar});
	        break;
	        case 'saveAvatarOk':
          		console.log('saved');
        	break;
	        case 'saveAvatarErr':
	          console.log(action);
	        break;
      	}
  	}

  	setNotification(changes, thetype) {
	    const {user} = this.props;
	    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
	          'subtype': 'setNotification', 
	          'userId': user.user_id,
	          'changes': changes,
	          'thetype': thetype,
	        } 
	    });
  	}

  	bmiPopup() {
  		const {setModal} = this.props;
  		setModal({headLine: 'Body Mass Index', message: 
  			(<div>
  				<p>BMI is a measurement of a person’s leanness or corpulence based on their height and weight, and is intended to quantify tissue mass.</p>
				<p>Healthy BMI range for adults : 18.5 kg/m2 - 25 kg/m2</p>
				<p>Please <a href="https://www.clevelandclinicabudhabi.ae/en/health-hub/health-tools-and-calculators/pages/bmi-calculator.aspx" target="_blank">click here</a> to know more</p>
  			</div>)
  		});
  	}
  	/*_handleImageChange(e, type) {
	    e.preventDefault();
	    alert('after select');
	    return null;

	    const {setModal} = this.props;
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    const allowed_types = [
	      'image/png', //png
	      'image/jpeg' //jpeg, jpg
	    ];

	    if (allowed_types.indexOf(file.type) != -1) {
	      if (file.size < 250000) {
	        reader.onloadend = () => {
	          console.log(file);

	          switch(type) {
	            case 'avatar':
	              this.setState({
	                avatarFile: file,
	                avatarPreview: reader.result,
	                enableSave: true,
	              });
	            break;
	          }
	          
	          //console.log(this.state);
	        }
	      } else {
	        setModal({headLine: 'File Warning', message:'A big File Size, please use 250 kb maximum'});
	      }
	    } else {
	       setModal({headLine: 'File Warning', message: 'Not Allowed File Type, Use Image Please'});
	    }

	    reader.readAsDataURL(file)
  	}*/

  	dataURLtoFile (dataurl, filename) {
 		var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    	theBlob.lastModifiedDate = new Date();
    	theBlob.name = fileName;
    	theBlob.type = 'image/jpeg';
    	return theBlob;
	}
    
  	saveMain() {
	    const {setModal} = this.props;
	    const {croppedImageUrl, src, blob} = this.state;
	    
	    /*const allowed_types = [
	      'image/png', //doc
	      'image/jpeg' //jpeg, jpg
	    ];*/

	    console.log(croppedImageUrl);
	   	//var avatarFile2 = this.dataURLtoFile(src,'toupload222.jpg');
	   	var avatarFile = blob;
	    console.log(avatarFile);
	    //console.log(avatarFile2);
	    
  		if (avatarFile) {
	      		if (avatarFile.size < 250000) {
			        var modelName = 'Files';
			        var attribute = 'thefile';
			        const dataForm = new FormData();
			        dataForm.append('access_token', this.props.user.token);
			        //dataForm.append('user_id', userId);
			        dataForm.append('name', avatarFile.name);
			        dataForm.append('size', avatarFile.size);
			        dataForm.append('filetype', avatarFile.type);
			        dataForm.append('modelName', modelName);
			        dataForm.append('attribute', attribute);
			        dataForm.append('type', 'avatar');
			        dataForm.append(`${modelName}[${attribute}]`, avatarFile);
			        sendImage.call(this, dataForm);
			        this.setState({enableSave: false, src: false});
			        this.setNotification('Main Photo is changed', 1);
	      		} else {
	      			setModal({ headLine: 'File Warning', message: 'A big File Size, please use 250 kb maximum' });
	      		}
	    } else {
	    	setModal({ headLine: 'File Warning', message: 'Not Allowed File Type, Use Image Please' });
	    }
  	}

  	saveWeight() {
	    var {weightVal} = this.state;
	    const {userData} = this.props;
	    userData.weight = weightVal*1000;
	    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, subtype: 'saveProfData', theaction: 'weight', value: userData.weight} });
	    this.setState({userData: userData, weightEdit: false});
	    this.setNotification('Main Weight is changed', 1);
  	}

  	saveHeight() {
	    var {heightVal} = this.state;
	    const {userData} = this.props;
	    userData.height = heightVal;
	    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, subtype: 'saveProfData', theaction: 'height', value: userData.height} });
	    this.setState({userData: userData, heightEdit: false});
	    this.setNotification('Main Height is changed', 1);
  	}

  	saveBirthday() {
  		var {birthday} = this.state;
	    const {userData} = this.props;
	    userData.birthday = birthday;
	    this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, subtype: 'saveProfData', theaction: 'birthday', value: userData.birthday} });
	    this.setState({userData: userData, birthEdit: false});
	    this.setNotification('Birthday is changed', 1);
  	}

  	// ============== Image upload and crop: =================

		  onSelectFile = e => {
		    if (e.target.files && e.target.files.length > 0) {
		      const reader = new FileReader();
		      reader.addEventListener('load', () =>
		        this.setState({ src: reader.result })
		      );
		      reader.readAsDataURL(e.target.files[0]);
		    }
		  };

		  // If you setState the crop in here you should return false.
		  	onImageLoaded = image => {
		    this.imageRef = image;
		    this.setState({
		    	enableSave: true
		    });
				/*
				const {setModal} = this.props;
			    const {src, crop} = this.state;
				setModal({ headLine: 'Crop Your Photo', message: (
		            <div>
		                <div className="App">
					        {src && (
					          <ReactCrop
					            src={src}
					            crop={crop}
					            ruleOfThirds
					            onImageLoaded={this.onImageLoaded}
					            onComplete={this.onCropComplete}
					            onChange={this.onCropChange}
					          />
					        )}
					       
					    </div>
		                
		                <p align="center">
		                    <PaperButton className='button-regular next transition'>
		                        <span>Save</span>
		                    </PaperButton>
		                </p>
		            </div>
		    	)});*/
		  	};

		  onCropComplete = crop => {
		    this.makeClientCrop(crop);
		  };

		  onCropChange = (crop, percentCrop) => {
		    // You could also use percentCrop:
		    // this.setState({ crop: percentCrop });
		    this.setState({ crop });
		  };

		  async makeClientCrop(crop) {
		    if (this.imageRef && crop.width && crop.height) {
		      const croppedImageUrl = await this.getCroppedImg(
		        this.imageRef,
		        crop,
		        'newFile.jpeg'
		      );
		      this.setState({ croppedImageUrl });
		    }
		  }

		getCroppedImg(image, crop, fileName) {
		    const canvas = document.createElement('canvas');
		    const scaleX = image.naturalWidth / image.width;
		    const scaleY = image.naturalHeight / image.height;
		    canvas.width = crop.width;
		    canvas.height = crop.height;
		    const ctx = canvas.getContext('2d');

		    ctx.drawImage(
		      image,
		      crop.x * scaleX,
		      crop.y * scaleY,
		      crop.width * scaleX,
		      crop.height * scaleY,
		      0,
		      0,
		      crop.width,
		      crop.height
		    );

		    return new Promise((resolve, reject) => {
		      canvas.toBlob(blob => {
		        if (!blob) {
		          //reject(new Error('Canvas is empty'));
		          console.error('Canvas is empty');
		          return;
		        }

		       	this.setState({
		       		blob: blob,
		       	});

		        blob.name = fileName;
		        window.URL.revokeObjectURL(this.fileUrl);
		        this.fileUrl = window.URL.createObjectURL(blob);
		        
		        resolve(this.fileUrl);
		      }, 'image/jpeg');
		    });
		}

  	// =================== end of image upload and crop ==================

	render() {
		const {firstName, lastName, gender, weight, height, birthday, regDate, uploadHandler} = this.props;
		const {weightVal, errors, heightVal, avatarRaw, avatarPreview, weightEdit, birthEdit, heightEdit, enableSave, uplo} = this.state;
		const { crop, croppedImageUrl, src } = this.state;

		var BMI = ( (weight/1000) / ((height/100)*(height/100)) ).toFixed(1);
	    const birth = moment.unix(birthday);
	    const join = moment.unix(regDate);
	    
	    var today = Date.now()/1000;
	    var age = null;
	    if (birthday) {
	    	var birthYear = birth.format('YYYY');
	    	var todayYear = moment.unix(today).format('YYYY');
	    	var birthMonth = birth.format('MM');
	    	var todayMonth = moment.unix(today).format('MM');

	    	var age = todayYear - birthYear;
	    	if (birthMonth > todayMonth) {
	    		age = age - 1;
	    	}
	    }

	    if (age) {
	    	age = age + ' years old';
	    } 
	    
	    var avatar = null;
	    if (typeof(gender) != 'undefined') {
		    avatar = Avatar_male;
		    if (gender == 'female') {
		      avatar = Avatar_feemale;
		    }
		}

	    if (avatarRaw) {
	      avatar = avatarRaw;
	    }

	    if (avatarPreview) {
	      avatar = avatarPreview;  
	    }

		return(
				<div className="col-12 col-lg-3 mb-4 text-center">
                      <div className="mb-2 user-photo">
                      	{croppedImageUrl && (<img src={croppedImageUrl} alt=""  onMouseEnter={ (e) => {this.setState({uplo: true})} } onClick={() => {uploadHandler('selectImage')} } className="img-fluid dash-ava" />)}
                        {!croppedImageUrl && avatar && (<img src={avatar} alt="" onMouseEnter={ (e) => {this.setState({uplo: true})}} onClick={() => {uploadHandler('selectImage')} } className="img-fluid dash-ava" />)}
                        <input id='selectImage' accept="image/*" hidden type="file" onChange={this.onSelectFile} />
                      
                      {uplo && (<div className="uplo" onMouseLeave={ (e) => {this.setState({uplo: false})}} onClick={ (e) => {uploadHandler('selectImage')}}>
                        	<span><img src={Edit} alt="" /> Upload Photo</span>
                      </div>)}
					  </div>
                      <div className="App">
				        {src && (
					        <ReactCrop
					            src={src}
					            crop={crop}
					            ruleOfThirds
					            onImageLoaded={this.onImageLoaded}
					            onComplete={this.onCropComplete}
					            onChange={this.onCropChange}
					        />
				        )}
				       
				      </div>
                      <div className="user-data-wrap position-relative">
                        <div className="bg-left-corner"></div>
                        <div className="user-name">
                          <h3>{firstName} {lastName}</h3>
                        </div>
                        <div className="mb-2 user-details">
                          <span className="years-old font-weight-bold">{age}</span>, <span className="font-weight-bold male-female">{gender}</span>
                        </div>
                        <div className="pb-3 position-relative user-birthday grey">
                          {birthday && birth.format('Do MMM, YYYY')} 
                          {!birthday && <span>Edit your Birth Date</span>}
                          &nbsp;<a className="pointer" onClick={() => {this.setState({birthEdit: true})} }><img src={Edit} alt="" /></a>
                          {birthEdit && 
                          	<div className='bday-sec'>
                          	<InputCalendar
			                  value={birthday || ''}
			                  fullYear
			                  locale='en'
			                  name='birthday'
			                  onChange={birthday => this.setState({'birthday':birthday})}
			                  errors={errors}
			                  disable={false}
			                  placeholder={'Birthday'}
			                  defaultValue={moment().subtract(18, 'y')}
			                />
                          	<button className="btn gold-button small-save" onClick={ () => {this.saveBirthday()} }>Save</button></div>
                          }
                        </div>
                        <div className="mt-3 mb-3 user-data">
                          <table className="mx-auto">
                            <tbody>
                              <tr>
                                <td className="text-right grey"><span> Weight</span></td>
                                <td className="text-left"><span className="mx-1 font-weight-bold weight-data">{weight/1000}</span>kg <a className="pointer" onClick={() => { this.setState({weightEdit: true})} }><img src={Edit} alt="" /></a></td>
                              </tr>
                              {weightEdit && <tr><td colspan="2"><input type="text" id="weightEdit" value={weightVal} onChange={ (e) => {this.setState({weightVal: e.target.value});} } /><button className="btn gold-button small-save" onClick={ () => {this.saveWeight()} }>Save</button></td></tr>}
                              <tr>
                                <td className="text-right grey"><span>Height</span></td>
                                <td className="text-left"><span className="mx-1 font-weight-bold height-data">{height}</span>cm <a className="pointer" onClick={() => {this.setState({heightEdit: true})} }><img src={Edit} alt="" /></a></td>
                              </tr>
                              {heightEdit && <tr><td colspan="2"><input type="text" id="heightEdit" value={heightVal} onChange={ (e) => {this.setState({heightVal: e.target.value});} } /><button className="btn gold-button small-save" onClick={ () => {this.saveHeight()} }>Save</button></td></tr>}
                              <tr>
                                <td className="text-right grey"><span>BMI</span></td>
                                <td className="text-left"><span className="mx-1 font-weight-bold index-data">{BMI}</span> <img className="pointer" onClick={ (e) => {this.bmiPopup()}} src={Question} alt="" /></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {enableSave && <button className="btn gold-button" onClick={ () => {this.saveMain()} }>Save</button>}
                        <div className="grey user-joined">
                          Joined on {join.format('Do MMM, YYYY')}
                        </div>
						<div className='mt-3 mb-3'>
							<h3>Body Analysis</h3>
							<a href="javascript:void(0);" onClick={ e => {   e.preventDefault(); browserHistory.push('/body-analysis');}}>View & Update</a>
						</div>  
                      </div>
                </div>
            );
	}
	

}

Main.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  updateModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(Main));