import React, { Component } from 'react';
import { Link } from 'react-router';

class FeedbackView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.FeedbackViewRef && this.setState({ xyz: true }), 300);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.FeedbackViewRef = el)} className={`row bg-1-1 main-page m-0 main-holder  ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='col-12 main-content without-scroll'>
          <div className='row title-holder'>
            <div className='col-12 title-block main-title'>
              <h1>Feedback</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Please leave your valuable comments about your healthy mealplan</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='row buttons-holder'>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-center align-items-md-end'>
              <Link to='/feedback/dish' className='transition button-regular main'>
                {/* <span className='transition icon-entree icons-regular' /> */}
                <span className='title'>About dishes</span>
              </Link>
            </div>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-center align-items-md-start'>
              <Link to='/feedback/general' className='transition button-regular main'>
                {/* <span className='transition icon-formyfriend icons-regular' /> */}
                <span className='title'>General feedback</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FeedbackView;
