import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noImage from 'assets/no_img.svg';
import LoveEmojiIcon from 'assets/emoji-ic1.svg';
import GoodEmojiIcon from 'assets/emoji-ic2.svg';
import OkEmojiIcon from 'assets/emoji-ic3.svg';
import BadEmojiIcon from 'assets/emoji-ic4.svg';
class DishDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullDesc: false
    };
  }

  render() {
    const { onClick, product, ingredients, showOnMobileBlockToggler, prevOnMobileBlock,ratings } = this.props;
    const ingredientsList = product.ingredients ? product.ingredients.map(id => <li key={id}>{ingredients[id]}</li>) : [];
    const getRating = rating => Array(5).fill(0).map((el, idx) => <i key={idx} className={`icon-star mx-1 letter-normal ${idx < rating ? 'green' : 'light-dark-text'}`} />);
    let emoji=LoveEmojiIcon;
    if(ratings[product.id]!=undefined){
      switch(ratings[product.id]){
          case 4:
            emoji=GoodEmojiIcon;
          break;
          case 3:
            emoji=OkEmojiIcon;
          break;
          case 2:
            emoji=BadEmojiIcon;
          break;
      }
    }
    return (
      <div className='col-12 left-dish dish-holder custom-scrollbar vertical-scrollbar'>
        
        <img className='dish-image' src={`${product.image || noImage}`} />
       {/* <div className='row heading'>
          <div className='col-9 stars text-right'>
              <img src={`${emoji}`} />
          </div>
        </div> */}
        <h2>{product.title}</h2>
        {product.title!=product.description&&<p className='regular'>{product.description}</p>}
        <p className='macros calories_sec'>        
        <span className='regular-bold green'>Calories : </span>
          <span className='green'>{` ${(product['calories'] / 1000)} cal`}</span>
        </p>
         <ul className='macros'>
          <li><span className='regular-bold'>Fat: {`${(product['fat'] / 1000)} g`}</span></li>
          <li><span className='regular-bold'>Protein: {`${(product['protein'] / 1000)} g`}</span></li>
          <li><span className='regular-bold'>Carbs: {`${(product['carb'] / 1000)} g`}</span></li>
        </ul>
        <div className="ingridients-contain">
          <p className='regular-bold green'>Ingredients</p>
          <ul className='ingridients'>
            {ingredientsList}
          </ul>
        </div>
        {/* <Reviews dishId={product.id} /> */}
      </div>
    );
  }
}

DishDescription.propTypes = {
  onClick: PropTypes.func,
  showOnMobileBlockToggler: PropTypes.func,
  prevOnMobileBlock: PropTypes.any,
  product: PropTypes.object.isRequired,
  ingredients: PropTypes.object.isRequired
};

DishDescription.defaultProps = {
  onClick: () => {}
};

export default DishDescription;
