import React, { Component, Fragment } from 'react';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Nav from './Nav';
import NavRight from './NavRight';
import Modal from './Modal';
import MenuModal from './MenuModal';
import { socketConnect } from 'socket.io-react';
import { burgerToggle, burgerHide } from 'store/burgerMenu';
import { spin, stop } from 'store/spinner';
import Spinner from 'components/Spinner';
import { setModal } from 'store/modal';
import './PageLayout.css';
import PaperButton from 'react-paper-button';

class PageLayout extends Component {
  constructor(props) {
    super(props);
    const needToTurn = window.orientation !== undefined && this.onOrientationChange();
    const showCookieMessage = !!(+this.getCookie('showCookieMessage'));
    this.state = {
      xyz: false,
      showMenu: false,
      showCookieMessage,
      needToTurn
    };
  }

  componentWillMount() {
    if (window.orientation !== undefined) {
      window.addEventListener('orientationchange', this.onOrientationChange);
    }
  }

  componentWillUpdate() {
    if (this.props.burger) {
      this.props.burgerHide();
    }
  }
  getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name, value, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  }

  doNotShowCookieMessage() {
    this.setCookie('showCookieMessage', '1', 10000);
    this.setState({ showCookieMessage: true });
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
  }

  logout(e) {
    e.preventDefault();
    const { browserId } = this.props.user;
    this.props.socket.emit('login', { type: 'logout', data: { browserId } });
    browserHistory.push('/sign-in');
  }

  onOrientationChange = (flag = true) => {
    const { height } = screen;
    let needToTurn = false;
    if (Math.abs(window.orientation / 90) === 1) {
      needToTurn = height < 640;
    }
    flag && this.setState({ needToTurn });
    return needToTurn;
  };

  showCalendlyModal =()=> {
    var message=<div><iframe allowfullscreen="allowfullscreen" height="500" width="100%"  allow="autoplay; fullscreen" src="https://calendly.com/team-basiligo" scrolling="auto"></iframe></div>;
    this.props.setModal({ headLine:' ',message });
  }
  socketExportPdf = (is_arabic=false) => {
    const {spin}=this.props;
    spin();
    this.props.socket.emit('order', { type: 'exportPdf', data: {'is_arabic':is_arabic} });
  }
  sentRenewRequest = () =>{
    const { user }= this.props;
    this.props.setModal({ headLine: 'Renew Plan', message: (
      <div>
        <p>Send Renew Plan Request</p>
        <p align="center"><PaperButton
          onClick={e => {
            var data = {
              userId: user.user_id, 
              email: user.email, 
              firstName: user.firstName, 
              lastName: user.lastName, 
              'phone': user.phone 
            };
            // this.setNotification(new_mealplan_text, 2);
            this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
          }}
          className='button-regular next transition'
        >
          <span>Send Request</span>
        </PaperButton></p>
      </div>
    )});
  }

  render() {
    const { xyz, showMenu, needToTurn, showCookieMessage } = this.state;
    const { location, children, burgerToggle, burgerHide, burger, isLogin, spinner, stop , user, planParams,spin } = this.props;
    const { hasPlan, token  } = user;
    const { end_date:planEnd } =planParams;
    const xyzFin = {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity 300ms linear, visibility 0s linear'
    };
    const xyzNo = {
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity 300ms linear, visibility 0s linear 300ms'
    };
    return (
      <Fragment>
        {!needToTurn
          ? <div className='container-fluid p-0 wrapper' style={xyz ? xyzFin : xyzNo}>
            {token && <header className={`row page-header  ${location.pathname !== '/' ? 'on-search' : ''}`}>
              <div className='col-12 m-0 p-0'>
                <div className='row p-0 top-nav-heading justify-content-between'>
                  <div className={`col-5 hidden-md-down px-0 ${location.pathname === '/' ? '' : ''}`}>
                    <ul className='navbar-nav navbar-nav-left flex-row justify-content-start'>
                      <Nav linkClass='nav-link' stopSpinner={stop} isLogin={isLogin} hasPlan={hasPlan} socketExportPdf ={this.socketExportPdf} planEnd={planEnd} sentRenewRequest={this.sentRenewRequest} startSpinner={spin} />
                    </ul>
                  </div>
                  <div className='col-8 offset-2 col-md-1 col-lg-2 offset-md-0 logo-holder d-flex justify-content-center px-0'>
                    <Link to='/' onClick={() => stop()} onlyActiveOnIndex />
                  </div>
                  <div className={`col-5 hidden-md-down px-0 ${location.pathname === '/' ? '' : ''}`}>
                    <ul className='navbar-nav navbar-nav-right flex-row justify-content-end'>
                      <NavRight
                        linkClass='nav-link'
                        isLogin={isLogin}
                        burgerToggle={burgerToggle}
                        burgerHide={burgerHide}
                        burger={burger}
                        logout={e => this.logout(e)}
                        stopSpinner={stop}
                        showCalendlyModal = {this.showCalendlyModal}
                      />
                    </ul>
                  </div>
                  <div
                    className='col-2 ml-auto hidden-lg-up'
                    onClick={() => this.setState({ showMenu: !showMenu })}
                  >
                    <div className='d-flex align-items-center justify-content-end h-100'>
                      <div className={`m-page-burger ${showMenu ? 'active-burger' : ''}`}>
                        <span className='m-page-burger-line' />
                        <span className='m-page-burger-line' />
                        <span className='m-page-burger-line' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>}
            {spinner && <Spinner />}
            {children}
            <Modal />
            {showMenu &&
            <MenuModal
              navLeft={<Nav linkClass='nav-link' toggleMenu={showMenu => this.setState({ showMenu })} stopSpinner={stop} isLogin={isLogin} hasPlan={hasPlan} socketExportPdf ={this.socketExportPdf} planEnd={planEnd} sentRenewRequest={this.sentRenewRequest} startSpinner={spin} />}
              navRight={<NavRight
                linkClass='nav-link'
                toggleMenu={showMenu => this.setState({ showMenu })}
                isLogin={isLogin}
                burger={burger}
                burgerToggle={burgerToggle}
                burgerHide={burgerHide}
                logout={e => this.logout(e)}
                stopSpinner={stop}
                showCalendlyModal = {this.showCalendlyModal}
              />}
            />}
          </div>
          : <div className='need-to-turn'>
            <h2>Please turn your device</h2>
          </div>
        }
        {!showCookieMessage &&
          <div className='cookie-message container-fluid'>
            <div className='row'>
              <div className='col-12 cookie-message-text text-center mb-2'>
                We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
              </div>
              <div className='col-12'>
                <div className='transition button-regular' onClick={() => this.doNotShowCookieMessage()}>Ok</div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}

PageLayout.propTypes = {
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node,
  isLogin: PropTypes.bool.isRequired,
  planParams: PropTypes.object.isRequired,
  burger: PropTypes.bool.isRequired,
  burgerToggle: PropTypes.func.isRequired,
  burgerHide: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  footerContent: PropTypes.any,
  spinner: PropTypes.bool,
  spin: PropTypes.func,
  stop: PropTypes.func,
  setModal: PropTypes.func
};

const mapStateToProps = state => ({
  isLogin: !!state.user.token,
  burger: state.burger,
  user: state.user,
  planParams: state.planParams,
  spinner: state.spinner
});

const bindAction = dispatch => ({
  burgerToggle: () => dispatch(burgerToggle()),
  burgerHide: () => dispatch(burgerHide()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, bindAction)(PageLayout));
