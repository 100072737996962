import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import MultiSelectLayout from './MultiSelectLayout';
import { connect } from 'react-redux';
import PaperButton from 'react-paper-button';
import HeadLine from 'layouts/PageLayout/HeadLine';
import { setModal } from 'store/modal';
import { setPlanParams } from 'store/planParams';
import TextAriaValidation from 'components/TextAriaValidation';

const animateTime = 100;

class Preferences extends Component {
  constructor(props) {
    super(props);
    const { preferences, allergies, dislikes, notes, user_id } = this.props.user;
    this.state = {
      xyz: false,
      preferences,
      allergies,
      dislikes,
      notes: notes,
      user_id: user_id,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    this.props.stop();
  }

  saveToProfile = () => {
    const { preferences, allergies, dislikes, notes, user_id } = this.state;
    const { socket, planParams, spin } = this.props;
    spin();
    const { diet: diet_id, mealType: mealTypes } = planParams;
    const data = { preferences, allergies, dislikes, scenario: 'preferences', notes, user_id };
    socket.emit('profile', { type: 'set', data });
    setTimeout(() => socket.emit('custom_plan', { type: 'get', data: { diet_id, mealTypes } }), 10);
  };

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  listener = action => {
    if (this.PrefRef) {
      this.props.stop();
      switch (action.type) {
        case 'setOk':
          const { planParams, setPlanParams } = this.props;
          setPlanParams({ ...planParams, step: Math.max(planParams.step, 3) });
          browserHistory.push('/menu/plan');
          break;
        case 'setErr':
          this.props.setModal({ message: action.data.message });
          break;
      }
    }
  };

  onChange = (name, value) => {
    this.setState({ [name]: value.map(el => +el) });
  };

  render() {
    const { xyz, preferences, allergies, dislikes } = this.state;
    const { dataArray } = this.props;
    const { ingredientList } = dataArray;

    const notesChange = (name, value) => {
      this.setState({
        notes: value
      });
    }

    const preferencesList = ingredientList && Object.keys(ingredientList).reduce((acc, cur) => {
      return (dislikes && dislikes.includes(+cur)) || (allergies && allergies.includes(+cur)) ? acc : { ...acc, [cur]: ingredientList[cur] };
    }, {});
    const dislikesList = ingredientList && preferences && Object.keys(ingredientList).reduce((acc, cur) => {
      return (preferences && preferences.includes(+cur)) ? acc : { ...acc, [cur]: ingredientList[cur] };
    }, {});
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.PrefRef = el)}>
        <HeadLine
          title='Select My Preferences'
          desc='Customize my dishes'
        />
        <div className='container-fluid regular-page pref-page'>
          <br/><h5 class="w-100 mp-table green mb-0">Likes & Notes</h5> <br/>
          <TextAriaValidation
              name={'notes'}
              value={this.state.notes || ''}
              onChange={notesChange}
              placeholder='Likes & Notes'
              rows={3}
          />
          <MultiSelectLayout
            onChange={this.onChange}
            name='allergies'
            value={allergies || []}
            list={dislikesList || {}}
            title='Allergies'
            placeholder='Choose allergies'
            icon='s-gray huge-icons icon-craftedbytopnochchef'
            subtext='For severe allergies, please note we cannot cater to your requirement due to risk of cross contamination.'
          />
          <MultiSelectLayout
            onChange={this.onChange}
            name='dislikes'
            value={dislikes || []}
            list={dislikesList || {}}
            title='Dislikes'
            placeholder='Choose dislikes'
            icon='s-gray huge-icons icon-nutsfree'
          />
        </div>
        <div className='container-fluid bottom-row'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-6 col-md-3 m-0 p-0'>
              <PaperButton onClick={() => browserHistory.push('/menu')} className='button-regular transition'>
                <span>Back to Meal Plan building</span>
              </PaperButton>
            </div>
            <div className='col-md-6 bg-1-2 d-m-none' />
            <div className='col-6 col-md-3 m-0 p-0'>
              <PaperButton onClick={this.saveToProfile} className='button-regular next transition'>
                <span>Select My Food</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Preferences.propTypes = {
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  planParams: PropTypes.object.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  planParams: state.planParams,
  dataArray: state.dataArray,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  setPlanParams: params => dispatch(setPlanParams(params)),
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(Preferences));
