/* eslint-disable no-tabs,indent,no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import PaperButton from 'react-paper-button';
import HeadLine from '../../../layouts/PageLayout/HeadLine';
import ProfileForm from 'components/ProfileForm';

class Profile extends Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, phone, gender, weight, height, birthday, medicalCondition, email, language } = this.props.user;
    this.state = {
      userData: { firstName, lastName, phone, gender, weight, height, birthday, medicalCondition, email, language },
      errors: {},
      xyz: false
    };
  }

  listener = action => {
    if (this.ProfileRef) {
      switch (action.type) {
        case 'setOk':
          browserHistory.push('/modify/address');
          break;
        case 'setErr':
          this.setState({ errors: action.data.errors });
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 100);
    this.props.socket.emit('get_arrays', {
      type: 'get',
      data: {
        type: [
          'gender',
          'language'
        ],
        language: this.props.user.language
      }
    });
  }

  onChange = (name, value) => this.ProfileRef && this.setState({ userData: { ...this.state.userData, [name]: value } });

  next = () => {
    const { userData } = this.state;
    this.props.socket.emit('profile', { type: 'set', data: { ...userData, scenario: 'main' } });
    //this.setNotification('Profile information is changed from the full profile info', 1);
  };

  render() {
    const { xyz, errors, userData } = this.state;
    const { user, dataArray } = this.props;
    const { onChange } = this;
    const listGender = 'gender' in dataArray ? dataArray['gender'] : {};
    const listLanguage = 'language' in dataArray ? dataArray['language'] : {};
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => { this.ProfileRef = el; }}>
        <HeadLine
          title='Profile Settings'
          desc='Please tell us some info about yourself'
        />
        <ProfileForm
          userData={userData}
          errors={errors}
          listGender={listGender}
          listLanguage={listLanguage}
          locale={(user.language || 'en-US').substr(-2)}
          onChange={onChange}
        />
        <div className='container-fluid bottom-row'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-3 m-0 p-0'>
              <PaperButton onClick={() => browserHistory.push('/menu/plan')} className='button-regular transition'>
                <span>Back to meals</span>
              </PaperButton>
            </div>
            <div className='col-6 bg-1-2' />
            <div className='col-3 m-0 p-0'>
              <PaperButton onClick={this.next} className='button-regular transition next'>
                <span>Select address</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user,
    dataArray: state.dataArray
  };
};

export default socketConnect(connect(mapStateToProps)(Profile));
