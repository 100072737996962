import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const animateTime = 100;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
  }

  render() {
    const { children } = this.props;
    console.log(children);
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
}

Menu.propTypes = {
  children: PropTypes.node.isRequired
};

export default Menu;
