import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class CreditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log(this.props.vouchers, nextProps.vouchers);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
  }

  Coupons = ({ vouchers, couponTypes }) => {
    const vKeys = Object.keys(vouchers || {});
    return (couponTypes && vKeys.length) ? vKeys.map(id => {
      const voucher = vouchers[id];
      return (<div className='col-12 col-lg-6 col-md-6 active voucher' key={id}>
        <div className='row b-rounded-gray'>
          <div className='col-7 heading-p'>
            <p className='type'>{voucher.type}</p>
            <p className='d-title'>Voucher</p>
          </div>
          <div className='col-5 text-right p-0'>
            <div className='bg-cos'>
              <p>
                <span className='ammount'>{voucher.type === 'percent' ? voucher.value : voucher.value / 100}</span>
                <span>{voucher.type === 'percent' ? '%' : 'AED'}</span>
              </p>
            </div>
          </div>
          <div className='code'>
            <span className='regular-bold'>voucher code: </span>
            <span>{voucher.code}</span>
          </div>
        </div>
      </div>);
    }) : <this.Placeholder />;
  };

  Placeholder = () => {
    return (
      <div className='row by-defoult'>
        <div className='col-12'>
          <h5 className='green'>You do not have any coupons yet.</h5>
          <p>Your coupons will be available here</p>
        </div>
        <div className='col-lg-6 col-lg-12 col-xl-6 active voucher'>
          <div className='row b-rounded-gray'>
            <div className='col-7 heading-p'>
              <p className='type'>discount</p>
              <p className='d-title'>Voucher</p>
            </div>
            <div className='col-5 text-right p-0'>
              <div className='bg-cos'>
                <p>
                  <span className='ammount'>20</span>
                  <span>%</span>
                </p>
              </div>
            </div>
            <div className='code'>
              <span className='regular-bold'>voucher code: </span>
              <span>12asd123sdfsdf1</span>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-lg-12 col-xl-6 active voucher'>
          <div className='row b-rounded-gray'>
            <div className='col-7 heading-p'>
              <p className='type'>discount</p>
              <p className='d-title'>Voucher</p>
            </div>
            <div className='col-5 text-right p-0'>
              <div className='bg-cos'>
                <p>
                  <span className='ammount'>30</span>
                  <span>%</span>
                </p>
              </div>
            </div>
            <div className='code'>
              <span className='regular-bold'>voucher code: </span>
              <span>12asd123sdfsdf1</span>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-lg-12 col-xl-6 active voucher'>
          <div className='row b-rounded-gray'>
            <div className='col-7 heading-p'>
              <p className='type'>credit</p>
              <p className='d-title'>Voucher</p>
            </div>
            <div className='col-5 text-right p-0'>
              <div className='bg-cos'>
                <p>
                  <span className='ammount'>150</span>
                  <span>AED</span>
                </p>
              </div>
            </div>
            <div className='code'>
              <span className='regular-bold'>voucher code: </span>
              <span>12asd123sdfsdf1</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { xyz } = this.state;
    const { couponTypes, vouchers } = this.props;
    return (
      <div className={`main-holder ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='container-fluid title-holder blog-heading-menu'>
          <div className='row blog-heading menu-heading'>
            <div className='col-12 d-flex flex-column justify-content-center heading title-block blog'>
              <h1>My Credit</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Your discount and credit vouchers</span>
                <span className='ar-golr-r' /></p>
            </div>
          </div>
          <div className='row my-plan choose-holder cupons-holder d-flex '>
            <div className='col-12 col-lg-8'>
              <this.Coupons {...{ vouchers, couponTypes }} />
            </div>
            <div className='col-12 col-lg-4'>
              <div className='b-rounded-gray desc-holder'>
                <div className='row desc-headline text-center'>
                  <div className='col-12 title-block'>
                    <p>
                      <span className='' />
                      <span className='m-title'>Credit voucher</span>
                      <span className='' />
                    </p>
                  </div>
                </div>
                <p className='p-4'>{'A credit voucher is a type of financial discount incentive that is used by many retail situations to allow consumers to purch' +
                'ase goods and services at reduced prices.'}</p>
              </div>
              <div className='b-rounded-gray desc-holder'>
                <div className='row desc-headline text-center'>
                  <div className='col-12 title-block'>
                    <p>
                      <span className='' />
                      <span className='m-title'>Discount voucher</span>
                      <span className='' />
                    </p>
                  </div>
                </div>
                <p className='p-4'>{'Vouchers of this type are often structured with what is known as a voucher code, a simple alpha' +
                'numeric sequence that can be scanned to ensure that the proper discount is applied to the purchase.'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreditView.propTypes = {
  vouchers: PropTypes.object.isRequired,
  couponTypes: PropTypes.object
};

CreditView.defaultProps = {
  couponTypes: {}
};

const mapStateToProps = state => ({
  vouchers: state.user.vouchers,
  couponTypes: state.dataArray.couponTypes
});

export default connect(mapStateToProps, null)(CreditView);
