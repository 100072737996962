import React, { Component } from 'react';
import HeadLine from 'layouts/PageLayout/HeadLine';
import bigLogo from 'assets/big_logo.png';

const animateTime = 100;

class Thank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    this.ThankRef && setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ThankRef = el)}>
        <HeadLine
          title='Thank You'
          desc='Order completed'
        />
        <div className='article-wrapper single-blog-wrapper row support-holder '>
          <div className='col-12'>
            <h4 className='thank-you'>Thank you for the order.</h4>
            <p className='thank-you'>Our manager will contact you soon.</p>
          </div>
        </div>
        <div className='thank-logo' />
      </div>
    );
  }
}

export default Thank;
