import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckBox extends Component {
  render() {
    const { title, name, defaultValue, value, className, val, inputClassName, labelClassName, spanClassName, spanClassName2, disabled } = this.props;
    const checked = defaultValue === 1 || typeof (value) === 'boolean' ? value === true : value === 1;
    return (
      <div className={className}>
        <label className={labelClassName}>
          <span className={spanClassName}>{title}</span>
          <input
            type='checkbox'
            className={inputClassName}
            checked={checked}
            onChange={e => this.props.onChange(name, e.target.checked ? 1 : 0, val)}
            disabled={disabled}
          />
          {spanClassName2 && <span className={spanClassName2} />}
        </label>
        {false && <p>{title}</p>}
      </div>
    );
  }
}

CheckBox.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  spanClassName: PropTypes.string,
  spanClassName2: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  val: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

CheckBox.defaultProps = {
  defaultValue: 0,
  className: '',
  inputClassName: '',
  spanClassName: '',
  spanClassName2: '',
  labelClassName: '',
  disabled: false,
  val: '',
  onChange: () => {}
};

export default CheckBox;
