import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { setModal } from 'store/modal';

class Fitbit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    const { cc } = this.props;
    cc && this.setFitbit();
    this.FitbitRef && setTimeout(() => this.setState({ xyz: true }));
  }

  setFitbit = () => {
    const { location, socket } = this.props;
    const { code } = location.query;
    socket.emit('profile', { type: 'setFitbit', data: { code } });
  };

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.cc && nextProps.cc) {
      this.setFitbit();
    }
  }

  listener = ({ data }) => {
    this.props.setModal({ message: (data || {}).message || '', closeRedirect: '/progress' });
  };

  render() {
    const { xyz } = this.state;
    return (
      <div className={`main-holder ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.FitbitRef = el)} />
    );
  }
}

Fitbit.propTypes = {
  socket: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  cc: PropTypes.bool
};

const props = state => ({
  cc: state.user.cc
});

const actions = dispatch => ({
  setModal: data => dispatch(setModal(data))
});

export default socketConnect(connect(props, actions)(Fitbit));
