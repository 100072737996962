import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      xyz: false
    };
  }

  listener = ({ type, status, data }) => {
    if (status === 'ok') {
      switch (type) {
        case 'email':
          this.setState(data);
          break;
      }
    } else if (status === 'err') {
      this.setState(data);
    }
  };

  componentWillMount() {
    this.props.socket.on('confirmation', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('confirmation', this.listener);
  }

  componentDidMount() {
    const { socket, params } = this.props;
    const { type, token } = params || {};
    if (type && token) {
      socket.emit('confirmation', { type, data: { token } });
    } else {
      this.setState({ message: 'This link is not valid' });
    }
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { message, xyz } = this.state;
    return (
      <div className={`main-holder d-flex flex-column justify-content-center align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='b-rounded-blue sign-up-form text-center shadowed restore-form'>
          {message && <span className='colorTitle letter-sm mt-3'>{message}</span>}
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.any.isRequired
};

export default socketConnect(Confirm);
