import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MultiCheckBoxInput extends Component {
  render() {
    const { elements, className, onChange, errors, classError, classNameLabel, classNameSpan, classNameSpan2 } = this.props;
    const elList = elements.map((val, key) =>
      <label className={classNameLabel} key={key}>
        <input type='checkbox' name={val.name} value={val.value} checked={val.value} onChange={e => onChange(val.name, e.target.checked)} />
        <span title={val.title} className={classNameSpan}>{val.title}</span>
        <span className={classNameSpan2} />
      </label>
    );
    return (
      <div className={className}>
        {elList}
        {name in errors && <span className={classError}>{errors[name]}</span>}
      </div>
    );
  }
}

MultiCheckBoxInput.propTypes = {
  className: PropTypes.string,
  classError: PropTypes.string,
  classNameSpan: PropTypes.string,
  classNameSpan2: PropTypes.string,
  classNameLabel: PropTypes.string,
  errors: PropTypes.object,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
      ]).isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

MultiCheckBoxInput.defaultProps = {
  errors: {},
  elements: [],
  classError: 'has-error',
  className: '',
  classNameLabel: 'checkbox-container',
  classNameSpan: 'letter-sm dark-text',
  classNameSpan2: 'checkmark mt-1',
  classNameCheck: ''
};

export default MultiCheckBoxInput;
