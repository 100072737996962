import axios from 'axios';
import PropTypes from 'prop-types';

function sendFile(dataForm, finish) {
  const path = '/api/user/set-file';
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  axios.post(path, dataForm, config)
    .then(data => {
      console.log(data);
      if ( typeof(finish) != 'undefined') {
        finish(data.data);
      }
    })
    .catch(response => {
      console.log('get data error');
      console.log(response);
    });
}

sendFile.propTypes = {
  dataForm: PropTypes.object.isRequired,
  finish: PropTypes.func
};

export default sendFile;