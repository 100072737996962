import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Modal from 'components/Modal';
import LogoImage from 'assets/Basiligo-Logo-Text.png';
import AppleStore  from 'assets/apple-icon.svg';
import GooglePlayStore  from 'assets/gplay-icon.svg';
class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
      message: '',
      modalMessage: '',
      xyz: false
    };
  }

  socketRestorePassword = action => {
    switch (action.type) {
      case 'checkOk':
        break;
      case 'checkErr':
        this.setState({ message: action.message });
        break;
      case 'setOk':
        this.setState({ modalMessage: action.message });
        setTimeout(() => {
          browserHistory.push('/');
        }, 1000);
        break;
      case 'setErr':
        this.setState({ modalMessage: action.message });
        break;
    }
  };

  componentWillMount() {
    this.props.socket.on('restore_password', this.socketRestorePassword);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('restore_password', this.socketRestorePassword);
  }

  componentDidMount() {
    const data = {
      token: this.props.params.token
    };
    this.props.socket.emit('restore_password', { type: 'check', data: data });
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  passwordChange(e) {
    this.setState({ password: e.target.value });
  }

  setNewPass = (e) => {
    e.preventDefault();
    const data = {
      token: this.props.params.token,
      password: this.state.password
    };
    this.props.socket.emit('restore_password', { type: 'set', data: data });
  };

  render() {
    const { message, showPassword, password, modalMessage, xyz } = this.state;
    return (
      <div className={`${xyz ? 'xyz-fin' : 'xyz'}`}>
       <div className='sign-container'>
        <div className='sign-b-left'>
          <div class="logo">
            <a href='/'><img src={LogoImage} alt="Basiligo" class="img-logo" /></a>
          </div>
        <div className='sign-up-form restore-form'>
          {message && <h1 className='colorTitle text-center letter-sm mt-3'>{message}</h1>}
          {!message && <Fragment>
            <div className='search-title mt-1 text-center'>
              <h1 className='colorTitle'>Restore</h1>
            </div>
            <form className='form forgot-form-eye' onSubmit={this.setNewPass}>
              <div className='sign-up-input mt-4'>
                <div className='form-input'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => this.passwordChange(e)}
                    className='standart-input b-rounded-blue padding-right-2rem'
                    placeholder='Password'
                  />
                  <span className='icon-write letter-lg' onClick={() => this.setState({ showPassword: !showPassword })}>{showPassword ? 'm' : 'n'}</span>
                </div>
              </div>
              <div className='sign-up-input my-4 sign-up-button d-flex align-items-center justify-content-around'>
                <input type='submit' value='Set new password' className='letter-sm w-100' />
              </div>
            </form>
          </Fragment>}
          </div>
          </div>
          <div className={`sign-b-right`}>
         <div class="sign-b-right-details">
            <h3>Manage your Plan via</h3>
            <h2>Basiligo Meal Plan App</h2>
            <h6>Download App to Sign Up</h6>
            <div class="app-store-gpay">
                <a href="https://apps.apple.com/bg/app/basiligo-meal-plan/id6496685190"><img src={AppleStore} alt="" class="img-app" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.basiligo.basiligomobileapp"><img src={GooglePlayStore} alt="" class="img-play" /></a>
            </div>
          </div>
         </div>
        
        </div>
          {modalMessage && (
            <Modal
              close={() => this.setState({ modalMessage: '' })}
              message={modalMessage}
            />
          )}
      </div>
    );
  }
}

Forgot.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.any.isRequired
};

export default socketConnect(Forgot);
