import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class LineGrid extends Component {
  getMax = (data, step) => data.reduce((acc, cur) => parseFloat(cur) >= acc ? (~~(parseFloat(cur) / +step) * step + step) : acc, -Infinity);

  render() {
    const { dataSets, labels, defaultBorderStyle, defaultPointStyle, displayLegend, displayGrid, layoutPadding, timestamps } = this.props;
    let xAxes = [];
    let yAxes = [];
    const datasets = Object.keys(dataSets).map((label, idx) => {
      const { data, fill, backgroundColor, borderProps, pointProps, lineTension, displayX, displayY, yAxisID, stepSizeY } = dataSets[label];
      const borderStyle = borderProps ? { ...defaultBorderStyle, ...borderProps } : defaultBorderStyle;
      const pointStyle = pointProps ? { ...defaultPointStyle, ...pointProps } : defaultPointStyle;
      xAxes = [...xAxes, {
        display: displayX || idx === 0,
        gridLines: {
          display: displayGrid.x || true
        }
      }];
      yAxes = [...yAxes, {
        type: 'linear',
        display: displayY || idx === 0,
        position: 'left',
        id: yAxisID || `y-axis-${idx}`,
        gridLines: {
          display: displayGrid.y || true
        },
        ticks: {
          stepSize: stepSizeY || 10,
          min: 30,
          max: this.getMax(data, stepSizeY || 10)
        }
      }];
      return {
        label,
        data,
        fill: fill || false,
        backgroundColor: backgroundColor || 'rgba(75,192,192,0.4)',
        lineTension: lineTension || 0.4,
        ...borderStyle,
        ...pointStyle
      };
    });
    const data = {
      labels,
      datasets
    };
    const options = {
      responsive: true,
      scales: {
        xAxes,
        yAxes
      },
      legend: {
        display: displayLegend
      },
      layout: {
        padding: layoutPadding
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem) => {
            const tooltipData = tooltipItem[0];
            return timestamps.length ? moment.unix(timestamps[tooltipData.index]).format('MM-DD-YYYY') : tooltipData.xLabel;
          }
        }
      }
    };
    return (
      <Line
        data={data}
        options={options}
      />
    );
  }
}

LineGrid.propTypes = {
  dataSets: PropTypes.object.isRequired,
  defaultBorderStyle: PropTypes.object.isRequired,
  defaultPointStyle: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  displayLegend: PropTypes.bool,
  displayGrid: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool
  }),
  layoutPadding: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
      bottom: PropTypes.number
    })
  ]),
  timestamps: PropTypes.array
};

LineGrid.defaultProps = {
  defaultBorderStyle: {
    borderColor: 'rgba(75,192,192,1)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter'
  },
  defaultPointStyle: {
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10
  },
  displayLegend: false,
  displayGrid: {
    x: false,
    y: false
  },
  layoutPadding: 10,
  timestamps: []
};

export default LineGrid;
