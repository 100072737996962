import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import HeadLine from 'layouts/PageLayout/HeadLine';
import PaperButton from 'react-paper-button';
import { setModal } from '../../store/modal';
import InputCalendar from 'components/InputCalendar';
import { browserHistory } from 'react-router';
const animateTime = 100;
class ClaimReward extends Component {
  constructor(props) {
    super(props);
    const { userId } = this.props.params;
    this.state = {
        userId: atob(userId),
        birthday:'',
      errors: {},
      xyz: false
    };
  }

  sendClaimreward = () => this.props.socket.emit('profile', { type: 'sendClaimreward', data: {user_id:this.state.userId,userId:this.state.userId} });

  listener = action => {
    if (this.ClaimRewardRef) {
        console.log(action.type);
      switch (action.type) {
        case 'sendClaimrewardOk':
          this.props.setModal({ headLine: 'Thank you', message: action.data.message });
            setTimeout(function(){
                browserHistory.push('/');
            },1000)
          break;
        case `${action.type}`:
            browserHistory.push('/');
          this.props.setModal({ headLine: 'Error', message: action.data.message });
          break;
      }
    }
  };
  changeUserInfo = (name, value, errorsToUnset) => 
  {   
      this.setState({
        [name]:value,
      });
  }
  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    this.sendClaimreward();
  }
  render() {
    const { xyz } = this.state;
    return (
      <div className={`d-flex main-holder flex-column f-page ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ClaimRewardRef = el)}>
        <HeadLine
          title='Thank you'
          desc='Team has been notified about your gift which would be added to your current plan'
        />
      </div>
    );
  }
}

ClaimReward.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  user_id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  user_id: state.user.user_id
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(ClaimReward));
