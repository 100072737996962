import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HorizontalScroll from 'components/HorizontalScroll';
import { connect } from 'react-redux';
import Rating from 'react-rating';
import { setMealPlan } from 'store/mealPlan';
import { intersect } from 'lib';
import noImage from 'assets/no_img.svg';

const animateTime = 100;

class Day extends Component {
  constructor(props) {
    super(props);
    const activeType = props.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity);
    this.state = {
      xyz: false,
      typeEdit: false,
      calories: 0,
      fat: 0,
      protein: 0,
      carbs: 0,
      activeType
    };
  }

  componentDidMount() {
    setTimeout(() => this.MainDayRef && this.setState({ xyz: true }), 3 * animateTime);
    this.caloriesCalculate();
  }

  caloriesCalculate() {
    var fat = 0;
    var protein = 0;
    var carbs = 0;
    var calories = 0;

    const { dataArray, mealPlan, date } = this.props;
    const { products } = dataArray;
    console.log(date);
    var timestamp = date._d.getTime()/1000;
    console.log(timestamp);
    Object.keys(mealPlan[timestamp]).map(function(type) {
      var product = products[mealPlan[timestamp][type]];
      fat += product.fat/1000;
      protein += product.protein/1000;
      carbs += product.carb/1000;
      calories += product.calories/1000;
    });

    console.log(date);
    console.log(fat);
    console.log(calories);
    console.log(protein);
    console.log(carbs);

    this.setState({
      calories: calories,
      fat: fat,
      protein: protein,
      carbs: carbs,
    });
  }

  finishClick() {
    this.setState({ typeEdit: false });
    this.caloriesCalculate();
  }

  componentWillReceiveProps(next) {
    if (this.MainDayRef && !this.state.activeType && next.planParams && next.planParams.mealType) {
      this.setState({ activeType: next.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity) });
    }
  }

  renderTypeList = () => {
    const { activeType, typeEdit, nutritions, calories, carbs, fat, protein} = this.state;
    const { dataArray, planParams, date } = this.props;
    const { typeList } = dataArray;
    const types = planParams['mealType'] || [];
    const list = types.sort((a, b) => typeList[a].order - typeList[b].order).map(typeId => {
    const type = typeList && typeList[typeId];
      return (
        <li
          key={typeId}
          className={+typeId === +activeType ? 'active' : ''}
          onClick={() => this.setState({ activeType: +typeId })}
        >
          {type.title}
        </li>
      );
    });
    if (typeEdit) {
      return (
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            {list}
            <li
              className='bg-gold'
              onClick={() => this.finishClick()}
            >
              Finish
            </li>
          </ul>
          <p className='click-on'>Click on the "Item card" to make selection.</p>
        </Fragment>
      );
    } else {
      return (
        <ul className='dish-groups pl-0 mb-0'>
          <li
            onClick={() => this.setState({ typeEdit: true })}
          >
            Change items</li>
          <li className='can-change'>You can change the selection on your own</li>
          <li className='day-calories'><b>Calories:</b> {calories}, <b>Fat:</b> {fat}g, <b>Protein:</b> {protein}g, <b>Carbs:</b> {carbs}g</li>
        </ul>
      );
    }
  };

  renderProduct = () => {
    const { activeType, typeEdit } = this.state;
    const { goMenu, dataArray, date, mealPlan, setMealPlan, user, customPlans, showOnMobileBlockToggler, showOnMobileBlock } = this.props;
    const { preferences, allergies, dislikes } = user;
    const { products, typeList } = dataArray;
    const unix = date.unix();
    const dayNum = date.date() - 1;
    const onChange = (value, checked) => {
      let oldDatePlan = { ...mealPlan[unix] };
      if (!checked) {
        delete oldDatePlan[activeType];
        setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [+activeType]: +value } });
      }
    };
    const plan = customPlans.find(el => +el.mealType_id === +activeType) || {};
    let prodKeys = (plan.data || {})[dayNum] || [];
    if (allergies && dislikes && allergies.length && dislikes.length) {
      prodKeys = prodKeys.filter(el => intersect(products[el].ingredients, allergies, dislikes).length === 0);
    }
    if (preferences && preferences.length) {
      prodKeys = prodKeys.sort((el1, el2) => intersect(products[el2].ingredients, preferences) - intersect(products[el1].ingredients, preferences));
    }
    const list = typeEdit ? prodKeys : Object.keys(mealPlan[unix] || {}).sort((el1, el2) => typeList[el1].order - typeList[el2].order);
    return list.map((key, idx) => {
      const productId = typeEdit ? key : mealPlan[unix][key];
      const product = products[productId];
      const checked = typeEdit && mealPlan[unix] && (mealPlan[unix][activeType] === +productId);
      const title = typeEdit ? typeList[activeType].title + ' option #' + (idx + 1) : typeList[key].title;
      return product ? (
        <div className='products' key={idx} onWheel={e => e.stopPropagation()}>
          <div className={`product transition ${checked ? 'chosen' : 'not'}`}>
            <div className={`inner ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && onChange(productId, checked); }}>
              <div className='img-holder' style={{ backgroundImage: `url(${product.preview || noImage})` }}></div>
              <div className='stars'>
                <p>
                  <Rating
                    readonly
                    initialRating={product.rating || 0}
                    emptySymbol={<span className='icon-star mx-1 letter-normal light-dark-text' />}
                    fullSymbol={<span className='icon-star mx-1 letter-normal green fullStar' />}
                  />
                </p>
              </div>
              <h3 className={` ${typeEdit && checked ? 'gold' : ''}`}>{checked ? 'Selected option' : title}</h3>
              <h2 className='product-title transition'>{product.title}</h2>
              <span className='letter-xs'>{`Total Calories: ${(product['calories'] / 1000)}`}</span>
              <ul className='tags m-0 p-0'>
                <li>
                  <span>Fat</span>
                  <span>{`${(product['fat'] / 1000)} g`}</span>
                </li>
                <li>
                  <span>Protein</span>
                  <span>{`${(product['protein'] / 1000)} g`}</span>
                </li>
                <li>
                  <span>Carbs</span>
                  <span>{`${(product['carb'] / 1000)} g`}</span>
                </li>
              </ul>
              <span className='spicy'>{product['spicy'] ? 'Spicy' : ''}</span>
            </div>
            <p className='text-right details transition' onClick={() => {
              goMenu('showDescription', +productId);
              showOnMobileBlockToggler('menu');
            }}
            >Click here for details</p>
          </div>
        </div>
      ) : null;
    });
  }; 

  render() {
    const { date } = this.props;
    const { xyz, typeEdit } = this.state;
    return (
      <div className={`day ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.MainDayRef = el)}>
        <h5 className={`${typeEdit ? 'editing' : ''}`}>{date && date.format('dddd, Do MMM')} <span>item cards</span></h5>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {this.renderTypeList()}
          <HorizontalScroll elements={this.renderProduct()} className={'d-flex list-holder custom-scrollbar horizontal-scrollbar'} />
        </nav>
      </div>
    );
  }

}

Day.propTypes = {
  dataArray: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  showOnMobileBlockToggler: PropTypes.func,
  showOnMobileBlock: PropTypes.any,
  date: PropTypes.object,
  customPlans: PropTypes.array,
  goMenu: PropTypes.func
};

const mapStateToProps = state => ({
  mealPlan: state.mealPlan,
  planParams: state.planParams,
  user: state.user,
  dataArray: state.dataArray,
  customPlans: state.customPlans
});

const bindAction = dispatch => ({
  setMealPlan: plan => dispatch(setMealPlan(plan))
});

export default connect(mapStateToProps, bindAction)(Day);
