import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import { browserHistory } from 'react-router';
import PaperButton from 'react-paper-button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectValidation from 'components/SelectValidation';
import InputValidation from 'components/InputValidation';
import HeadLine from 'layouts/PageLayout/HeadLine';
import CheckBox from 'components/CheckBox';
import { setModal } from 'store/modal';
import { clearMealPlan } from 'store/mealPlan';
import { setPlanParams } from 'store/planParams';
import { getDataArray } from 'store/dataArray';

const animateTime = 100;

class Summary extends Component {
  constructor(props) {
    super(props);
    this.sent = false;
    this.state = {
      xyz: false,
      paymentType: 'cash',
      acceptTC: false,
      coupon: '',
      commonVoucher: '',
      voucher_id: '',
      voucher_code: ''
    };
  } 

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  listener = action => {
    if (this.RefSummary) {
      const { stop, setPlanParams, clearMealPlan, setModal } = this.props;
      stop();
      switch (action.type) {
        case 'setOk':
          setPlanParams({ step: 7 });
          browserHistory.push('/menu/thank');
          setTimeout(() => clearMealPlan(), 1000);
          break;
        case 'setErr':
          setModal({ message: action.data.message, headLine: 'Validation Error' });
          this.sent = false;
          break;
      }
    }
  };

  componentDidMount() {
    const { dataArray, socket } = this.props;
    if (dataArray && !dataArray.couponTypes) {
      getDataArray({ keys: ['couponTypes', 'commonVouchers'], socket });
    }
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
  }

  next = (acceptTC, price, shipping) => {
    if (acceptTC && !this.sent) {
      this.sent = true;
      const { paymentType: paymentMethod, coupon, voucher_id } = this.state;
      const { planParams, mealPlan: details, spin } = this.props;
      spin();
      const { mealPlan, diet, deliverySlot, build } = planParams;
      const orderData = {
        'coupon_id': +coupon || null,
        'voucher_id': +voucher_id || null,
        paymentMethod,
        'mealPlan_id': +mealPlan,
        price,
        'diet_id': +diet,
        shipping
      };
      const detailsData = Object.keys(details).reduce((data, unix) => {
        const date = details[unix];
        const datePlan = Object.keys(date).reduce((acc, cur) => [...acc, {
          date: +unix,
          'mealType_id': +cur,
          'dish_id': +date[cur]
        }], []);
        return [...data, ...datePlan];
      }, []);
      this.props.socket.emit('order', {
        type: 'set',
        data: {
          orderData,
          detailsData,
          deliverySlot,
          build
        }
      });
    } else {
      this.props.setModal({ message: 'Please, accept Terms and Conditions', headLine: 'Validation Error' });
    }
  };

  onChange = (name, value) => {
    let extra = {};
    if (name === 'coupon') {
      extra = { commonVoucher: '', voucher_id: '', voucher_code: '' };
    }
    this.setState({ [name]: value, ...extra });
  };

  render() {
    const { xyz, paymentType, acceptTC, coupon, voucher_code, commonVoucher } = this.state;
    const { planParams, dataArray, mealPlan, vouchers, setModal } = this.props;
    const typeCounts = mealPlan && Object.values(mealPlan).reduce((acc, cur) => {
      let tmp = { ...acc };
      Object.keys(cur).forEach(id => tmp[id] !== undefined ? (tmp[id]++) : (tmp[id] = 1));
      return tmp;
    }, {});
    const { mealType: mealTypeIds, mealPlan: planId, diet: dietId } = planParams;
    const { priceList, planList, typeList, dietList, commonVouchers } = dataArray;
    const diet = dietList ? dietList[dietId] : '';
    const couponsList = Object.keys(vouchers || {}).reduce((acc, cur) => {
      const vou = vouchers[cur];
      const val = `${vou.type === 'fixed' ? 'AED ' : ''}${vou.type === 'fixed' ? vou.value / 100 : vou.value}${vou.type === 'percent' ? ' %' : ''}`;
      return { ...acc, [cur]: val };
    }, { '': 'None' });
    const prices = priceList
      ? priceList.filter(({ diet_id, mealPlan_id }) => +diet_id === +dietId && +mealPlan_id === +planId).reduce((acc, cur) => ({ ...acc, [cur['mealType_id']]: cur['value'] }), {})
      : {};
    let sum = 0;
    const deliveryPrice = 0;
    const rows = planList && typeList && mealTypeIds && mealTypeIds.map(typeId => {
      const plan = planList[planId] || {};
      const type = typeList[typeId] || {};
      const count = typeCounts[typeId];
      const price = prices[typeId] * count;
      sum += price;
      return (
        <Fragment key={typeId}>
          <div className='col-9 selected-plan summary-product-holder'>
            <p className='s-product-title m-0'>{type.title}</p>
            <p className='s-product-count m-0 text-right'>
              <span className='s-term'>{plan.title || ''}</span>
              <span className='s-count regular-bold'>{count || ''}</span>
            </p>
          </div>
          <div className='col-3 col-lg-2 bg-gr-lither summary-product-holder'>
            <p className='s-product-count w-100 text-left m-0'>
              <span className=''>{`AED ${price / 100}`}</span>
            </p>
          </div>
        </Fragment>
      );
    });
    const vou = vouchers[coupon] || commonVoucher || false;
    const totalPrice = sum + deliveryPrice;
    const discount = vou ? (vou.type === 'percent' ? totalPrice * (vou.value / 100) : vou.value) : 0;
    const total = (sum + deliveryPrice - discount) / 100;
    const haveOwnCoupons = Object.keys(couponsList || {}).length > 1;
    const applyVoucher = () => {
      const voucher = (commonVouchers || []).find(({ code }) => voucher_code.toUpperCase() === code.toUpperCase());
      if (voucher) {
        this.setState({ commonVoucher: voucher, coupon: '', voucher_id: voucher.id });
      } else {
        this.setState({ commonVoucher: '', voucher_code: '', voucher_id: '' });
        setModal({ message: 'Wrong voucher code', headLine: 'Validation Error' });
      }
    };
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.RefSummary = el)}>
        <HeadLine
          title='My meal plan summary'
          desc='Order summary'
        />
        <div className='container-fluid regular-page summary-page'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='row'>
                <div className='col-9 col-lg-9 bg-gr-light-duration'>
                  <h5 className='duration2'>Product</h5>
                </div>
                <div className='col-3 col-lg-2 bg-gr-light-s'>
                  <p className='duration2 prices m-0'>Price</p>
                </div>
                {rows}
                <div className='col-9 summary-product-holder'>
                  <p className='total regular-bold'>Subtotal</p>
                </div>
                <div className='col-3 col-lg-2 summary-product-holder'>
                  <span className='regular-bold t-price'>{`AED ${sum / 100}`}</span>
                </div>
                <div className='col-9 selected-plan summary-product-holder'>
                  <p className='total'>Shipping</p>
                </div>
                <div className='col-3 col-lg-2 summary-product-holder bg-gr-lither'>
                  <span className='t-price'>{deliveryPrice ? `AED ${deliveryPrice}` : 'Free delivery'}</span>
                </div>
                {vou && <Fragment>
                  <div className='col-9 selected-plan summary-product-holder'>
                    <p className='total'>Discount</p>
                  </div>
                  <div className='col-3 col-lg-2 summary-product-holder bg-gr-lither'>
                    <span className='t-price'>{`- AED ${discount / 100}`}</span>
                  </div>
                </Fragment>}
                <div className='col-9 summary-product-holder'>
                  <p className='total regular-bold'>Total</p>
                </div>
                <div className='col-3 col-lg-2 summary-product-holder'>
                  <span className='regular-bold t-price'>{`AED ${total < 0 ? 0 : total}`}</span>
                </div>
                {total < 0 && <Fragment>
                  <div className='col-9 summary-product-holder'>
                    <p className='total regular-bold'>New voucher</p>
                  </div>
                  <div className='col-3 col-lg-2 summary-product-holder'>
                    <span className='regular-bold t-price'>{`AED ${total * -1}`}</span>
                  </div>
                </Fragment>}
                <div className='mt-4'>
                  <div className='row'>
                    <CheckBox
                      className='col-12 summary-check'
                      labelClassName='checkbox-container checkbox-box mr-0 pr-0 d-flex align-items-center flex-row-reverse'
                      spanClassName='regular green m-0'
                      spanClassName2='checkmark mt-1 position-relative d-inline-block mr-2'
                      name='acceptTC'
                      title='I have read and accept'
                      value={acceptTC}
                      onChange={this.onChange}
                    />
                    <a href="/terms" className="terms-url" target="_blank">the terms & conditions</a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <div className='row'>
                <div className='col-12'>
                  <p className='m-0 pr-holder'>
                    <span className='duration2'>Program: </span>
                    <span className='duration2 green'>{diet}</span>
                  </p>
                </div>
                <div className='col-12 mt-3 mb-2'>
                  <p className='m-0 p-0'>You can select one of your coupons</p>
                </div>
                <div className='col-12 pt-3 pb-3 bg-gr-light-s'>
                  <div className='form-input b-shadowed-gray'>
                    <SelectValidation
                      onChange={this.onChange}
                      emptyValue={haveOwnCoupons ? 'Select coupon' : 'You don`t have any coupons'}
                      list={couponsList}
                      name='coupon'
                      value={coupon}
                      disabled={!haveOwnCoupons}
                    />
                  </div>
                </div>
                <div className='col-12 mt-3 mb-2'>
                  <p className='m-0 p-0'>Or apply voucher code</p>
                </div>
                <div className='col-12 pt-3 pb-3 bg-gr-light-s'>
                  <div className='form-input b-shadowed-gray code-input'>
                    <InputValidation
                      name={'voucher_code'}
                      value={voucher_code || ''}
                      placeholder='Enter the code'
                      onChange={(name, value) => this.onChange(name, (value || '').length > 10 ? voucher_code : value.toUpperCase())}
                    />
                    <div onClick={applyVoucher} className='apply-button transition'>
                      <span>Apply</span>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-3 mb-2'>
                  <p className='m-0 p-0'>
                    <span className='duration2 w-100 m-0 p-0'>Payment Method:</span>
                  </p>
                  <p className='m-0 p-0'>Please choose your payment method</p>
                </div>
                <CheckBox
                  className='col-12 summary-check'
                  labelClassName='checkbox-container checkbox-box mr-0 pr-0 d-flex align-items-center flex-row-reverse mt-3'
                  spanClassName='regular green m-0'
                  spanClassName2='radiomark mt-1 position-relative d-inline-block mr-2'
                  name='paymentType'
                  title='Cash on delivery'
                  value={paymentType === 'cash'}
                  val='cash'
                  onChange={(name, checked, val) => this.onChange(name, val)}
                />
                <CheckBox
                  className='col-12 summary-check'
                  labelClassName='checkbox-container checkbox-box mr-0 pr-0 d-flex align-items-center flex-row-reverse mt-3'
                  spanClassName='regular green m-0'
                  spanClassName2='radiomark mt-1 position-relative d-inline-block mr-2'
                  name='paymentType'
                  title='Pay with credit card on delivery'
                  value={paymentType === 'card'}
                  val='card'
                  onChange={(name, checked, val) => this.onChange(name, val)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid bottom-row first-mt-2'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-6 col-lg-3 m-0 p-0'>
              <PaperButton
                onClick={() => browserHistory.push('/menu/address')}
                className='button-regular transition'
              >
                <span>BACK</span>
              </PaperButton>
            </div>
            <div className='col-6 hidden-md-down bg-1-2' />
            <div className='col-6 col-lg-3 m-0 p-0'>
              <PaperButton
                onClick={() => this.next(acceptTC, sum, deliveryPrice)}
                className='button-regular next transition'
              >
                <span>NEXT</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Summary.propTypes = {
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  vouchers: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  planParams: state.planParams,
  vouchers: state.user.vouchers,
  mealPlan: state.mealPlan
});

const bindAction = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearMealPlan: () => dispatch(clearMealPlan()),
  setPlanParams: obj => dispatch(setPlanParams(obj))
});

export default socketConnect(connect(mapStateToProps, bindAction)(Summary));
