import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleCalendar from 'components/SimpleCalendar';
import InputCalendar from 'components/InputCalendar';
import ToolTip from 'components/ToolTip';
import moment from 'moment';
import InputValidation from 'components/InputValidation';
import PaperButton from 'react-paper-button';
import maleImage from 'assets/men-mesurement.png';
import femaleImage from 'assets/women-mesurement.png';
class ModalForm extends Component {
  constructor(props) {
    super(props);
    let firstDay=moment().utc().startOf('month').unix();
    this.state = {
      selectedDate: moment().utc().startOf('day'),
      changedData: {},
      newWeight:{},
      selectedMonth: firstDay,
      selectedweek: moment.unix(firstDay).weekday(0).startOf('day').unix(),
      type:'Weight',
      weekdays:[],
      errors:{}
    };
  }
  componentWillMount() {
    const { selectedMonth } = this.state;
    this.setState({weekdays:this.getWeeksOfMonth(selectedMonth)});
  }
  
  renderWeekTabs = (type)=>{
    const { selectedweek,weekdays } = this.state;
    if(type=='Weight'){
      const week=weekdays.map((key,ind)=>{
        console.log(key);
          return <li className={selectedweek==key.unix()?'selected':''} onClick={() => this.setState({selectedweek:key.unix()})}>Week {ind+1}</li>;
      })
      return (<ul className='tabs'>
                  {week}
              </ul>);
    }
    const week=weekdays.filter((element, index)=> (index==0 || index==3)).map((key,ind)=>{
      console.log(key.unix());
      return <li className={selectedweek==key.unix()?'selected':''} onClick={() => this.setState({selectedweek:key.unix()})}>Week {(ind==0)?ind+1:ind+3}</li>;
     });
     return (<ul className='tabs'>
            {week}
        </ul>);
  }

  renderBodyMeasureTabs =(type)=>{ 
    const { selectedMonth } = this.state;
    const measurements=['Weight','Body'].map((key,ind)=>{
        return <li className={type==key?'selected':''} onClick={() => this.setState({type:key,selectedweek:moment.unix(selectedMonth).weekday(0).startOf('day').unix()})}>{key}</li>;
    });
    return (<ul className='tabs'>
        {measurements}
    </ul>);
  }

  renderFields =(type)=>{ 
    const { progress,weight } = this.props;
    const {  waist,thigh,rightBiceps,calf,bust,leftBiceps,hips } = progress;
   
    const {  changedData,selectedweek, newWeight, errors} = this.state;
    console.log(errors);
    const { bust: newBust, left_biceps: newLeftBiceps, right_biceps: newRightBiceps, thigh: newThigh, waist: newWaist,calf : newCalf,hips : newHips } = changedData;
    const changeHandler = (name, value, date, mul = 1) => this.setState({ changedData: { ...changedData, [name]: { ...changedData[name], [+date]: value * mul } } });
    const changeWeight = (value, date, mul = 1) => this.setState({ newWeight: { ...newWeight, [+date]: value * mul } });
    const stamp = selectedweek;
    console.log('stamp');
    console.log(stamp);
    console.log(weight);
     if(type=='Weight'){
      return <Fragment><div className='col-6'>
      <h5 className='green'>Weight</h5>
      <div className='bg-gr-light-2'>
        <InputValidation
          type={'number'}
          min={0}
          max={200}
          step={'0.1'}
          name={'weight'}
          value={((weight!=undefined && weight[stamp]) || newWeight[stamp])/1000  || ''}
          placeholder='Weight'
          errors={errors[stamp]!=undefined && errors[stamp] || [] }
          onChange={(name, value) => changeWeight(value <= 200 ? value : 200, stamp, 1000)}
        />
      </div>
    </div></Fragment>;
     }
     return <Fragment><div className='col-6'>
     <h5 className='green'>1.Bust</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'bust'}
         value={((newBust && newBust[stamp]) || bust[stamp])  || ''}
         placeholder='Bust'
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 1)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>2.Waist</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'waist'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newWaist && newWaist[stamp]) || waist[stamp]) || ''}
         placeholder='Waist'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 1)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>3.Hips</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'hips'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newHips && newHips[stamp]) || hips[stamp]) || ''}
         placeholder='Hips'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 1)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>4.Biceps</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'left_biceps'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newLeftBiceps && newLeftBiceps[stamp]) || leftBiceps[stamp]) || ''}
         placeholder='Left Biceps'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 1)}
       />
        <InputValidation
         type={'number'}
         min={0}
         max={200}
         step={'0.1'}
         name={'right_biceps'}
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         value={((newRightBiceps && newRightBiceps[stamp]) || rightBiceps[stamp]) || ''}
         placeholder='Right Biceps'
         onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 1)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>5.Thigh</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={100000}
         step={'1'}
         name={'thigh'}
         value={((newThigh && newThigh[stamp]) || thigh[stamp]) || ''}
         placeholder='Thigh'
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         onChange={(name, value) => changeHandler(name, value <= 100000 ? value : 100000, stamp)}
       />
     </div>
   </div>
   <div className='col-6'>
     <h5 className='green'>6.Calf</h5>
     <div className='bg-gr-light-2'>
       <InputValidation
         type={'number'}
         min={0}
         max={100000}
         step={'1'}
         name={'calf'}
         value={((newCalf && newCalf[stamp]) || calf[stamp]) || ''}
         placeholder='Calf'
         errors={errors[stamp]!=undefined && errors[stamp] || [] }
         onChange={(name, value) => changeHandler(name, value <= 100000 ? value : 100000, stamp)}
       />
     </div>
   </div></Fragment>;
  }
  getWeeksOfMonth = (selectedMonth) =>{
    // const { selectedMonth } = this.state;
    let firstDay = moment.unix(selectedMonth).startOf('month').unix();
    const weeks = [];
    weeks.push(moment.unix(firstDay).weekday(0).startOf('day'));
    weeks.push(moment.unix(firstDay).weekday(7).startOf('day'));
    weeks.push(moment.unix(firstDay).weekday(14).startOf('day'));
    weeks.push(moment.unix(firstDay).weekday(21).startOf('day'));   
    return weeks;
  }
  renderBodyMessage = () =>{
    const { gender } = this.props;
    let image=maleImage;
    if(gender=='female'){
      image=femaleImage;
    }
    return <div className='row body-msg'>
      <div className='col-6'><p>What should you do?
Take all your measurements twice per month, first time in the beginning of the month and at the end
of the month, so you can notice the changes and compare. You will be able to notice the weight
change not only on the scale but on your size as well!</p>
     </div>
      <div className='col-6 text-center'>
          <img src={image} />
      </div>
    </div>;
  }
  validateValues = () =>{
    const { changedData, type,selectedweek,newWeight,errors } = this.state;
    const changeValues =(type=='Weight')?newWeight:changedData;
    let isSave= true;
    console.log(changedData);
  
    // if(changeValues){
    //   console.log(changeValues);
    // }
    if(type=='Weight'){
        if(newWeight[selectedweek]==undefined){
          this.setState({ errors: { ...errors, [selectedweek]:{...errors[selectedweek],['weight']: 'Please enter value' } }});
          isSave=false;
        }
    }else{
      const validfeild=['bust','waist','hips','left_biceps','right_biceps','thigh','calf'];
      let error ={};
        validfeild.forEach((key)=>{
          if(changedData[key]== undefined || changedData[key][selectedweek]==undefined){
              error={...error,[selectedweek]:{...error[selectedweek],[key]:'Please enter value'}};
              isSave=false;
          }
      })
      this.setState({ errors: error});
    }
    if(isSave){
      this.props.save(changeValues,type);
    }
  }
  render() {
   
    const { changedData,type, selectedMonth,newWeight } = this.state;
    const changeValues =(type=='Weight')?newWeight:changedData;
    return (
      <div className={'row'} style={{ maxWidth: '700px '}}>
        <div className={'col-12'}>
          <div className='row form-line text-left'>
          <div className='col-12'>
          <h5 className='green'>Select Month</h5>
          <InputCalendar
                  value={selectedMonth}
                  before
                  locale='en'
                  name='month'
                  onChange={selectedMonth => this.setState({selectedMonth:selectedMonth,weekdays:this.getWeeksOfMonth(selectedMonth),selectedweek:moment.unix(selectedMonth).weekday(0).startOf('day').unix()})}
                  // errors={errors}
                  disable={false}
                  viewMode='months'
                  dateFormat='YYYY-MM'
                  placeholder={'Select Month'}
                />
            </div>
            <div className='col-12'>
            <h5 className='green'>Select Measurments</h5>
              {this.renderBodyMeasureTabs(type)}
              {type=='Body'&&this.renderBodyMessage()}
      {type=='Weight'&&<p>Weight yourself each week, same time in early morning first thing in the morning after going to bathroom.</p>}
           </div>    
            {selectedMonth && <div className='col-12'>
            <h5 className='green'>Select Week</h5>
              {this.renderWeekTabs(type)}
           </div>}    
            {selectedMonth && this.renderFields(type)}
          </div>
        </div>
        <div className='col-12 mt-2'>
          <PaperButton
            className={'place-order button-regular transition w-lg-50 ml-auto mr-auto'}
            onClick={() => this.validateValues()}
          >
            Save
          </PaperButton>
        </div>
      </div>
    );
  }
}

ModalForm.propTypes = {
  locale: PropTypes.string.isRequired,
  progress: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired
};

export default ModalForm;