import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import HeadLine from 'layouts/PageLayout/HeadLine';
import Rating from 'react-rating';
import TextAriaValidation from 'components/TextAriaValidation';
import PaperButton from 'react-paper-button';
import { setModal } from '../../../store/modal';
const animateTime = 100;
class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackData: {},
      errors: {},
      xyz: false
    };
  }

  getFeedbackData = () => this.props.socket.emit('rating', { type: 'getTotalRating', data: {} });

  listener = action => {
    if (this.GeneralRef) {
      switch (action.type) {
        case 'getTotalRatingOk':
          this.setState(action.data);
          break;
        case 'setTotalRatingOk':
          this.props.setModal({ headLine: 'Thank you', message: action.data.message });
          break;
        case `${action.type}Err`:
          this.props.setModal({ headLine: 'Error', message: action.data.message });
          break;
      }
    }
  };

  onChange = (name, value) => this.setState({ feedbackData: { ...this.state.feedbackData, [name]:value } });

  componentWillMount() {
    this.props.socket.on('rating', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('rating', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    this.getFeedbackData();
  }

  setNotification(changes, thetype) {
      const {user_id} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification', 
            'userId': user_id,
            'changes': changes,
            'thetype': thetype,
          } 
      });
  }

  saveData = () => {
    const { feedbackData } = this.state;
    const { user_id, socket } = this.props;
    const { feedback, rating, rating2, rating3, rating4 } = feedbackData;
    if (!feedback || !rating || !rating2 || !rating3 || !rating4) {
      let errors = {};
      errors = !feedback ? { ...errors, 'feedback': 'Leave few words about MealPlan' } : errors;
      errors = !rating ? { ...errors, 'rating': 'Please rate overall experience' } : errors;
      errors = !rating2 ? { ...errors, 'rating2': 'Please rate food' } : errors;
      errors = !rating3 ? { ...errors, 'rating3': 'Please rate customer service' } : errors;
      errors = !rating4 ? { ...errors, 'rating4': 'Please rate delivery' } : errors;
      this.setState({ errors });
    } else {
      socket.emit('rating', {
        type: 'setTotalRating',
        data: { feedbackData: { ...feedbackData, user_id } }
      });

      this.setNotification('General feedback', 3);
    }
  };

  render() {
    const { xyz, feedbackData, errors } = this.state;
    const { feedback, rating, rating2, rating3, rating4 } = feedbackData;
    return (
      <div className={`d-flex main-holder flex-column f-page ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.GeneralRef = el)}>
        <HeadLine
          title='Your feedback and review'
          desc='Please provide us feedback on delivery, customer service, or anything else!'
        />
        <div className='container-fluid regular-page feedback-page'>
          <div className='row'>
            <div className='col-12'>
              <h5 className='duration2 line-h-initial mt-5 mb-0'>Please rate our service.</h5>
              <div className='letter-xs mb-1 light-dark-text'></div>
              <div className='bg-gray-bordered padded rating-side'>
               <b>Overall Experience:</b> <Rating
                  onChange={value => this.onChange('rating', value)}
                  initialRating={+rating || 0}
                  emptySymbol={<span className='icon-star mx-1 letter-xxx light-dark-text' />}
                  fullSymbol={<span className='icon-star mx-1 letter-xxx green' />}
                /> <br/>

                <b>Food:</b> <Rating
                  onChange={value => this.onChange('rating2', value)}
                  initialRating={+rating2 || 0}
                  emptySymbol={<span className='icon-star mx-1 letter-xxx light-dark-text' />}
                  fullSymbol={<span className='icon-star mx-1 letter-xxx green' />}
                /> <br/>

                <b>Customer Service:</b> <Rating
                  onChange={value => this.onChange('rating3', value)}
                  initialRating={+rating3 || 0}
                  emptySymbol={<span className='icon-star mx-1 letter-xxx light-dark-text' />}
                  fullSymbol={<span className='icon-star mx-1 letter-xxx green' />}
                /> <br/>

                <b>Delivery:</b> <Rating
                  onChange={value => this.onChange('rating4', value)}
                  initialRating={+rating4 || 0}
                  emptySymbol={<span className='icon-star mx-1 letter-xxx light-dark-text' />}
                  fullSymbol={<span className='icon-star mx-1 letter-xxx green' />}
                /> <br/>
                {errors.rating && <span className='has-error letter-xs red-color'>{errors.rating}</span>}
              </div>
              <div className='letter-xs mt-3 mb-1 light-dark-text'>Your feedback is valuable for us.</div>
              <div className='bg-gray-bordered padded'>
                <TextAriaValidation
                  value={feedback || ''}
                  name='feedback'
                  onChange={this.onChange}
                  rows={3}
                  placeholder='Please start typing here...'
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid bottom-row m-0'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-lg-9 bg-1-2' />
            <div className='col-12 col-lg-3 m-0 p-0'>
              <PaperButton onClick={this.saveData} className='button-regular transition next'>
                <span>Save</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

General.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  user_id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  user_id: state.user.user_id
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(General));
