export const SET_CUSTOM_PLAN_PARAMS = 'SET_CUSTOM_PLAN_PARAMS';
export const UPDATE_CUSTOM_PLAN_PARAMS = 'UPDATE_CUSTOM_PLAN_PARAMS';
export const CLEAR_CUSTOM_PLAN_PARAMS = 'CLEAR_CUSTOM_PLAN_PARAMS';

const initialState = {};

export function setCustomPlanParams(obj) {
  return {
    type    : SET_CUSTOM_PLAN_PARAMS,
    payload : obj
  };
}

export function updateCustomPlanParams(obj) {
  return {
    type    : UPDATE_CUSTOM_PLAN_PARAMS,
    payload : obj
  };
}

export function clearCustomPlanParams() {
  return {
    type    : CLEAR_CUSTOM_PLAN_PARAMS
  };
}

export default function customPlanParamsReducer(state = initialState, action) {
  if (action.type === SET_CUSTOM_PLAN_PARAMS) {
    return action.payload;
  }
  if (action.type === UPDATE_CUSTOM_PLAN_PARAMS) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_CUSTOM_PLAN_PARAMS) {
    return initialState;
  }
  return state;
}
