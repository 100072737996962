import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.socket.on('static_pages', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeEventListener('static_pages', this.listener);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.cc && nextProps.cc) {
      this.props.socket.emit('static_pages', { type: 'get', data: { id: 'contact' } });
    }
  }

  listener = ({ type, status, data }) => {
    if (status === 'ok') {
      if (type === 'get') {
        const { data: pageData } = data;
        let content = '';
        try {
          content = decodeURI(atob(pageData.content));
        } catch (e) {
          content = pageData.content;
        }
        this.setState({ ...pageData, content });
      }
    } else if (status === 'err') {
      const { message, ...rest } = data;
      console.error(message, rest);
    }
  };

  render() {
    const { content } = this.state;
    return (<div className={'bg-1-1 main-page main-holder xyz-fin'} dangerouslySetInnerHTML={{ __html: content }} />);
  }
}

Test.propTypes = {
  socket: PropTypes.object,
  cc: PropTypes.bool
};

const props = state => ({
  cc: state.user.cc
});

export default socketConnect(connect(props)(Test));
