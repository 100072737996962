import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ChartHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 'week',
      amount: 2
    };
  }

  render() {
    const { renderer, propser, caption, isEmpty } = this.props;
    const { period, amount } = this.state;
    const props = propser(period, amount);
    const setParams = (period, amount = 1) => this.setState({ period, amount });
    return (
      <div className='col-12 col-lg-6'>
        <div className='row chart-head-line'>
          <div className='col-4'><h5 className='green'>{caption}</h5></div>
          <div className='col-8'>
            <ul className={'progress-chart-navigation'}>
              <li className={period === 'year' && amount === 1 ? 'active' : ''} onClick={() => setParams('year')}>Year</li>
              <li className={period === 'month' && amount === 3 ? 'active' : ''} onClick={() => setParams('month', 3)}>Three Months</li>
              <li className={period === 'month' && amount === 1 ? 'active' : ''} onClick={() => setParams('month')}>Month</li>
              <li className={period === 'week' && amount === 2 ? 'active' : ''} onClick={() => setParams('week', 2)}>Two Weeks</li>
              <li className={period === 'week' && amount === 1 ? 'active' : ''} onClick={() => setParams('week')}>Week</li>
            </ul>
          </div>
        </div>
        <div className={isEmpty ? 'empty-chart' : ''}>
          {renderer(props)}
        </div>
      </div>
    );
  }
}

ChartHandler.propTypes = {
  renderer: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  propser: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired
};

export default ChartHandler;
