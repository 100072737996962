import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment/moment';

class BarGrid extends Component {
  render() {
    const { dataSets, labels, type, displayLegend, timestamps } = this.props;
    let xAxes = [];
    let yAxes = [];
    const datasets = Object.keys(dataSets).map((label, idx) => {
      const { data, fill, backgroundColor, borderColor, hoverBackgroundColor, hoverBorderColor, yAxisID, displayX, displayY } = dataSets[label];
      xAxes = [...xAxes, {
        display: displayX || idx === 0,
        gridLines: {
          display: true
        }
      }];
      yAxes = [...yAxes, {
        type: 'linear',
        display: displayY || idx === 0,
        position: 'left',
        id: yAxisID || `y-axis-${idx}`,
        gridLines: {
          display: true
        },
        ticks: {
          min: 0
        }
      }];
      return {
        type,
        label,
        data,
        fill: fill || false,
        backgroundColor: backgroundColor || '#71B37C',
        borderColor: borderColor || '#71B37C',
        hoverBackgroundColor: hoverBackgroundColor || '#71B37C',
        hoverBorderColor: hoverBorderColor || '#71B37C',
        yAxisID: yAxisID || `y-axis-${idx}`
      };
    });
    const options = {
      responsive: true,
      tooltips: {
        mode: 'label',
        callbacks: {
          title: (tooltipItem) => {
            const tooltipData = tooltipItem[0];
            return timestamps.length ? moment.unix(timestamps[tooltipData.index]).format('MM-DD-YYYY') : tooltipData.xLabel;
          }
        }
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes,
        yAxes
      },
      legend: {
        display: displayLegend
      }
    };
    const data = {
      labels,
      datasets
    };
    return (
      <Bar
        data={data}
        options={options}
      />
    );
  }
}

BarGrid.propTypes = {
  dataSets: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['bar', 'horizontalBar']),
  displayLegend: PropTypes.bool,
  timestamps: PropTypes.array
};

BarGrid.defaultProps = {
  type: 'bar',
  displayLegend: false,
  timestamps: []
};

export default BarGrid;
