export const SET_PLAN_PARAMS = 'SET_PLAN_PARAMS';
export const UPDATE_PLAN_PARAMS = 'UPDATE_PLAN_PARAMS';
export const CLEAR_PLAN_PARAMS = 'CLEAR_PLAN_PARAMS';

const initialState = {};

export function setPlanParams(obj) {
  return {
    type    : SET_PLAN_PARAMS,
    payload : obj
  };
}

export function updatePlanParams(obj) {
  return {
    type    : UPDATE_PLAN_PARAMS,
    payload : obj
  };
}

export function clearPlanParams() {
  return {
    type    : CLEAR_PLAN_PARAMS
  };
}

export default function planParamsReducer(state = initialState, action) {
  if (action.type === SET_PLAN_PARAMS) {
    return action.payload;
  }
  if (action.type === UPDATE_PLAN_PARAMS) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_PLAN_PARAMS) {
    return initialState;
  }
  return state;
}
