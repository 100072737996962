import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Day from './Day';
import { setMealPlan } from 'store/mealPlan';
import { setModal } from 'store/modal';
import PaperButton from 'react-paper-button';
import { setPlanParams } from 'store/planParams';
import ToolTip from 'components/ToolTip';
require('moment-weekday-calc');

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.date,
      xyz: false
    };
  }

  componentDidMount() {
    this.props.onReady();
  }

  onChangeDate = diff => {
    const { date } = this.state;
    const { period } = this.props;
    const newDate = date.clone().add(diff, 'week');
    if (diff < 0) {
      newDate.endOf('week');
    }
    if (newDate.isSameOrAfter(period.from) && newDate.isBefore(period.to)) {
      this.props.onChangeDate(newDate.startOf('week'));
    }
  };

  replicate = () => {
    const { mealPlan, period, setMealPlan, includesDay, excludedDates } = this.props;
    if (Object.keys(mealPlan).filter(el => Object.keys(mealPlan[el]).length > 0).length && (Object.keys(mealPlan).length >= includesDay.length)) {
      const startOfWeek = period.from.unix();
      const endOfWeek = period.from.clone().add(1, 'week').unix();
      const week = Object.keys(mealPlan).filter(el => +el >= startOfWeek && +el < endOfWeek).map(el => +el);
      if (week.length >= includesDay.length) {
        let replicated = week.reduce((acc, cur) => cur in mealPlan ? { ...acc, [+cur]: mealPlan[cur] } : acc, {});
        let date = period.from.clone().add(1, 'week');
        let i = 0;
        while (date.startOf('day').isBefore(period.to)) {
          replicated = { ...replicated, [date.unix()]: replicated[date.clone().subtract(1, 'week').unix()] };
          date = date.addWeekdaysFromSet(1, includesDay, excludedDates);
          if (i++ > 100) {
            break;
          }
        }
        setMealPlan(replicated);
        this.props.setModal({ message: 'The selection was duplicated for the respective days of the week.' });
      }
      return null;
    }
    this.props.setModal({ message: 'Please select at least one dish for one day.' });
  };

  render() {
    const { xyz, date } = this.state;
    const { goMenu, excludeWeekDay, period, isMob, showOnMobileBlock, excludedDates } = this.props;
    const week = Array(7).fill(0).map((el, id) => {
      const day = date.clone().add(id, 'day');
      const fDate = day.format('YYYY-MM-DD');
      if (!(excludeWeekDay.includes(day.weekday()) || !(day.isSameOrAfter(period.from) && day.isSameOrBefore(period.to)) || excludedDates.includes(fDate))) {
        return (<Day
          key={id}
          date={date.clone().add(id, 'day')}
          goMenu={goMenu}
        />);
      }
    });
    const hasPrevWeek = date.clone().subtract(1, 'week').endOf('week').isAfter(period.from);
    const hasNextWeek = date.clone().add(1, 'week').startOf('week').isBefore(period.to);
    // const needRepButton = !!(hasPrevWeek || hasNextWeek);
    const needRepButton = !!(period.to.diff(period.from, 'days') > 7);
    console.log(period.to.diff(period.from, 'days'));
    const toShow = isMob ? showOnMobileBlock : 'menu';
    return (
      <Fragment>
        {(toShow === 'menu') &&
          <Fragment>
            <div className={`order-block-holder  ${xyz ? 'xyz' : 'wrapper xyz-fin'}`}>
              <div className='head-line'>
                <ToolTip
                  overlayClassName={'on-dishes'}
                  tooltipDirection={'bottom'}
                  trigger={['click']}
                  tooltipText={<div className=''>
                    <div className='regular-bold'>Your order is divided into weeks.</div>
                    <div className='t-total'><span className='green'>To switch between the weeks, use "next" and "previous" buttons</span></div>
                    {needRepButton &&
                    <Fragment>
                      <div className='regular-bold'>You can duplicate your choice for the days of the week.</div>
                      <div className='t-total'><span className='green'>For duplication, please select at least one dish for one day.</span></div>
                    </Fragment>
                    }
                  </div>}
                >
                  <h5>
                    <span>?</span>
                    Choice details
                  </h5>
                </ToolTip>
                <p className='sub-line'>Your meal plan menu</p>
              </div>
              {week}
            </div>
          </Fragment>
        }
        {(toShow === 'menu') &&
          <Fragment>
            <div className='order-buttons'>
              {(hasPrevWeek || hasNextWeek) &&
              <Fragment>
                <div className='row weeks-nav'>
                  <Fragment>
                    <div className={needRepButton ? 'col-6 mini' : 'col-6'}>
                      <div className={`week-switch ${hasPrevWeek ? '' : 'disabled'}`} onClick={() => hasPrevWeek && this.onChangeDate(-1)}><span>←</span>Prev week</div>
                    </div>
                    <div className={needRepButton ? 'col-6 mini text-right' : 'col-6 text-right'}>
                      <div className={`week-switch last ${hasNextWeek ? '' : 'disabled'}`} onClick={() => hasNextWeek && this.onChangeDate(1)}>Next week<span>→</span></div>
                    </div>
                  </Fragment>
                  {needRepButton &&
                  <div className='col-12 mini mini-replicate d-none'>
                    <PaperButton className='replicate button-regular transition w-100' onClick={this.replicate}>
                      Replicate
                    </PaperButton>
                  </div>
                  }
                </div>
              </Fragment>
              }
           
            </div>
          </Fragment>
        }
      </Fragment>
    );
  }
}

Order.propTypes = {
  planParams: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  excludeWeekDay: PropTypes.array,
  includesDay: PropTypes.array,
  excludedDates: PropTypes.array,
  dataArray: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  spin: PropTypes.func.isRequired,
  setModal: PropTypes.func,
  onChangeDate: PropTypes.func,
  goMenu: PropTypes.func,
  onReady: PropTypes.func,
  showOnMobileBlock: PropTypes.any,
  isMob: PropTypes.any
};

const mapStateToProps = state => ({
  planParams: state.planParams,
  dataArray: state.dataArray,
  mealPlan: state.mealPlan
});

const bindAction = dispatch => ({
  setMealPlan: plan => dispatch(setMealPlan(plan)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  setModal: obj => dispatch(setModal(obj))
});

export default connect(mapStateToProps, bindAction, null, { withRef: true })(Order);
