import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PureCheckBox extends Component {
  render() {
    const { name, defaultValue, value, val, className, disabled } = this.props;
    const checked = defaultValue === 1 || typeof (value) === 'boolean' ? value === true : value === 1;
    return (
      <input
        type='checkbox'
        className={className}
        checked={checked}
        onChange={e => this.props.onChange(name, e.target.checked ? 1 : 0, val)}
        disabled={disabled}
      />
    );
  }
}

PureCheckBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  val: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

PureCheckBox.defaultProps = {
  defaultValue: 0,
  className: '',
  val: '',
  disabled: false,
  value: false,
  onChange: () => {}
};

export default PureCheckBox;
