import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectValidation from '../../../../components/MultiSelectValidation';

const MultiSelectLayout = props => {
  return (
    <div className='row multiselect'>
      <div className='col-12 mb-0 mb-md-3'>
        <h5 className='w-100 mp-table green mb-0'>{props.title}</h5>
        <span className='mb-0 subtext light-dark-text letter-xs'>{props.subtext}</span>
      </div>
      <div className='col-12 bg-1-2 multiselect-holder'>
        <MultiSelectValidation {...props} />
      </div>
    </div>
  );
};

MultiSelectLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string
};

MultiSelectLayout.defaulProps = {
  subtext: ''
};

export default MultiSelectLayout;
