import React from 'react';
import { Route, IndexRoute, browserHistory } from 'react-router';
import Forgot from './Forgot';
import Confirm from './Confirm';
import Restore from './Restore';
import Home from './Home';
import SignIn from './SignIn';
import NotFound from './NotFound';
import PageLayout from '../layouts/PageLayout/PageLayout';
import Feedback from './Feedback';
import Dish from './Feedback/Dish';
import General from './Feedback/General';
import FeedbackRating from './Feedback/Rating';
import SignUp from './SignUp';
import ContactUs from './ContactUs';
import Menu from './Menu';
import MenuPlan from './Menu/Plan';
import MenuChoose from './Menu/Choose';
import Preferences from './Menu/Preferences';
import Address from './Menu/Address';
import Summary from './Menu/Summary';
import Thank from './Menu/Thank';
import MenuProfile from './Menu/Profile';
import MenuModify from './MenuModify';
import MenuModifyPlan from './MenuModify/Plan';
import AddressModify from './MenuModify/Address';
import SummaryModify from './MenuModify/Summary';
import MenuModifyProfile from './MenuModify/Profile';
import Profile from './Profile';
import Progress from './Progress';
import Support from './Support';
import MyCredit from './MyCredit';
import Blog from './Support/Blog';
import BlogArticle from './Support/Blog/Article';
import Forum from './Support/Forum';
import ForumThread from './Support/Forum/Thread';
import EditThread from './Support/Forum/EditThread';
import Fitbit from './Fitbit';
import Static, { Privacy, Terms, Refunds } from './Static';
import Test from './Test';
import Dashboard from './Dashboard';
import Telrpayment from './Payment/Telr';
import UpdateBirthday from './Profile/BirthDay';
import ClaimReward from './ClaimReward';
import BodyAnalysis  from './BodyAnalysis';
const notAuth = store => {
  return () => {
    let { user } = store.getState();
    if (user.token) {
      browserHistory.push('/');
    }
  };
};

const needAuth = (store, callback) => {
  return () => {
    let { user } = store.getState();
    if (!user.token) {
      browserHistory.push('/sign-in');
    } else {
      if (callback && !callback(store)) {
        browserHistory.push('/');
      }
    }
  };
};

const steps = {
  1: '',
  2: 'preferences',
  3: 'plan',
  4: 'profile',
  5: 'address',
  6: 'summary',
  7: 'thank'
};

const checkPlan = (store, buildStep) => {
  return () => {
    let { planParams: { step } } = store.getState();
    if (buildStep > step) {
      browserHistory.push(`/menu/${steps[step]}`);
    }
  };
};

const hasPlan = store => {
  let { user } = store.getState();
  const { hasPlan, renewPlan, canBeRenewed } = user;
  return !hasPlan || (renewPlan && canBeRenewed);
};

const createRoutes = store => (
  <Route path='/' component={PageLayout} >
    //<Route path='home' component={Home} />
    //<IndexRoute component={Home} />
    <IndexRoute component={Dashboard} onEnter={needAuth(store)} />
    /*<Route path='menu' component={Menu} onEnter={needAuth(store, hasPlan)} >
      <IndexRoute component={MenuChoose} />
      <Route path='preferences' component={Preferences} onEnter={checkPlan(store, 2)} />
      <Route path='plan' component={MenuPlan} onEnter={checkPlan(store, 3)} />
      <Route path='profile' component={MenuProfile} onEnter={checkPlan(store, 4)} />
      <Route path='address' component={Address} onEnter={checkPlan(store, 5)} />
      <Route path='summary' component={Summary} onEnter={checkPlan(store, 6)} />
      <Route path='thank' component={Thank} onEnter={checkPlan(store, 7)} />
    </Route>*/
    <Route path='modify' component={MenuModify} onEnter={needAuth(store)} >
      <IndexRoute component={MenuModifyPlan} />
      <Route path='profile' component={MenuModifyProfile} onEnter={checkPlan(store)} />
      <Route path='address' component={AddressModify} onEnter={checkPlan(store)} />
      <Route path='summary' component={SummaryModify} onEnter={checkPlan(store)} />
    </Route>
    <Route path='profile' component={Profile} onEnter={needAuth(store)} />
    <Route path='my-credit' component={MyCredit} onEnter={needAuth(store)} />
    <Route path='progress' component={Progress} onEnter={needAuth(store)} />
    <Route path='body-analysis' component={BodyAnalysis} onEnter={needAuth(store)} />
    <Route path='sign-in' component={SignIn} onEnter={notAuth(store)} />
    <Route path='nutrition-support'>
      <IndexRoute component={Support} />
      <Route path='blog'>
        <IndexRoute component={Blog} />
        <Route path='article/:id' component={BlogArticle} />
      </Route>
      <Route path='forum'>
        <IndexRoute component={Forum} />
        <Route path='thread/:id' component={ForumThread} />
        <Route path='edit/:id(\d+)' component={EditThread} onEnter={needAuth(store)} />
        <Route path='add' component={EditThread} onEnter={needAuth(store)} />
      </Route>
    </Route>
    <Route path='feedback' onEnter={needAuth(store)} >
      <IndexRoute component={Feedback} />
      <Route path='dish' component={Dish} />
      <Route path='general' component={General} />
    </Route>
    {/*<Route path='sign-up' component={SignUp} onEnter={notAuth(store)} />*/}
    <Route path='/forgot/:token' component={Forgot} onEnter={notAuth(store)} />
    <Route path='/confirm(/:type(/:token))' component={Confirm} />
    <Route path='/fitbit' component={Fitbit} />
    <Route path='/restore' component={Restore} onEnter={notAuth(store)} />
    <Route path='/page/(:alias)' component={Static} />
    <Route path='/terms'>
      <IndexRoute component={Terms} />
    </Route>
    <Route path='telrpayment/:action' component={Telrpayment} />
    <Route path='update-birthday/:userId' component={UpdateBirthday} />
    <Route path='claim-reward/:userId' component={ClaimReward} />
    <Route path='feedback/rating/:id/:order_id' component={FeedbackRating} />
    <Route path='/refunds'>
      <IndexRoute component={Refunds} />
    </Route>
     <Route path='/privacy-policy'>
      <IndexRoute component={Privacy} />
    </Route>
    <Route path='/dashboard'>
      <IndexRoute component={Dashboard} />
    </Route>
    <Route path='*' component={Static} />
   
  </Route>
);

export default createRoutes;
