import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { browserHistory } from 'react-router';
import MultiCheckBoxInput from 'components/MultiCheckBoxInput';
import InputCalendar from 'components/InputCalendar';
import { setPlanParams, updatePlanParams } from 'store/planParams';
import { setMealPlan } from 'store/mealPlan';
import SelectValidation from 'components/SelectValidation';
import PureCheckBox from 'components/PureCheckBox';
import { setModal } from 'store/modal';
import ToolTip from 'components/ToolTip';
import HeadLine from 'layouts/PageLayout/HeadLine';
import moment from 'moment';
import PaperButton from 'react-paper-button';

import { intersect, parseDates } from 'lib';

const animateTime = 100;

const numNames = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

class Choose extends Component {
  constructor(props) {
    super(props);
    const { user, planParams, dataArray } = props;
    const { mealType, mealPlan, diet, date, deliverySlot, cutlery } = planParams;
    const { planList, defaultTypes } = dataArray;
    const defPlan = planList && Object.keys(planList)[0];
    this.loaded = false;
    this.state = {
      xyz: false,
      errors: {},
      date: date || '',
      mealType: mealType || defaultTypes || [],
      mealPlan: mealPlan || defPlan || '',
      diet: diet || '',
      deliverySlot: deliverySlot || user['slot_id'] || '',
      cutlery: cutlery || false
    };
  }

  listener = ({ type, data }) => {
    if (this.ChooseRef) {
      switch (type) {
        case 'getOk':
          const { planParams } = data;
          const { id, coupon, price, payed, date, ...filteredParams } = planParams;
          const { slot_id: deliverySlot, mealPlan, mealType, diet } = filteredParams;
          this.setState({ deliverySlot, mealPlan, mealType: mealType.map(el => +el), diet });
          this.props.updatePlanParams(filteredParams);
          break;
        case `${type}Err`:
          console.error(data.message);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeEventListener('order', this.listener);
  }

  componentDidMount() {
    if (this.ChooseRef) {
      if (!this.loaded) {
        this.props.socket.emit('order', { type: 'get', data: { orderType: 'active' } });
        this.loaded = true;
      }
      const { mealType } = this.state;
      if (!mealType.length && this.props.dataArray['requiredTypes']) {
        this.setState({ mealType: this.props.dataArray['requiredTypes'] });
      }
      setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    }
  }

  componentWillReceiveProps(next) {
    const { planParams, socket } = this.props;
    const { planParams: nextParams, dataArray } = next;
    if ((planParams || nextParams || {}).build === 'renew' && !this.loaded) {
      socket.emit('order', { type: 'get', data: { orderType: 'active' } });
      this.loaded = true;
    }
    if (this.ChooseRef) {
      const { mealType, mealPlan } = this.state;
      const { defaultTypes } = dataArray;
      let newState = {};
      if ((!mealType.length && defaultTypes) || (defaultTypes && mealType.length !== defaultTypes.length)) {
        newState['mealType'] = dataArray['defaultTypes'];
      }
      if (!mealPlan && dataArray['planList']) {
        newState['mealPlan'] = Object.keys(dataArray['planList'])[0];
      }
      this.setState(newState);
    }
  }

  changeType = (typeId, checked) => {
    typeId = +typeId;
    const min = 2;
    const { mealType: oldMealType } = this.state;
    if (oldMealType.length === min && !checked) {
      const message = <div className='row'>
        <div className='col-12'>
          <p className='text-center mt-2 spaced'>
            You must choose <span className='regular-bold'>at least {numNames[min]}</span> meal{min > 1 ? 's' : ''}.
          </p>
        </div>
      </div>;
      this.props.setModal({ message, headLine: 'Notice' });
      return;
    }
    const mealType = checked ? [...oldMealType, typeId] : oldMealType.filter(id => id !== typeId);
    this.setState({ mealType });
  };

  changePlan = val => this.setState({ mealPlan: val, diet: false, date: '' });

  onChange = (name, val) => {
    this.setState({ [name]: val });
  };

  changeDiet = (e, mealPlan) => {
    this.setState({
      diet: e.target.checked && e.target.name,
      mealPlan,
      date: +mealPlan !== +this.state.mealPlan ? '' : this.state.date
    });
  };

  fillPlanDates = (startDate, count, excludedDays, excludedDates) => {
    const includedDays = Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(el));
    const initialDate = moment.unix(startDate).utc();
    let dates = {};
    for (let i = 0; i < count; i++) {
      const date = +initialDate.addWeekdaysFromSet(i, includedDays, excludedDates).unix();
      dates[date] = {};
    }
    return dates;
  };

  save = excludedDates => {
    const { mealType, cutlery, diet } = this.state;
    const { planParams, mealPlan, setMealPlan, setPlanParams, dataArray, spin } = this.props;
    if (!diet) {
      this.props.setModal({ message: 'Please select diet', headLine: 'Validation error' });
      return;
    }
    let result = {
      errors: {},
      params: {
        mealType,
        cutlery
      }
    };
    ['date', 'diet', 'mealPlan', 'deliverySlot'].forEach(name => {
      result = this.checkState(result, name);
    });
    if (Object.keys(result.errors).length) {
      this.setState({ errors: result.errors });
    } else {
      spin();
      const newPlanParams = { ...planParams, ...result.params };
      const { mealPlan: planId, diet, mealType: types, date: start } = newPlanParams;
      const { excludeWeekDay, count } = dataArray['planList'][planId];
      const planDates = this.fillPlanDates(start, count, excludeWeekDay, excludedDates);
      const oldDates = Object.keys(mealPlan || {});
      const newDates = Object.keys(planDates || {});
      const oldAmount = oldDates.length;
      const newAmount = newDates.length;
      if (!oldAmount || oldAmount !== newAmount || intersect(oldDates, newDates).length !== oldAmount) {
        setMealPlan(planDates);
      }
      this.getDefaultDishes(diet, types);
      setPlanParams({ ...newPlanParams, step: Math.max(planParams.step, 2) });
      browserHistory.push('/menu/preferences');
    }
  };

  getDefaultDishes = (dietId, types) => {
    this.props.socket.emit('default_dishes', { type: 'get', data: { dietId, types } });
  };

  checkState = (result, name) => {
    let params, errors;
    if (this.state[name]) {
      params = {
        ...result.params,
        [name]: this.state[name]
      };
      errors = result.errors;
    } else {
      params = result.params;
      errors = { ...result.errors, [name]: `${name} is required` };
    }
    return { params, errors };
  };

  renderPrices = (planId) => {
    const isMob = window.innerWidth <= 768 || screen.width <= 768 || false;
    const { typeList, planList, priceList, dietList } = this.props.dataArray;
    const { mealPlan, mealType, diet } = this.state;
    return dietList && Object.keys(dietList).map(dietId => {
      let sum = 0;
      const prices = priceList ? (priceList.filter ? priceList : [])
        .filter(price => (+price['diet_id'] === +dietId && +price['mealPlan_id'] === +planId && mealType.includes(+price['mealType_id'])))
        .reduce((acc, cur) => {
          sum += +cur['value'];
          return { ...acc, [cur['mealType_id']]: cur['value'] };
        }, {}) : [];
      const days = planList[planId] ? +planList[planId]['count'] : 0;
      const planSum = days * sum / 100;
      const dishes = mealType.map(id => {
        const title = typeList[id] ? typeList[id].title : '';
        return <div key={id} className='t-row'><span className='ttitle'>{title}: </span><span className='tprice green'>AED {prices[id] / 100}</span></div>;
      });
      return (
        <div className={`col-12 col-md transition program-choose ${+planId === +mealPlan ? 'selected-plan' : ''}`} key={dietId}>
          <div className='title-for-mobile'>{dietList[dietId]}</div>
          {isMob &&
            <div className='tool-tip-on-mb'>
              <ToolTip
                trigger={['click']}
                tooltipText={<div>
                  {dishes}
                  <div className='t-total'><span className='regular-bold'>Total per day: </span><span className='green'>AED {sum / 100}</span></div>
                  <div className='regular-bold'>AED {planSum} ({days} days / week)</div>
                </div>}
              >
                <span>i</span>
              </ToolTip>
            </div>
          }
          <label className='checkbox-container pr-0 m-0 pointer'>
            <input
              type='checkbox'
              className=''
              checked={+planId === +mealPlan && +dietId === +diet}
              name={dietId}
              onChange={e => this.changeDiet(e, planId)}
            />
            {!isMob && <ToolTip
              tooltipText={<div>
                {dishes}
                <div className='t-total'><span className='regular-bold'>Total per day: </span><span className='green'>AED {sum / 100}</span></div>
                <div className='regular-bold'>AED {planSum} ({days} days / week)</div>
              </div>}
            >
              <div className='check-block shadowed d-flex flex-column justify-content-center h-100'>
                <span className='text-uppercase check-block-text'>
                  <span className={'hide-aed'}>AED</span> {planSum}
                </span>
                <span className='checkmark m-0' />
              </div>
            </ToolTip>}
            {isMob && <div className='check-block shadowed d-flex flex-column justify-content-center h-100'>
              <span className='text-uppercase check-block-text'>
                <span className={'hide-aed'}>AED</span> {planSum}
              </span>
              <span className='checkmark m-0' />
            </div>}
          </label>
        </div>
      );
    });
  };

  renderDay = (props, curd, seld, exWeekdays, exDates, minDate) => {
    if (curd.isBefore(minDate) || exWeekdays.includes(curd.day()) || exDates.includes(curd.format('YYYY-MM-DD'))) {
      props.className = `${props.className} rdtDisabled`;
      delete props.onChange;
      delete props.onClick;
    }
    return <td {...props}>{ curd.date() }</td>;
  };

  render() {
    const { dataArray, locale, settings, planParams } = this.props;
    const holidays = settings.getValue('HOLIDAYS');
    const { typeList, dietList, planList, slotsList } = dataArray;
    const deliveryItems = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const { mealType, mealPlan, diet, date, deliverySlot, cutlery, errors } = this.state;
    const excludedWeekdays = (planList && planList[mealPlan] && planList[mealPlan]['excludeWeekDay']) || [];
    const typeListResult = typeList && Object.keys(typeList).sort((a, b) => typeList[a].order - typeList[b].order).map(typeId => {
      const type = typeList[typeId];
      return (
        {
          title: type.title,
          name: typeId,
          value: mealType.includes(+typeId)
        }
      );
    });
    const dietListResult = dietList && Object.keys(dietList).map(el => (
      <div className='col-12 col-md bg-gr-light programm-title' key={el}>
        <h5>{dietList[el]}</h5>
      </div>
    ));
    const excludedDates = parseDates(holidays);
    let planListPrice = [];
    const planListResult = planList && Object.keys(planList).map(id => {
      const plan = planList[id];
      planListPrice = [...planListPrice, (
        <div className='row programm-titles' key={id}>
          {this.renderPrices(id)}
        </div>
      )];
      return (<div
        className={`col-12 plan-name paper-button transition ${+mealPlan === +id ? 'regular-bold choosed' : 'regular'}`}
        title={plan.title}
        key={id}
        onClick={() => this.changePlan(id)}
      >{plan.title}</div>);
    });
    const { build, end_date } = planParams || {};
    const minDate = build === 'renew' ? (end_date ? moment.unix(end_date) : moment()).utc().startOf('day').add(1, 'd') : moment().startOf('day').add(3, 'd');
    return (
      <div className='main-holder d-flex flex-column' ref={el => (this.ChooseRef = el)}>
        <HeadLine
          title='Build my meal plan'
          desc='Weight loss, Weight Management, Paleo, Keto, Vegan'
        />
        <div className='container-fluid w-100 position-relative regular-page'>
          <div className='row my-plan choose-holder d-flex '>
            <div className='col-12 col-md-7 text-left'>
              <h2 className='green text-center-on-mobile'>Step 1: Select meals</h2>
            </div>
            <div className='col-12 col-md-5 text-left text-md-right'>
              <p className='green text-center-on-mobile'>Please select minimum of two items.</p>
            </div>
            <div className='col-12 check-group plan-check'>
              <MultiCheckBoxInput
                classNameLabel='checkbox-container transition'
                classNameSpan='regular meals'
                classNameSpan2='checkmark mt-1'
                elements={typeListResult}
                onChange={this.changeType}
                errors={errors}
              />
            </div>
          </div>
          <div className='buttons-grid-holder row p-0'>
            <div className='col-6 col-md-3 time-programm-group'>
              <div className='row plan-selection-header'>
                <div className='col-12'>
                  <h5 className='step-title green marged-on-mobile text-center-on-mobile'>Step 2: Select duration</h5>
                </div>
                <div className='col-12 bg-gr-light-duration'>
                  <h5 className='duration'>Please select your plan below</h5>
                </div>
              </div>
              <div className='row plan-list marged-on-mobile'>
                {planListResult}
              </div>
            </div>
            <div className='col-6 col-md-9 buttons grid'>
              <div className='row programm-titles plan-selection-header'>
                <div className='col-12'>
                  <h5 className='step-title green text-left text-md-center marged-on-mobile text-center-on-mobile'>Step 3: Plan selection</h5>
                </div>
                {dietListResult}
              </div>
              {planListPrice}
            </div>
          </div>

          <div className='allert-message-aed green'>Prices are indicated in AED</div>
        </div>
        <div className='container-fluid bottom-row'>
          <div className='row step-holder bottom-buttons-holder menu-choose'>
            <div className='col-12 col-md-3 d-flex align-items-center m-0 p-0'>
              <div className='w-100'>
                <div className='cal-holder search-profile-main-cal form-input calendar-input pointer absolute-cal e'>
                  <InputCalendar
                    inputClassName='letter-xs pointer'
                    className='b-rounded-gray letter-xs white-back pointer'
                    locale={locale}
                    value={date}
                    name='date'
                    errors={errors}
                    renderDay={(a, b, c) => this.renderDay(a, b, c, excludedWeekdays, excludedDates, minDate)}
                    onViewModeChange={() => false}
                    onNavigateBack={(amount, type, curDate) => moment().utc().startOf(type).isBefore(curDate.utc())}
                    onChange={val => this.onChange('date', val)}
                    placeholder='Select start day'
                    disable={!mealPlan || !diet}
                    tooltipProps={{
                      tooltipText: (
                        <div className='text-center'>
                          <span className='green'>Select plan first</span>
                        </div>
                      ),
                      tooltipDirection: 'top',
                      trigger: !mealPlan || !diet ? ['click'] : []
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-3 d-flex align-items-center inp-on-mobile'>
              <div className='w-100'>
                <SelectValidation
                  containerClassName='pointer position-relative'
                  className='styled-select b-rounded-gray '
                  selectClassName='letter-xs pointer'
                  emptyValue='Select delivery time window'
                  list={deliveryItems}
                  onChange={this.onChange}
                  value={deliverySlot}
                  errors={errors}
                  name='deliverySlot'
                />
              </div>
            </div>
            <div className='col-12 col-md-3 d-flex align-items-center justify-content-between inp-on-mobile chheck-holder'>
              <span className='req'>Cutlery required?</span>
              <label className='checkbox-container check-bas p-0 mb-0 position-relative d-flex align-items-center'>
                <PureCheckBox
                  name='cutlery'
                  value={cutlery}
                  onChange={this.onChange}
                />
                <span className='toggle-mark' />
              </label>
            </div>
            <div className='col-12 col-md-3 m-0 p-0 inp-on-mobile'>
              <PaperButton
                className={`button-regular transition next ${mealType.length && date && diet && mealPlan && deliverySlot ? '' : 'disabled'}`}
                onClick={() => this.save(excludedDates)}
              >
                Select my preferences
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Choose.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  updatePlanParams: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  settings: PropTypes.object,
  spin: PropTypes.func
};

const mapStateToProps = state => ({
  settings: state.settings,
  dataArray: state.dataArray,
  planParams: state.planParams,
  mealPlan: state.mealPlan,
  user: state.user,
  locale: (state.user.language || 'en-US').substr(-2)
});

const mapDispatchToProps = dispatch => ({
  setPlanParams: params => dispatch(setPlanParams(params)),
  setMealPlan: plan => dispatch(setMealPlan(plan)),
  updatePlanParams: obj => dispatch(updatePlanParams(obj)),
  setModal: modal => dispatch(setModal(modal))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(Choose));
