import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleCalendar from 'components/SimpleCalendar';
import PaperButton from 'react-paper-button';
import moment from 'moment';

class PlanCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      date: this.props.date
    };
  }

  componentDidMount() {
    this.props.onChange(this.state.date);
  }

  componentWillReceiveProps(nextProps) {
    const { date: prevDate } = this.props;
    const { date } = nextProps;
    if (date && prevDate && !date.isSame(prevDate)) {
      this.setState({ date });
    }
  }

  socketMailPdf = (is_arabic=false) => {
    const {spin}=this.props;
    spin();
    this.props.socket.emit('order', { type: 'mailPdf', data: {'is_arabic':is_arabic} });
  }

  socketExportPdf = (is_arabic=false) => {
    const {spin}=this.props;
    spin();
    this.props.socket.emit('order', { type: 'exportPdf', data: {'is_arabic':is_arabic} });
  }

  changeForm = e => {
    const { period } = this.props;
    let date = e.utc().startOf('day');
    if (date.isBetween(period.from, period.to, null, '[]')) {
      this.props.onChange(date.startOf('week'));
    }
  };

  origin = (props, curd, seld, month, holidays) => {
    const { date } = this.state;
    const { period, excludeWeekDay, mealPlan, mealType } = this.props;
    let selector = '';
    const notHoliday = !holidays.includes(curd.format('YYYY-MM-DD'));
    if (curd.isSameOrAfter(period.from) && curd.isSameOrBefore(period.to) && !excludeWeekDay.includes(curd.weekday()) && notHoliday) {
      if (curd.isSameOrBefore(date.clone().endOf('week')) && curd.isSameOrAfter(date)) {
        selector = 'one';
      } else {
        selector = 'two';
      }
    }
    if (curd.isSameOrAfter(period.from.clone()) && curd.isSameOrBefore(period.to.clone()) && !excludeWeekDay.includes(curd.weekday()) && notHoliday) {
      props.className = `${props.className} delivery-day`;
    }
    if (curd.isBefore(month.clone().startOf('month')) || curd.isAfter(month.clone().endOf('month'))) {
      selector = 'one-more';
    }
    if (curd.unix() in mealPlan && Object.keys(mealPlan[curd.unix()]).length < mealType.length) {
      props.className = `${props.className} not-completed`;
    }
    return <td {...{ ...props, selector }} >{ curd.date() }</td>;
  };

  modify = (props, curd, seld, month, holidays) => {
    const { mealPlan, mealType, skipped } = this.props;
    const { date } = this.state;
    let selector = '';
    const curStamp = +curd.unix();
    const newDates = Object.values(skipped).map(el => +el);
    const notHoliday = !holidays.includes(curd.format('YYYY-MM-DD'));
    if ((curStamp in mealPlan || newDates.includes(curStamp)) && notHoliday) {
      const today = moment().utc().startOf('day');
      if (curd.isBetween(today, today.clone().add(3, 'day'), null, '()')) {
        props.className = `${props.className} in-buffer`;
      } else {
        props.className = `${props.className} delivery-day`;
      }
      if (curStamp in skipped) {
        props.className = `${props.className} skipped-day`;
      }
      if (curd.isBefore(moment().add(48, 'h'))) {
        selector = 'past';
      } else if (curd.isSameOrBefore(date.clone().endOf('week')) && curd.isSameOrAfter(date)) {
        selector = 'one';
      } else {
        selector = 'two';
      } 
    } else if (props.onClick) {
      delete props.onClick;
    }
    if (!curd.isBetween(month.clone().startOf('month'), month.clone().endOf('month'), null, '[]')) {
      selector = 'one-more';
    }
    if (curd.unix() in mealPlan && Object.keys(mealPlan[curd.unix()]).length < mealType.length) {
      props.className = `${props.className} not-completed`;
    }
    return <td {...{ ...props, selector }} >{ curd.date() }</td>;
  };

  render() {
    const { xyz, date } = this.state;
    const { locale, bottomButtonClick, dayRenderer, children, /* period, mealPlan, isModify, */ showOnMobile, excludedDates } = this.props;
    // const firstDate = !isModify ? period.from : moment.unix(Object.keys(mealPlan)[0]).utc();
    // const lastDate = !isModify ? period.to : moment.unix(Object.keys(mealPlan).reverse()[0]).utc();
    // const diff = Math.fround(lastDate.clone().add(1, 'd').diff(firstDate, 'month', true));
    const firstCal = date; // diff < 1 ? lastDate.clone().subtract(1, 'month').startOf('day') : period.from.clone().startOf('day');
    // const showUpNav = flag => lastDate.diff(firstDate, 'month') > 1 && flag && false;
    return (
      <div className={`calendar-holder d-flex flex-ccol-9 selected-plan summary-product-holderolumn
      ${xyz ? 'xyz' : 'wrapper xyz-fin'} ${showOnMobile === 'calendar' ? 'hidden-on-desktop' : 'hidden-block'}`}>
        <div className='calendar-inner'>
              <div className="pdf-icons-side">
              <div className="pdf-container">
                    <div className="pdf-head"><h3>Mail Menu</h3><span>In PDF format</span></div>
                  
                  <div className="pdf-icon" onClick={ (e) => {
                      this.socketMailPdf();
                    }
                 }>
                  <i class="icon-mail mx-2"></i>
                  <span>English</span>
                </div>
                <div className="pdf-icon" onClick={ (e) => {
                      this.socketMailPdf(true);
                    }
                 }>
                  <i class="icon-mail mx-2"></i>
                  <span>Arabic</span>
                </div>
                </div>
                <div className="pdf-container">
                    <div className="pdf-head"><h3>Export Menu</h3> <span>In PDF format</span></div>
                 <div className="pdf-icon" onClick={ (e) => {
                      this.socketExportPdf();
                    }
                 }>
                  <i class="icon-print mx-2"></i>
                  <span>English</span>
                </div>
                <div className="pdf-icon" onClick={ (e) => {
                      this.socketExportPdf(true);
                    }
                 }>
                  <i class="icon-print mx-2"></i>
                  <span>Arabic</span>
                </div>
                </div>
                {/*
                <div className="pdf-icon">
                  <i class="icon-arrow-top mx-2"></i>
                  <span>web preview</span>
                </div> */}
              </div>
          {/* showUpNav(firstCal.month() - firstDate.month() !== 0) && <div className='to-button up' onClick={() => this.setState({ date: date.clone().subtract(1, 'month') })}>
            <span>&#x2B9D;</span>
          </div> */}
          <SimpleCalendar
            key='calendar-1'
            className='hide-arrow-calendar'
            locale={locale}
            onChange={this.changeForm}
            renderDay={(p, c, s) => this[dayRenderer](p, c, s, firstCal, excludedDates)}
            onNavigateBack={() => {
              this.setState({ date: date.clone().subtract(1, 'month') });
              return false;
            }}
            onNavigateForward={() => {
              this.setState({ date: date.clone().add(1, 'month') });
              return false;
            }}
            defaultValue={firstCal}
            viewDate={firstCal.utc()}
            value={firstCal}
            changeOnClickOverMonth={false}
          />
          <SimpleCalendar
            key='calendar-2'
            className='hide-arrow-calendar no-arrow'
            locale={locale}
            onChange={this.changeForm}
            renderDay={(p, c, s) => this[dayRenderer](p, c, s, firstCal.clone().add(1, 'month').startOf('day'), excludedDates)}
            onNavigateBack={() => false}
            onNavigateForward={() => false}
            defaultValue={firstCal.clone().add(1, 'month').startOf('day')}
            viewDate={firstCal.clone().add(1, 'month').startOf('day').utc()}
            value={firstCal.clone().add(1, 'month').startOf('day')}
            changeOnClickOverMonth={false}
          />
          {/* showUpNav(firstCal.month() - firstDate.month() === 0) && <div className='to-button down' onClick={() => this.setState({ date: date.clone().add(1, 'month') })}>
            <span>&#x2B9F;</span>
          </div> */}
        </div>
        <div className='bottom-container'>
          {children}
          <PaperButton onClick={bottomButtonClick} className='button-regular transition mt-auto mb-4 w-90'>
            <span>Back</span>
          </PaperButton>
        </div>
      </div>
    );
  }
}

PlanCalendar.propTypes = {
  date: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  mealType: PropTypes.array.isRequired,
  excludeWeekDay: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.any,
  bottomButtonClick: PropTypes.func,
  excludedDates: PropTypes.array,
  locale: PropTypes.string,
  skipped: PropTypes.object,
  dayRenderer: PropTypes.oneOf(['origin', 'modify', 'paused']),
  showOnMobile: PropTypes.string,
  isModify: PropTypes.bool
};

PlanCalendar.defaultProps = {
  onChange: () => {},
  bottomButtonClick: () => {},
  locale: 'GB',
  dayRenderer: 'origin',
  isModify: false,
  excludedDates: []
};

export default PlanCalendar;
