import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleCalendar from 'components/SimpleCalendar';
import PaperButton from 'react-paper-button';
import moment from 'moment';

class PlanMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      date: this.props.date
    };
  }

  componentDidMount() {
    const { mealPlan } = this.props;
    let index = 0;
    if(mealPlan){
      index = Object.keys(mealPlan).indexOf(this.state.date.unix());
    }
    this.props.onChange(this.state.date,index);
  }

  componentWillReceiveProps(nextProps) {
    const { date: prevDate } = this.props;
    const { date } = nextProps;
    if (date && prevDate && !date.isSame(prevDate)) {
      this.setState({ date });
    }
  }

  socketMailPdf = (is_arabic=false) => {
    const {spin}=this.props;
    spin();
    this.props.socket.emit('order', { type: 'mailPdf', data: {'is_arabic':is_arabic} });
  }

  socketExportPdf = (is_arabic=false) => {
    const {spin}=this.props;
    spin();
    this.props.socket.emit('order', { type: 'exportPdf', data: {'is_arabic':is_arabic} });
  }

  changeForm = (e,index) => {
    const { period } = this.props;
    let date = e.utc().startOf('day');
    if (date.isBetween(period.from, period.to, null, '[]')) {
      // this.props.onChange(date.startOf('week'));
      this.props.onChange(date,index);
    }
  };

  origin = (props, curd, seld, month, holidays) => {
    const { date } = this.state;
    const { period, excludeWeekDay, mealPlan, mealType } = this.props;
    let selector = '';
    const notHoliday = !holidays.includes(curd.format('YYYY-MM-DD'));
    if (curd.isSameOrAfter(period.from) && curd.isSameOrBefore(period.to) && !excludeWeekDay.includes(curd.weekday()) && notHoliday) {
      if (curd.isSameOrBefore(date.clone().endOf('week')) && curd.isSameOrAfter(date)) {
        selector = 'one';
      } else {
        selector = 'two';
      }
    }
    if (curd.isSameOrAfter(period.from.clone()) && curd.isSameOrBefore(period.to.clone()) && !excludeWeekDay.includes(curd.weekday()) && notHoliday) {
      props.className = `${props.className} delivery-day`;
    }
    if (curd.isBefore(month.clone().startOf('month')) || curd.isAfter(month.clone().endOf('month'))) {
      selector = 'one-more';
    }
    if (curd.unix() in mealPlan && Object.keys(mealPlan[curd.unix()]).length < mealType.length) {
      props.className = `${props.className} not-completed`;
    }
    return <td {...{ ...props, selector }} >{ curd.date() }</td>;
  };

  modify = (props, curd, seld, month, holidays) => {
    const { mealPlan, mealType, skipped } = this.props;
    const { date } = this.state;
    let selector = '';
    const curStamp = +curd.unix();
    const newDates = Object.values(skipped).map(el => +el);
    const notHoliday = !holidays.includes(curd.format('YYYY-MM-DD'));
    if ((curStamp in mealPlan || newDates.includes(curStamp)) && notHoliday) {
      const today = moment().utc().startOf('day');
      if (curd.isBetween(today, today.clone().add(3, 'day'), null, '()')) {
        props.className = `${props.className} in-buffer`;
      } else {
        props.className = `${props.className} delivery-day`;
      }
      if (curStamp in skipped) {
        props.className = `${props.className} skipped-day`;
      }
      if (curd.isBefore(moment().add(48, 'h'))) {
        selector = 'past';
      } else if (curd.isSameOrBefore(date.clone().endOf('week')) && curd.isSameOrAfter(date)) {
        selector = 'one';
      } else {
        selector = 'two';
      }
    } else if (props.onClick) {
      delete props.onClick;
    }
    if (!curd.isBetween(month.clone().startOf('month'), month.clone().endOf('month'), null, '[]')) {
      selector = 'one-more';
    }
    if (curd.unix() in mealPlan && Object.keys(mealPlan[curd.unix()]).length < mealType.length) {
      props.className = `${props.className} not-completed`;
    }
    return <td {...{ ...props, selector }} >{ curd.date() }</td>;
  };
  renderDays(){
    const { mealPlan, mealType, skipped,excludedDates:holidays } = this.props;
    const { date } = this.state;
   
    let self = this;
    let dates=Object.keys(mealPlan).map(function(dateIndex,index){
        return <li key={index} className={`day-list ${dateIndex==date.unix() ? 'active' : ''}`} onClick={() => self.changeForm(moment.unix(dateIndex),index)
        }>{ moment.unix(dateIndex).format("ddd, Do MMM, YYYY") }</li>;
    });
    return dates;
  }
  render() {
    const { xyz, date } = this.state;
    const { locale, bottomButtonClick, dayRenderer, children, period, mealPlan, isModify, showOnMobile, excludedDates,showCalendlyModal,sentRenewRequest } = this.props;
    const firstDate = !isModify ? period.from : moment.unix(Object.keys(mealPlan)[0]).utc();
    const lastDate = !isModify ? period.to : moment.unix(Object.keys(mealPlan).reverse()[0]).utc();
    const diff = Math.fround(lastDate.clone().add(1, 'd').diff(firstDate, 'month', true));
    const firstCal = date; // diff < 1 ? lastDate.clone().subtract(1, 'month').startOf('day') : period.from.clone().startOf('day');
    // const showUpNav = flag => lastDate.diff(firstDate, 'month') > 1 && flag && false;
    const today = moment().startOf('day');
    const buffered = lastDate.isBetween(today, today.clone().add(7, 'day'), '[)');
    return (
      <div className={`calendar-holder d-flex flex-ccol-9 selected-plan summary-product-holderolumn
      ${xyz ? 'xyz' : 'wrapper xyz-fin'} ${showOnMobile === 'calendar' ? 'hidden-on-desktop' : 'hidden-block'}`}>
        <div className='calendar-inner'>
              {/* <div className="pdf-icons-side clearfix">
                <div className="pdf-container">
                    <div className="pdf-head"><h3>Mail Menu</h3><span>In PDF format</span></div>

                  <div className="pdf-icon" onClick={ (e) => {
                      this.socketMailPdf();
                    }
                 }>
                  <span>English</span>
                  <i class="icon-mail mx-2"></i>
                </div>
                <div className="pdf-icon" onClick={ (e) => {
                      this.socketMailPdf(true);
                    }
                 }>
                  <span>Arabic</span>
                  <i class="icon-mail mx-2"></i>
                </div>
                </div>
                <div className="pdf-container">
                    <div className="pdf-head"><h3>Export Menu</h3> <span>In PDF format</span></div>
                 <div className="pdf-icon" onClick={ (e) => {
                      this.socketExportPdf();
                    }
                 }>
                  <span>English</span>
                  <i class="icon-print mx-2"></i>
                </div>
                <div className="pdf-icon" onClick={ (e) => {
                      this.socketExportPdf(true);
                    }
                 }>
                  <span>Arabic</span>
                  <i class="icon-print mx-2"></i>
                </div>
                </div>
              </div> */}
           <div className="consultation">
                <i class="head-icon"></i>
                 <p>Our nutritionist is here to help with your wellness journey</p>
                 <PaperButton className='button-regular book-btn transition mt-auto mb-4 w-90' onClick={() => showCalendlyModal()}>
                  <span>Book a consultation</span>
                </PaperButton>
           </div>
           <div className="valid-links">
                 <ul>
                 <li><a href="javascript:void(0);" className='active' onClick={ (e) => {
                      this.socketExportPdf();
                    } 
                 }>View My Menu<i class="fa fa-chevron-right" aria-hidden="true"></i></a></li>
                   <li><a href="/profile">Profile<i class="fa fa-chevron-right" aria-hidden="true"></i></a></li>
                   {buffered && <li><a href="javascript:void(0);" onClick={ (e) => {
                      sentRenewRequest();
                    } 
                 }>Renew My Plan<i class="fa fa-chevron-right" aria-hidden="true"></i></a></li>}
                 </ul>
           </div>
           <div className="mealplanselect-list">
             <p><i class="icon-meal"></i>Select My Meals</p>
             <ul className="renderMealplanDays clearfix">{this.renderDays() }</ul>
           </div>
         
        </div>
        <div className='bottom-container'>
          {children}
          <PaperButton onClick={bottomButtonClick} className='button-regular transition mt-auto mb-4 w-90'>
            <span>Back</span>
          </PaperButton>
        </div>
      </div>
    );
  }
}

PlanMenu.propTypes = {
  date: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  mealType: PropTypes.array.isRequired,
  excludeWeekDay: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.any,
  bottomButtonClick: PropTypes.func,
  excludedDates: PropTypes.array,
  locale: PropTypes.string,
  skipped: PropTypes.object,
  dayRenderer: PropTypes.oneOf(['origin', 'modify', 'paused']),
  showOnMobile: PropTypes.string,
  isModify: PropTypes.bool
};

PlanMenu.defaultProps = {
  onChange: () => {},
  bottomButtonClick: () => {},
  locale: 'GB',
  dayRenderer: 'origin',
  isModify: false,
  excludedDates: []
};

export default PlanMenu;