import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleCalendar from 'components/SimpleCalendar';
import ToolTip from 'components/ToolTip';
import moment from 'moment';
import InputValidation from 'components/InputValidation';
import PaperButton from 'react-paper-button';

class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().utc().startOf('day'),
      changedData: {}
    };
  }

  renderDay = (props, curd, seld) => {
    const { regDate, progress } = this.props;
    const { changedData, selectedDate } = this.state;
    const { weight, calories, waist } = progress;
    const { weight: newWeight, calories: newCalories, waist: newWaist } = changedData;
    props.className = 'rdtDay';
    const date = moment.unix(regDate).startOf('day');
    let trigger = [];
    const stamp = curd.unix();
    if (!curd.isBetween(date, moment(), null, '[]')) {
      props.className = `${props.className} rdtOld`;
      delete props.onClick;
    } else {
      if (stamp in weight || stamp in waist || stamp in calories || (newWeight && stamp in newWeight) || (newWaist && stamp in newWaist) || (newCalories && stamp in newCalories)) {
        trigger = ['hover'];
      }
      if (curd.isSame(selectedDate)) {
        props.className = `${props.className} selected`;
      }
      props.onClick = () => {
        this.setState({ selectedDate: curd });
      };
    }
    const weightVal = ((newWeight && newWeight[stamp]) || weight[stamp]);
    const waistVal = ((newWaist && newWaist[stamp]) || waist[stamp]);
    const calVal = ((newCalories && newCalories[stamp]) || calories[stamp]);
    const valsSum = !!weightVal + !!waistVal + !!calVal;
    const filled = valsSum === 3 ? 'full' : (valsSum === 0 ? 'empty' : 'partial');
    props.className = `${props.className} ${filled}`;
    return (
      <ToolTip
        key={props.key}
        tooltipText={
          <div>
            {weightVal && <div className='t-row'>
              <span className='regular-bold'>Weight: </span><span className='green'>{`${weightVal / 1000} kg`}</span>
            </div>}
            {waistVal && <div className='t-row'>
              <span className='regular-bold'>Waist: </span><span className='green'>{`${waistVal / 10} cm`}</span>
            </div>}
            {calVal && <div className='t-row'>
              <span className='regular-bold'>Calories: </span><span className='green'>{`${calVal / 10} kal`}</span>
            </div>}
          </div>
        }
        tooltipDirection={'top'}
        trigger={trigger}
      >
        <td {...props}>{ curd.date() }</td>
      </ToolTip>
    );
  };

  render() {
    const { locale, regDate, progress } = this.props;
    const { weight, calories, waist } = progress;
    const { selectedDate, changedData } = this.state;
    const { weight: newWeight, calories: newCalories, waist: newWaist } = changedData;
    const changeHandler = (name, value, date, mul = 1) => this.setState({ changedData: { ...changedData, [name]: { ...changedData[name], [+date]: value * mul } } });
    const stamp = selectedDate.unix();
    let prevWeight = false;
    let prevWaist = false;
    const today = moment().utc().startOf('day');
    if (selectedDate.clone().isSame(today)) {
      const yesterday = today.clone().subtract(1, 'd').unix();
      prevWeight = weight[today.unix()] ? prevWeight : weight[yesterday];
      prevWaist = waist[today.unix()] ? prevWaist : waist[yesterday];
    }
    return (
      <div className={'row'} style={{ maxWidth: '700px '}}>
        <div className={'col-6 calendar-on-progress'}>
          <SimpleCalendar
            locale={locale}
            onChange={() => {}}
            renderDay={this.renderDay}
            onNavigateBack={(a, t, c) => c.clone().subtract(a, t).endOf(t).isAfter(moment.unix(regDate))}
            onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment())}
            defaultValue={selectedDate}
            changeOnClickOverMonth={false}
          />
        </div>
        <div className={'col-12 col-lg-6'}>
          <div className='row form-line'>
            <div className='col-12'>
              <h5 className='green'>Weight (kg)</h5>
              <div className='bg-gr-light-2'>
                <InputValidation
                  type={'number'}
                  min={0}
                  max={500}
                  step={'0.1'}
                  name={'weight'}
                  value={((newWeight && newWeight[stamp]) || prevWeight || weight[stamp]) / 1000 || ''}
                  placeholder='Weight'
                  onChange={(name, value) => changeHandler(name, value <= 500 ? value : 500, stamp, 1000)}
                />
              </div>
            </div>
            <div className='col-12'>
              <h5 className='green'>Waist (cm)</h5>
              <div className='bg-gr-light-2'>
                <InputValidation
                  type={'number'}
                  min={0}
                  max={200}
                  step={'0.1'}
                  name={'waist'}
                  value={((newWaist && newWaist[stamp]) || prevWaist || waist[stamp]) / 10 || ''}
                  placeholder='Waist'
                  onChange={(name, value) => changeHandler(name, value <= 200 ? value : 200, stamp, 10)}
                />
              </div>
            </div>
            <div className='col-12'>
              <h5 className='green'>Calories (kcal)</h5>
              <div className='bg-gr-light-2'>
                <InputValidation
                  type={'number'}
                  min={0}
                  max={100000}
                  step={'1'}
                  name={'calories'}
                  value={((newCalories && newCalories[stamp]) || calories[stamp]) || ''}
                  placeholder='Calories'
                  onChange={(name, value) => changeHandler(name, value <= 100000 ? value : 100000, stamp)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 mt-2'>
          <PaperButton
            className={'place-order button-regular transition w-lg-50 ml-auto mr-auto'}
            onClick={() => this.props.save(changedData)}
          >
            Save
          </PaperButton>
        </div>
      </div>
    );
  }
}

ModalForm.propTypes = {
  locale: PropTypes.string.isRequired,
  regDate: PropTypes.number.isRequired,
  progress: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired
};

export default ModalForm;
