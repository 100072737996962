import React, { Component, Fragment, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { getArrays } from 'lib';
import { spin, stop } from 'store/spinner';

const animateTime = 100;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    const { socket, dataArray } = this.props;
    const types = ['typeList', 'dietList', 'slotsList', 'planList', 'priceList', 'ingredientList', 'areasListFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  render() {
    const { children, spin, stop, spinner } = this.props;
    let child = children && Children.map(children, child => cloneElement(child, { spin, stop, spinner }));
    return <Fragment>{child}</Fragment>;
  }
}

Menu.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func,
  spinner: PropTypes.bool
};

const props = state => ({
  dataArray: state.dataArray,
  spinner: state.spinner
});

const actions = dispatch => ({
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop())
});

export default socketConnect(connect(props, actions)(Menu));
