import axios from 'axios';
import PropTypes from 'prop-types';

function sendImage(dataForm, finish) {
  const path = '/api/user/set-images';
  axios.post(path, dataForm)
    .then(data => {
      finish(data.data);
    })
    .catch(response => {
      console.log('get data error');
      console.log(response);
    });
}

sendImage.propTypes = {
  dataForm: PropTypes.object.isRequired,
  finish: PropTypes.func
};

export default sendImage;
