/* eslint-disable no-extend-native */
import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import HeadLine from 'layouts/PageLayout/HeadLine';
import PaperButton from 'react-paper-button';
import BarGrid from 'components/Charts/Bar';
import LineGrid from 'components/Charts/Line';
import ModalWrapper from 'components/ModalWrapper';
import moment from 'moment';
import ModalForm from './ModalForm';
import BodyAnalysisForm from './ModalForm/bodyanalysis';
import { setPlanParams, clearPlanParams } from 'store/planParams';
import { setMealPlan, clearMealPlan } from 'store/mealPlan';
import DailyMenu from 'components/DailyMenu';
import DishDescription from 'components/DishDescription';
import ChartHandler from './ChartHandler';
import { getDataArray } from 'store/dataArray';
import Edit from 'assets/dashboard/img/edit.svg';
Array.prototype.sum = function () {
  return this.reduce((acc, cur) => acc + cur, 0);
};

Array.prototype.mid = function () {
  return this.sum() / this.length;
};


class BodyAnalysis extends Component {
  constructor(props) {
    super(props);
    this.loaded = false;
    this.state = {
      xyz: false,
      showModal: false,
      closeModal: false,
      showBFMModal: false,
      closeBFMModal: false,
      type: 'dishes',
      dishId: 0,
      activePart: 0,
      activeYear:moment().year(),
      weightHistory:{},
      BMIHistory:{},
      SMMHistory:{},
      BFMHistory:{},
      progress: {
        bust: {},
        waist: {},
        hips: {},
        leftBiceps: {},
        rightBiceps:{},
        thigh:{},
        calf:{},
      },
      lastDates: false,
      errors:{}
    };
    
  }

  showOnMobileBlockToggler = (block) => {
    if (window.innerWidth <= 768 || screen.width <= 768) {
      this.setState({ prevOnMobileBlock: this.state.showOnMobileBlock });
      this.setState({ showOnMobileBlock: block });
      document.querySelector('body').scrollIntoView();
      // console.log(this.state.showOnMobileBlock, this.state.prevOnMobileBlock);
    }
  };
  getWeightHistory = () =>{
    this.props.socket.emit('progress',{type:'getWeightHistory'});
  }
  getBodyMeasurements = () =>{
    this.props.socket.emit('progress',{type:'getBodyMeasurements'});
  }
  getBodyAnalaysis = () =>{
    this.props.socket.emit('progress',{type:'getBodyAnalaysis'});
  }
  componentWillMount() {
    const { socket } = this.props;
    socket.on('progress', this.listenerProgress);
    socket.on('order', this.listenerOrder);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('progress', this.listenerProgress);
    this.props.socket.removeListener('order', this.listenerOrder);
  }

  listenerOrder = ({ type, data }) => {
    if (this.BodyAnalysisRef) {
      switch (type) {
        case 'getOk':
          const { mealPlan, planParams } = data;
          this.props.setMealPlan(mealPlan);
          this.props.setPlanParams(planParams);
          break;
        case `${type}Err`:
          console.error(data.message);
          break;
      }
    }
  };

  listenerProgress = ({ type, data }) => {
    if (this.BodyAnalysisRef) {
      switch (type) {
        case 'getOk':
          const { progress } = data;
          const today = moment().utc().startOf('day');
          const lastDates = Object.keys(progress).reduce((acc, cur) => {
            const dates = Object.keys(progress[cur]);
            const date = dates.length ? moment.unix(dates.reverse()[0]) : null;
            if (date && today.diff(date, 'h') > 24) {
              return { ...acc, [cur]: date };
            } else {
              return acc;
            }
          }, {});
          let state = { progress };
          if (Object.keys(lastDates).length) {
            state = { ...state, lastDates, showModal: true, closeModal: () => this.setState({ showModal: false, closeModal: false, lastDates: false }) };
          }
          this.setState(state);
          break;
        case 'setBodyMeasurementsOk':
          this.setState({ ...data, showModal: false, closeModal: false, lastDates: false });
          break;
        case 'getWeightHistoryOk':
          this.setState({ ...data});
        break;
        case 'getBodyAnalaysisOk':
          this.setState({ ...data});
        break;
        case 'setBodyAnalaysisOk':
          const { SMMHistory,BFMHistory } = this.state; 
          let SMMHist = {...SMMHistory,...data.SMMHistory};
          let BFMHist = {...BFMHistory,...data.BFMHistory};
          this.setState({SMMHistory:SMMHist,BFMHistory:BFMHist,showBFMModal:false,closeBFMModal:false })
        break;
        case 'setWeightOk':
          const { weightHistory,BMIHistory } = this.state; 
          let weighHist = {...weightHistory,...data.weightHistory};
          let BMIHist = {...BMIHistory,...data.BMIHistory};
          this.setState({ weightHistory:weighHist,BMIHistory:BMIHist,showModal: false, closeModal: false, lastDates: false });
        break;
        case 'getBodyMeasurementsOk':
          this.setState({ ...data});
        break;
        default:
          if (['getErr', 'setErr'].includes(type)) {
            console.log({ type, data });
            if(type=='setErr'){
              this.setState({ ...data});
            }
          }
          break;
      }
    }
  };

  componentDidMount() {
    console.log('DidMount')
    setTimeout(() => this.setState({ xyz: true }), 300);
    const { dataArray, socket } = this.props;
    const keys = ['typeList', 'dietList', 'planList', 'products', 'ingredientList','medicalConditions'].filter(el => !(el in dataArray));
    keys.length && getDataArray({ keys, socket });
    // console.log(dataArray);
    this.getWeightHistory();
    this.getBodyMeasurements();
    this.getBodyAnalaysis();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { cc, socket } = this.props;
    // if (!cc && nextProps.cc && !this.loaded) {
    //   socket.emit('progress', { type: 'get' });
    //   socket.emit('order', { type: 'get' });
    //   this.loaded = true;
    // }
    this.getWeightHistory();
    this.getBodyMeasurements();
  }

  getLabels = (type, initialDate = moment().utc().startOf('day'), activeYear ,keys, amount) => {
    let labels = {};
    let lowDate = initialDate.clone();
    console.log(lowDate);
    // const { activeYear }=this.state;
    // console.log(activeYear);
    let existKeys = [];
   
    switch (type) {
      case 'year':
        lowDate.subtract(12 * 1, 'month').startOf('month');
        // console.log('lowDate');
        // console.log(lowDate);
         keys&&keys.filter(el => (moment.unix(el).year()==activeYear)).map((date)=>{
            existKeys.push(moment.unix(date).format('MMM'))
            labels = { ...labels, [date]: moment.unix(date).format('MMM Do') };
          });
        while (lowDate.add(1, 'month').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          console.log(lowDate);
          console.log(lowDate.unix());
          if(!existKeys.includes(lowDate.format('MMM')) ){
            labels = { ...labels, [stamp]: lowDate.format('MMM') };
          }
        }
        break;
        case 'month':
          
          lowDate.subtract(amount, 'month');
          keys&&keys.filter(el => (moment.unix(el).year()==activeYear && (moment.unix(el).month()==lowDate.month() || moment.unix(el).month()==moment().month()))).map((date)=>{
           
            existKeys.push(moment.unix(date).format('MMM'))
            labels = { ...labels, [date]: moment.unix(date).format('MMM Do') };
          });
      
          while (lowDate.add(1, 'day').isSameOrBefore(initialDate)) {
            const stamp = lowDate.unix();
           
            if(!existKeys.includes(lowDate.format('MMM')) ){
              labels = { ...labels, [stamp]: lowDate.format('MMM Do') };
            }
          }
        break;
    }
    return labels;
  };

  _groupData = (data, dates, calc = 'sum', mul = 1) => {
    const stamps = Object.keys(dates);
    const dif = +stamps[1] - +stamps[0];
    let dataSet = stamps.reduce((acc, cur) => {
      const tmp = Object.keys(data).reduce((res, stamp) => +stamp == +cur  ? [...res, data[stamp]] : res, []);
      return { ...acc, [+cur]: tmp };
    }, {});
    return Object.values(dataSet).map(set => set.length ? (set[calc]() * mul).toFixed(2) : null);
  };

  getProps = (dataObject) => {
    const { label, data, period, calcType, mul, additional, initialDate, activeYear,min, amount } = dataObject;
    const keys = Object.keys(data);
    console.log(initialDate);
    const labels = this.getLabels(period, initialDate, activeYear,keys, amount);
    console.log(labels);
    return {
      labels: Object.values(labels),
      timestamps: Object.keys(labels),
      min,
      label:label,
      dataSets: {
        [label]: {
          data: this._groupData(data, labels, calcType, mul),
          ...additional
        }
      }
    };
  };
  saveBodyAna =(data)=>{
    this.props.socket.emit('progress', {
      type: 'setBodyAnalaysis',
      data
    });
  }
  saveProgress = (progress,type) => {
    if(type=='Weight'){
      this.props.socket.emit('progress', {
        type: 'setWeight',
        data: { weight:progress }
      });
    }else{
      this.props.socket.emit('progress', {
        type: 'setBodyMeasurements',
        data: { progress }
      });
    }
   
  };


  renderFillLastDatesModal = dates => {
    const fillProgress = () => {
      const today = moment().utc().startOf('day');
      const { progress } = this.state;
      const newProgress = Object.keys(dates).reduce((acc, cur) => {
        let tmp = {};
        const date = dates[cur].clone();
        if (!date.isUTC()) {
          date.utc();
        }
        const val = progress[cur][date.unix()];
        while (date.add(1, 'd').isSameOrBefore(today)) {
          tmp = { ...tmp, [date.unix()]: val };
        }
        return { ...acc, [cur]: tmp };
      }, {});
      this.saveProgress(newProgress);
    };
    return (
      <div className='text-center modal-message'>
        <div className='pl-5 pr-5'>
          <p className='text-center'>{'You did not complete the data for a few days. You can enter data manually or copy the last record'}</p>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-3'>
            <PaperButton
              className='place-order button-regular transition progress-message w-100 ml-auto'
              onClick={() => this.setState({ closeModal: false, lastDates: false })}
            >
              Enter manually
            </PaperButton>
          </div>
          <div className='col-12 col-sm-3'>
            <PaperButton
              className='place-order button-regular transition progress-message w-100 mr-auto'
              onClick={fillProgress}
            >
              Copy last record
            </PaperButton>
          </div>
        
        </div>
      </div>
    );
  };

  Buttons = ({ className }) => {
    const { fitbit_token } = this.props;
    const buttClassName = 'w-100 next';
    return <div className={className}>
      <h5 className='green'>Track my progress</h5>
      <div className={'buttons'}>
        <div>
          <PaperButton onClick={() => this.setState({ showModal: true })} className={buttClassName}>
            <span>Progress tracking</span>
          </PaperButton>
        </div>
      </div>
    </div>;
  };
  calculateAge = (birthday) => { // birthday is a date
    const birth = moment.unix(birthday);
    var today = Date.now()/1000;
    var age = null;
    if (birthday) {
      var birthYear = birth.format('YYYY');
      var todayYear = moment.unix(today).format('YYYY');
      var birthMonth = birth.format('MM');
      var todayMonth = moment.unix(today).format('MM');

      var age = todayYear - birthYear;
      if (birthMonth > todayMonth) {
        age = age - 1;
      }
    }

    if (age) {
      age = age;
    } 
    return age;
  }
  getBodyFatMassText =(age,bodyFatMass,gender)=>{
    let text ='';
    if(gender=='male'){

      if(age>20 && age<=40){
        text="Underfat";
        if(bodyFatMass>8 && bodyFatMass <=19){
          text="Healthy";
        }else if(bodyFatMass>19 && bodyFatMass <=25){
          text="Overweight";
        }else if(bodyFatMass>25){
          text="Obese";
        }
      }else if(age>40 && age<=60){
        text="Underfat";
        if(bodyFatMass>11 && bodyFatMass <=22){
          text="Healthy";
        }else if(bodyFatMass>22 && bodyFatMass <=27){
          text="Overweight";
        }else if(bodyFatMass>27){
          text="Obese";
        }
      }else if(age>60 && age<=79){
        text="Underfat";
        if(bodyFatMass>13 && bodyFatMass <=25){
          text="Healthy";
        }else if(bodyFatMass>25 && bodyFatMass <=30){
          text="Overweight";
        }else if(bodyFatMass>30){
          text="Obese";
        }
      }

    }else{
    
      if(age>20 && age<=40){
        text="Underfat";
        if(bodyFatMass>21 && bodyFatMass <=33){
          text="Healthy";
        }else if(bodyFatMass>33 && bodyFatMass <=39){
          text="Overweight";
        }else if(bodyFatMass>39){
          text="Obese";
        }
      }else if(age>40 && age<=60){
        text="Underfat";
        if(bodyFatMass>23 && bodyFatMass <=35){
          text="Healthy";
        }else if(bodyFatMass>35 && bodyFatMass <=40){
          text="Overweight";
        }else if(bodyFatMass>40){
          text="Obese";
        }
      }else if(age>60 && age<=79){
        text="Underfat";
        if(bodyFatMass>24 && bodyFatMass <=36){
          text="Healthy";
        }else if(bodyFatMass>36 && bodyFatMass <=42){
          text="Overweight";
        }else if(bodyFatMass>42){
          text="Obese";
        }
      }
   }

   return text;
  }
  calculateBodyFatMass =(gender,BMI,age) =>{
      let bodyFatMass=Math.ceil((1.20 * BMI) + (0.23 * age) - 5.4);
      if(gender=='male'){
        bodyFatMass = Math.ceil((1.20 * BMI) + (0.23 * age) - 16.2);
      }
      return bodyFatMass;
  }
  calculateIdealBodyWeight = (gender,height) =>{
    let bodyweight= Math.ceil(45.5 + (0.91 * (height - 152.4)));
    if(gender=='male'){
       bodyweight = Math.ceil(50 + (0.91 * ( height - 152.4)));
    }
    return bodyweight;
  }
  render() {
    const { locale, regDate, user, dataArray } = this.props;
    const { firstName , lastName, height,weight:cweight,gender,birthday,registered, medicalCondition } =user;
    var BMI = ( (cweight/1000) / ((height/100)*(height/100)) ).toFixed(1);
    const { xyz, progress, showModal, type, closeModal, lastDates, activePart,weightHistory,BMIHistory,SMMHistory,BFMHistory,activeYear,errors, showBFMModal, closeBFMModal } = this.state;
    const { medicalConditions } = dataArray;
    let yearList ={};
    let regYear = moment.unix(registered).year();
    const differ =activeYear-regYear;
    if(differ>0){
      for(var i=0;i<=differ;i++){
        yearList[regYear+i]=regYear+i;
      }
    }
    // console.log(weightHistory);
    // console.log(SMMHistory);
    console.log('BMIHistory');
    console.log(BMIHistory);
    console.log(weightHistory);
    let lastUpdate =(weightHistory && Object.keys(weightHistory).length)?Object.keys(weightHistory)[Object.keys(weightHistory).length-1]:registered;
   
    // console.log(lastUpdate);
    // console.log(moment.unix(lastUpdate).format('DD/MM/YYYY'));
    const close = () => closeModal === false ? this.setState({ showModal: false }) : closeModal();
    const closeBFM = () => closeModal === false ? this.setState({ showBFMModal: false }) : closeBFMModal();
    this.isMob = window.innerWidth <= 768 || screen.width <= 768 || false;
    
    const grids = <div className='col-12 col-md-12 col-lg-12 part-1'>
      <div className='row part-1-inner'>
      <ChartHandler
          caption={'Weight'}
          renderer={props => <LineGrid {...props} />}
          activeYear={activeYear}
          yearList={yearList}
          propser={(period, activeYear, amount) => this.getProps({
            label: 'Weight',
            data: weightHistory,
            calcType: 'mid',
            mul: 0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:30,
            amount
          })}
          isEmpty={!(weightHistory && Object.keys(weightHistory).length)}
          emptyClass='measurement'
        />
         <ChartHandler
          caption={'BMI'}
          activeYear={activeYear}
          yearList={yearList}
          renderer={props => <LineGrid {...props} />}
          propser={(period, activeYear, amount) => this.getProps({
            label: 'BMI',
            data: BMIHistory,
            calcType: 'mid',
            mul:0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:10,
            amount
          })}
          isEmpty={!(BMIHistory && Object.keys(BMIHistory).length)}
          emptyClass='measurement'
        />
        
        <ChartHandler
          caption={'Skeletal Muscle Mass'}
          activeYear={activeYear}
          yearList={yearList}
          renderer={props => <LineGrid {...props} />}
          propser={(period, activeYear, amount) => this.getProps({
            label: 'Skeletal Muscle Mass',
            data: SMMHistory,
            calcType: 'mid',
            mul: 0.01,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:10,
            amount
          })}
          isEmpty={!(SMMHistory && Object.keys(SMMHistory).length)}
          emptyClass='bodymass'
        />
        <ChartHandler
          caption={'Percentage Body Fat'}
          activeYear={activeYear}
          yearList={yearList}
          renderer={props => <LineGrid {...props} />}
          propser={(period, activeYear, amount) => this.getProps({
            label: 'Percentage Body Fat',
            data: BFMHistory,
            calcType: 'mid',
            mul: 0.01,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:10,
            amount
          })}
          isEmpty={!(BFMHistory && Object.keys(BFMHistory).length)}
          emptyClass='bodymass'
        />
        
      </div>
    </div>;
    let age = this.calculateAge(birthday);
    // let bodyFatMass = (gender&& BMI &&age )?this.calculateBodyFatMass(gender,BMI,age):null;
    let bodyFatMass =(BFMHistory && Object.keys(BFMHistory).length)?BFMHistory[Object.keys(BFMHistory)[Object.keys(BFMHistory).length-1]]/100:null;
    let idealBodyWeight = (gender&& height )? this.calculateIdealBodyWeight(gender,height):null;
    // console.log(BFMHistory);
    let medical=(medicalCondition != undefined && medicalCondition.length >0 && medicalConditions != undefined)?medicalCondition.map(el => medicalConditions[el]).join(' , '):'None';
    return (
      <div className={`main-holder wrap-progress-page  ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.BodyAnalysisRef = el)}>
        <HeadLine
          title='Body Analysis'
          desc=''
        />
        <div className='container-fluid sub-details'>
          <div className='row progress-page-inner grid'>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col'>
             <p><strong>Name</strong></p>
             <p>{firstName} {lastName}</p>
          </div>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col border-l-r'>
             <p><strong>Height</strong></p>
             <p>{height} cm</p>
          </div>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col border-l-r'>
             <p><strong>Age</strong></p>
             <p>{age}</p>
          </div>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col gender'>
             <p><strong>Gender</strong></p>
             <p >{gender}</p>
          </div>
          </div>
        </div>
        <div className='container-fluid sub-details'>
          <div className='row progress-page-inner grid grid-3'>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col'>
             <p><strong>Current Weight</strong></p>
             <p>{cweight/1000}</p>
             <p>Last Update At : {moment.unix(lastUpdate).format('DD/MM/YYYY')}</p>
          </div>
          <div className='col-12 col-md-3 col-lg-3 pro-page-col border-l-r'>
             <p><strong>BMI</strong></p>
             <p>{BMI}</p>
             <p>{(BMI>=18.5&&BMI<=24.9)?'Normal':'Over'}</p>
          </div>
          <div className='col-12 col-md-2 col-lg-2 pro-page-col'>
             <p><strong>Medical Conditions</strong></p>
             <p>{medical}</p>
          </div>
          <div className='col-12 col-md-2 col-lg-2 pro-page-col border-l-r'>
             <p><strong>Body Fat Mass &nbsp; <a className="pointer" title='Add/Edit BFM & SMM' onClick={() => { this.setState({showBFMModal: true})} }><img src={Edit} alt="" /></a></strong></p>
             <p className='body-mass-text'>{bodyFatMass} </p>
             {bodyFatMass&&<p>{this.getBodyFatMassText(age,bodyFatMass,gender)}</p>}
          </div>
         
          <div className='col-12 col-md-2 col-lg-2 pro-page-col last-col'>
             <p><strong>Ideal Body Weight</strong></p>
             <p>{idealBodyWeight}</p>
          </div>
          </div>
        </div>
        <div className='container-fluid regular-page h-100 progress-page'>
          <div className='row progress-page-inner'>
             <Fragment> <div className='col-12 mr-auto'> <PaperButton
              className='text-center place-order button-regular transition progress-message mr-auto' onClick={() => this.setState({ showModal: true })}
            >
             Measurement Tracking
            </PaperButton></div>{grids}</Fragment>
          </div>
        </div>
        {/* <div className='container-fluid'>
        <div className='row progress-page-inner'>
        <div className='col-12'>
           <h2>Measurments</h2>
          
        </div>
        </div>
        </div> */}
        <ModalWrapper md={4} close={closeBFM} show={showBFMModal} headLine={'BFM & SMM'} className={'some-class progress-modal'}>
        { <BodyAnalysisForm locale={locale} regDate={regDate} BFM={ BFMHistory } SMM={ SMMHistory } errors={errors} save={this.saveBodyAna} />}
        </ModalWrapper>
        <ModalWrapper md={4} close={close} show={showModal} headLine={'Measurements'} className={'some-class progress-modal'}>
        
        { <ModalForm locale={locale} regDate={regDate} progress={progress} weight={weightHistory} errors={errors} save={this.saveProgress} />}
        </ModalWrapper>
      </div>
    );
  }
}

BodyAnalysis.propTypes = {
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  hasPlan: PropTypes.bool.isRequired,
  regDate: PropTypes.number.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  clearMealPlan: PropTypes.func.isRequired,
  clearPlanParams: PropTypes.func.isRequired,
  remainDeliveries: PropTypes.array,
  fitbit_token: PropTypes.bool,
  cc: PropTypes.bool
};

const props = state => {
  const { language, registered: regDate, fitbit_token, hasPlan, cc } = state.user;
  return {
    user : state.user,
    dataArray: state.dataArray,
    planParams: state.planParams,
    mealPlan: state.mealPlan,
    remainDeliveries: state.remainDeliveries,
    locale: (language || 'en-US').substr(-2),
    regDate,
    fitbit_token,
    hasPlan,
    cc
  };
};

const actions = dispatch => ({
  setMealPlan: obj => dispatch(setMealPlan(obj)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  clearMealPlan: () => dispatch(clearMealPlan()),
  clearPlanParams: () => dispatch(clearPlanParams())
});

export default socketConnect(connect(props, actions)(BodyAnalysis));
