import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Day extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  render() {
    const { date, mealPlan, typeList, planParams } = this.props;
    const types = planParams['mealType'] || [];
    const unix = date.unix();
    const plan = mealPlan[unix] || {};
    let filled = 0;
    const type = Object.keys(plan).length
      ? <div className='col-12 selections'>
        <p>
          {types.map(typeId => {
            const chosen = !!(plan && plan[typeId]);
            filled += +chosen;
            const typeTitle = typeList && typeList[typeId] && typeList[typeId].title;
            return (
              <span key={typeId} className={chosen ? 'selected' : 'not-selected'}>{typeTitle}</span>
            );
          })}
        </p>
      </div>
      : <div className='col-12 selections'>no food selected</div>;
    return (
      <div
        className={`day-menu transition row ${filled === Object.keys(types).length ? 'filled' : ''}`}
        onClick={() => { Object.keys(plan).length && this.props.goMenu('showOrder', unix); }}
      >
        <div className='col-2 date'>
          <p className='m-0'>{date.date()}</p>
          <p className='m-0'>{date.format('MMM')}</p>
        </div>
        <div className='col-10'>
          <div className='row'>
            <div className='col-12'><h2>{date.format('dddd')}</h2></div>
            {type}
          </div>
        </div>
      </div>
    );
  }
}

Day.propTypes = {
  typeList: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  date: PropTypes.object,
  goMenu: PropTypes.func
};

const mapStateToProps = state => ({
  mealPlan: state.mealPlan,
  planParams: state.planParams,
  typeList: state.dataArray.typeList
});

export default connect(mapStateToProps)(Day);
