import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { setModal, clearModal } from 'store/modal';
import sendImage from 'handler/sendImage';
import { spin, stop } from 'store/spinner';

import Before from 'assets/dashboard/img/my-progress-1.jpg';
import After from 'assets/dashboard/img/my-progress-2.jpg';
import Edit from 'assets/dashboard/img/edit.svg';
import EditWhite from 'assets/dashboard/img/edit-white.svg';

class MyProgress extends Component {

	constructor(props) {
	    super(props);
	    
	    this.state = { 
	      	enableSaveProgress: false,
	        beforePic: Before,
      		afterPic: After,
      		history: [],
      		file: null,
      		file2: null,
      		reader: null,
	    };

	    this.getProgressImgs();
  	}

  	componentWillMount() {
	    this.props.socket.on('profile', this.listener);
	}

	componentWillUnmount() {
	    this.props.socket.removeListener('profile', this.listener);
	}

  	listener = action => {
      switch (action.type) {
        case 'getHistoryOk':
          const {stop} = this.props;
  		  stop();
          this.setState({
            history: action.data.history
          }, function(){
          	this.progressHistory();
          });
        break;
        case 'getProgressImgsOk':
          this.setState({beforePic: action.data.before, afterPic: action.data.after});
          console.log(this.state);
        break;
        case 'getErr':
          console.log(action.data.errors);
        break;
      }
    
  	};

  	getHistory = () => {
  		const {spin} = this.props;
  		spin();
	    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
	        'subtype': 'getHistory', 
	        'userId': this.props.user.user_id,
	    } });
  	}

  	getProgressImgs = () => this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: this.props.user.user_id, 'subtype': 'getProgressImgs' } });

	progressHistory() { 
		const {setModal} = this.props;
		const {history} = this.state;
		var beforePic = Before;
		var afterPic = After;
		var rows = [];
		Object.keys(history).map( (time, idx) => {
			var date = moment.unix(time).format('D MMM, YYYY');
			console.log(date);
			console.log(history[time]);
			Object.keys(history[time]).map( (type, idx) => {
				if (type == '1') {
					beforePic = history[time][type];
				} else {
					afterPic = history[time][type];
				}
			});
			rows.push(
					<div className="progress-block ">
						<span className="date">{date}</span>
						<div className="row">
						<div className="before col-md-6">
							<img src={beforePic} />
						</div>
						<div className="after col-md-6">
							<img src={afterPic} />
						</div>
						</div>
					</div>
			);
		});
		setModal({ headLine: 'Progress History', message: (
			<div>
				{rows}
			</div>
		)});
	}

	 _handleImageChange(e, type) {
	    e.preventDefault();

	    let reader = new FileReader();
	    let file = e.target.files[0];
	    const {setModal} = this.props;

	    const allowed_types = [
	      'image/png', //png
	      'image/jpeg' //jpeg, jpg
	    ];

	    if (allowed_types.indexOf(file.type) != -1) {
	      if (file.size < 250000) {
	        reader.onloadend = () => {
	          console.log(file);

	          switch(type) {
	            case 'before':
	              this.setState({
	                beforePic: reader.result,
	                file: file,
	                enableSaveProgress: true,
	              });
	              console.log(this.state);
	            break;

	            case 'after':
	              this.setState({
	                afterPic: reader.result,
	                file2: file,
	                enableSaveProgress: true,
	              });
	            break;
	          }
	          
	          //console.log(this.state);
	        }
	      } else {
	      	setModal({ headLine: 'File Warning', message: 'A big File Size, please use 250 kb maximum' });
	      }
	    } else {
	    	setModal({ headLine: 'File Warning', message: 'Not Allowed File Type, Use Image Please' });
	    }

	    reader.readAsDataURL(file);
  	}

	saveProgress(e) {
		e.preventDefault();
	    const {file, file2} = this.state;
	    const {getImages} = this.props;
	    if (file) {
	    	this.sendProgress(file, 'before');
		}

		if (file2) {
			this.sendProgress(file2, 'after');
		}
	    
	    this.setState({
	    	enableSaveProgress: false,
	    });
  	}

  	sendProgress(file, type) {
  		//const {userId} = this.props;
	    const {setModal} = this.props;
	    const allowed_types = [
	      'image/png', //doc
	      'image/jpeg' //jpeg, jpg
	    ];

  		if (allowed_types.indexOf(file.type) != -1) {
	      		if (file.size < 250000) {
			        var modelName = 'Files';
			        var attribute = 'thefile';
			        const dataForm = new FormData();
			        dataForm.append('access_token', this.props.user.token);
			       // dataForm.append('user_id', userId);
			        dataForm.append('name', file.name);
			        dataForm.append('size', file.size);
			        dataForm.append('filetype', file.type);
			        dataForm.append('modelName', modelName);
			        dataForm.append('attribute', attribute);
			        dataForm.append('type', 'progressImage');
			        dataForm.append('thetype', type);
			        dataForm.append(`${modelName}[${attribute}]`, file);
			        sendImage.call(this, dataForm);
	      		} else {
	      			setModal({ headLine: 'File Warning', message: 'A big File Size, please use 250 kb maximum' });
	      		}
	    } else {
	    	setModal({ headLine: 'File Warning', message: 'Not Allowed File Type, Use Image Please' });
	    }
  	}

	render() {
		const {uploadHandler, photoDemo} = this.props;
		const {enableSaveProgress, beforePic, afterPic} = this.state;
		return(
			    <div className={photoDemo ? "demoview my-progress" : 'my-progress'}>
			        <h3>My Progress</h3>
			        {photoDemo && <div className="progressSoon">Coming soon...</div>}
			          <div className="row mb-2">
			            <div className="col-6">
			              <h5>14th Jan 2015</h5>
			              <div className="position-relative mx-auto photo-progress" >
			                <img src={beforePic || Before} className="progressImg" onClick={() => {uploadHandler("selectImageBefore")}} />
			                {!photoDemo && <a className="edit-photo-button" ><img className="pointer" src={EditWhite} onClick={() => {uploadHandler("selectImageBefore")}} alt="" /></a>}
			              </div>
			              <input id='selectImageBefore' hidden type="file" onChange={(e)=> this._handleImageChange(e, 'before')} />
			            </div>
			            <div className="col-6">
			              <h5>21th Jan 2019</h5>
			              <div className="position-relative mx-auto photo-progress">
			                <img src={afterPic || After} className="progressImg" onClick={() => {uploadHandler("selectImageAfter")}} />
			                {!photoDemo && <a className="edit-photo-button" ><img className="pointer" src={EditWhite} onClick={() => {uploadHandler("selectImageAfter")}} alt="" /></a>}
			              </div>
			              <input id='selectImageAfter' hidden type="file" onChange={(e)=> this._handleImageChange(e, 'after')} />
			            </div>
			          </div>
			           {enableSaveProgress && <button className="btn gold-button" onClick={ (e) => {this.saveProgress(e)} }>Save</button>}
			          {!photoDemo && <div className="mb-1 view-history-button">
			            <a className="pointer" onClick={ () => this.getHistory()}>View History</a> 
			          </div>}
			          <div className="att-document-privacy grey">*Privacy policy applies, only viewable by Nutritionist</div>
			    </div>
		)
	}
}

MyProgress.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(MyProgress));