import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HorizontalScroll extends Component {
  wheel = e => {
    if (this.props.need) {
      e.preventDefault();
      this.HorizontalScrollRef.scrollLeft += e.deltaY / 2;
    }
  };

  render() {
    const { elements, className } = this.props;
    return (
      <div className={className} onWheel={this.wheel} ref={el => (this.HorizontalScrollRef = el)}>
        {elements}
      </div>
    );
  }
}

HorizontalScroll.propTypes = {
  elements: PropTypes.array.isRequired,
  need: PropTypes.bool,
  className: PropTypes.string
};

HorizontalScroll.defaultProps = {
  className: 'd-flex custom-scrollbar horizontal-scrollbar',
  need: true
};

export default HorizontalScroll;
