import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Day from './Day';
import ToolTip from 'components/ToolTip';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      date: false
    };
  }

  componentDidMount() {
    this.props.onReady();
  }

  render() {
    const { goMenu, excludeWeekDay, period, isMob, planName, planTitle, showOnMobileBlockToggler, showOnMobileBlock, excludedDates } = this.props;
    // console.log(excludedDates);
    const date = this.state.date || this.props.date;
    const week = date
      ? Array(isMob ? 31 : 7)
        .fill(0)
        .map((el, key) => key)
        .filter(el => {
          const curDate = date.clone().add(el, 'day');
          const inRange = curDate.isBetween(period.from, period.to + 1, null, '[)');
          const excludedByWeekdays = !excludeWeekDay.includes(curDate.weekday());
          const excludedByHolidays = !excludedDates.includes(curDate.format('YYYY-MM-DD'));
          return inRange && excludedByWeekdays && excludedByHolidays;
        })
        .map(id =>
          <Day key={id} date={date.clone().add(id, 'day')} goMenu={goMenu} {...{ showOnMobileBlock, isMob, showOnMobileBlockToggler }} />
        ) : [];
    return (
      <Fragment>
        <div className={`dish-holder ${!isMob ? 'custom-scrollbar vertical-scrollbar' : ''}`} ref={el => (this.MainRef = el)}>
          <div className='offset-top' />
          <div className='head-line'>
            <ToolTip
              overlayClassName={'on-dishes'}
              tooltipDirection={'bottomLeft'}
              trigger={['click']}
              tooltipText={<div className=''>
                <div className='regular-bold hidden-on-desktop'>Your order is divided into weeks.</div>
                <div className='t-total hidden-on-desktop'><span className='green'>To switch between the weeks, use "next" and "previous" buttons</span></div>
                <div className='regular-bold'>By default, dishes are selected on the recommendation of our nutritionist.</div>
                <div className='t-total'><span className='green'>To change the selection, click on the checkbox on the appropriate dish.</span></div>
              </div>}
            >
              <h5>
                <span>?</span>
                {planTitle}, {planName}, <span className='mini'>{period.from.format('dddd, Do MMM')} - {period.to.format('dddd, Do MMM')}</span>
              </h5>
            </ToolTip>
          </div>
          {week}
        </div>
      </Fragment>
    );
  }
}

Main.propTypes = {
  planName: PropTypes.string.isRequired,
  planTitle: PropTypes.string.isRequired,
  period: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  excludeWeekDay: PropTypes.array.isRequired,
  excludedDates: PropTypes.array.isRequired,
  goMenu: PropTypes.func,
  bottomButtonClick: PropTypes.func,
  onReady: PropTypes.func,
  isMob: PropTypes.any,
  showOnMobileBlock: PropTypes.any,
  showOnMobileBlockToggler: PropTypes.func
};

export default Main;
