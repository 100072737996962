import React, { Component } from 'react';
import LogoImage from 'assets/Basiligo-Logo-Text.png';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    this.TermsRef && setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.TermsRef = el)} className={`row main-holder flex-column justify-content-center align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div id="terms-page">
        <div class="logo">
            <a href='/'><img src={LogoImage} alt="Basiligo" class="img-logo" /></a>
          </div>
          <h1>Privacy Policy</h1>
            <div className="terms-content">
            <ul>
              <li>All credit/debit card's details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.</li>
              <li>Merchant will not pass any debit/credit card details to third parties.</li>
              <li>Merchant takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, (basiligo.ae) cannot guarantee the security of any information that is disclosed online.</li>
              <li>The merchant is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.</li>
              <li>The Website Policies and Terms &amp; Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the Customers are encouraged to frequently visit these sections to be updated about the changes on the website.</li>
              <li>Modifications will be effective on the day they are posted.</li>
              <li>We treat client data according to the best practices (and laws) of data storage. Client data is stored in our highly secure data center and can be securely accessed only by the Basiligo team, which runs in your web browser. Further, while we do not sell or share it with third parties, we do, from time to time, conduct our own in-house research on anonymized client data.</li>
            </ul>
            </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
