import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { userLogin } from 'store/user';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      username: '', 
      password: '',
      referer: '',
      showPassword: false,
      errorModalMessage: '',
      close: () => this.setState({ errorModalMessage: '' }),
      xyz: false
    };
  }

  socketRegistration = action => {
    if (this.SignUpRef) {
      if (action.type === 'setOk') {
        this.props.login(action.data);
        browserHistory.push('/');
      } else if (action.type === 'setErr') {
        this.setState({ errors: action.data });
      } else if (['facebookErr', 'googleErr'].indexOf(action.type) > -1) {
        this.setState({ errorModalMessage: action.message });
      } else if (action.type === 'checkRefOk') {
        this.setState({ referer: action.data.refId });
      } else if (action.type === 'checkRefErr') {
        this.setState({
          errorModalMessage: action.message,
          close: () => {
            this.setState({ errorModalMessage: '' });
            browserHistory.push('/sign-up');
          }
        });
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('registration', this.socketRegistration);
    const { params } = this.props;
    if ('token' in params && params.token) {
      this.props.socket.emit('registration', { type: 'checkRef', data: { token: this.props.params.token } });
    }
  }

  componentWillUnmount() {
    this.props.socket.removeListener('registration', this.socketRegistration);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  handleOptionChange = (name, value) => {
    this.setState({ [name]: value });
  };

  submit(e, browserId) {
    e.preventDefault();
    const { email, password, referer } = this.state;
    this.props.socket.emit('registration', { type: 'set', data: { email, password, referer, browserId } });
  }

  submitFacebook(response, browserId) {
    if ('id' in response) {
      response.type = this.state.type;
      response.language = this.props.language;
      this.props.socket.emit('registration', { type: 'facebook', data: { ...response, browserId } });
    }
  }

  submitGoogle(response, browserId) {
    response.type = this.state.type;
    response.language = this.props.language;
    this.props.socket.emit('registration', { type: 'google', data: { ...response, browserId } });
  }

  render() {
    const { errors, errorModalMessage, email, password, xyz, close } = this.state;
    const onChange = (name, value) => this.setState({ [name]: value });
    const { settings, browserId } = this.props;
    const facebookAppId = settings.getValue('FACEBOOK_APP_ID');
    const googleAppId = settings.getValue('GOOGLE_APP_ID');
    return (
      <div ref={el => (this.SignUpRef = el)} className={`main-holder d-flex flex-column justify-content-center align-items-center main-on-mb ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='b-rounded-blue sign-up-form text-center shadowed'>
          <div className='search-title mt-1'>
            <span className='colorTitle'>Sign Up</span>
          </div>
          <form className='form'>
            <div className='sign-up-input mt-4'>
              <InputValidation
                value={email}
                name='email'
                onChange={onChange}
                errors={errors}
                placeholder='E-mail address'
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='sign-up-input mt-4'>
              <InputValidation
                type='password'
                value={password}
                name='password'
                onChange={onChange}
                errors={errors}
                placeholder='Password'
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='search-title login-text mt-3'>
            <p className='line-height-xsnormal letter-xs login-text mb-0'>
              {' By signing in or signing up, I agree to Basiligo\'s '}
              <Link to='terms' className='letter-xs'>{'Terms & Conditions'}</Link>, <Link to='/refunds' className='letter-xs'>{'Refund & Cancellation policy'}</Link>{' and '}
              <Link to='/privacy-policy' className='letter-xs'>{'Privacy Policy'}</Link>{',\n' +
            'I confirm that I am 18 years of age or older, and consent to receiving email communication.'}
            </p>
            </div>
            <div className='sign-up-input mt-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input onClick={e => this.submit(e, browserId)} type='submit' value='Create Account' className='form-button letter-sm w-100' />
            </div>
          </form>
          <div className='search-title login-text mt-3'>
            <span className='colorTitle letter-sm'>Or</span>
          </div>
          {googleAppId && <GoogleLogin
            clientId={googleAppId}
            onSuccess={response => this.submitGoogle(response, browserId)}
            buttonText='Sign up with Google'
            className='sign-up-input mt-3 sign-up-button-google d-flex align-items-center justify-content-around'
            onFailure={data => console.log(data)}
          />}
          {facebookAppId && <FacebookLogin
            appId={facebookAppId}
            autoLoad={false}
            size='small'
            callback={response => this.submitFacebook(response, browserId)}
            fields='name,email,picture,first_name,last_name'
            textButton='Sign up with Facebook'
            cssClass='sign-up-input mt-3 mb-2 sign-up-button-facebook d-flex align-items-center justify-content-around'
          />}
          
        </div>
        {errorModalMessage &&
          <Modal
            close={close}
            message={errorModalMessage}
          />
        }
      </div>
    );
  }
}

SignUp.propTypes = {
  socket: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  browserId: PropTypes.string.isRequired,
  params: PropTypes.any.isRequired,
  login: PropTypes.func.isRequired,
  settings: PropTypes.object
};

const props = state => ({
  language: state.user.language,
  browserId: state.user.browserId,
  settings: state.settings
});

function actions(dispatch) {
  return {
    login: user => dispatch(userLogin(user))
  };
}

export default socketConnect(connect(props, actions)(SignUp));
