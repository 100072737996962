import React, { Component } from 'react';
import LogoImage from 'assets/Basiligo-Logo-Text.png';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    this.TermsRef && setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.TermsRef = el)} className={`row main-holder flex-column justify-content-center align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div id="terms-page">
        <div class="logo">
            <a href='/'><img src={LogoImage} alt="Basiligo" class="img-logo" /></a>
          </div>
        <h1>Terms & Conditions</h1>
        <div className="terms-content">
<ol>
  <li>All clients who have completed their online sign up or sign up via email or with a team member are deemed to have agreed to these terms and conditions from their registration until the end of their plan and upon renewal.</li>
  <li>
    Basiligo reserves the right to refuse a client under the following cases:
        <ul>
        <li>With any medical condition or health risk such as but not limited to pregnancy, type 1 diabetes, heart disease, ulcers, colitis, inflammatory bowel and or Chron’s disease, or any other medical conditions.
        <p className="disclaimer">
      Basiligo does not intend to provide any medical advice, diagnosis or treatment. Therefore, the company, its nutritionists and its affiliates do not take any responsibility and are not liable for any negative outcome of the meal plan for the clients who are suffering with any medical conditions. Always seek the advice of your physician regarding your medical condition or any other health related matter before signing up for any of the meal plans offered.</p>
    </li>
        <li>Severe gluten allergy, celiac disease, or lactose intolerance.
        <p className="disclaimer">
      The Basiligo kitchen does not have a dedicated gluten-free or dairy free section. However, the whole team is well trained to ensure separation of gluten containing ingredients from the dishes of gluten-intolerant and celiac customers to reduce risks.</p>
    </li>
        <li>Dislike and allergic to cooked onions.</li>
        <li>Dislike or allergic to gluten, dairy and eggs together; with or without nuts and even extreme dislikes/allergies.</li>
        <li>Specific diets.
&nbsp;    <p className="disclaimer">
    Basiligo can’t accommodate specific diets that are not within the Basiligo programs.</p>
    </li>
        <li>Raw and cooked capsicums and tomatoes, depending on the rest of dislikes/allergies (all plans).</li>
        <li>Spice allergies e.g. cumin or black pepper (all plans).</li>
        <li>Vegetarian modifications to the Athlete meal plans.</li>
        <li>Gluten free modifications to the Athlete meal plans.</li>
        <li>Basiligo considers any confirmation made online over the phone, SMS, Whatsapp, and email valid and binding.</li>
        <li>Basiligo reserves the right to send emails, whatsapp messages and SMS communications to all clients unless they have been unsubscribed or requested to unsubscribe.</li>
        <li>Basiligo reserves the right to update or amend these Terms and Conditions at any time without prior notice.</li>
    </ul>
    
    </li><li><b>General Terms &amp; Conditions:</b><br/>
      <ul>
        <li>BASILIGO Restaurant LLC – Sole Proprietorship [BASILIGO] maintains BASILIGO.ae and BASILIGO.com Website ("Site"). The following are the Terms &amp; Conditions that govern use of the Site ("Terms &amp; Conditions"). By using the Site you expressly agree to be bound by these Terms &amp; Conditions and the BASILIGO.ae privacy policy and to follow these Terms &amp; Conditions and all applicable laws and regulations governing use of the Site. BASILIGO reserves the right to change these Terms &amp; Conditions at any time, effective immediately upon posting on the Site. Please check this page of the Site periodically. We will note when there are updates to the Terms &amp; Conditions at the bottom of the Terms &amp; Conditions. If you violate these Terms &amp; Conditions, BASILIGO may terminate your use of the Site, bar you from future use of the Site, and/or take appropriate legal action against you.</li>
        <li>BASILIGO may change, suspend, or discontinue any aspect of the Site at any time. BASILIGO may also, without notice or liability, impose limits on certain features and services or restrict your access to all or portions of the Site. You shall have no rights to the proprietary software and related documentation, if any, provided to you in order to access the Site. You shall have no right to directly or indirectly, own, use, loan, sell, rent, lease, license, sublicense, assign, copy, translate, modify, adapt, improve, or create any new or derivative works from, or display, distribute, perform, or in any way exploit the Site, or any of its contents (including software) in whole or in part.</li>
        <li>SITE OPERATION: United Arab Emirates is our Country of Domicile. BASILIGO controls this Site from the U.A.E. BASILIGO makes no representation that this Site is appropriate for use in other locations. If you use this Site from other locations you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any U.A.E export laws and regulations.</li>
        <li>APPLICABLE LAW. The Laws of the United Arab Emirates shall govern the use of the Site and the Terms &amp; Conditions, without regards to conflict of laws principals. All disputes arising in connection therewith shall be heard only by a court of competent jurisdiction in U.A.E.</li>
        <li>CURRENCY of TRANSACTION, the displayed price and currency selected by you is in United Arab Emirates Dirham (AED), will be the same price and currency charged to the Card and printed on the Transaction Receipt.</li>
      </ul>
    </li>
    <li><b>PURCHASES.</b><br/> BASILIGO accepts payment by cash, bank transfer, and Visa or Mastercard debit and credit cards in AED for its products and services. All online purchases are also governed by the terms and conditions of respective merchant service providers. Please review respective merchant service provider’s user agreement and privacy policy before entering any transaction. Interpretation. These Terms &amp; Conditions supersede all other written and oral communications or agreements about the subject matters discussed in these Terms &amp; Conditions &amp; Conditions. A waiver or modification of these Terms &amp; Conditions will only be effective if made in writing signed by an authorized officer of BASILIGO.</li>
    <li><b>REFUNDS.</b><br/> Refunds will be made onto the original mode of payment and will be processed within 10 to 45 days depends on the issuing bank of the credit card. Should there be a request to cancel meal plans; it will take effect in 3 business days. Client can transfer the remaining days to a third party or as an online credit to basiligo.ae online orders.
        <ul>
        <li>Cancellation refund for meal plans is only under extenuating circumstance and upon approval of Basiligo Management. A two (2) weeks processing time with twenty percent (20%) cancellation fee will apply on the meal plan retail price with all discounts forfeited. Credit card charges on any product purchased incurred by Basiligo Restaurant will be deducted from the refunded amount.</li>
        <li>Cancellation of al-a-carte delivery orders will result in no refund as the food is prepared as soon as the order is received.</li>
        <li>Cancellation of catering orders must be completed prior to 24 hours of the event. If the client cancels under 24 hours, Basiligo will charge half the total quotation.</li>
        <li>In the event of a global pandemic, Basiligo will not refund but you have the option to pause your plan valid up to one year to resume. </li>
      </ul>
    </li>
    <li><b>REPRESENTATIONS BY YOU.</b><br/> By visiting the Site, you represent, warrant and covenant that (a) you are at least 18 years old; (b) that all materials of any kind submitted by you to BASILIGO through the Site or for inclusion on the Site will not plagiarize, violate or infringe upon the rights of any third-party including trade secret, copyright, trademark, trade dress, privacy, patent, or other personal or proprietary rights. The customer using the website who are Minor /under the age of 18 shall not register as a User of the website and shall not transact on or use the website.</li>
    <li><b>PERMITTED USE.</b><br/> You agree that you are only authorized to visit, view and to retain a copy of pages of this Site for your own personal use, that you shall not duplicate, download, publish, modify or otherwise distribute the material on this Site for any purpose other than for personal use, unless otherwise specifically authorized by BASILIGO to do so. You also agree not to deep-link to the site for any purpose, unless specifically authorized by BASILIGO to do so. The content and software on this Site is the property of BASILIGO. The cardholder must retain a copy of transaction records and Merchant policies and rules.</li>
    <li><b>YOUR ACCOUNT.</b><br/> If you use BASILIGO Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your account from any devices, and you agree to accept responsibility for all activities that occur under your account or password. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this section.</li>
    <li><b>NO COMMERCIAL USE.</b><br/> This Site may not be used by you for any commercial purposes such as to conduct sales of merchandise or services of any kind. You must obtain BASILIGO‘s prior written consent to make commercial offers of any kind on the Site, whether by advertising, solicitations, links, or any other form of communication. BASILIGO will investigate and take appropriate legal action against anyone who violates this provision, including without limitation, removing the offending communication from the Site and barring such violators from use of the Site.</li>
    <li> <b>LINKS AND SEARCH RESULTS.</b><br/> The Site may automatically produce search results that reference and/or link to third party sites throughout the World Wide Web. BASILIGO has no control over these sites or the content within them. BASILIGO does not guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or inoffensive. BASILIGO does not endorse the content of any third party site, nor does it make any representation or warranty about these sites, including that they will not contain viruses or otherwise impact your computer. By using the Site to search for or link to another site, you agree and understand that you may not make any claim against BASILIGO for any damages or losses, whatsoever, resulting from your use of the Site to obtain search results or to link to another site. If you have a problem with a link from the Site, you may notify us at&nbsp;<a href="mailto:team@basiligo.ae">team@basiligo.ae</a>.</li>
    <li><b>TRADEMARK &amp; COPYRIGHT POLICY.</b><br/> Basiligo is a trademarked logo, company, and brand. Any infringement will lead to persecution in UAE courts. BASILIGO may terminate the privileges of any user who uses this Site to unlawfully transmit copyrighted material without a license, express consent, valid defence or fair use exemption to do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or other rights of third parties.</li>

    <li><b>INTELLECTUAL PROPERTY.</b><br/> Although BASILIGO is not responsible for the content, quality or accuracy of data provided by users, compilations of such data, text, information and other materials made available to users through BASILIGO’s system. The On-line Materials are BASILIGO’s intellectual property, and are protected by U.S., UAE and international intellectual property laws. The On-line Materials may not be copied or redistributed either in whole or in part without prior written consent of BASILIGO, except as expressly and specifically permitted under these Terms &amp; Conditions.
      <ul>
        <li>The On-line Materials are and will remain the exclusive property of BASILIGO. All rights, titles and interests in and to the On-line Materials will be and remain vested solely in BASILIGO. Under no circumstances will you have any right, directly or indirectly, to own, use, copy, loan, sell, rent, lease, license, sublicense, redistribute, assign or otherwise convey the On- line Materials, or any rights thereto, except as expressly and specifically provided in the Terms &amp; Conditions. Nothing in these Terms &amp; Conditions will convey to you any right, title or interest, except that of a license with the express rights and subject to all limitations herein. Nothing in these Terms &amp; Conditions grants you the right, directly or indirectly, to use the On-line Materials to create a product for resale or to use the On-line Materials in any way that competes with BASILIGO.</li>
        <li>You acknowledge and agree that BASILIGO will own all rights, titles and interests in and to any copy, translation, modification, adaptation, derivative work or improvement of the On- line Materials made by or for you. At BASILIGO’s request, you must execute, or obtain the execution of, any instrument that may be necessary to assign these rights, titles or interests to BASILIGO or perfect these rights, titles or interests in BASILIGO’s name.</li>
      </ul>
    </li><li><b>DISCLAIMER OF WARRANTY, LIMITATION OF DAMAGES.</b><br/> Basiligo makes no warranty or representation of any kind, either express or implied, including but not limited to warranty of title or non-infringement or implied warranty of merchantability, fitness for a particular purpose, non-infringement or other violation of rights in relation to the availability, accuracy, validity, reliability or content of these pages and/or the site. Basiligo also does not make any representation or warranty regarding the accuracy or reliability of any advice, opinion, statement or other information that is submitted, displayed or uploaded through the site by any user.
      <ul>
        <li>Basiligo shall not be liable for any direct, indirect, incidental, special or consequential damages, lost profits or for business interruption arising out of the use of or inability to use this site and/or food and beverage products, even if basiligo has been advised of the possibility of such damages. some jurisdictions do not allow exclusion of certain warranties or limitations of liability, so the above limitations or exclusions may not apply to you. The liability of Basiligo would in such case be limited to the greatest extent of liability permitted by law.</li>
        <li>You understand and agree that Basiligo nor their team members, owners, employees are not liable for any health consequences or medical conditions that arise from consuming Basiligo food and/or beverages. All food, beverage, lifestyle changes, and nutritional advice should be approved by your medical doctor prior to starting or consuming Basiligo products and/or services. You understand and agree that Basiligo is not liable for any hospital fees or any other fees related to your complaints. </li>
      </ul>
    </li><li><b>VIOLATION OF TERMS &amp; CONDITIONS.</b><br/> You understand and agree that in BASILIGO’s sole discretion, and without prior notice, BASILIGO may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized user information, if BASILIGO believes that the information you provide has violated or is inconsistent with these Terms &amp; Conditions, or violates the rights of BASILIGO, or any third party, or violates the law. You agree that monetary damages may not provide a sufficient remedy to BASILIGO for violations of these Terms &amp; Conditions and you consent to injunctive or other equitable relief for such violations. BASILIGO may release user information about you if required by law or subpoena.</li>
    <li> <b>INDEMNITY.</b><br/> You agree to indemnify and hold BASILIGO, its subsidiaries, affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of the Site, including also your use of the Site to provide a link to another site or to upload content or other information to the Site, or your breach of the Terms &amp; Conditions.</li>
    <li><b>LICENCE GRANTED TO YOU.</b><br/> By providing materials to BASILIGO, including by submitting or uploading content or materials for use on the Site you represent and warrant that you or the owner of all rights to such content or materials has expressly granted BASILIGO an irrevocable world-wide right in all languages and in perpetuity to use and exploit all or any part of the content and materials provided by you. BASILIGO may publish and distribute any such submitted content or materials at its sole discretion by any method now existing or later developed. You agree that you shall waive all claims and have no recourse against BASILIGO for any alleged or actual infringement or misappropriation of any proprietary rights in any communication, content or material submitted to BASILIGO. Any communication or materials you send to BASILIGO will be treated as non- confidential and non-proprietary and may be disseminated or used by BASILIGO for any purpose, including, but not limited to, developing, creating, manufacturing or marketing products or services.</li>
    <li><b>ADVERTISING.</b><br/> The Site may contain advertisements and/or sponsorships. The advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. BASILIGO is not responsible for the acts or omissions of any advertiser or sponsor.</li>
    <li><b>SEVERABILITY.</b><br/> If any provision of the Terms &amp; Conditions is held to be invalid, void or unenforceable, the remaining provisions shall nevertheless continue in full force and effect. Headings &amp; section titles in this Agreement are for convenience and do not define, limit, or extend any provision of this Agreement.</li>
    <li><b>PRIVACY POLICY.</b><br/>
      <ul>
        <li>All credit/debit card's details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.</li>
        <li>Merchant will not pass any debit/credit card details to third parties.</li>
        <li>Merchant takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, (basiligo.ae) cannot guarantee the security of any information that is disclosed online.</li>
        <li>The merchant is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.</li>
        <li>The Website Policies and Terms &amp; Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the Customers are encouraged to frequently visit these sections to be updated about the changes on the website.</li>
        <li>Modifications will be effective on the day they are posted.</li>
        <li>We treat client data according to the best practices (and laws) of data storage. Client data is stored in our highly secure data center and can be securely accessed only by the Basiligo team, which runs in your web browser. Further, while we do not sell or share it with third parties, we do, from time to time, conduct our own in-house research on anonymized client data.</li>
      </ul>
    </li><li><b>DELIVERY POLICY.</b><br/>
      <ul>
        <li>Products process of delivery the items/goods conditions - Basiligo reserves the right to deliver goods as per logistics capabilities and at their own timeline. Delivery fees - As of January 1st, 2019 is AED 0. Payment confirmation of their total order will be sent by email to the customer.</li>
      </ul>
  </li>
  
</ol>
</div>
</div>
      </div>
    );
  }
}

export default Terms;
