import React, { Component, Fragment } from 'react';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empty: ''
    };
  }

  render() {
    return (
      <Fragment>
        <div className='page-404'>
          <h1>404</h1>
        </div>
        <div className='empty-jar'>
          <span>Not found</span>
          <div className='jar-img' />
        </div>
      </Fragment>
    );
  }
}

export default NotFound;
